import requirement001 from '../../vue/individualWorkSupport/Requirement001.vue';
import requirement002 from '../../vue/individualWorkSupport/Requirement002.vue';
import requirement004 from '../../vue/individualWorkSupport/Requirement004.vue';
import requirement007 from '../../vue/individualWorkSupport/Requirement007.vue';
import requirement009 from '../../vue/individualWorkSupport/Requirement009.vue';
import requirement012 from '../../vue/individualWorkSupport/Requirement012.vue';
import requirement013 from '../../vue/individualWorkSupport/Requirement013.vue';
import requirement017 from '../../vue/individualWorkSupport/Requirement017.vue';
import requirement020 from '../../vue/individualWorkSupport/Requirement020.vue';
import requirement021 from '../../vue/individualWorkSupport/Requirement021.vue';
import requirement022 from '../../vue/individualWorkSupport/Requirement022.vue';
import requirement023 from '../../vue/individualWorkSupport/Requirement023.vue';
import requirement024 from '../../vue/individualWorkSupport/Requirement024.vue';
import requirement025 from '../../vue/individualWorkSupport/Requirement025.vue';
import requirement026 from '../../vue/individualWorkSupport/Requirement026.vue';
import requirement031 from '../../vue/individualWorkSupport/Requirement031.vue';
import requirement034 from '../../vue/individualWorkSupport/Requirement034.vue';
import requirement035 from '../../vue/individualWorkSupport/Requirement035.vue';
import requirement036 from '../../vue/individualWorkSupport/Requirement036.vue';
import requirement037 from '../../vue/individualWorkSupport/Requirement037.vue';
import requirement038 from '../../vue/individualWorkSupport/Requirement038.vue';
import requirement039 from '../../vue/individualWorkSupport/Requirement039.vue';
import requirement040 from '../../vue/individualWorkSupport/Requirement040.vue';
import requirement046 from '../../vue/individualWorkSupport/Requirement046.vue';
import requirement047 from '../../vue/individualWorkSupport/Requirement047.vue';
import requirement048 from '../../vue/individualWorkSupport/Requirement048.vue';
import requirement049 from '../../vue/individualWorkSupport/Requirement049.vue';
import requirement050 from '../../vue/individualWorkSupport/Requirement050.vue';
import requirement051 from '../../vue/individualWorkSupport/Requirement051.vue';
import requirement052 from '../../vue/individualWorkSupport/Requirement052.vue';
import requirement053 from '../../vue/individualWorkSupport/Requirement053.vue';
import requirement055 from '../../vue/individualWorkSupport/Requirement055.vue';
import requirement056 from '../../vue/individualWorkSupport/Requirement056.vue';
import requirement057 from '../../vue/individualWorkSupport/Requirement057.vue';
import requirement058 from '../../vue/individualWorkSupport/Requirement058.vue';
import requirement060 from '../../vue/individualWorkSupport/Requirement060.vue';
import requirement061 from '../../vue/individualWorkSupport/Requirement061.vue';
import requirement062 from '../../vue/individualWorkSupport/Requirement062.vue';
import requirement063 from '../../vue/individualWorkSupport/Requirement063.vue';
import requirement066 from '../../vue/individualWorkSupport/Requirement066.vue';
import requirement067 from '../../vue/individualWorkSupport/Requirement067.vue';
import requirement068 from '../../vue/individualWorkSupport/Requirement068.vue';
import requirement069 from '../../vue/individualWorkSupport/Requirement069.vue';
import requirement070 from '../../vue/individualWorkSupport/Requirement070.vue';
import requirement071 from '../../vue/individualWorkSupport/Requirement071.vue';
import requirement072 from '../../vue/individualWorkSupport/Requirement072.vue';
import requirement073 from '../../vue/individualWorkSupport/Requirement073.vue';
import requirement074 from '../../vue/individualWorkSupport/Requirement074.vue';
import requirement075 from '../../vue/individualWorkSupport/Requirement075.vue';
import requirement076 from '../../vue/individualWorkSupport/Requirement076.vue';
import requirement077 from '../../vue/individualWorkSupport/Requirement077.vue';
import requirement078 from '../../vue/individualWorkSupport/Requirement078.vue';
import requirement079 from '../../vue/individualWorkSupport/Requirement079.vue';
import requirement080 from '../../vue/individualWorkSupport/Requirement080.vue';
import requirement081 from '../../vue/individualWorkSupport/Requirement081.vue';
import requirement082 from '../../vue/individualWorkSupport/Requirement082.vue';
import requirement083 from '../../vue/individualWorkSupport/Requirement083.vue';
import requirement085 from '../../vue/individualWorkSupport/Requirement085.vue';
import requirement086 from '../../vue/individualWorkSupport/Requirement086.vue';
import requirement087 from '../../vue/individualWorkSupport/Requirement087.vue';
import requirement088 from '../../vue/individualWorkSupport/Requirement088.vue';
import requirement089 from '../../vue/individualWorkSupport/Requirement089.vue';
import requirement090 from '../../vue/individualWorkSupport/Requirement090.vue';
import requirement091 from '../../vue/individualWorkSupport/Requirement091.vue';
import requirement093 from '../../vue/individualWorkSupport/Requirement093.vue';
import requirement094 from '../../vue/individualWorkSupport/Requirement094.vue';
import requirement095 from '../../vue/individualWorkSupport/Requirement095.vue';
import requirement096 from '../../vue/individualWorkSupport/Requirement096.vue';
import requirement097 from '../../vue/individualWorkSupport/Requirement097.vue';
import requirement098 from '../../vue/individualWorkSupport/Requirement098.vue';
import requirement099 from '../../vue/individualWorkSupport/Requirement099.vue';
import requirement100 from '../../vue/individualWorkSupport/Requirement100.vue';
import requirement101 from '../../vue/individualWorkSupport/Requirement101.vue';
import requirement102 from '../../vue/individualWorkSupport/Requirement102.vue';
import requirement104 from '../../vue/individualWorkSupport/Requirement104.vue';
import requirement107 from '../../vue/individualWorkSupport/Requirement107.vue';
import requirement108 from '../../vue/individualWorkSupport/Requirement108.vue';
import requirement109 from '../../vue/individualWorkSupport/Requirement109.vue';
import requirement110 from '../../vue/individualWorkSupport/Requirement110.vue';
import requirement111 from '../../vue/individualWorkSupport/Requirement111.vue';
import requirement112 from '../../vue/individualWorkSupport/Requirement112.vue';
import requirement113 from '../../vue/individualWorkSupport/Requirement113.vue';
import requirement114 from '../../vue/individualWorkSupport/Requirement114.vue';
import requirement115 from '../../vue/individualWorkSupport/Requirement115.vue';
import requirement116 from '../../vue/individualWorkSupport/Requirement116.vue';
import requirement117 from '../../vue/individualWorkSupport/Requirement117.vue';
import requirement118 from '../../vue/individualWorkSupport/Requirement118.vue';
import requirement119 from '../../vue/individualWorkSupport/Requirement119.vue';
import requirement120 from '../../vue/individualWorkSupport/Requirement120.vue';
import requirement121 from '../../vue/individualWorkSupport/Requirement121.vue';
import requirement122 from '../../vue/individualWorkSupport/Requirement122.vue';
import requirement123 from '../../vue/individualWorkSupport/Requirement123.vue';
import requirement124 from '../../vue/individualWorkSupport/Requirement124.vue';
import requirement126 from '../../vue/individualWorkSupport/Requirement126.vue';
import requirement127 from '../../vue/individualWorkSupport/Requirement127.vue';
import requirement128 from '../../vue/individualWorkSupport/Requirement128.vue';
import requirement130 from '../../vue/individualWorkSupport/Requirement130.vue';
import requirement131 from '../../vue/individualWorkSupport/Requirement131.vue';
import requirement132 from '../../vue/individualWorkSupport/Requirement132.vue';
import requirement133 from '../../vue/individualWorkSupport/Requirement133.vue';
import requirement134 from '../../vue/individualWorkSupport/Requirement134.vue';
import requirement135 from '../../vue/individualWorkSupport/Requirement135.vue';
import requirement136 from '../../vue/individualWorkSupport/Requirement136.vue';
import requirement137 from '../../vue/individualWorkSupport/Requirement137.vue';
import requirement138 from '../../vue/individualWorkSupport/Requirement138.vue';
import requirement139 from '../../vue/individualWorkSupport/Requirement139.vue';
import requirement140 from '../../vue/individualWorkSupport/Requirement140.vue';
import requirement141 from '../../vue/individualWorkSupport/Requirement141.vue';

/**
 * components指定
 * vueをimportし、components名を設定する
 */
export const wizardComponents = {
  'requirement001': requirement001,
  'requirement002': requirement002,
  'requirement004': requirement004,
  'requirement007': requirement007,
  'requirement009': requirement009,
  'requirement012': requirement012,
  'requirement013': requirement013,
  'requirement017': requirement017,
  'requirement020': requirement020,
  'requirement021': requirement021,
  'requirement022': requirement022,
  'requirement023': requirement023,
  'requirement024': requirement024,
  'requirement025': requirement025,
  'requirement026': requirement026,
  'requirement031': requirement031,
  'requirement034': requirement034,
  'requirement035': requirement035,
  'requirement036': requirement036,
  'requirement037': requirement037,
  'requirement038': requirement038,
  'requirement039': requirement039,
  'requirement040': requirement040,
  'requirement046': requirement046,
  'requirement047': requirement047,
  'requirement048': requirement048,
  'requirement049': requirement049,
  'requirement050': requirement050,
  'requirement051': requirement051,
  'requirement052': requirement052,
  'requirement053': requirement053,
  'requirement055': requirement055,
  'requirement056': requirement056,
  'requirement057': requirement057,
  'requirement058': requirement058,
  'requirement060': requirement060,
  'requirement061': requirement061,
  'requirement062': requirement062,
  'requirement063': requirement063,
  'requirement066': requirement066,
  'requirement067': requirement067,
  'requirement068': requirement068,
  'requirement069': requirement069,
  'requirement070': requirement070,
  'requirement071': requirement071,
  'requirement072': requirement072,
  'requirement073': requirement073,
  'requirement074': requirement074,
  'requirement075': requirement075,
  'requirement076': requirement076,
  'requirement077': requirement077,
  'requirement078': requirement078,
  'requirement079': requirement079,
  'requirement080': requirement080,
  'requirement081': requirement081,
  'requirement082': requirement082,
  'requirement083': requirement083,
  'requirement085': requirement085,
  'requirement086': requirement086,
  'requirement087': requirement087,
  'requirement088': requirement088,
  'requirement089': requirement089,
  'requirement090': requirement090,
  'requirement091': requirement091,
  'requirement093': requirement093,
  'requirement094': requirement094,
  'requirement095': requirement095,
  'requirement096': requirement096,
  'requirement097': requirement097,
  'requirement098': requirement098,
  'requirement099': requirement099,
  'requirement100': requirement100,
  'requirement101': requirement101,
  'requirement102': requirement102,
  'requirement104': requirement104,
  'requirement107': requirement107,
  'requirement108': requirement108,
  'requirement109': requirement109,
  'requirement110': requirement110,
  'requirement111': requirement111,
  'requirement112': requirement112,
  'requirement113': requirement113,
  'requirement114': requirement114,
  'requirement115': requirement115,
  'requirement116': requirement116,
  'requirement117': requirement117,
  'requirement118': requirement118,
  'requirement119': requirement119,
  'requirement120': requirement120,
  'requirement121': requirement121,
  'requirement122': requirement122,
  'requirement123': requirement123,
  'requirement124': requirement124,
  'requirement126': requirement126,
  'requirement127': requirement127,
  'requirement128': requirement128,
  'requirement130': requirement130,
  'requirement131': requirement131,
  'requirement132': requirement132,
  'requirement133': requirement133,
  'requirement134': requirement134,
  'requirement135': requirement135,
  'requirement136': requirement136,
  'requirement137': requirement137,
  'requirement138': requirement138,
  'requirement139': requirement139,
  'requirement140': requirement140,
  'requirement141': requirement141,
};