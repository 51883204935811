<b-modal v-model="modal_real_world_data_vsoc" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'wait'">
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'wait'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 許諾確認 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="box-margin-right real-world-data-message">
                <strong v-html="$t('message.message_real_world_data_vsoc')"></strong>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行中 -->
        <template v-if="phase === 'wait'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right real-world-data-message">
                  <strong v-html="$t('message.label_vehicle_connect')"></strong>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 結果確認（成功） -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right real-world-data-message">
                  <strong v-html="$t('message.message_real_world_data_vsoc_success')"></strong>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 結果確認（失敗） -->
        <template v-if="phase === 'failed'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right real-world-data-message">
                  <strong v-html="$t('message.message_real_world_data_vsoc_failed')"></strong>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="phase === 'start'">
        <b-button size="sm" variant="outline-primary" @click="closeWizard" :disabled="isLoading" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="executeProcess" :disabled="isLoading" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'success' || phase === 'failed'">
        <b-button size="sm" variant="primary" @click="closeWizard" :disabled="isLoading" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>