import config from "../config";
import api from '@/common/api';
import handler from './apiResultValidator';
import alert from '../alert';
import individualDiagnose from './individualDiagnose';
import util from './utils';

/**
 * POSTのレスポンスのハンドリングを行う
 * @param self 呼び出し元のthis
 * @param response GetのResponse
 * @returns 成功時のみレスポンスを返す（失敗は null を返す）
 */
const postResponseHandler = (self, response) => {
  return new Promise((resolve) => {
    handler.validate(handler.validateTypes.all, response, self, null, () => {
      // 正常レスポンスの場合はそのまま返す
      return resolve(response);
    },
      (result) => {
        // 不正応答コールバックが呼び出された場合も処理終了
        const cause = result.causeType;
        const behaviorType = result.behaviorType;
        // セッションが不正・ネットワークエラー・CCエラーならログアウト処理を行う
        if (cause === handler.causeTypes.networkError ||
          cause === handler.causeTypes.invalidSessionToken ||
          cause === handler.causeTypes.clientConnectorNotAliveError) {
          self.$nextTick(() => {
            alert.showWarning(self, null, result.message, () => {
              if (behaviorType === handler.behaviorTypes.logout) {
                self.logout(self);
              } else if (behaviorType === handler.behaviorTypes.resetSession) {
                self.resetSession(self);
              }
            });
          });
        } else if (cause === handler.causeTypes.permissionError) {
          self.$nextTick(() => {
            // ユーザー権限エラーの場合、エラーダイアログを表示しログアウトする
            alert.showError(self, null, result.message, () => {
              self.logout(self);
            });
          });
        }
        // エラーの場合は、null を返す
        return resolve(null);
      }, null, false);
  });
};

/**
 * 作業サポートの各 requirement 関連のポーリング処理共通関数
 * - ※パラメータの詳細は、jsdoc を参照すること（individualDiagnose.js の上部にある各型の説明で Callback の詳細も記載している）
 * @param {object} self 呼び出し元の this を指定する
 * @param {string} netAppId クラウドコネクター ID
 * @param {string} systemId システム ID
 * @param {string} workSupportId 作業サポート（不変） ID
 * @param {object} requestBody POST リクエストの body
 * @param {import('./individualDiagnose').CallbackError} errorCallback エラー発生のコールバック関数
 * @param {import('./individualDiagnose').CallbackAPIResponseError} checkPostApiErrorResultCallback POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {import('./individualDiagnose').CallbackAPIResponseError} checkGetApiErrorResultCallback GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {number} pollingRetryCount Option: ポーリングのリトライ回数指定（指定なしの場合は、作業サポート独自の環境変数の値を使用）
 * @param {number} pollingRetryInterval Option: ポーリングのリトライ待機時間指定（指定なしの場合は、作業サポート独自の環境変数の値を使用）
 * @param {boolean} pollingStopTimeOutFlg Option: ポーリング時のタイムアウト停止フラグ（指定なしの場合は、タイムアウト停止する（true））
 * @returns {object} 最終的な GET レスポンス（エラー発生時は null になる）
 */
export async function pollingWspApi(
  self,
  netAppId,
  systemId,
  workSupportId,
  requestBody,
  errorCallback = (errorType) => { errorType; },
  checkPostApiErrorResultCallback = (result) => { result; errorCallback(config.INDIVIDUAL_API_CALL_ERROR_POST_INVALID_RESPONSE); },
  checkGetApiErrorResultCallback = (result) => { result; errorCallback(config.INDIVIDUAL_API_CALL_ERROR_GET_INVALID_RESPONSE); },
  pollingRetryCount = process.env.VUE_APP_WORK_SUPPORT_RETRY_COUNT,
  pollingRetryInterval = process.env.VUE_APP_WORK_SUPPORT_RETRY_INTERVAL,
  pollingStopTimeOutFlg = true,
) {
  return await individualDiagnose.pollingApi(
    self,
    // POST API 呼び出しのコールバック
    async () => {
      return await api.postCall(
        config.WSP,
        '/netApps/' + netAppId + '/systems/' + systemId + '/workSupports/' + workSupportId,
        requestBody
      );
    },
    // GET API 呼び出しのコールバック
    async (postApiResponse) => {
      return await api.getCall(
        config.WSP,
        '/netApps/' + netAppId + '/systems/' + systemId + '/workSupports/' + workSupportId + '/requests/' + postApiResponse.data.request_id
      );
    },
    // エラー発生のコールバック
    (errorType) => { errorCallback(errorType); },
    // POST API レスポンスチェックのコールバック
    (postApiResponse) => {
      // リクエストID が正常に返ってきたら成功とする
      return postApiResponse && postApiResponse.data && postApiResponse.data.request_id ? '' : config.INDIVIDUAL_API_CALL_ERROR_POST_INVALID_RESPONSE;
    },
    // GET API レスポンスチェックのコールバック
    (getApiResponse) => {
      let result = config.INDIVIDUAL_POLLING_RESULT_ERROR;
      if (getApiResponse && getApiResponse.data && getApiResponse.data.status) {
        switch (getApiResponse.data.status) {
          case config.STATUS_WSP_COMPLETED:
          case config.STATUS_WSP_FAILED:
            // failed でも呼び出し元でハンドリングしたいケースがあるため、いずれもポーリング終了を返す
            result = config.INDIVIDUAL_POLLING_RESULT_FINISH;
            break;
          case config.STATUS_WSP_IN_PROGRESS:
            // ポーリング続行を返す
            result = config.INDIVIDUAL_POLLING_RESULT_CONTINUE;
            break;
          default:
            // それ以外の場合はエラー発生を返す（エラーコールバックが呼ばれる）
            break;
        }
      }
      return result;
    },
    // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { checkPostApiErrorResultCallback(result); },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { checkGetApiErrorResultCallback(result); },
    // ポーリングのリトライ回数指定（指定なしの場合は、作業サポート独自の環境変数の値を使用）
    pollingRetryCount,
    // ポーリングのリトライ待機時間指定（指定なしの場合は、作業サポート独自の環境変数の値を使用）
    pollingRetryInterval,
    //ポーリング時のタイムアウト停止フラグ（指定なしの場合は、タイムアウト停止する（true））
    pollingStopTimeOutFlg,
  );
}

/**
 * DID変換後の値を[項目表示名] : [DID変換した値] [単位] で返す処理を行う関数
 * data_typeによる変換処理の分岐を追加するときは、resourceMap.jsのgetSaveOrderTable()にも同じ分岐を実装すること
 * @param {object} self 呼び出し元の this を指定する
 * @param systemId systemId
 * @param didItems DID配列(readout_data)[did, data_record, unit, data_type, digits_after_decimal]
 * @param {*} isWs1 WS.1から参照
 * @returns {string} [項目表示名] : [DID変換した値] [単位]の配列を返却
 */
export async function getReadOutData(self, systemId, didItems, isWs1 = false) {
  let result = '';
  if (didItems) {
    //表示データ編集
    const data = didItems.readout_data;
    // HACK: Phase4Step2-2終了間際にESLintのルールが変更になり、ロジックは変更出来なかったため回避。
    /* eslint-disable complexity */
    data.forEach((item) => {
      //項目表示名
      let name = undefined;
      if (self.$te(`wsDid${systemId}.${item.did}`)) {
        //文言表に存在する場合は項目表示名を表示
        name = self.$t(`wsDid${systemId}.${item.did}`);
      }
      else {
        //文言表に存在しない場合はdidを表示
        name = item.did;
      }

      // WS.1の場合、'-'または文言表に存在しない場合は項目表示名を表示しない
      if (isWs1) {
        if (name === '-' || !self.$te(`wsDid${systemId}.${item.did}`)) {
          name = '';
        }
      }

      let rec = item.data_record;
      let isSizeOver = false;

      //データタイプ分岐
      switch (item.data_type) {
        case 'FLG':
          if (rec === 'FF' || rec === '00') {
            const flg = item.did + '_' + rec;
            rec = self.$t(`wsDid${systemId}.${flg}`);
          }
          break;
        case 'HEX': {
          //WS.13、WS.47の場合、画像データ判定
          if (self.workSupportId === 1301 || self.workSupportId === 4701) {
            const decPictureDidLower = parseInt("3002", 16);
            const decPictureDidUpper = parseInt("300B", 16);
            const decTargetDid = parseInt(item.did.slice(0, 4), 16);
            if (decTargetDid >= decPictureDidLower && decTargetDid <= decPictureDidUpper) {
              isSizeOver = true;
              break;
            }
          }

          let hex = item.did + '_' + util.addPadding(rec, 2).toUpperCase();

          //変換成功判定処理
          if (self.$te(`wsDid${systemId}.${hex}`)) {
            rec = self.$t(`wsDid${systemId}.${hex}`);
            break;
          }
          //変換失敗⇒defaultで再度変換
          hex = item.did + '_default';
          //変換成功
          if (self.$te(`wsDid${systemId}.${hex}`)) {
            rec = self.$t(`wsDid${systemId}.${hex}`);
          }
          //さらに失敗(datを入れ替えずデータのまま使用)
          break;
        }
        case 'YYYYMMDDhhmmss': 
        case 'PRG_YYYYMMDDhhmm': {
          // バックエンドから渡された値はGMTの文字列のため、Dateに変換
          const gmtDate = new Date(rec);

          // 日付として無効な文字列の場合Backendから帰ってきた文字列をそのまま表示する
          if (gmtDate.toString() === 'Invalid Date') break;

          // 日付として有効ならLocalTimeに変換
          const convertType = item.data_type === 'PRG_YYYYMMDDhhmm' ? 'min' : null;
          rec = rec ? util.timeConverter(gmtDate, null, convertType) : null;
          break;
        }
        // データ編集を実施しないため何もせずに抜ける
        case 'ASCII':
        case 'LatLng_DMM':
        case 'YYYYMMDDhhmm':
        case 'min2hhmm':
        case 'sec2hhmm':
        case 'sec2hhmmss':
        case 'decWithDot':
        case 'YYMMDD':
        case 'Lat_DEG':
        case 'Lng_DEG':
        case 'MFD_BCD1':
        case 'MFD_BCD2':
          break;
        // digits_after_decimal から少数点以下の桁数を調整
        case 'Value':
        case 'ACL2INC':
          if (item.data_record || item.data_record === 0) {
            if (item.digits_after_decimal !== null && !Number.isInteger(item.data_record)) {
              //小数点以下編集
              const dec = item.digits_after_decimal;
              rec = item.data_record.toFixed(dec);
            }
            else {
              //そのまま表示
              rec = item.data_record;
            }
            // 単位を設定する
            rec = addUnit(self, item, rec);
          }
          break;
        // decExStrの場合、文字列への変換を行い、引き当てが存在しなかった場合はValueを算出する
        case 'decExStr': {
          const hex = item.did + '_' + util.addPadding(rec, 2).toUpperCase();
          //変換成功判定処理
          if (self.$te(`wsDid${systemId}.${hex}`)) {
            rec = self.$t(`wsDid${systemId}.${hex}`);
            break;
          }
          // 文字列に変換できなかった場合、数値に変換する
          rec = util.convertHexOfDidToInt(item, hex);
          // 単位を設定する
          rec = addUnit(self, item, rec);
          break;
        }
      }
      result += name;
      if (!isSizeOver) {
        if (name !== '') {
          result += ':';
        }
        result += rec;
      }
      result += '\n';
    });
  }
  return result;
}

/**
 * data_type:Value、ACL2INC、decExStrにて、単位の設定を行う関数
 * @param {object} self 呼び出し元の this を指定する 
 * @param {object} item 対象のレスポンスデータ
 * @param {string | number} rec data_recordの変換結果 
 * @returns {string | number} 変換結果
 */
function addUnit(self, item, rec) {
  let retVal = rec;
  // WS.97、WS.98、WS.107、WS.108の場合は単位不要の為処理を行わない
  if (item.unit && self.workSupportId !== 9701 && self.workSupportId !== 9801 && self.workSupportId !== 10701 && self.workSupportId !== 10801) {
    //単位あり
    retVal += self.$t(`didUnit.${item.unit}`);
  }
  return retVal;
}

/**
 * 作業サポートで認証番号の照合を行うための共通関数
 *
 * @param {object} self 呼び出し元の this を指定する
 * @param {string} requirementId 要求 ID
 * @param {string} systemId システム ID
 * @param {object} requestBody POST リクエストの body
 * @returns {boolean} 最終的なレスポンス（エラー発生時は null になる）
 */
export async function postVerifyPinNumber(self, requirementId, systemId, body = {}) {
  const response = await api.postCall(
    config.WSP,
    '/verifyPinNumber/'+ requirementId + '/' + systemId,
    body
  );
  return await postResponseHandler(self, response);
}

export default {
  pollingWspApi,
  getReadOutData,
  postVerifyPinNumber,
};
