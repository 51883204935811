<div class="container-fluid ml-n2">
  <processing-view :processing="processing"></processing-view>

  <!-- Get Registration History (type3) confirm to start sequence -->
  <b-modal v-model="getregistrationhistorytype3confirmstartsequence" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div class="text-left m-4">
        <div>
          <b>{{$t('imb.get_the_registration_history_confirm')}}</b>
        </div>
        <div>
          <b>{{$t('imb.common_press_ok_or_cancel')}}</b>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button variant="outline-primary"
          @click="cancelButtonClick()">
          {{ $t('message.common_button_cancel')}}
        </b-button>
        <b-button variant="primary" v-on:click="onGetRegistrationHistoryOkButtonClick()" class="ml-4">
          {{ $t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Get Registration History (type3) confirm to start reading history -->
  <b-modal v-model="getregistrationhistorytype3confirmstartreadhistory" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.get_the_registration_history_display_previous_reg_history')">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          {{$t('imb.common_press_ok_or_cancel')}}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary" v-on:click="onShowHistoryCancelButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_cancel')}}
        </b-button>
        <b-button size="sm" variant="primary" v-on:click="onRegHistoryOkBtnClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Get Registration History (type 3) error response -->
  <b-modal v-model="getregistrationhistorytype3failure" size="lg" header-bg-variant="danger" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_error')}}</div>
    </template>
    <template v-slot:default>
      <div v-if="!isCausesAndActionDialog(imbStatusInfo.error_code)">
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div v-else>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
          <div class="col-9">{{ imbStatusInfo.error_code }}</div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_cause_message"></div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_action_message"></div>
        </div>
      </div>
      <div>
        <div class="text-center w-100 mt-2">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onErrorOkButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Get Registration History (type 3) Display Registration History -->
  <b-modal v-model="getregistrationhistorytype3displayregstrationhistory" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('imb.get_the_registration_history_regisration_history')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
        <div class="text-center w-100" v-html="imbStatusInfo.error_messages">
        </div>
        <div class="text-center w-100">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onFinishOKButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</div>