<div class="container-fluid ml-n2">
  <div class="button-display text-right mt-3">
    <b-button v-if="reprogramTableTypes.includes('update') && displayReproTypeMode === 'campaign' && !isTestMode"
      variant="primary" v-on:click="showMerchantEcuList" size="sm">
      {{ $t('message.button_vehicle_system_show_distributor_ecu') }}</b-button>
    <span class="ml-3"></span>
    <b-button v-if="reprogramTableTypes.includes('restore') && displayReproTypeMode === 'campaign' && !isTestMode"
      variant="primary" v-on:click="showRestoreEcuList" size="sm">
      {{ $t('message.button_vehicle_system_show_distributor_ecu') }}</b-button>
    <span class="ml-3"></span>
    <div v-if="isVisible">
      <div v-if='isPublished'>
        <div>
          <b-button variant="primary" v-on:click="switchTable" size="sm">
            {{ $t('message.button_vehicle_system_show_unpublished') }}</b-button>
        </div>
        <div class="mt-2">
          <strong>{{ $t('message.label_vehicle_published') }}</strong>
        </div>
      </div>
      <div v-else>
        <div>
          <b-button variant="primary" v-on:click="switchTable" size="sm">
            {{ $t('message.button_vehicle_system_show_published') }}</b-button>
        </div>
        <div class="mt-2">
          <strong>{{ $t('message.label_vehicle_unpublished') }}</strong>
        </div>
      </div>
    </div>
  </div>

  <div class="text-left">
    <cstm-table useSort currentSortkey="installationResource" initSortDir="asc" v-show="isPublished" rowSelect
      :items="publishedTableItems" :columns="setupTableColumn()" class="mt-2 ml-2" type="simple" @rowClicked="clickRow"
      usePopup @mouseHover="onMouseHover" hasTabs>
    </cstm-table>
    <cstm-table useSort currentSortkey="installationResource" initSortDir="asc" v-show="!isPublished && isVisible"
      rowSelect :items="unpublishedTableItems" :columns="setupTableColumn()" class="mt-2 ml-2" type="simple"
      @rowClicked="clickRow" usePopup @mouseHover="onMouseHover" hasTabs>
    </cstm-table>
  </div>
  <div v-if="displayReproTypeMode !== 'campaign'" class="text-right">
    <!-- 戻るボタン -->
    <b-button variant="outline-primary" v-on:click="back()" class="ml-2 mr-2 mt-4" size="sm">
      {{ $t('message.common_button_back') }}
    </b-button>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>