<div class="maintenance-container">
  <div class="row">
    <div class="col-12 text-center">
      <p class="maintenance-message mt-5" v-html="$t('error.label_maint_run_maintenance')"></p>
      <p class="maintenance-date mt-4" v-html="msg"></p>
      <p class="mt-4" v-html="$t('error.label_maint_apologize')"></p>
      <p class="mt-4" v-html="$t('error.label_maint_update')"></p>
    </div>
    <div class="col-12 text-center mt-2 mb-3">
      <b-button variant="outline-dark" @click="reloadScreen()">{{ $t('error.button_maintenance_try_reload') }}</b-button>
    </div>
  </div>
</div>