import individualDiagnose from '../../../../share/util/individualDiagnose';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import session from '../../../../share/session';

/** 終了処理（要求） */
export async function exitLogic(self) {
  try {
    // スピナー表示
    self.isLoading = true;
    const systemId = individualDiagnose.getCurrentSystemId(self);
    const token = session.getToken();

    // 終了処理 (エラーが発生しても、何も行わない
    await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'exit',
        isFirst: self.isSpecialTransition && self.isFirstAPI // 特殊機能からの遷移かつ初回呼び出しの場合、trueを設定
      }
    );
  } finally {
    // 結果に関わらず、初回API呼び出しフラグをfalseに設定
    self.isFirstAPI = false;
    self.isLoading = false;
  }
}