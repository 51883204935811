<!-- カメラECU交換時のキャリブレーションデータ読み出し ダイアログ -->
<b-modal v-model="modal_requirement_135" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <!-- ヘッダー -->
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- データ読出し -->
      <template v-if="phase === 'executing'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 停止 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- エラー -->
      <template v-if="phase === 'executeFailed' || phase === 'savingFailed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>
  <!-- 文言表示 -->
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- データ読出し -->
        <template v-if="phase === 'executing'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_135_TXT_1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 停止 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">
                  <div>{{ $t(`${i18nWsKey}.REQ_WS_135_TXT_2`) }}</div>
                  <div>{{ $t(`${i18nWsKey}.label_save_date`,[saveDate]) }}</div>
                  <div>{{ $t(`${i18nWsKey}.label_data_id`,[saveDataId]) }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- エラー -->
        <template v-if="phase === 'executeFailed'">
          <div class="w-100">
            <div class="work-support-message">
              {{ $t(`${i18nWsKey}.REQ_WS_135_TXT_3`) }}
            </div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>
  <!-- フッター -->
  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 停止 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エラー -->
      <template v-if="phase === 'executeFailed' || phase === 'savingFailed'">
        <b-button size="sm" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>