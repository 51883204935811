import config from "../config";
import api from '@/common/api';
import errors from "@/common/nodejs/errors";

const moment = require('moment');

/**
 * イベントログの操作 ID 発行
 * @param netAppId netAppId
 */
export const createEventLogHandleId = async (netAppId, apiType=config.DIAG) => {
  // イベントログの操作 ID 発行 API の実行
  await api.postCall(
    apiType,
    '/netApps/' + netAppId + '/eventLogs/handleId', {}
  );
};

/**
 * イベントログのページビューカウント
 * @param pageName 画面名
 */
export const eventLogPageViewCount = async (pageName) => {
  const param = {
    page_name: pageName,
    date: Number(moment().format('YYYYMMDD'))  // 登録日付（YYYYMMDD）のNumber型
  };
  // イベントログのページビューカウント API の実行
  await api.postCall(
    config.DIAG,
    '/pageViewCount',
    param
  );
};

/**
 * MAC鍵機能の再IG-ON/OFF指示画面で終了ボタン押下時にイベントログを更新する
 * @param {String} netAppId netAppId
 */
export const updateMacEventLog = async (netAppId) => {
  await api.postCall(
    config.MAC,
    '/updateEventLogRecord/' + netAppId,
    { err_code: errors.MAC_KEY_END_IG_OFF_ON_ERROR }
  );
};

/**
 * イモビ機能でキャンセルボタン押下時にイベントログを更新する
 * @param {String} netAppId netAppId
 */
export const updateImbEventLogCanceled = async (netAppId) => {
  await api.postCall(
    config.IMB,
    '/updateEventLogRecord/' + netAppId,
    { err_code: errors.IMB_CANCEL_IGON_ERROR }
  );
};

export default {
  createEventLogHandleId,
  eventLogPageViewCount,
  updateMacEventLog,
  updateImbEventLogCanceled
};
