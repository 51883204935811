import individualDiagnose from '../../../../../share/util/individualDiagnose';
import individualWorkSupportUtil from '../../../../../share/util/individualWorkSupportUtil';
import session from '../../../../../share/session';
import { REQUEST_STATUS_COMPLETED } from '../../../../../share/util/api/polling';
import { showUnexpectedError, validateErrorResult } from './utils';

/**
 * 読出し前の初期処理(sessionSecurity APIを投げる)関数
 * @param {object} self this インスタンス
 */
export async function sessionSecurity(self) {
  // EUCキーの取得
  const systemId = individualDiagnose.getCurrentSystemId(self);
  const token = session.getToken();
  // 開始処理・セキュリティアクセス (要求)（バックエンドAPI_POST） Getのポーリング処理も行う
  const response = await individualWorkSupportUtil.pollingWspApi(
    self,
    self.netAppId,
    systemId,
    self.workSupportId,
    {
      id_token: token.id_token,
      type: 'sessionSecurity',
      is_special: true,
    },
    // エラー発生のコールバック
    (errorType) => { errorType; showUnexpectedError(self); },
    // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { validateErrorResult(result, self); },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { validateErrorResult(result, self); },
  );
  // 結果が正常以外だった場合、共通エラー画面へ遷移する
  if (response && response.data.status !== REQUEST_STATUS_COMPLETED) {
    showUnexpectedError(self);
  }
}