<div class="col-12">
  <div class="col-12">
    <!-- メモボタン -->
    <b-button class="ml-2" variant="primary" size="sm" @click="parentOpenMemoModal">
      {{ $t('message.button_vehicle_dtc_note_modal') }}
    </b-button>
    <!-- ファイルエクスポートボタン -->
    <b-button class="ml-2" variant="primary" size="sm" @click="csvExport">
      {{ $t('message.file_export') }}
    </b-button>
  </div>
  <div class="col-12">
    <custom-table class="mt-2 ml-2" type="simple" useSort useStripeStyle rowSelect hasTabs currentSortkey="status"
      initSortDir="asc" :items="measuredDataItems" :columns="measuredDataColumns" @rowButtonClicked="openDetailFDModal">
      <template v-slot:rowButton>{{ $t(`individual.button_fd`) }}</template>
    </custom-table>
  </div>
  <processing-view :processing="processing"></processing-view>
</div>