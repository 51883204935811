import config from '../config';
import util from './utils';

// 車両番号を２つに分解して返却する
// chassisNumber : 車両番号 xxxxx-xxxxxxx
export const chassisNumberSplit = (chassisNumber) => {
    const result = ['', ''];
    if (chassisNumber) {
        const arr = chassisNumber.split(config.VEHICLEINFO_VEHICLENUMBER_DELIMITER);
        if (arr && arr.length !== 0) {
            result[0] = arr[0];
            result[1] = arr[1];
        }
    }
    return result;
};

// リプロ対象の対象ユニット情報を作成する
// info : vehicle 情報
// targetUnits : 対象ユニット一覧情報
// region : 仕向け地情報
// recovery : 直接リプロフェーズに復帰するかどうか
export const createTargetUnitInfo = (info, recovery) => {

    const result = {
        unitId: info.vehicle.unit_id,
        fileId: info.vehicle.pfc_id,
        publishmentMode: true,
        directRecoveryToRpr: recovery,
        paneConfig: {}
    };

    return result;
};

// 登録・変更された車種情報を、キャッシュ用のオブジェクトに変更する。
// 登録・変更された情報のツリー階層を、次回の一覧表示時に開いた状態で表示するため。
// code: 開発コード
// region: 仕向け地
// vehicleModel: 車種
// classification: 型式
// annualImprovements: 区分
// yearModel: 年式
// annualClassification: 年改区分
export const createLatestInfo = (code, region, vehicleModel, classification, annualImprovements, yearModel, annualClassification) => {

    const result = {
        code: code,
        region: region,
        vehicleModel: vehicleModel,
        annualClassification: annualClassification === '' ? config.VEHICLEINFO_NOT_SET_IMPROVEMENTS_CODE : annualClassification
    };
    if (region === config.REGION_DOMESTIC)
        result.typeModel = classification + ' ' + annualImprovements;
    else
        result.typeModel = yearModel;

    return result;
};

// Get target unit display names for the language
export const getTargetUnitDisplayNames = (targetUnitList, language) => {
    const targetUnitDisplayData = {};
    targetUnitList.forEach(item => {
        const filteredList = item.display_names.filter(displayName => displayName.language === language);
        targetUnitDisplayData[item.id] = filteredList.length === 0 ? '' : filteredList[0].name;
    });
    return targetUnitDisplayData;
};

// Get vehicle model display names for the language
export const getVehicleModelDisplayNames = (vehicleModelList, language) => {
    const vehicleModelListDisplayData = {};
    vehicleModelList.forEach(item => {
        const filteredList = item.display_names.filter(displayName => displayName.language === language);
        vehicleModelListDisplayData[item.id] = filteredList.length === 0 ? '' : filteredList[0].name;
    });
    return vehicleModelListDisplayData;
};

// 車両情報取得ステータスからメッセージIDを取得する
// state : 車両情報取得時のステータス情報
// beforeMessageId : 前回設定時のメッセージID
// apiType: APIのサービス種類 repro or diag
// execType: 情報取得type(all or reacquisition_dtc)
export const getPhaseResources = (state, beforeMessageId, apiType, execType = config.INQUIRY_TYPE_ALL) => {
    let result = null;
    if (execType === config.INQUIRY_TYPE_ALL) {
        // APIの向き先がREPROかつCOMMUNICATION_UI_COMMUNICATION_COMPLETEDの場合「搭載システムと通信中…」で固定
        // APIの向き先がDIAGかつCOMMUNICATION_UI_COMMUNICATION_COMPLETEDの場合「構成情報生成中…」で固定
        if (state === config.COMMUNICATION_UI_COMMUNICATION_COMPLETED) {
            return apiType === config.REPRO ? 'message.label_vehicle_connect_systems' : 'message.label_vehicle_connect_completed';
        }
        // 診断のみの車両情報取得の場合メッセージ文言は「DTC取得中....」で固定
        if (apiType === config.DIAG) {
            return 'message.label_vehicle_connect_diag_code';
        }
    }
    switch (state) {
        case config.COMMUNICATION_UI_VIN_REQUIRED:
            result = 'message.header_vin_input_title';
            break;
        case config.COMMUNICATION_UI_VEHICLE_CGW:
        case config.COMMUNICATION_UI_CONFIRM_READ_LEGISLATED_SUPPORT_IDS:
            result = 'message.label_vehicle_connect_cgw';
            break;
        case config.COMMUNICATION_UI_VEHICLE_OBD:
        case config.COMMUNICATION_UI_VIN_COMPLETED:
        case config.COMMUNICATION_UI_CGW_CECU_NOT_COMMUNICATED:
            result = 'message.label_vehicle_connect_obd';
            break;
        case config.COMMUNICATION_UI_VEHICLE_SYSTEMS:
            if (beforeMessageId !== 'message.label_vehicle_connect_update_rxswin') {
                result = 'message.label_vehicle_connect_systems';
            }
            break;
        case config.COMMUNICATION_UI_RXSWIN_UPDATED:
            result = 'message.label_vehicle_connect_update_rxswin';
            break;
        case config.COMMUNICATION_UI_GET_DIAG_CODE:
            result = 'message.label_vehicle_connect_diag_code';
            break;
        case config.COMMUNICATION_UI_COMMUNICATION_COMPLETED:
            result = 'message.label_vehicle_connect_completed';
            break;
        default:
            result = 'message.label_vehicle_connect_completed';
            break;
    }
    return result;
};

// Get vehicle model display names for the language
export const getVehicleSystemMode = (isTestMode, isTechUser) => {
    let result = 'nomalMode';
    if (isTestMode) {
        result = 'testMode';
        return result;
    }
    if (isTechUser) {
        result = 'techMode';
        return result;
    }
    return result;
};

/**
 * 車両情報の表示編集処理
 * @param {*} vehicleInfo 車両情報
 * @param {*} vehicleModelDisplayNameList ディスプレイ表示用車両名リスト
 * @param {*} language 使用言語
 * @returns vehicleDisplayInfo
 */
export const formatVehicleInfo = (vehicleInfo, vehicleModelDisplayNameList, language) => {
    const vcl = vehicleInfo.vehicle;
    const vehicleDisplayInfo = {};
    // vin
    vehicleDisplayInfo.vin = vcl.vin.vin;
    // spec
    switch (vcl.vin.type) {
        case config.VEHICLEINFO_TYPE_VIN:
            vehicleDisplayInfo.spec = vcl.spec.model_year ? vcl.spec.model_year : '-';
            break;
        case config.VEHICLEINFO_TYPE_CHASSISNUMBER:
            vehicleDisplayInfo.spec = vcl.spec.classification ? vcl.spec.classification : '-';
            break;
        case config.VEHICLEINFO_TYPE_UN_KNOWN:
            vehicleDisplayInfo.spec = '-';
            break;
        default:
            vehicleDisplayInfo.spec = '-';
            break;
    }
    // specMode
    vehicleDisplayInfo.specMode = vcl.vin.type === 'vin' ? 'abroad' : 'domestic';
    // appliedModel
    vehicleDisplayInfo.appliedModel = (vcl.vin.type === config.VEHICLEINFO_TYPE_CHASSISNUMBER && vcl.vin.applied_model) ? vcl.vin.applied_model : '-';
    // modelDisplayName
    vehicleDisplayInfo.model = vcl.spec.model || null;
    if (vehicleDisplayInfo.model) {
        const vehicleModel = getVehicleModelDisplayNames(vehicleModelDisplayNameList, language);
        vehicleDisplayInfo.modelDisplayName = vehicleModel[vehicleDisplayInfo.model] || '-';
    } else {
        vehicleDisplayInfo.modelDisplayName = '-';
    }

    // registrationNumber
    if (vcl.vin.type === config.VEHICLEINFO_TYPE_VIN) {
        // 海外VINの場合、登録番号は画面から非表示にする
        vehicleDisplayInfo.hideRegistrationNumber = false;
    } else {
        // 海外VIN以外の場合、登録番号は画面に表示にする
        vehicleDisplayInfo.hideRegistrationNumber = true;
        // 登録番号
        vehicleDisplayInfo.registrationNumber = ('{0} {1} {2} {3}').format(
            util.isNullOrDefault(vcl.vin.area_name, ''),
            util.isNullOrDefault(vcl.vin.category, ''),
            util.isNullOrDefault(vcl.vin.hiragana, ''),
            util.isNullOrDefault(vcl.vin.individual_number, '')
        );
    }
    return vehicleDisplayInfo;
};

export const isDomestic = (region) => {
    return region === config.REGION_DOMESTIC;
};

export default {
    chassisNumberSplit,
    createTargetUnitInfo,
    createLatestInfo,
    getTargetUnitDisplayNames,
    getVehicleModelDisplayNames,
    getPhaseResources,
    getVehicleSystemMode,
    formatVehicleInfo,
    isDomestic
};