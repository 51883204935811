module.exports = {
  PASSWORD_EXPIRED_DAY: 90,
  USER_CREATE_INIT_PASSWORD_EXPIRED_DAY: 30,
  PASSWORD_PROHIBITION_PERIOD_DAY: 2,
  USER_DELETE_PROHIBITION_PERIOD_DAY_ON_ACTIVE: 8,
  UNUSE_USER_DAY: 180,
  USER_LOCK_LIMIT: 5,
  USER_LOCK_TIME: 600000, // Time in milliseconds
  DAY_TO_MILLISECONDS: 24 * 60 * 60 * 1000,
  MAINTENANCE_MIN_TIME: 30,
  MAINTENANCE_MAX_TIME: 60,

  BOUNCE_MAIL_TEMPLATE_SUBJECT: 'Mail bounced for user (v2)',
  BOUNCE_MAIL_TEMPLATE_BODY: `
    SSM5<br/>
    There are Japanese and English messages.<br/>
    <br/>
    ===== Japanese =====<br/>
    利用者へのメール送信に失敗しました。<br/>
    <br/>
    ===== English =====<br/>
    Mail sending to the user has been failed.<br/>
    <br/>
    Sign in ID : <b>{userName}</b><br/>
    <br/>
    注：本メールはSSM5が自動配信しております。<br/>
    Note: This is auto generated email from SSM5.<br/>
  `,

  Command_NetApp_Connected: "net_app_connected",
  Command_Initialize: "net_app_init",
  Command_Pairing: "net_app_pairing",
  Command_UnPairing: "net_app_remove_pairing",
  Command_Inquiry: "net_app_inquiry",
  Command_Execute: "net_app_repro_start",
  Command_Execute_Status: "net_app_repro_progress",
  Command_IgOff: "net_app_ig_off",
  Command_IgOn: "net_app_ig_on",
  Command_Get_Latest_Repro_State: "net_app_get_latest_repro_state",
  Command_Watchdog: "net_app_watchdog",
  Command_GetCGWKey: "net_app_get_cgw_key",
  Command_GetECUKey: "net_app_get_ecu_key",
  Command_Disconnect: "net_app_disconnect",
  Command_Read_VBat: "net_app_read_vbatt",
  Command_CGW_Support_Check: "net_app_cgw_support_check",
  Command_Read_RxSWIN: "net_app_rxswin_list",
  Command_Installed_Ecu_List: "net_app_installed_ecu_list",
  Command_Communicated_Ecu_List: "net_app_communicated_ecu_list",
  Command_Read_Vin: "net_app_read_vin",
  Command_Read_Security_Access_Logic: "net_app_read_security_access_logic",
  Command_All_Target_Inquiry: "net_app_all_target_inquiry",
  Command_NetApp_Check_Connected: "net_app_check_connected",
  Command_TestExecute: "net_app_test_repro_start",
  Command_Update_RxSWIN_List: "net_app_update_rxswin_list",
  Command_Storage_Inquiry: "net_app_storage_inquiry",
  Command_Authenticate_Id: "net_app_authenticate_id",
  Command_Read_Vehicle_Timestamp: "net_app_read_vehicle_timestamp",
  Command_Installed_Communicated_Ecu_List: "net_app_installed_communicated_ecu_list",
  Command_Installed_Communicated_Other_Ecu_List: "net_app_installed_communicated_ecu_other",
  Command_Read_Diag_Information_List: 'net_app_read_diag_information_list',
  Command_Clear_Dtc_List: "net_app_clear_dtc_list",
  Command_Get_Clear_Dtc_Progress: 'net_app_get_clear_dtc_progress',
  Command_GetDTCProgress: 'net_app_get_dtc_progress',
  Command_MacProgress: 'net_app_mac_progress',
  Command_Mac_getECUKey: 'net_app_get_cgw_key',
  Command_Mac_Update_Progress: "net_app_mac_update_progress",
  Command_Mac_Registration_Progress: "net_app_mac_key_registration_progress",
  Command_MAC_Get_Mac_Key: "net_app_get_mac_key",
  Command_MAC_Net_App_Key_Update_Inspection: "net_app_key_update_inspection",
  Command_IgOnOrder: "net_app_ig_on_order",
  Command_IgOffOrder: "net_app_ig_off_order",
  Command_Imb_Vehicle_Specification: "net_app_imb_vehicle_specification",
  Command_WorkSupports: "net_app_execute_work_support",
  Command_Monitor_Cancellation_Work_Support: "net_app_monitor_cancellation_work_support",
  Command_Read_SupportIds_Cache: 'net_app_read_support_ids_cache',
  Command_Read_Config_Cache: 'net_app_read_config_cache',
  Command_Read_SupportIds: "net_app_read_support_ids",
  Command_Read_Config: "net_app_read_config",
  Command_Read_Menu_Support: "net_app_read_menu_support",
  Command_Clear_behavior_information: "net_app_clear_behavior_information",
  Command_Read_behavior_information: "net_app_read_behavior_information",
  Command_Read_System_Timestamp: "net_app_read_system_timestamp",
  Command_Abort_Sequence_Dll: 'net_app_abort_sequence_dll',
  Command_Read_Readiness_Code: 'net_app_read_readiness_code',
  Command_Read_Diag_Information: 'net_app_read_diag_information',
  Command_Read_Permanent_Diag_Information: 'net_app_read_permanent_diag_information',
  Command_Read_Legislated_Support_ids: 'net_app_read_legislated_support_ids',
  Command_Read_Legislated_Data: 'net_app_read_legislated_data',
  Command_Customize_Read: 'net_app_customize_read',
  Command_Customize_Write: 'net_app_customize_write',
  Command_Read_Cancel_Code_Common: 'net_app_read_cancel_code_common',
  Command_Read_Cancel_Code_Rcr: 'net_app_read_cancel_code_rcr',
  Command_Read_ActiveTest_Data: 'net_app_read_activetest_data',
  Command_Read_Support_Protocol: 'net_app_read_support_protocol',
  Command_Read_Cancel_Code_Es: 'net_app_read_cancel_code_es_ver4',
  Command_Read_Obd_Identifier_Data: 'net_app_read_obd_identifier_data',
  Command_Read_Obd_Comm_Config: 'net_app_read_obd_comm_config',
  Command_Read_Isa_Support: 'net_app_read_isa_support',
  Command_Get_Real_World_Data_ISA: 'net_app_get_realWorldData_ISA',
  Command_Get_Real_World_Data_VSOC: 'net_app_get_realWorldData_VSOC',
  Command_Get_Launch_Flash_Write: 'net_app_launch_flash_write',

  USER_ID_MAXIMUM_LENGTH: 20,
  USER_EMAIL_MAXIMUM_LENGTH: 256,
  USER_FIRSTNAME_MAXIMUM_LENGTH: 64,
  USER_LASTNAME_MAXIMUM_LENGTH: 64,
  USER_INFO_MAXIMUM_LENGTH_64: 64,
  USER_INFO_MAXIMUM_LENGTH_256: 256,
  USER_PASSWORD_MINIMUM_LENGTH: 9,
  USER_PASSWORD_MAXIMUM_LENGTH: 127,
  USER_CATEGORYNAME_MAXIMUM_LENGTH: 64,
  COMPANYNAME_MAXIMUM_LENGTH: 64,
  DEPARTMENTNAME_MAXIMUM_LENGTH: 64,

  USER_GROUP_SYSTEM_ADMIN: 'system_admins',
  USER_GROUP_SERVICE_ADMIN: 'service_admins',
  USER_GROUP_REPRO_REGISTRANT: 'repro_registrants',
  USER_GROUP_REPRO_FILE_ADMIN: 'repro_file_admins',
  USER_GROUP_USER_ADMIN: 'user_admins',
  USER_GROUP_REPRO_FILE_TESTER: 'repro_file_tester',
  USER_GROUP_USERS: 'users',
  USER_GROUP_VARIANT_DATA_FILE_ADMIN: 'variant_data_file_admins',
  USER_GROUP_EVENT_LOG_ADMIN: 'event_log_admins',
  USER_GROUP_SBR_REPRO_TESTER: 'sbr_repro_tester',

  SUPER_USER_ID: 'superuser',
  SERVICE_NAME_REPRO: 'repro',
  SERVICE_NAME_MANAGE: 'manage',
  USER_TYPE_COMPANY: 'company',
  USER_TYPE_SHOP: 'shop',
  SUBARU_OF_CHINA_CODE: "as03",
  SUBARU_CODE: "sbr0",

  COMPANY_DIRECT: 'direct',
  COMPANY_INDIRECT: 'indirect',
  COMPANY_HYPHEN: 'hyphen',

  COMPANY_CORD_SBR: 'sbr0',
  COMPANY_NAME_SBR: 'subaru',

  COMPANY_GROUP_HEADQUARTER: 'headquarter',
  COMPANY_GROUP_DISTRIBUTOR: 'distributor',
  COMPANY_GROUP_TRADING_COMPANY: 'trading_company',
  COMPANY_GROUP_MAINTENACE_COMPANY: 'maintenace_company',
  COMPANY_GROUP_SBR: 'sbr',
  COMPANY_GROUP_AREA: 'area_company',
  COMPANY_GROUP_OTHER: 'other',
  COMPANY_GROUP_REGION: 'region',

  SHOP_GROUP_JP_HEAD_OFFICE: 'jp_head_office',
  SHOP_GROUP_JP_DEALER: 'jp_dealer',
  SHOP_GROUP_JP_TRADE_SALES: 'jp_trade_sales',
  SHOP_GROUP_JP_EXCEPT_DISTRIBUTOR: 'jp_except_distributor',
  SHOP_GROUP_NA_DIST_HQ: 'na_dist_hq',
  SHOP_GROUP_NA_DEALER: 'na_dealer',
  SHOP_GROUP_NA_AFTERMARKET_SHOP: 'na_aftermarket_shop',
  SHOP_GROUP_NA_DIST_BRANCH: 'na_dist_branch',
  SHOP_GROUP_NA_SERVICE_SHOP: 'na_service_shop',
  SHOP_GROUP_NA_GST: 'na_gst',
  SHOP_GROUP_NA_OTHER: 'na_other',
  SHOP_GROUP_EU_DIST_HQ: 'eu_dist_hq',
  SHOP_GROUP_EU_DIST_BRANCH: 'eu_dist_branch',
  SHOP_GROUP_EU_DIST_INDEPENDENT: 'eu_dist_independent',
  SHOP_GROUP_EU_DEALER: 'eu_dealer',
  SHOP_GROUP_EU_SERVICE_SHOP: 'eu_service_shop',
  SHOP_GROUP_EU_OTHER: 'eu_other',
  SHOP_GROUP_OTHER_SERVICE_DEPERTMENT: 'other_service_depertment',
  SHOP_GROUP_OTHER_ENGINEERING_DEPERTMENT: 'other_engineering_depertment',
  SHOP_GROUP_OTHER_OTHER: 'other_other',

  USER_GROUP_PERMISSION_MAP: {
    'sbr_repro_tester': 512,
    'event_log_admins': 256,
    'variant_data_file_admins': 128,
    'system_admins': 64,
    'service_admins': 32,
    'repro_registrants': 16,
    'repro_file_admins': 8,
    'user_admins': 4,
    'repro_file_tester': 2,
    'users': 1
  },

  // 各単位設定の定数
  UNIT_SETTINGS_DISTANCE: {
    KM: 'km',
    MILE: 'mile'
  },
  UNIT_SETTINGS_SPEED: {
    KMH: 'km/h',
    MPH: 'MPH'
  },
  UNIT_SETTINGS_TEMPERATURE: {
    CELSIUS: 'ﾟC',
    FAHRENHEIT: 'ﾟF'
  },
  UNIT_SETTINGS_PRESSURE: {
    KPA: 'kPa',
    MMHG: 'mmHg',
    INHG: 'inHg',
    PSI: 'psi',
    INH2O: 'inH2O'
  },
  UNIT_SETTINGS_FLOW_RATE: {
    GS: 'g/s',
    LBMIN: 'lb/min'
  },
  UNIT_SETTINGS_WEIGHT: {
    KG: 'kg',
    LB: 'lb'
  },
  UNIT_SETTINGS_LOAD: {
    GREV: 'g/rev',
    LBREV: 'lb/rev'
  },

  UNIT_SETTINGS_FLOW_RATE_CHECK: {
    MGCYL: 'mg/cyl',
    MGSTROKE: 'mg/stroke'
  },

  COUNTRY_GROUP_DOMESTIC: 'Domestic',
  COUNTRY_GROUP_NORTH_AMERICA: 'NorthAmerica',
  COUNTRY_GROUP_EUROPE: 'Europe',

  EMAIL_PATTERN_REGX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  URL_PATTERN_REGX: /https?:\/\/[\w!?/\+\-_~=;\.,*&@#$%\(\)\' ]+$/,

  ADMIN_JOB_TYPE_EXPORT: 'export',
  ADMIN_JOB_TYPE_IMPORT: 'import',

  ADMIN_JOB_DATA_TYPE_COMPANY: 'company',
  ADMIN_JOB_DATA_TYPE_SHOP: 'shop',
  ADMIN_JOB_DATA_TYPE_COUNTRY: 'country',
  ADMIN_JOB_DATA_TYPE_USERS: 'users',
  ADMIN_JOB_DATA_TYPE_RELATION: 'relation',
  ADMIN_JOB_DATA_TYPE_COMPANY_USERS: 'company_users',

  ADMIN_JOB_STATUS_PROGRESS: 'processing',
  ADMIN_JOB_STATUS_UPLOADED: 'uploaded',
  ADMIN_JOB_STATUS_SUCCESS: 'success',
  ADMIN_JOB_STATUS_FAILED: 'failed',

  MASTER_DATA_TYPE_ENGINES: 'engines',
  MASTER_DATA_TYPE_ASPIRATIONS: 'aspirations',
  MASTER_DATA_TYPE_TRANSMISSIONS: 'transmissions',
  MASTER_DATA_TYPE_REPRO_FACTORS: 'repro_factors',
  MASTER_DATA_TYPE_TARGET_UNITS: 'target_units',
  MASTER_DATA_TYPE_VEHICLE_MODELS: 'vehicle_models',
  MASTER_DATA_TYPE_VEHICLE_MODELS_DISPLAY_NAME: 'vehicle_models_displays',
  MASTER_DATA_TYPE_CANID_MODELS: 'canid_models',

  PFC_UPLOAD_STATUS_UPLOADED: 'uploaded',
  PFC_UPLOAD_STATUS_UPLOADING: 'uploading',
  PFC_UPLOAD_STATUS_UPLOAD_FAILED: 'upload_failed',

  PFC_PUBLISH_STATUS_UNPUBLISHED: 'unpublished',
  PFC_PUBLISH_STATUS_PUBLISHED: 'published',
  PFC_PUBLISH_STATUS_DISCONTINUED: 'discontinued',

  PFC_APPROVAL_STATUS_APPROVED: 'approved',
  PFC_APPROVAL_STATUS_UNAPPROVED: 'unapproved',
  PFC_APPROVAL_STATUS_DISTRIBUTOR_ONLY: 'distributor_only',

  PFC_REPROGRAM_STATUS_OK: 'ok',
  PFC_REPROGRAM_STATUS_ERROR: 'error',
  PFC_REPROGRAM_STATUS_REPROGRAMMABLE: 'reprogrammable',
  PFC_REPROGRAM_STATUS_NOT_REPROGRAMMABLE: 'not_reprogrammable',

  TARGET_ID_TYPE_ROMID: 'ROMID',
  TARGET_ID_TYPE_CID: 'CID',
  TARGET_ID_TYPE_SWID: 'SWID',

  VEHICLE_INFO_TYPE_VIN: 'vin',
  VEHICLE_INFO_TYPE_CHASSIS_NUMBER: 'chassis_number',
  VEHICLE_INFO_TYPE_UNKNOWN: 'unknown',

  TARGET_UNIT_ID_TYPE: {
    0x01: 'romid',
    0x02: 'cid',
    0x03: 'swid',
    0x04: 'cid',
    0x05: 'special_id'
  },

  SYSTEM_EYE: 'EYE',
  SYSTEM_EYE_1: 'EYE_1',
  SYSTEM_EYE_2: 'EYE_2',
  SYSTEM_CCU: 'CCU',

  //SpecialData.bin対応で使用
  //何度も書き込まれるbin(targetID=newID)を含む可能性のあるシステム
  OVERWRITTEN_SYSTEM: ['EYE_1', 'EYE_2'],

  //2つで1つのシステムだが、片方のみSUで登録する可能性のあるシステム
  ONE_SIDE_SU_SYSTEM: ['EPS_M', 'EPS_S'],

  FILE_TYPE_CCU: 'ccu',
  FILE_TYPE_PFC: 'pfc',

  /**
   * @deprecated v2.5.0でssm5-vehicle-inquiryに定義したGATEWAY_ECU_NOT_MANAGE_SYSTEM_LISTに移行
   */
  SPECIAL_ECU_LIST: ['FCR_L', 'FCR_R', 'BECM_CGW', 'DMCM_CGW'], // Installation/communication status of these ECUs cannot be checked with CGW bit position

  PFC_CLASSIFICATION_DELIMITER: ' ',
  VIN_MINIMUM_LENGTH: 17,           //仕様 Hex - 長さ：34
  VIN_CHASSIS_NUM_HEADER_LEN: 3,    //仕様 Hex - 長さ：6

  INSTALL_STATUS_INSTALLED: 'installed',
  INSTALL_STATUS_NOT_INSTALLED: 'not_installed',
  INSTALL_STATUS_ERROR: 'error',
  COMMUNICATE_STATUS_COMMUNICATED: 'communicated',
  COMMUNICATE_STATUS_NOT_COMMUNICATED: 'not_communicated',
  COMMUNICATE_STATUS_ERROR: 'error',

  REPRO_FILE_DOWNLOADING: 'downloading',
  REPRO_PRE_PROGRAMMING: 'pre_programming',
  REPRO_PROGRAMMING: 'programming',
  REPRO_POST_PROGRAMMING: 'post_programming',

  SYSTEM_NAME_CGW: 'CGW',
  SYSTEM_NAME_CECU: 'C-ECU',
  SYSTEM_NAME_BIU: 'BIU',
  SYSTEM_NAME_IMB: 'IMB',
  SYSTEM_NAME_SMT: 'SMT',
  SYSTEM_NAME_SMTC: 'SMTC',
  SYSTEM_NAME_SMTP: 'SMTP',
  SYSTEM_NAME_ES: 'ES',
  SYSTEM_NAME_ES_25EPF: 'ES_25ePF',

  DID_0251: '0251',
  DID_1031: '1031',
  DID_1033: '1033',
  DID_1034: '1034',
  DID_1062: '1062',
  DID_1140: '1140',
  DID_1158: '1158',
  DID_115C: '115C',
  DID_2047: '2047',

  PHYSICS_ADDRESS_REQUEST_1872: 1872,
  PHYSICS_ADDRESS_REQUEST_1873: 1873,
  PHYSICS_ADDRESS_REQUEST_1874: 1874,
  PHYSICS_ADDRESS_REQUEST_1881: 1881,
  PHYSICS_ADDRESS_REQUEST_1882: 1882,
  PHYSICS_ADDRESS_REQUEST_1897: 1897,
  PHYSICS_ADDRESS_REQUEST_1972: 1972,
  PHYSICS_ADDRESS_REQUEST_1980: 1980,

  MAX_CID_LENGTH: 32, // 16 byte



  // 車種情報のtypeフィールドの値
  VEHICLEINFO_TYPE_DEVELOPMENTCODE: "development_code",
  VEHICLEINFO_TYPE_IMPROVEMENTS_CODE: "improvements_code",
  VEHICLEINFO_TYPE_AREA: "area",
  VEHICLEINFO_TYPE_VEHICLEMODEL: "vehicle_model",
  VEHICLEINFO_TYPE_CLASSIFICATION: "classification",
  VEHICLEINFO_TYPE_MODELYEAR: "model_year",
  VEHICLEINFO_TYPE_CHASSISNUMBER: "chassis_number",
  VEHICLEINFO_TYPE_VIN: "vin",
  // 車種情報の型式と年改、VINとPubNoを分けるデリミタ
  VEHICLEINFO_DELIMITER: ' ',
  // 車種情報の車両番号を分けるデリミタ
  VEHICLEINFO_APPLIED_MODEL_DELIMITER: ' / ',
  VEHICLEINFO_VEHICLENUMBER_DELIMITER: '-',

  VEHICLEINFO_NOT_SET_IMPROVEMENTS_CODE: "NOT_SET",

  REGISTRATION_TYPE_CC: 'cloudconnector',


  // バリデーションの定義

  // 会社情報　最大文字数
  COMPANY_ID_MAX_LEN: 4,
  COMPANY_NAME_MAX_LEN: 128,
  COMPANY_ADDRESS_MAX_LEN: 128,
  COMPANY_BULLETINURL_MAX_LEN: 1024,
  COMPANY_REMARKS_MAX_LEN: 256,
  // 会社情報　チェックパターン
  COMPANY_CODE_PATTERN: /^[a-zA-Z0-9]{4}$/,

  // 店舗情報　最大文字数
  SHOP_NAME_MAX_LEN: 128,
  SHOP_MANAGEMENT_CODE_MAX_LEN: 16,
  SHOP_ADDRESS_MAX_LEN: 128,
  SHOP_REMARKS_MAX_LEN: 256,
  // 店舗情報　チェックパターン
  SHOP_CODE_PATTERN: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,

  // メンテナンス項目　最大文字数
  COUNTRY_SSMCODE_MAX_LEN: 4,
  COUNTRY_NAMEJA_MAX_LEN: 128,
  COUNTRY_NAMEEN_MAX_LEN: 128,
  COUNTRY_ABBREVIATION_MAX_LEN: 128,
  TARGETUNIT_SYSTEMNAME_MAX_LEN: 128,
  TARGETUNIT_NAME_MAX_LEN: 128,
  ENGINE_NAME_MAX_LEN: 128,
  INTAKE_NAME_MAX_LEN: 128,
  TM_NAME_MAX_LEN: 128,
  REPRO_NAME_MAX_LEN: 128,
  VEHICLEINFO_CODE_MAX_LEN: 128,
  VEHICLEINFO_VEHICLE_MODEL_MAX_LEN: 128,
  VEHICLEINFO_MODEL_MAX_LEN: 2,
  VEHICLEINFO_ANNUAL_IMPROVEMENTS_MAX_LEN: 1,
  VEHICLEINFO_ANNUAL_CLASSIFICATION_MAX_LEN: 1,
  VEHICLEINFO_MODEL_YEAR_MAX_LEN: 4,
  VEHICLEINFO_CHASSIS_NUMBER1_MAX_LEN: 3,
  VEHICLEINFO_CHASSIS_NUMBER2_MAX_LEN: 6,
  VEHICLEINFO_APPLIED_MODEL_MAX_LEN: 7,
  VEHICLEINFO_VIN_MAX_LEN: 17,
  VEHICLEINFO_PUB_NO_MAX_LEN: 128,
  VEHICLEINFO_IMPROVEMENTS_CODE_MAX_LEN: 1,
  CANIDINFO_CANID_MAX_LEN: 8,
  CANIDINFO_CODE_MAX_LEN: 128,
  CANIDINFO_NAME_MAX_LEN: 128,
  // メンテナンス項目　チェックパターン
  SSM_COUNTRY_CODE_PATTERN: /^[a-zA-Z0-9]*$/,
  TARGETUNIT_SYSTEMNAME_PATTERN: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
  VEHICLEINFO_CODE_PATTERN: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
  VEHICLEINFO_MODEL_PATTERN: /^[a-zA-Z0-9]*$/,
  VEHICLEINFO_ANNUAL_IMPROVEMENTS_PATTERN: /^[a-zA-Z0-9]*$/,
  VEHICLEINFO_ANNUAL_CLASSIFICATION_PATTERN: /^[a-zA-Z0-9]*$/,
  VEHICLEINFO_MODEL_YEAR_PATTERN: /^[0-9]*$/,
  VEHICLEINFO_CHASSIS_NUMBER1_PATTERN: /^[0-9A-HJ-NPR-Z]{3}$/,
  VEHICLEINFO_CHASSIS_NUMBER1_A_PATTERN: /^[0-9A-HJ-NPR-Z\*]{3}$/,
  VEHICLEINFO_CHASSIS_NUMBER2_PATTERN: /^[0-9A-HJ-NPR-Z]{6}$/,
  VEHICLEINFO_CHASSIS_NUMBER2_A_PATTERN: /^[0-9A-HJ-NPR-Z\*]{6}$/,
  VEHICLEINFO_APPLIED_MODEL_PATTERN: /^[0-9A-Z]{7}$/,
  VEHICLEINFO_APPLIED_MODEL_A_PATTERN: /^[0-9A-Z\*]{7}$/,
  VEHICLEINFO_VIN_PATTERN: /^[0-9A-HJ-NPR-Z]{17}$/,
  VEHICLEINFO_VIN_A_PATTERN: /^[0-9A-HJ-NPR-Z\*]{17}$/,
  VEHICLEINFO_PUB_NO_PATTERN: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
  CANIDINFO_CANID_PATTERN: /^[0-9A-Fa-f]{1,8}$/,

  // PFC詳細　最大文字数
  PFC_CVN_MAX_LEN: 64,
  PFC_ENCRYPTION_MAX_LEN: 64,
  PFC_SERVICE_NEWS_MAX_LEN: 64,
  PFC_SUPPLEMENTARY_MAX_LEN: 1024,
  PFC_REMARKS_MAX_LEN: 1024,
  PFC_DETAILS_MAX_LEN: 1024,
  PFC_NOTES_MAX_LEN: 1024,
  PFC_DEALER_SERVICE_NEWS_MAX_LEN: 1024,
  // PFC詳細　チェックパターン
  PFC_CVN_PATTERN: /^[a-zA-Z0-9!#-/:-@\[\]-`{-~]*$/,
  // 現在は使用していないためコメントアウトする
  // PFC_ENCRYPTION_PATTERN: /^[a-zA-Z0-9!#-/:-@\[\]-`{-~]*$/,
  PFC_SERVICE_NEWS_PATTERN: /^[a-zA-Z0-9!#-/:-@\[\]-`{-~]*$/,
  PFC_DEALER_SERVICE_NEWS_PATTERN: /^[\w/:%#\$&\?\(\)~\.=\+\-\'\!\*]+$/,

  // PFCファイルパスワード　チェックパターン
  PFC_FILE_PASSWORD_PATTERN: /^(?=.*[0-9])(?=.*[A-Z])[0-9A-Z]{8}$/,

  LOG_FILE_PATH: 'log/execute',
  LOG_OPERATION_MANUAL: 'Manual',
  LOG_OPERATION_AUTO: 'Auto',
  LOG_TYPE_INFO: 'Info',
  LOG_TYPE_REPRO: 'Repro',
  LOG_TYPE_REPRO_RECONNECT: 'Repro_reconnect',
  LOG_TYPE_REPRO_FAIL: 'ReproFail',

  VEHICLE_INFO_CHECK_STATUS_ABORT_SEQUENCE_DLL: 'abort_sequence_dll',
  VEHICLE_INFO_CHECK_STATUS_READ_BATTERY: 'read_vbat',
  VEHICLE_INFO_CHECK_STATUS_READ_VIN: 'read_vin',
  VEHICLE_INFO_VEHICLE_CHECK_STATUS_FAILED: 'vehicle_vin_failed',
  VEHICLE_INFO_VEHICLE_CHECK_STATUS_VIN_COMPLETED: 'vehicle_completed',
  VEHICLE_INFO_VEHICLE_CHECK_STATUS_CHASSIS_COMPLETED: 'vehicle_completed_chassis_number',
  VEHICLE_INFO_CHECK_STATUS_CHECK_CGW: 'check_cgw_support',
  VEHICLE_INFO_CHECK_STATUS_READ_RxSWIN: 'read_rxswin',
  VEHICLE_INFO_CHECK_STATUS_CHECK_INSTALLED_COMMUNICATED_ECU: 'read_installed_communicated',
  VEHICLE_INFO_CHECK_STATUS_CHECK_INSTALLED_ECU: 'read_installed_ecu',
  VEHICLE_INFO_CHECK_STATUS_CHECK_COMMUNICATED_ECU: 'read_communicated_ecu',
  VEHICLE_INFO_CHECK_STATUS_ALL_TARGET_INQUIRY: 'all_target_inquiry',
  VEHICLE_INFO_CHECK_STATUS_TARGET_INQUIRY: 'target_inquiry',
  VEHICLE_INFO_CHECK_STATUS_READ_SECURITY_ACCESS: 'read_security_access',
  VEHICLE_INFO_CHECK_STATUS_RXSWIN_UPDATING: 'rxswin_updating',
  VEHICLE_INFO_CHECK_STATUS_COMPLETED: 'completed',
  VEHICLE_INFO_CHECK_STATUS_DIAG_INFO: 'read_diag_info',
  VEHICLE_INFO_CHECK_STATUS_READ_TIMESTAMP: 'read_vehicle_timestamp',
  VEHICLE_INFO_CHECK_STATUS_READ_LEGISLATED_SUPPORT_IDS: 'read_legislated_support_ids',
  VEHICLE_INFO_CHECK_STATUS_READ_LEGISLATED_DATA: 'read_legislated_data',
  VEHICLE_INFO_CHECK_STATUS_CGW_CECU_NOT_COMMUNICATED: 'cgw_cecu_not_communicated',
  VEHICLE_INFO_CHECK_STATUS_READ_SUPPORT_PROTOCOL: 'read_support_protocol',
  VEHICLE_INFO_DEVICE_CHECK_SUPPORTED_PROTOCOL_FAILED: 'device_not_supported_protocol',
  VEHICLE_INFO_CHECK_STATUS_CONFIRM_READ_LEGISLATED_SUPPORT_IDS: 'confirm_read_legislated_support_ids',
  VEHICLE_INFO_CHECK_STATUS_UNSUPPORTED_DEVICE: 'unsupported_device',

  VEHICLE_CONTINUE_CHECK_SYSTEM_TYPE_CGW_CECU_NOT_COMMUNICATED: 'cgwNotCommunicated',
  VEHICLE_CONTINUE_CHECK_SYSTEM_TYPE_GET_OBFCM: 'getObfcm',

  VBATT_NEXT_STRATEGY_READ_VIN: 'read_vin',
  VBATT_NEXT_STRATEGY_UPDATE_RXSIN: 'update_rxswin',

  RXSWIN_DUMMY_VALUE: 'NotRecorded 00',

  STORAGE_INQUIRY_STATUS_READ_STORAGE: 'read_storage',
  STORAGE_INQUIRY_STATUS_COMPLETED: 'completed',

  // 車両情報取得動作タイプ
  VEHICLE_INFO_INQUIRY_TYPE_ALL: 'all',               // repro & diag サービス稼働時
  VEHICLE_INFO_INQUIRY_TYPE_DTC: 'reacquisition_dtc', // DTC 再取得・消去
  VEHICLE_INFO_INQUIRY_TYPE_DIAG: 'diag',             // diag サービス単体稼働時

  USER_INACTIVE_TYPE_MANUAL: 'manual',
  USER_INACTIVE_TYPE_AUTO: 'auto',

  VERSION_UNKNOWN: '-',

  PFC_FILE_LIST_TYPE_OWN_COUNTRY: 'own_country',
  PFC_FILE_LIST_TYPE_OTHER_COUNTRY: 'other_countries',

  GPLM_PFC_REGISTER_FOLDER_NAME: '__GPLM__',
  REPRO_SETTINGS_RE_REGISTRATION_NOTIFICATE: 'reregistration_notificate',
  REPRO_SETTINGS_REGISTRATION_NOTIFICATE: 'registration_notificate',

  GPLM_PFC_HISTORY_STATUS: {
    REGISTERED: "registered",
    UNACQUIRED: "unacquired",
    FAILED: "failed",
  },

  GPLM_PFC_HISTORY_ERROR_REASON: {
    NO_QA_UPDATE_TIME: 'no_qa_update_time',
    NO_EXIST_PFC: 'no_exist_pfc',
    NO_EXIST_HASH_VALUE: 'no_exist_hash_value',
    NO_MATCH_HASH_VALUE: 'no_match_hash_value',
    TEMPORARY_REGISTRATION_ERROR: 'temporary_registration_error',
  },

  // Data type information
  DOWNLOAD_FUNCTION_INFORMATION: 'gpa_download_function_information',
  DOWNLOAD_VEHICLE_INFORMATION: 'gpa_download_vehicle_information',
  DOWNLOAD_CONFIGURATION_SUPPORT_INFORMATION: 'gpa_download_configuration_support_information',
  DOWNLOAD_SWID_REGISTRATION_TIME_INFORMATION: 'gpa_download_swid_registration_time_information',
  DOWNLOAD_HWID_REGISTRATION_TIME_INFORMATION: 'gpa_download_hwid_registration_time_information',
  DOWNLOAD_CONFIGURATION_INFORMATION: 'gpa_download_configuration_information',
  DOWNLOAD_REPROGRAMMING_FILES: 'gpa_download_reprogramming_files',
  DOWNLOAD_DEVELOPMENT_CODE_REGULATION_INFORMATION: 'gpa_download_development_code_regulation_information',
  DOWNLOAD_HARDWARE_GROUP_INFORMATION: 'gpa_download_hardware_group_information',
  DOWNLOAD_SOFTWARE_GROUP_INFORMATION: 'gpa_download_software_group_information',
  DOWNLOAD_VEHICLE_CONFIGURATION_INFORMATION: 'gpa_download_vehicle_configuration_information',
  DOWNLOAD_12DIGITS_MODEL_PARTS_INFORMATION: 'gpa_download_12digits_model_parts_information',
  DOWNLOAD_HARDWARE_SPEC_INFORMATION: 'gpa_download_hardware_spec',
  DOWNLOAD_SOFTWARE_SPEC_INFORMATION: 'gpa_download_software_spec',
  DOWNLOAD_HARDWARE_PREREQUISITE_INFORMATION: 'gpa_download_hardware_prerequisite_information',
  DOWNLOAD_ORIGIN_SPEC_DIVISION_INFORMATION: 'gpa_download_origin_spec_division_information',
  DOWNLOAD_SOFTWARE_PREREQUISITE_INFORMATION: 'gpa_download_software_prerequisite_information',
  UPLOAD_VEHICLE_HISTORY_INFORMATION: 'gpa_upload_vehicle_history',

  // Config Data Event Type Information
  CONFIG_EVENT_TYPE_GET_12_DIGIT: 'config_data_event_get_12_digit',
  CONFIG_EVENT_TYPE_PARALLEL_MANAGER: 'config_data_event_parallelManager',
  CONFIG_EVENT_TYPE_DOWNLOAD: 'config_data_event_doenload',

  // Transfer data file name prefix
  TRANSFER_FILE_PREFIX_FUNCTION_INFORMATION: 'function_IF-06_',
  TRANSFER_FILE_PREFIX_VEHICLE_INFORMATION: 'vehicle_IF-02_',
  TRANSFER_FILE_PREFIX_CONFIGURATION_INFORMATION: 'configuration_IF-03_',
  TRANSFER_FILE_PREFIX_CONFIGURATION_SUPPORT_INFORMATION: 'configuration_support_IF-07_',
  TRANSFER_FILE_PREFIX_SWID_REGISTRATION_TIME_INFORMATION: 'swid_registration_time_IF-08_',
  TRANSFER_FILE_PREFIX_HWID_REGISTRATION_TIME_INFORMATION: 'hwid_registration_time_IF-09_',
  TRANSFER_FILE_PREFIX_DEVELOPMENT_CODE_REGULATION_INFORMATION: 'code_regulation_IF-11_',
  TRANSFER_FILE_PREFIX_HARDWARE_GROUP_INFORMATION: 'hardware_group_IF-12_',
  TRANSFER_FILE_PREFIX_SOFTWARE_GROUP_INFORMATION: 'software_group_IF-13_',
  TRANSFER_FILE_PREFIX_VEHICLE_CONFIGURATION_INFORMATION: 'vehicle_configuration_IF-14_',
  TRANSFER_FILE_PREFIX_12DIGITS_MODEL_PARTS_INFORMATION: '12digits_model_parts_IF-15_',
  TRANSFER_FILE_PREFIX_HARDWARE_SPEC_INFORMATION: 'hardware_spec_IF-16_',
  TRANSFER_FILE_PREFIX_SOFTWARE_SPEC_INFORMATION: 'software_spec_IF-17_',
  TRANSFER_FILE_PREFIX_HARDWARE_PREREQUISITE_INFORMATION: 'hardware_prerequisite_IF-18_',
  TRANSFER_FILE_PREFIX_ORIGIN_SPEC_INFORMATION: 'origin_spec_IF-19_',
  TRANSFER_FILE_PREFIX_SOFTWARE_PREREQUISITE_INFORMATION: 'software_prerequisite_IF-20_',

  // Transfer data file name extension
  TRANSFER_FILE_EXTENSION: '.temp',

  // Stage information
  DATA_PROCESSING_STAGE_START: 'start',
  DATA_PROCESSING_STAGE_EXECUTE: 'processing',
  DATA_PROCESSING_STAGE_FINISH: 'finish',
  DATA_PROCESSING_STAGE_CLEANUP: 'cleanup',

  // parallel status
  PARALLEL_CONSENT_STATUS_WAITING: 'waiting',
  PARALLEL_CONSENT_STATUS_STARTING: 'starting',
  PARALLEL_CONSENT_STATUS_EXECUTING: 'executing',
  PARALLEL_CONSENT_STATUS_FINISHED: 'finished',

  // Repro consent status
  REPRO_CONSENT_STATUS_CONSENTED: 'consented',
  REPRO_CONSENT_STATUS_REJECTED: 'rejected',
  REPRO_CONSENT_STATUS_UNSETTED: 'unsetted',

  // Relation repro status
  RELATION_REPRO_STATUS_NOT_COMPLETE: 'not_complete',
  RELATION_REPRO_STATUS_EXECUTING: 'executing',
  RELATION_REPRO_STATUS_COMPLETE: 'complete',
  RELATION_REPRO_STATUS_CONTINUE: 'continue',

  // Registration time tabele initialization id type
  REGISTRATION_TIME_ID_TYPE_SWID: 'swid',
  REGISTRATION_TIME_ID_TYPE_HWID: 'hwid',

  // Two step repro
  TWO_STEP_PFC_SUPPORTED_SYSTEMS: ['DMS_CGW', 'VDC_AES', 'VDC_A_AES'],
  TWO_STEP_PFC_PAIRING_MODE_SET: 'set',
  TWO_STEP_PFC_PAIRING_MODE_CANCEL: 'cancel',

  // Su Processing Complete Table Category and process status
  FUNCTION_PROCESS: {
    STATUS: {
      PROCESSING: 'processing',
      COMPLETE: 'complete',
      ERROR: 'error'
    },
    NOT_SU_CONFIRMED_STEP: {
      RXSWIN_SPECIFIC: 'rxswin_specific',
      ASSUMED_STATUS_CONFIRMATION: 'assumed_status_confirmation',
      PREREQUISITE_HW_CONDITIONS_CONFIRMATION: 'prerequisite_hw_conditions_confirmation'
    }
  },

  // SLO
  PROVIDER_NAME_SUBARUNET: 'SUBARUNET',
  PROVIDER_NAME_SUBSCRIBER: 'SUBSCRIBER',

  // リプロ種別
  REPRO_TYPE: {
    NORMAL: 'Normal',                     // 通常リプロ
    /**
     * @deprecated v250で削除予定
     */
    FORCE: 'Force',                       // 強制リプロ(市場措置)
    /**
     * @deprecated v250で削除予定
     */
    OPTIONAL: 'Optional',                 // 任意リプロ(その他)
    MERCHANTABILITY: 'Merchantability',   // 商品性リプロ(機能向上)
    NOT_SET: 'NotSet',                    // G-PLM連携でPFCファイルを登録する際のリプロ種別
    RESTORE: 'Restore'                    // 商品性の戻しリプロ
  },

  // BatchWriteにて一括で登録できる項目数
  BATCH_WRITE_CHUNK_SIZE: 24,

  /**
   * 構成情報にPFCファイル名が設定されていない場合や、ファイルが未登録の際に処理中に一時的に割り当てるリプロ種別
   * 他のリプロ種別とは異なりDBに記録されることの無い値のため、REPRO_TYPEとは別途定義する。
   */
  REPRO_TYPE_UNKNOWN: 'Unknown',

  RESPONSE_TYPE_OK: 'ok',
  RESPONSE_TYPE_NG: 'ng',
  RESPONSE_TYPE_NO: 'no',

  // FIXME: RESPONSE_TYPE_NO と被っているので、今後使用禁止。
  //        呼び出し元を全て削除し次第、ここからも削除する。
  RESPONSE_TYPE_no: 'no',

  /**
   * maintenance時に、activeになっている環境の定数
   * master/backup対応で利用する環境の定数
   */
  MAINTENANCE_ACTIVE_ENV_DEFAULT: 'master',
  MAINTENANCE_ACTIVE_ENV_BACKUP: 'backup',
  // 環境が切り替え中の状態を表す定数
  MAINTENANCE_ACTIVE_ENV_SWITCH: 'switch',

  // ECU type
  ECU_TYPE_REPRO: 'repro',
  ECU_TYPE_DIAG: 'diag',
  ECU_TYPE_IMB: 'imb',

  // Usage count types
  USAGE_COUNT_TYPE_REPRO: 'repro',
  USAGE_COUNT_TYPE_DIAG: 'diag',
  USAGE_COUNT_TYPE_DTC: 'individual_dtc',
  USAGE_COUNT_TYPE_VCH: 'individual_vch',
  USAGE_COUNT_TYPE_CST: 'individual_cst',
  USAGE_COUNT_TYPE_WSP: 'individual_wsp',
  USAGE_COUNT_TYPE_CNC: 'individual_cnc',
  USAGE_COUNT_TYPE_DM: 'individual_dm',
  USAGE_COUNT_TYPE_AT: 'individual_at',
  USAGE_COUNT_TYPE_SP_DM: 'special_dm',
  USAGE_COUNT_TYPE_SP_DTC: 'special_dtc',
  USAGE_COUNT_TYPE_SP_CNC: 'special_cnc',
  USAGE_COUNT_TYPE_SP_WSP: 'special_wsp',
  USAGE_COUNT_TYPE_DATA_PLAYBACK: 'data_playback',
  USAGE_COUNT_TYPE_IMMOBILIZER: 'immobilizer',
  USAGE_COUNT_TYPE_MACKEY: 'mackey',
  USAGE_COUNT_TYPE_REAL_WORLD_DATA: 'real_world_data',

  // Read method
  READ_METHOD_SDR: 'SDR',
  READ_METHOD_SSR: 'SSR',
  READ_METHOD_PID: 'PID',
  READ_METHOD_UNKNOWN: '-',

  // Protocol names
  PROTOCOL_FHI_CAN: 'FHI_CAN',
  PROTOCOL_Phase4: 'Phase4',
  PROTOCOL_Phase5: 'Phase5',

  // DTC status
  DTC_STATUS_NONE: 'no_status',
  DTC_STATUS_TENTATIVE: 'tentative',
  DTC_STATUS_CURRENT: 'current',
  DTC_STATUS_PAST: 'past',
  DTC_STATUS_NOT_SUPPORT: 'dtc_not_support',
  DTC_STATUS_NO_DTC: 'no_dtc',
  DTC_STATUS_CANNOT_CONNECT: 'cannot_connect',
  DTC_STATUS_LATEST: 'latest',
  DTC_STATUS_TENTATIVE_LATEST: 'tentative_latest',
  DTC_STATUS_CONFIRM: 'confirm',
  DTC_STATUS_CONFIRM_LATEST: 'confirm_latest',
  DTC_STATUS_CONFIRM_TENTATIVE: 'confirm_tentative',
  DTC_STATUS_CONFIRM_TENTATIVE_LATEST: 'confirm_tentative_latest',
  DTC_STATUS_CURRENT_PAST: 'current_past',

  REPRO_MEASUREMENT_FUNCTION_ALL_DTC: 'alldtc',
  REPRO_MEASUREMENT_FUNCTION_INDIVIDUAL_DTC: 'individualDtc',

  FFD_DATA_TYPE_CONFIRM: 'confirm',
  FFD_DATA_TYPE_TENTATIVE: 'tentative',

  FFD_ID_TYPE_DID: 'did',
  FFD_ID_TYPE_PID: 'pid',

  FFD_INFO_TYPE_BYTE: 'byte',
  FFD_INFO_TYPE_BIT: 'bit',

  DIAG_CGW_STATUS_CURRENT: "current",
  DIAG_CGW_STATUS_HYPHEN: "hyphen",

  // メモ情報
  MEMOINFO_MAX_LEN: 1024,
  MEMOINFO_DETA_NAME_MAX_LEN: 128,

  MEASUREMENT_DEFAULT_DATA_NAME: 'Data',

  // mac_key
  MAC_DLL: 'mac_dll',
  MAC_SPECIFIC_DTC_VALUE: ['P1E00', 'B1E00', 'C1E00'],

  // mac_key system_name
  MC: "MC",
  ESGW: "ESGW",
  EBB: "EBB",
  SAS: "SAS",
  EPS: "EPS",
  ES: "ES",
  BC: "BC",
  TM: "TM",
  EGI: "EGI",
  CGW: "CGW",
  CECU: "C-ECU",
  TLM_25ePF: "TLM_25ePF",

  // mac_key current_program_status (used on frontend)
  MAC_CURRENT_STATUS_VEHICLE_STATUS: "vehicle_status",
  MAC_CURRENT_STATUS_KEY_INSPECTION: "mac_key_inspection",
  MAC_CURRENT_STATUS_IG_ON_REQUEST: 'ig_on_request',
  MAC_CURRENT_STATUS_IG_ON_REQUEST1: "ig_on_request1",
  MAC_CURRENT_STATUS_IG_ON_REQUEST2: "ig_on_request2",
  MAC_CURRENT_STATUS_IG_OFF_REQUEST: "ig_off_request",
  MAC_CURRENT_STATUS_KEY_UPDATE: "mac_key_update",
  MAC_CURRENT_STATUS_UPLOAD_DOWNLOAD: "upload_download",
  MAC_CURRENT_STATUS_PLATFORM_GEN_DECISION: "platform_gen_decision",
  MAC_CURRENT_STATUS_REGISTRATION: "key_registration",

  // mac_key mac_status
  MAC_STATUS_EXECUTING: 'executing',
  MAC_STATUS_EXECUTING_IG_ON: 'executing_ig_on',
  MAC_STATUS_EXECUTING_IG_OFF: 'executing_ig_off', // not in use
  MAC_STATUS_NO_PROCESSING: 'none',
  MAC_STATUS_START_PROCESSING: 'processing_is_starting',
  MAC_STATUS_EXECUTING_IG_ON_UPDATE1: 'executing_ig_on_1_update',
  MAC_STATUS_EXECUTING_IG_ON_UPDATE2: 'executing_ig_on_2_update',
  MAC_STATUS_EXECUTING_IG_OFF_UPDATE: 'executing_ig_off_update',
  MAC_STATUS_UPLOAD_DOWNLOAD: 'upload_download', // not in use
  MAC_KEY_UPDATE_FAILED: 'update_failed',
  MAC_KEY_UPDATE_FINISHED: 'update_finished',
  MAC_KEY_NET_APP_KEY_UPDATE_INSPECTION: 'net_app_key_update_inspection', // not in use

  // mac_key mac_status (also used on frontend)
  MAC_STATUS_SUCCESS: 'success',
  MAC_KEY_IG_ON_REQUEST_ONE: 'ig_on_request1',
  MAC_KEY_IG_ON_REQUEST_TWO: 'ig_on_request2',
  MAC_KEY_IG_ON_REQUEST_ONE_AGAIN: 'ig_on_request1_again', // not in use
  MAC_KEY_IG_ON_REQUEST_TWO_AGAIN: 'ig_on_request2_again', // not in use
  MAC_KEY_IG_ON_REQUEST_AGAIN: 'ig_on_request_again',
  MAC_KEY_IG_OFF_REQUEST: 'ig_off_request',
  MAC_KEY_IG_OFF_REQUEST_AGAIN: 'ig_off_request_again',
  MAC_KEY_UPLOAD_DOWNLOAD: 'upload_download',
  MAC_KEY_PLATFORM_GEN_BEING_IDENTIFIED: 'platform_gen_being_identified',
  MAC_KEY_COMPLETED: 'completed',

  MAC_STATUS_FAILED: 'failed',
  MAC_KEY_NOT_SUPPORTED_ECU: 'not_support_ecu',
  MAC_KEY_LOW_VOLTAGE: 'low_voltage',
  MAC_KEY_READ_DATA_FAILURE: 'read_data_failure',
  MAC_KEY_INVALID_PROCESS: 'invalid_process',
  MAC_KEY_INVALID_JSON: 'invalid_json',
  MAC_KEY_NOT_FOUND_SEQUENCE: 'not_found_sequence',
  MAC_KEY_DEVICE_OPEN_ERROR: 'device_open_error',
  MAC_KEY_UNABLE_TO_CONNECT: 'unable_to_connect',
  MAC_KEY_SYSTEM_ERROR: 'system_error',
  MAC_KEY_NO_RESPONSE_FROM_KEY_MANAGEMENT_MASTER: 'no_response_from_key_management_master',

  // mac_key process_name
  MAC_KEY_PROCESS_INSPECTION: 'mac_inspection',
  MAC_KEY_PROCESS_UPDATE: 'mac_update',
  MAC_KEY_PROCESS_REGISTRATION: 'key_registration',

  // mac_key platform_gen
  MAC_KEY_PLATFORM_GEN_19EPF: '19ePF',
  MAC_KEY_PLATFORM_GEN_25EPF: '25ePF',

  // immobilizer
  IMB_PROCESS_STATUS_EXECUTING: 'executing',
  IMB_PROCESS_STATUS_COMPLETED: 'completed',

  IMB_STATUS_CHECKING_THE_NUMBER_OF_REGISTER_KEYS: 'checking_the_number_of_registered_keys',
  IMB_STATUS_CHECKING_THE_NUMBER_OF_REGISTER_KEYLESS_ACCESS_KEYS: 'checking_the_number_of_registered_keylesss_access_keys',
  IMB_STATUS_IGON_EXPECTED: 'igon_expected',
  IMB_STATUS_CHECKING_THE_VEHICLE_STATUS: 'checking_the_vehicle_status',
  IMB_STATUS_REMOTE_ENGINE_START_REGISTRATION_IN_PROGRESS: 'remote_engine_start_registration_in_progress',
  IMB_STATUS_CHECKING_THE_NUMBER_OF_REGISTER_ACCESS_KEYS: 'checking_the_number_of_registered_keys',
  IMB_STATUS_TELEMATICS_CU_START_REGISTRATION_IN_PROGRESS: 'telematics_cu_start_registration_in_progress',
  IMB_STATUS_START_GETTING_THE_REGISTRATION_HISTORY: 'start_getting_the_registration_history',
  IMB_STATUS_DELETE_ID_OF_IMMOBILIZER_KEY_START_REGISTRATION_IN_PROGRESS: 'delete_the_id_of_immobilizer_key_start_registration_in_progress',
  IMB_STATUS_CENTRAL_GATEWAY_START_REGISTRATION_IN_PROGRESS: 'central_gateway_start_registration_in_progress',

  IMB_STATUS_COMPLETED_READING_OF_NUMBER_OF_KEYS: 'completed_reading_of_number_of_keys',
  IMB_STATUS_COMPLETED_READING_OF_NUMBER_OF_ACCESS_KEYS: 'completed_reading_of_number_of_access_keys',
  IMB_STATUS_SSM_COMMUNICATION_ABNORMALITY_ERROR: 'imb_status_ssm_communication_abnormality_error',
  IMB_STATUS_KEYS_NOT_REGISTERED: 'no_keys',
  IMB_STATUS_NUM_REGISTERED_KEYS_EXCEEDED: 'imb_status_num_registered_keys_exceeded',
  IMB_STATUS_KEY_REGISTRATION_AVAILABLE: 'key_registration_available',
  IMB_STATUS_INTEGRATED_SECURITY_ACCESS_FAILURE_ERROR: 'integrated_security_access_failure_error',

  IMB_STATUS_REGISTERING_KEY_END: 'registering_key_end',
  IMB_STATUS_KEY_REGISTRATION_REGISTERING: 'key_registration_registering',
  IMB_STATUS_KEY_REGISTRATION_COMPLETE_REGISTER: 'key_registration_complete_register',
  IMB_STATUS_KEY_REGISTRATION_COMPLETE_REGISTER_LIMIT: 'key_registration_complete_register_limit',
  IMB_STATUS_REGISTRATION_REGISTERED: 'key_registration_registered',

  IMB_NET_APP_IMOBI_PROGRESS: 'net_app_imobi_progress',
  IMB_STATUS_IGNITION_OFF: 'igoff',
  IMB_STATUS_PREPARED_TO_START_REGISTRATION: 'prepared_to_start_registration',
  IMB_STATUS_LOW_VOLTAGE: 'low_voltage',
  IMB_STATUS_IOCP_RCID_COMMUNICATION: 'iocp_rcid_communication_error',
  IMB_STATUS_REGISTER_EGI: 'registering_egi',
  IMB_STATUS_REGISTER_ECU: 'registering_ecu',
  IMB_SYS_STATUS_FINISHED_KEY_REG_PROCESS: 'finished_key_registration_process',
  IMB_SYS_STATUS_FINISHED_PROCESS: 'finished',

  IMB_STATUS_WAITING_FOR_OK_BUTTON_PRESSED: 'waiting_for_ok_button_pressed',
  IMB_STATUS_COMMUNICATION_ERROR: 'communication_error',
  IMB_STATUS_MODE_TRANSITION_FAILURE: 'mode_transition_failure',
  IMB_STATUS_FAILED_TO_REGISTRATION: 'failed_to_registration',
  IMB_STATUS_CANNOT_CONFIRM_IMMOBILIZER_UNSET: 'cannot_confirm_immobilizer_unset',
  IMB_STATUS_CANNOT_CONFIRM_THE_CURRENT_STATUS: 'cannot_confirm_the_current_status',
  IMB_STATUS_CANNOT_CONFIRM_ACCESS_KEY_REGISTERED_IN_THE_SYSTEM: 'cannot_confirm_access_key(s)_registered_in_the_system',
  IMB_STATUS_TIME_OUT_OCCURRED_DURING_ACCESS_KEY_COLLATION: 'time_out_occurred_during_access_key_collation',
  IMB_STATUS_TIME_OUT_OCCURRED_DURING_ACCESS_KEY_REGISTRATION: 'time_out_occurred_during_access_key_registration',
  IMB_STATUS_NUMBER_OF_KEY_REGISTRATIONS_EXCEEDED: 'number_of_key_registrations_exceeded',
  IMB_STATUS_TIME_OUT_OCCURRED_WHILE_TURNING_THE_IGNITION_SWITCH_OFF: 'time_out_occurred_while_turning_the_ignition_switch_OFF',
  IMB_STATUS_TIME_OUT_OCCURRED_WHILE_TURNING_THE_IGNITION_SWITCH_ON: 'time_out_occurred_while_turning_the_ignition_switch_ON',

  // IMB commands
  IMB_COMMAND_GET_NUM_REGISTERED_KEYS: 'net_app_read_data_by_Identifier',
  IMB_COMMAND_KEY_CAN_BE_REGISTERED: 'net_app_key_registration',
  IMB_COMMAND_SEND_ECU_KEY: 'net_app_get_ecu_key',
  IMB_COMMAND_NET_APP_KEY_MATCHING: 'net_app_key_matching',
  IMB_COMMAND_COMPLETED_REGISTRATION: "completed_registration",
  IMB_COMMAND_NET_APP_OK_WAIT_DISPLAY: "net_app_ok_wait_display",
  IMB_COMMAND_NET_APP_ACCESS_KEY_REGISTRATION: "net_app_access_key_registration",
  IMB_COMMAND_NET_APP_IMOBI_START: 'net_app_imobi_start',

  // imobi cmd numbers
  REGISTER_ECU: 1,
  REGISTER_REMOTE_ENGINE_START_CU: 2,
  REGISTER_TELEMATICS_CU: 3,
  REGISTER_IMMOBILIZER_SYSTEM: 4,
  REGISTER_KEY_LESS_IMMOBILIZER: 5,
  GET_THE_NUMBER_OF_REGISTERED_KEYS: 6,
  GET_THE_NUMBER_OF_REGISTERED_ACCESS_KEY: 7,
  DELETE_THE_ID_OF_IMMOBILIZER_KEY: 8,
  REGISTER_CENTRAL_GATEWAY: 9,
  REGISTER_COMBINATION_METER: 10,
  REGISTER_STEERING_LOCK_CU: 11,
  REGISTER_HPCU: 12,
  DELETE_ACCESS_KEY_ID: 13,
  GET_THE_REGISTRATION_HISTORY: 14,

  // process name for each feature
  PROCESS_NAME_REGISTER_ECU: 'register_ecu',
  PROCESS_NAME_REGISTER_REMOTE_ENGINE_START_CU: 'register_remote_engine_start',
  PROCESS_NAME_REGISTER_TELEMATICS_CU: 'register_telematics',
  PROCESS_NAME_REGISTER_IMMOBILIZER_SYSTEM: 'register_imb_system',
  PROCESS_NAME_GET_THE_NUMBER_OF_REGISTERED_KEYS: 'num_registered_keys',
  PROCESS_NAME_GET_THE_NUMBER_OF_REGISTERED_ACCESS_KEY: 'num_registered_access_keys',
  PROCESS_NAME_REGISTER_CENTRAL_GATEWAY: 'register_cgw',
  PROCESS_NAME_REGISTER_COMBINATION_METER: 'register_combination_meter',
  PROCESS_NAME_REGISTER_KEY_LESS_IMMOBILIZER: 'register_imb_system_key_less',
  PROCESS_NAME_DELETE_THE_ID_OF_IMMOBILIZER_KEY: 'delete_imb_key_id',
  PROCESS_NAME_REGISTER_STEERING_LOCK_CU: 'register_steering_lock',
  PROCESS_NAME_DELETE_ACCESS_KEY_ID: 'delete_access_key_id',
  PROCESS_NAME_GET_THE_REGISTRATION_HISTORY: 'registration_history',

  // register immobilizer system
  IMB_STATUS_ADD_KEY_MODE_MATCHING_KEY_MATCHING: 'add_key_mode_matching_key_matching',
  IMB_STATUS_ADD_KEY_MODE_MATCHING_KEY_END: 'add_key_mode_matching_key_end',
  IMB_STATUS_KEY_REGISTRATION_REGISTED: 'key_registration_registed',
  IMB_STATUS_ALL_KEY_MATCHING_READING: 'all_key_matching_reading',
  IMB_STATUS_ALL_KEY_MATCHING_MATCHING: 'all_key_matching_matching',
  IMB_STATUS_ALL_KEY_MATCHING_READ_OUT: 'all_key_matching_read_out',
  IMB_STATUS_ALL_KEY_MATCHING_READ_COMPLETE: 'all_key_matching_read_complete',
  IMB_STATUS_COMPLETED: 'completed_registration',
  IMB_STATUS_FAILURE: 'failure',
  IMB_STATUS_PROCESSING: 'processing',
  IMB_STATUS_MATCHING_KEY1_MATCHING: 'matching_key1_matching',
  IMB_STATUS_MATCHING_KEY1_END: 'matching_key1_end',
  IMB_STATUS_KEY_MATCHING2_READING: 'key_matching2_reading',
  IMB_STATUS_KEY_MATCHING2_READ_OUT: 'key_matching2_read_out',
  IMB_STATUS_KEY_MATCHING2_READ_COMPLETE: 'key_matching2_read_complete',
  IMB_STATUS_START_BIU_EXCHANGE: 'start_biu_exchange',
  IMB_STATUS_IMMOBILIZER_REGISTRATION_START_REGISTRATION_IN_PROGRESS: 'immobilizer_registration_start_registration_in_progress',
  IMB_STATUS_REMOTE_ENIGINE_START_REGISTRATION_IN_PROGRESS: 'remote_engine_start_registration_in_progress',
  IMB_STATUS_TELEMATICS_CU_REGISTRATION_IN_PROGRESS: 'telematics_CU_registration_in_progress',
  IMB_STATUS_TRANSITIONING_REGISTRATION_MODE: 'transitioning_registration_mode',
  IMB_STATUS_CHECKING_CODE: 'checking_code',
  IMB_STATUS_CHECKING_OWNER_TYPE2: 'checking_owner_type2',
  IMB_STATUS_CHECKING_OWNER: 'checking_owner',
  IMB_STATUS_REGISTERING: 'registering',
  IMB_STATUS_REGISTER_LIMIT: 'register_limit',
  IMB_STATUS_REGISTERING_ACCESS_KEY: 'registering_access_key',
  IMB_STATUS_IMOBI_IGNITION_OFF: 'imobi_ignition_off',
  IMB_STATUS_IMOBI_IGNITION_ON: 'imobi_ignition_on',
  IMB_STATUS_INITIALIZATION_COMMUNICATION: 'initialization_communication',
  IMB_STATUS_REGISTERING_TELEMATICS: 'registering_telematics',
  IMB_STATUS_REGISTERING_REMOTE_START: 'registering_remote_start',
  IMB_STATUS_DELETE_ID_OF_IMMOBILIZER_KEY_IN_PROGRESS: 'delete_id_of_immobilizer_key_in_progress',
  IMB_STATUS_COMPLETE: 'completed',
  IMB_STATUS_IGNORE_CURRENT_PROGRAM_STATUS: 'priority_iot_message',

  //immoblizer iocp rcid status
  IMB_IOCP_RCID_CODE_MISMATCHED_BETWEEN_KEYLESS_ACCESS_CM_OR_ID: '1001',
  IMB_IOCP_RCID_COMMUNICATION_FAILED_BETWEEN_ECM_KEYLESS_ACCESS: '1002',
  IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_EEPROM_FAILURE: '1006',
  IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAILURE: '1007',
  IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_FAILURE: '1010',
  IMB_IOCP_RCID_PREPARATION_INCORRECT: '0000',
  IMB_IOCP_RCID_NOT_PERFORMED: '0010',
  IMB_IOCP_RCID_OTHER_FUNCTIONS_RUNNING: '0011',
  IMB_IOCP_RCID_COMM_FAIL_BETWEEN_IMB_CM_AND_RES_CM: '1020',
  IMB_IOCP_RCID_ANTENNA_FAILURE: '1012',
  IMB_IOCP_RCID_COMM_FAILURE_BETWEEN_IMB_CM_AND_REMOTE_ENGINE_START_CM: '1021',
  IMB_IOCP_RCID_COMMUNICATION_FAILURE_BETWEEN_IMB_CM_AND_TELEMATICS_CM: '1030',
  IMB_IOCP_RCID_TELEMATICS_CM_REGISTRATION_FAILURE: '1031',
  IMB_IOCP_RCID_CENTRAL_GATEWAY_COMMUNICATION_FAILURE: '1040',
  IMB_IOCP_RCID_CENTRAL_GATEWAY_IDENTIFICATION_FAILURE: '1041',
  IMB_IOCP_RCID_CENTRAL_GATEWAY_FAILURE: '1050',
  IMB_IOCP_RCID_TIMEOUT: '1090',
  IMB_IOCP_RCID_COMM_FAILURE_BET_IMB_CM_AND_RES_CM: '1015',
  IMB_IOCP_RCID_COMM_FAILURE_BET_IMMOBILIZER_CM_AND_TELEMATICS_CM: '1017',
  IMB_IOCP_RCID_TELEMATICS_CM_REG_FAILURE: '1016',
  IMB_IOCP_RCID_CENTRAL_GATEWAY_FAIL: '1003',
  IMB_IOCP_RCID_TRANSPONDER_COMM_FAIL: '1004',
  IMB_IOCP_RCID_TRANSPONDER_IDENTIFICATION_FAIL: '1005',
  IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAIL: '1008',
  IMB_IOCP_RCID_REMOTE_ENGINE_START_CM_REGISTRATION_FAILURE: '1014',
  IMB_IOCP_RCID_ANTENNA_FAIL: '1000',
  IMB_IOCP_RCID_COMBINATION_METER_SECURITY_CM_COMMUNICATION_FAILURE: '1011',
  IMB_IOCP_RCID_CENTERAL_GATE_FAILURE: '1042',

  //Immobilizer frontend process numbers
  GET_REGISTERED_KEYS_PROCESS_NO: 1,
  GET_REGISTERED_ACCESSS_KEYS_PROCESS_NO: 2,
  REGISTER_ECU_TYPE_1_PROCESS_NO: 3,
  REGISTER_ECU_TYPE_2_PROCESS_NO: 4,
  REGISTER_ECU_TYPE_3_PROCESS_NO: 5,
  REGISTER_IMB_SYSTEM_TYPE_1_PROCESS_NO: 6,
  REGISTER_IMB_SYSTEM_TYPE_2_PROCESS_NO: 7,
  REGISTER_KEYLESS_IMB_SYSTEM_TYPE_3_PROCESS_NO: 8,
  REMOTE_ENGINE_START_CU_TYPE_1_PROCESS_NO: 9,
  REMOTE_ENGINE_START_CU_TYPE_2_PROCESS_NO: 10,
  REMOTE_ENGINE_START_CU_TYPE_3_PROCESS_NO: 11,
  TELEMATICS_CU_TYPE_2_PROCESS_NO: 12,
  TELEMATICS_CU_TYPE_3_PROCESS_NO: 13,
  REGISTER_CENTRAL_GATEWAY_TYPE_2_PROCESS_NO: 14,
  DELETE_ID_OF_IMB_KEY_TYPE_1_PROCESS_NO: 15,
  DELETE_ID_OF_IMB_KEY_TYPE_2_PROCESS_NO: 16,
  DELETE_ID_OF_ACCESS_KEY_TYPE_3_PROCESS_NO: 17,
  REGISTER_STEERING_LOCK_CU_TYPE_3_PROCESS_NO: 18,
  REGISTER_COMBINATION_METER_TYPE_1_PROCESS_NO: 19,
  REGISTER_COMBINATION_METER_TYPE_2_PROCESS_NO: 20,
  GET_REGISTRATION_HISTORY_TYPE_3_PROCESS_NO: 21,

  // delete id of imb key status
  IMB_STATUS_KEY_CANNOT_DELETE_ANYMORE: 'can_not_delete_key_anymore',
  IMB_STATUS_ERASING_KEY_ID: 'erasing_key_id',

  IMB_STATUS_WAITING_FOR_KEY_REMOVAL: 'waiting_for_key_removal',

  // register combination meter status
  IMB_STATUS_KEY_NOT_FOUND: 'key_not_found',

  // get registration history
  IMB_STATUS_CONFIRM_EXEC: 'confirm_exec',

  //frontend api end points
  IMB_START_REGISTRAION_REGISTER_ECU: '/registerECUStartRegistration/',
  IMB_IG_ON_REGISTER_ECU: '/imbregisterECUIgOn/',
  IMB_START_REGISTRAION_REGISTER_IMB_SYSTEM: '/registerImmobilizerStartRegistration/',
  IMB_IG_ON_REGISTER_IMB_SYSTEM: '/imbRegisterImmobilizerIgon/',
  IMB_START_REGISTRAION_REGISTER_KEYLESS_IMB_SYSTEM: '/startRegistrationKeylessImmobilizer/',
  IMB_START_REGISTER_REMOTE_ENGINE_START_CU: '/registerRemoteEngineStartRegistration/',
  IMB_IG_ON_REGISTER_REMOTE_ENGINE_START_CU: '/registerRemoteEngineStartIgOn/',
  IMB_NOTIFY_OK_BTN_PUSHED: '/notifyOkBtnPushed/',
  IMB_IG_ON_REGISTER_COMBINATION_METER: '/registerCombinationMeterIgOn/',
  IMB_START_REGISTRATION_REGISTER_COMBINATION_METER: '/registerCombinationMeterStartRegistration/',
  IMB_START_TELEMATICS_CU: '/registerTelematicsStartRegistration/',
  IMB_START_TELEMATICS_CU_IG_ON: '/registerTelematicsIgOn/',
  IMB_START_TELEMATICS_CU_KEYLESS: '/registerTelematicsStartRegistrationKeyless/',
  IMB_START_REGISTRATION_REMOTEENGINE_STARTREGISTRATION_KEYLESS: '/registerRemoteEngineStartRegistrationKeyless/',
  IMB_START_REGISTER_CENTRAL_GATEWAY: '/registerCentralGatewayStartRegistration/',
  IMB_IGON_REGISTER_CENTRAL_GATEWAY: '/registerCentralGatewayIgOn/',
  IMB_IGON_DELETE_IMB_ID_KEY: '/deleteIdOfImmobilizerIgOn/',
  IMB_START_REGISTER_STEERING_LOCK_CU: '/registerSteeringLockStartRegistration/',
  IMB_GET_REGISTRATION_HISTORY_NOTIFY_OK_BTN_PUSHED: '/imbGetRegistrationHistoryNotifyOkBtnPushed/',
  IMB_START_DELETE_KEYS: '/startDeleteKeys/',
  IMB_START_DELETION: '/startDeletion/',
  IMB_START_REGISTRATION_HISTORY: '/getRegistrationHistoryStartReadingHistory/',
  IMB_CREATE_EVENT_LOG_RECORD: '/createEventLogRecord/',


  //IMB display codes
  IMB_DISPLAY_CODE_IG_ON_AGAIN: 1,
  IMB_DISPLAY_CODE_COMBINATION_METER_SECURITY_CM_IS_NOT_COMPLETED: 2,
  IMB_DISPLAY_CODE_COMBINATION_METER_SECURITY_CM_REGISTRATION_FAILURE: 3,
  IMB_DISPLAY_CODE_SSM_COMMUNICATION_ABNORMALITY: 4,
  IMB_DISPLAY_CODE_ACCESS_CM_REGISTRATION: 7,
  IMB_DISPLAY_CODE_ACCESS_IMB_REGISTRATION: 8,
  IMB_DISPLAY_CODE_PREV_REG_HISTORY: 5,
  IMB_DISPLAY_CODE_READING_HISTORY: 6,

  IMB_MESSAGE_REGISTRATION_HISTORY: 'message_registration_history',
  IMB_MESSAGE_NORMAL_REGISTRATION: 'message_normal_registration',
  IMB_MESSAGE_INCORRECT_KEY_CODE: 'message_incorrect_key_code',
  IMB_MESSAGE_INCOMPATIBLE_KEY_DETECTED: 'message_incompatible_key_detected',
  IMB_MESSAGE_DIFFERENT_RADIO_WAVE_CHARACTERISTICS: 'message_different_radio_wave_characteristics',
  IMB_MESSAGE_DIFFERENT_FREQUENCY: 'message_different_frequency',
  IMB_MESSAGE_DIFFERENT_APPEARANCES: 'message_different_appearances',
  IMB_MESSAGE_KEY_COLLATION_TIME_OUT: 'message_key_collation_time_out',
  IMB_MESSAGE_SW_PASS_OVER_COLLATION: 'message_sw_pass_over_collation',
  IMB_MESSAGE_INDOOR_ANTENNA_COLLATION: 'message_indoor_antenna_collation',
  IMB_MESSAGE_PREVIOUSLY_REGISTERED_KEY_DETECTED: 'message_previously_registered_key_detected',
  IMB_MESSAGE_REGISTERED_KEY_PASSING: 'message_registered_key_passing',
  IMB_MESSAGE_MAIN_KEY_CONFIRMATION_FAILURE: 'message_main_key_confirmation_failure',
  IMB_MESSAGE_KEY_OR_CONTROL_MODULE_MISMATCH: 'message_key_or_control_module_mismatch',
  IMB_MESSAGE_REGISTERED_KEY_FROM_ANOTHER_VEHICLE_DETECTED: 'message_registered_key_from_another_vehicle_detected',
  IMB_MESSAGE_DIFFERENT_CERTIFICATION_CODE_DETECTED: 'message_different_certification_code_detected',
  IMB_MESSAGE_BETWEEN_ID_CODE_BOX: 'message_between_id_code_box',
  IMB_MESSAGE_BETWEEN_STEERING_LOCK_CM: 'message_between_steering_lock_cm',
  IMB_MESSAGE_CERTIFICATION_CODE_HAS_ALREADY_REGISTERED: 'message_certification_code_has_already_registered',
  IMB_MESSAGE_TIME_OUT: 'message_time_out',
  IMB_MESSAGE_SW_PASS_OVER_COLLATION_SYSTEM_INCONSISTENCY: 'message_sw_pass_over_collation_system_inconsistency',
  IMB_MESSAGE_INDOOR_ANTENNA_COLLATION_SYSTEM_INCONSISTENCY: 'message_indoor_antenna_collation_system_inconsistency',
  IMB_MESSAGE_SYSTEM_RESPONSE_INCONSISTENCY: 'message_system_response_inconsistency',
  IMB_MESSAGE_PRECONDITION_FAILURE: 'message_precondition_failure',
  IMB_MESSAGE_ID_CODE_BOX_SPECIFICATION: 'message_id_code_box_specification',
  IMB_MESSAGE_PUSH_BUTTON_IGNITION_SWITCH_FAILURE: 'message_push_button_ignition_switch_failure',
  IMB_MESSAGE_DETECTION_VEHICLE_SPEED: 'message_detection_vehicle_speed',
  IMB_MESSAGE_LIN_COMMUNICATION_FAILURE: 'message_lin_communication_failure',
  IMB_MESSAGE_DIAGNOSIS_COMMUNICATION_FAILURE: 'message_diagnosis_communication_failure',
  IMB_MESSAGE_WHILE_SPECIAL_MODE: 'message_while_special_mode',
  IMB_MESSAGE_DATA_WRITING_FAILURE: 'message_data_writing_failure',
  IMB_MESSAGE_CLASSIFICATION_CODE_KEYLESS_ACCESS_CM: 'message_classification_code_keyless_access_cm',
  IMB_MESSAGE_CERTIFICATION_CODE_ID_CODE_BOX: 'message_certification_code_id_code_box',
  IMB_MESSAGE_CERTIFICATION_CODE_STEERING_LOCK_CM: 'message_certification_code_steering_lock_cm',
  IMB_MESSAGE_REGISTRATION_INFORMATION_OF_KEYLESS_ACCESS_CM: 'message_registration_information_of_keyless_access_cm',
  IMB_MESSAGE_ID_CODE_BOX_STEERING_LOCK_CM_ABNORMAL_RESPONSE: 'message_id_code_box_steering_lock_cm_abnormal_response',
  IMB_MESSAGE_TEMPORARY_DATE_MISMATCH: 'message_temporary_date_mismatch',
  IMB_REGISTERING_REMOTE_START: 'registering_remote_start',
  IMB_REGISTERING_COMBINATION_METER: 'registering_combination',

  // repro
  REPRO_REQUEST_STATUS_IN_PROGRESS: 'in_progress',
  REPRO_REQUEST_STATUS_COMPLETED: 'completed',
  REPRO_REQUEST_STATUS_FAILED: 'failed',
  REPRO_REQUEST_COMMAND_INITIALIZE: 'initialize',
  REPRO_REQUEST_COMMAND_INDIVIDUAL_DTC: 'individual_dtc',
  REPRO_REQUEST_COMMAND_READINESS_CODE: 'readiness_code',
  REPRO_REQUEST_COMMAND_READ_REAL_WORLD_DATA: 'read_real_world_data',

  LEGISLATED_TYPE_PID: 'pid',
  LEGISLATED_TYPE_FFD: 'ffd',
  LEGISLATED_TYPE_TID: 'tid',
  LEGISLATED_TYPE_MID: 'mid',
  LEGISLATED_TYPE_INFO_TYPE: 'info_type',

  UPPER_LAYER_PROTOCOL_FHI_CAN: 'FHI_CAN',
  UPPER_LAYER_PROTOCOL_PHASE4: 'Phase4',
  UPPER_LAYER_PROTOCOL_PHASE5: 'Phase5',
  UPPER_LAYER_PROTOCOL_SAEJ1979: 'SAEJ1979',
  UPPER_LAYER_PROTOCOL_OBD_ON_UDS: 'OBDonUDS',

  // work support
  WORK_SUPPORT_REQUEST_STATUS_IN_PROGRESS: 'in_progress',
  WORK_SUPPORT_REQUEST_STATUS_COMPLETED: 'completed',
  WORK_SUPPORT_REQUEST_STATUS_FAILED: 'failed',
  WORK_SUPPORT_REQUEST_COMMAND_REQUIREMENT: "requirement",
  WORK_SUPPORT_REQUEST_COMMAND_INITIALIZE: "initialize",

  // vehicle-control-history
  VEHICLE_CONTROL_HISTORY_REQUEST_STATUS_IN_PROGRESS: 'in_progress',
  VEHICLE_CONTROL_HISTORY_REQUEST_STATUS_COMPLETED: 'completed',
  VEHICLE_CONTROL_HISTORY_REQUEST_STATUS_FAILED: 'failed',
  VEHICLE_CONTROL_HISTORY_COMMAND_MEASURED_DATA: "measured_data",
  VEHICLE_CONTROL_HISTORY_COMMAND_INITIALIZE: "initialize",
  VEHICLE_CONTROL_HISTORY_COMMAND_CLEAR: "vch_clear",

  BMD_DATA_TYPE_03: 'subFcn03',
  BMD_DATA_TYPE_13: 'subFcn13',

  // customize
  CUSTOMIZE_REQUEST_STATUS_IN_PROGRESS: 'in_progress',
  CUSTOMIZE_REQUEST_STATUS_COMPLETED: 'completed',
  CUSTOMIZE_REQUEST_STATUS_FAILED: 'failed',
  CUSTOMIZE_REQUEST_COMMAND_INITIALIZE: "initialize",
  CUSTOMIZE_REQUEST_COMMAND_READ: 'customize_read',
  CUSTOMIZE_REQUEST_COMMAND_WRITE: 'customize_write',
  CUSTOMIZE_RESULT_STATUS_NOT_AVAILABLE: 'not_available',
  CUSTOMIZE_RESULT_STATUS_FAILURE: 'failure',
  CUSTOMIZE_RESULT_STATUS_ERROR: 'error',
  CUSTOMIZE_RESULT_STATUS_SUCCESS: 'success',

  // initialize
  INITIALIZATION_STATUS_IN_PROGRESS: 'in_progress',
  INITIALIZATION_STATUS_COMPLETED: 'completed',
  INITIALIZATION_STATUS_FAILED: 'failed',

  INITIALIZATION_DATA_TYPE_SUPPORT_IDS: 'support_ids',
  INITIALIZATION_DATA_TYPE_CONFIG: 'CONFIG',
  INITIALIZATION_DATA_TYPE_MENU_SUPPORT: 'menu_support',
  INITIALIZATION_DATA_TYPE_SPECIAL_MENU_SUPPORT: 'special_menu_support',
  INITIALIZATION_DATA_TYPE_OBD_IDENTIFIER_DATA: 'obd_did_data',
  INITIALIZATION_DATA_TYPE_AT_MENU_SUPPORT: 'at_menu_support',

  // datamonitor-activetest
  DATA_MONITOR_ACTIVE_TEST_STATUS_IN_PROGRESS: 'in_progress',
  DATA_MONITOR_ACTIVE_TEST_STATUS_COMPLETED: 'completed',
  DATA_MONITOR_ACTIVE_TEST_STATUS_FAILED: 'failed',
  DATA_MONITOR_ACTIVE_TEST_COMMAND_INITIALIZE: "initialize",
  DATA_MONITOR_ACTIVE_TEST_COMMAND_REQUIREMENT: "requirement",
  DATA_MONITOR_ACTIVE_TEST_FUNCTION_TYPE_DATA_MONITOR: "data_monitor",
  DATA_MONITOR_ACTIVE_TEST_FUNCTION_TYPE_ACTIVE_TEST: "active_test",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_START: "start",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_STOP: "stop",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_ERROR_TERMINATE: "errorTerminate",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_READ: "read",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_STANDBY: "standby",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_TERMINATE: "terminate",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_START_TO_CC: "start",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_STOP_TO_CC: "stop",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_READ_TO_CC: "read",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_STANDBY_TO_CC: "standby",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_TERMINATE_TO_CC: "terminate",
  DATA_MONITOR_ACTIVE_TEST_SEQUENCE_TYPE_ERROR_TERMINATE_TO_CC: "error_terminate",
  DATA_MONITOR_ACTIVE_TEST_STATUS_COMPLETE_FROM_CC: 'complete',
  DATA_MONITOR_ACTIVE_TEST_STATUS_IN_PROGRESS_FROM_CC: 'in_progress',
  DATA_MONITOR_ACTIVE_TEST_ERROR_TYPE_STATUS: 'statusError',
  DATA_MONITOR_ACTIVE_TEST_ERROR_TYPE_COMMUNICATION: 'communicationError',
  DATA_MONITOR_ACTIVE_TEST_ERROR_TYPE_NRC: 'nrcError',

  // special handling system for FFD/BMD
  DIAG_SPECIAL_HANDLING_FHI_CAN_FFD: ['EGI_OLD', 'EGI', 'PU_EGI', 'EGI_25ePF', 'TM', 'TM_OLD', 'TM_25ePF', 'HPC', 'PU_HPC'],

  // Plan management related constants
  LANGUAGES_SUPPORTED: ["ja", "en", "de", "es", "fr", "it", "ru", "zh"],
  DEFAULT_PLAN_ID: 1,
  DEFAULT_PLAN_ID_SOA_USER: 2,
  PLAN_NAME_MAX_LEN: 64,

  // Function key names
  FUNCTION_TYPE_REPRO: 'repro',
  FUNCTION_TYPE_DATA_PLAYBACK: 'data_playback',
  FUNCTION_TYPE_DIAGNOSIS: 'diagnosis',
  FUNCTION_TYPE_IMMOBILIZER: 'immobilizer',
  FUNCTION_TYPE_MAC_KEY: 'mackey',
  FUNCTION_TYPE_INDIVIDUAL_DIAGNOSIS: 'individual_diagnosis',
  FUNCTION_TYPE_REAL_WORLD_DATA: 'real_world_data',
  FUNCTION_TYPE_SPECIAL_FEATURE: 'specialFeatureEcuTable',
  FUNCTION_TYPE_INDIVIDUAL: 'individualEcuTable',

  // cancel-code
  CANCEL_CODE_REQUEST_STATUS_IN_PROGRESS: 'in_progress',
  CANCEL_CODE_REQUEST_STATUS_COMPLETED: 'completed',
  CANCEL_CODE_REQUEST_STATUS_FAILED: 'failed',
  CANCEL_CODE_REQUEST_COMMAND_INITIALIZE: 'initialize',
  CANCEL_CODE_REQUEST_COMMAND_MEASURED_DATA: 'measured_data',
  CANCEL_CODE_REQUEST_COMMAND_CRUISE_SW: 'cruise_sw',

  // Event Log
  EVENT_LOG_TABLE_G1PK_VEHICLE_COM: 'vehicleCom',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_SUPPORT_IDS: 'read_support_ids',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_CONFIG: 'read_config',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_SYSTEM_TIMESTAMP: 'read_system_timestamp',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_DIAG_INFORMATION: 'read_diag_information',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_CLEAR_DTC_LIST: 'clear_dtc_list',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_READINESS_CODE: 'read_readiness_code',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_BEHAVIOR_INFORMATION: 'read_behavior_information',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_CLEAR_BEHAVIOR_INFORMATION: 'clear_behavior_information',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_MENU_SUPPORT: 'read_menu_support',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_CANCEL_CODE_COMMON: 'read_cancel_code_common',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_CANCEL_CODE_RCR: 'read_cancel_code_rcr',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_EXECUTE_WORK_SUPPORT: 'execute_work_support',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_CUSTOMIZE_READ: 'customize_read',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_CUSTOMIZE_WRITE: 'customize_write',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_CGW_SUPPORT_CHECK: 'cgw_support_check',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_INSTALLED_COMMUNICATED_ECU_LIST: 'installed_communicated_ecu_list',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_INSTALLED_COMMUNICATED_ECU_OTHER: 'installed_communicated_ecu_other',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_LEGISLATED_SUPPORT_IDS: 'read_legislated_support_ids',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_LEGISLATED_DATA: 'read_legislated_data',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_VEHICLE_TIMESTAMP: 'read_vehicle_timestamp',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_IMB_VEHICLE_SPECIFICATION: 'imb_vehicle_specification',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_ALL_TARGET_INQUIRY: 'all_target_inquiry',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_DIAG_INFORMATION_LIST: 'read_diag_information_list',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_SUPPORT_PROTOCOL: 'read_support_protocol',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_ISA_SUPPORT: 'read_isa_support',
  EVENT_LOG_TABLE_VEHICLE_COM_FUNCTION_READ_REAL_WORLD_DATA: 'read_real_world_data',
  EVENT_LOG_TYPE_REPRO: 'repro',
  EVENT_LOG_TYPE_VEHICLE_COM: 'vehicleCom',
  EVENT_LOG_TYPE_ALL_DTC: 'alldtc',
  EVENT_LOG_TYPE_PAGE_VIEW: 'pageView',
  EVENT_LOG_RESULT_FAILED: 'Failed',
  EVENT_LOG_RESULT_SUCCESS: 'Success',
  REPRO_JOB_TYPE_EXPORT_PROJECT_EVENT_LOG: 'export_eventlog_project',
  REPRO_JOB_TYPE_LIST_EVENT_LOG_MANAGE: 'list_eventlog_manage',
  REPRO_JOB_TYPE_LIST_EVENT_LOG: 'list_eventlog_project',
  REPRO_JOB_TYPE_EXPORT_EVENT_LOG_MANAGE: 'export_eventlog_manage',
  REPRO_JOB_TYPE_EXPORT_EVENT_LOG_PAGE_VIEW: 'export_eventlog_page_view',
  REPRO_JOB_STATUS_PROGRESS: 'processing',
  REPRO_JOB_STATUS_SUCCESS: 'success',
  REPRO_JOB_STATUS_FAILED: 'failed',
  IMB_JOB_STATUS_PROGRESS: 'processing',
  IMB_JOB_STATUS_SUCCESS: 'success',
  IMB_JOB_STATUS_FAILED: 'failed',
  IMB_JOB_TYPE_EXPORT_PROJECT_EVENT_LOG: 'export_eventlog_project',
  IMB_JOB_TYPE_LIST_EVENT_LOG: 'list_eventlog_project',
  MAC_JOB_STATUS_PROGRESS: 'processing',
  MAC_JOB_STATUS_SUCCESS: 'success',
  MAC_JOB_STATUS_FAILED: 'failed',
  MAC_JOB_TYPE_EXPORT_PROJECT_EVENT_LOG: 'export_eventlog_project',
  MAC_JOB_TYPE_LIST_EVENT_LOG: 'list_eventlog_project',

  // Function Name for Imb Event Log
  FUNCTION_NAME_REGISTER_ECU: 'Register ECU',
  FUNCTION_NAME_REGISTER_CENTRAL_GATEWAY: 'Register Central Gateway',
  FUNCTION_NAME_REGISTER_COMBINATION_METER: 'Register Combination Meter',
  FUNCTION_NAME_GET_THE_NUMBER_OF_REGISTERED_KEY: 'Get the number of registered keys',
  FUNCTION_NAME_GET_THE_NUMBER_OF_REGISTERED_ACCESS_KEY: 'Get the number of registered access keys',
  FUNCTION_NAME_DELETE_ACCESS_KEY_ID: 'Delete Access Key ID',
  FUNCTION_NAME_REGISTER_REMOTE_ENGINE_START_CU: 'Register Remote Engine Start CU',
  FUNCTION_NAME_REGISTER_TELEMATICS_CU: 'Register Telematics CU',
  FUNCTION_NAME_REGISTER_KEYLESS_IMMOBILIZER: 'Register Keyless Immobilizer',
  FUNCTION_NAME_REGISTER_STEERING_LOCK_CU: 'Register Steering Lock CU',
  FUNCTION_NAME_GET_THE_REGISTRATION_HISTORY: 'Get the registration history',
  FUNCTION_NAME_REGISTER_IMMOBILIZER_SYSTEM: 'Register Immobilizer system',
  FUNCTION_NAME_DELETE_IMMOBILIZER_KEY_ID: 'Delete Immobilizer Key ID',

  // Page Name for Page View Event Log
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_ALL_DTC: 'allDtc',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_INDIVIDUAL_DTC: 'individualDiagCode',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_INDIVIDUAL_VCH: 'individualVehicleControlHistory',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_INDIVIDUAL_CST: 'individualCustomize',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_INDIVIDUAL_CNC: 'individualCancelCode',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_INDIVIDUAL_WSP: 'individualWorkSupport',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_SPECIAL_WSP: 'specialWorkSupport',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_SPECIAL_DTC: 'specialDiagCode',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_SPECIAL_CNC: 'specialCancelCode',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_IMMOBILIZER: 'immobilizer',
  EVENT_LOG_PAGE_VIEW_PAGE_NAME_MAC_KEY: 'macKey',

  EVENT_LOG_PAGE_VIEW_PAGE_NAME_MANAGE: [
    { page_name: 'allDtc', display_page_name: 'ALL DTC' },
    { page_name: 'individualDiagCode', display_page_name: 'DTC' },
    { page_name: 'individualWorkSupport', display_page_name: 'Work support' },
    { page_name: 'individualVehicleControlHistory', display_page_name: 'Control Operation History' },
    { page_name: 'individualCustomize', display_page_name: 'Customize' },
    { page_name: 'individualCancelCode', display_page_name: 'Cancel code' },
    { page_name: 'macKey', display_page_name: 'Register Unit' },
    { page_name: 'immobilizer', display_page_name: 'Immobilizer' },
    { page_name: 'specialWorkSupport', display_page_name: 'Special Feature(Work support)' },
    { page_name: 'specialDiagCode', display_page_name: 'Special Feature(DTC)' },
    { page_name: 'specialCancelCode', display_page_name: 'Special Feature(Cancel code)' },
  ],

  // Function Name for Mac Event Log
  FUNCTION_NAME_REGISTRATION_MAC_KEY: 'Registration Mac key',
  FUNCTION_NAME_INSPECTION_MAC_KEY: 'Inspection MAC key',
  FUNCTION_NAME_REGISTRATION_AUTH_KEY: 'Registration auth key',

  // ログレベル
  SILLY: 'silly',
  VERBOSE: 'verbose',
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  EXCEPTION: 'exception',

  // Device communication protocol
  DEVICE_COMMUNICATION_PROTOCOL_CAN: 'can',
  DEVICE_COMMUNICATION_PROTOCOL_CANFD: 'canfd',
  DEVICE_COMMUNICATION_PROTOCOL_IP: 'ip',

  // Gateway ECU type
  GATEWAY_ECU_TYPE_CECU: 'c-ecu',
  GATEWAY_ECU_TYPE_CGW: 'cgw',
  GATEWAY_ECU_TYPE_NONE: 'none',

  // address format type
  ADDRESS_TYPE_NORMAL: 'normal',
  ADDRESS_TYPE_EXTENDED: 'extended',

  // can format type
  CAN_FORMAT_TYPE_NORMAL: 'normal',
  CAN_FORMAT_TYPE_EXTENDED: 'extended',

  // Execute Data Monitoring
  Command_NetApp_Execute_Data_Monitoring: "net_app_execute_data_monitoring",
  Command_NetApp_Execute_Active_Testing: "net_app_execute_active_test",

  // Necessary Item Names for FFD (and BMD)
  FFD_NECESSARY_ITEM_NAMES: [
    "byte", "bit", "data", "monitor_order", "unit",
    "data_type", "digit_after_decimal", "time_format",
    "byte_or_bit", "bit_size", "byte_size", "signature", "scaling_bit", "offset"
  ]
};
