<b-modal v-model="modal_requirement_113" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start' ">
        {{ $t(`individual.common_title_execution`) }}
      </template>
      <template v-if="phase === 'completed' ">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'error' || phase === 'commonError' ">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 完了画面 -->
        <template v-if="phase === 'completed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">
              <div v-html="$t(`${i18nWsKey}.label_reading_param_complete`)"></div>
              <div>{{ $t(`${i18nWsKey}.label_save_date_reading_param`,[showRegisteredDay]) }}</div>
              <div>{{ $t(`${i18nWsKey}.label_data_id_reading_param`,[resDataId]) }}</div>
            </div>
          </div>
        </template>
        <!-- エラー -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_113_TXT_1`) }}</div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'commonError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t('individual.error_communication_error') }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 完了画面 -->
      <template v-if="phase === 'completed'">
        <b-button size="sm" variant="primary" @click="onOkClicked" class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'commonError'">
        <b-button size="sm" variant="primary" @click="onOkClicked" class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>