<div class="container-fluid ml-n2">

  <b-modal v-model="modalconfirmnoofregkeys" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div class="text-left w-auto m-4">
        <div>
          <b>{{$t('imb.get_the_number_of_registered_keys_confirm_num_reg_key')}}</b>
        </div>
        <div>
          <b>{{$t('imb.common_press_ok_or_cancel')}}</b>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button variant="outline-primary" v-on:click="cancelButtonClick()">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button variant="primary" v-on:click="onClickOkGetRegKeys()" class="ml-4">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalimbgetregkeyresult" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('imb.common_result')}}
      </div>
    </template>
    <template v-slot:default>
      <div class="text-center w-100 mt-3">
        {{ imbStatusInfo.error_message }}
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="okButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalimbgetregkeyerror" size="lg" header-bg-variant="danger" header-text-variant="light" no-stacking
    centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_error')}}
      </div>
    </template>
    <template v-slot:default>
      <div v-if="!isCausesAndActionDialog(imbStatusInfo.error_code)">
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div v-else>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
          <div class="col-9">{{ imbStatusInfo.error_code }}</div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_cause_message"></div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_action_message"></div>
        </div>
      </div>
      <div>
        <div class="text-center w-100 mt-2">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="okButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</div>