<div class="container-fluid">
  <div class="text-left row">
    <div class="text-left col-6">
      <b-form-group>
        <b-form autocomplete="off">
          <b-form-input v-model="filterText" :placeholder="$t('individual.label_work_support_filter')" type="text"
            id="filter-individual-work-support" class="w-100 mt-4" size="sm" @input="updateFilter" />
        </b-form>
      </b-form-group>
      <!-- TODO: v2マージ後のフルテストのタイミングでuseFixSortを削除し、他のテーブルに影響がないかを確認する -->
      <cstm-table useStripeStyle useSort useFixSort initSortDir="asc" rowSelect hasTabs :items="filterFunctionList"
        :columns="systemTableColumns" class="support-table mt-2" type="simple" @rowClicked="clickRow" usePopup>
      </cstm-table>
    </div>
    <div class="text-left col-6">
      <b-form-group>
        <label style="font-weight: bold;" class="label-sm mt-4">{{ $t('individual.common_label_description')
          }}</label>
        <b-form-textarea type="text" :value="selectedFunction.menu_description" class="mt-3"
          style="background-color: white;" readonly no-resize size="sm" rows="12">
        </b-form-textarea>
        <div class="text-right w-100">
          <b-button @click="onClickSelect()" size="sm" class="mt-2" variant="primary">
            {{ $t('individual.button_work_support_select') }}
          </b-button>
        </div>
      </b-form-group>
    </div>
    <component v-if="currentWizard" @close-wizard="closeWizard" :i18nWsKey="i18nWsKey"
      :workSupportId="selectedFunction.work_support_id" :additionalInfo="selectedFunction.additional_info"
      :is="currentWizard"></component>
  </div>
  <processing-view :processing="processing"></processing-view>
</div>