<!-- ABS機能チェックモード ダイアログ -->
<b-modal v-model="modal_work_support_109" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">

  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'waiting' ||
                      phase === 'judge' ||
                      phase === 'confirm' ||
                      phase === 're_execute'
                      ">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'execution' ||
                      phase === 'flrr_valve_check' ||
                      phase === 'frrl_valve_check' 
                      ">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'end'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'error_speed' ||
                      phase === 'error_start' ||
                      phase === 'error_end' ||
                      phase === 'failed'
                      ">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'waiting'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- センサ値判定画面 -->
        <template v-if="phase === 'judge'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_2`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 確認表示画面 -->
        <template v-if="phase === 'confirm'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_3`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行画面 -->
        <template v-if="phase === 'execution'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_4`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- FL/RRバルブチェック画面 -->
        <template v-if="phase === 'flrr_valve_check'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_5`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- FR/RLバルブチェック画面 -->
        <template v-if="phase === 'frrl_valve_check'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_6_0001`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 終了表示画面 -->
        <template v-if="phase === 'end'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_7_0002`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 再実行表示画面 -->
        <template v-if="phase === 're_execute'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_8`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 車輪速超過表示画面 -->
        <template v-if="phase === 'error_speed'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_9`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 開始不可表示画面 -->
        <template v-if="phase === 'error_start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_10`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 終了不可表示画面 -->
        <template v-if="phase === 'error_end'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_109_TXT_11`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 共通エラー画面 -->
        <template v-if="phase === 'failed'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`individual.error_communication_error`) }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>
  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 確認表示画面 -->
      <template v-if="phase === 'confirm'">
        <b-button size="sm" variant="primary" @click="sessionTransitionProcess" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 再実行表示画面 -->
      <template v-if="phase === 're_execute'">
        <b-button size="sm" variant="outline-primary" @click="exit(0)" class="mx-2" :disabled="isLoading">
          {{ $t('individual.common_button_no') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="startProcess" class="mx-2" :disabled="isLoading">
          {{ $t('individual.common_button_yes') }}
        </b-button>
      </template>
      <!-- 車輪速超過表示 -->
      <template v-if="phase === 'error_speed'">
        <b-button size="sm" variant="primary" @click="exit(0)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" @click="exit(1)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>