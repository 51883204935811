// Execute Constant class

/** headerの表示種別：言語選択のみ可能 (ログイン画面)  */
export const headerType_none = "none";
/** headerの表示種別：言語・利用者・同意・ログアウト・Cloud Connector の接続状態 表示 */
export const headerType_start = "start";
/** headerの表示種別：Start画面へ・言語・利用者・同意・ログアウト・Cloud Connector の接続状態・電圧 表示 */
export const headerType_vehicle = "vehicle";
/** headerの表示種別：言語・利用者・同意・ログアウト・Cloud Connector の接続状態・電圧 表示 */
export const headerType_result = "result";

/** コンテナーのデザインの種類：メイン画面用 */
export const CONTAINER_STYLE_COMMON_MAIN = "common-main";
/** コンテナーのデザインの種類：実行画面用 */
export const CONTAINER_STYLE_EXECUTE = "container-execute";

/** 画面上部判定用 HTML の ID */
export const ID_SCROLL_TAB_BLOCK = "scrollTabBlock";
