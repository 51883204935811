import individualDiagnose from '../../../../../share/util/individualDiagnose';
import { PHASE, STANDBY_TYPE } from './constants';

/**
 * エラー発生時の表示
 * @param {object} self this インスタンス
 * @param {boolean} standbyType 履歴読出し時のタイプ。(変数で扱われているが、standbyAPIから呼び出された時に使用するため、引数としている)
 */
export async function showUnexpectedError(self, standbyType = null) {
  // standbyTypeが｢作動履歴の発生日時｣の場合、｢IG ON回数(アイサイトバージョン3の読出し)｣に変更
  if (standbyType === STANDBY_TYPE.CONTROL_HISTORY) {
    self.standbyType = STANDBY_TYPE.IG_ON;
    return;
  }
  // 共通エラー画面の場合、ダイアログを閉じる
  if (self.phase === PHASE.ERROR) {
    self.closeWizard();
    return;
  }
  // 異常終了：エラー表示
  self.phase = PHASE.ERROR;
}

/**
 * API 通信で共通バリデーションでエラーが発生した際のコールバック
 * @param {object} result バリデーション結果
 * @param {boolean} standbyType 履歴読出し時のタイプ。(変数で扱われているが、standbyAPIから呼び出された時に使用するため、引数としている)
 * @param {object} self this インスタンス
 */
export function validateErrorResult(result, self, standbyType = null) {
  // アイサイトバージョンが4の履歴読み出しの場合、return
  if (standbyType === STANDBY_TYPE.CONTROL_HISTORY) {
    self.standbyType = STANDBY_TYPE.IG_ON;
    return;
  }
  individualDiagnose.apiErrorDefaultAction(
    self,
    result,
    // ログアウト処理のコールバック
    (instance) => { instance; self.logout(self); },
    // セッションリセット処理のコールバック
    (instance) => { instance; self.resetSession(self); },
    // その他エラー発生のコールバック
    (result) => { result; showUnexpectedError(self); },
  );
}


/**
 * 履歴情報の表示用変換
 * @param {object} self this インスタンス
 * @returns {Array} 表示用履歴情報
 */
export function historyInfoTableDataConvert(self) {
  self.historyInfoTableColumns = [
    {
      name: "displayName",
      label: "individual.header_history",
    }
  ];

  const historyArr = [];
  // レスポンスの履歴情報から、バージョンのkeyを確認
  const verKeys = Object.keys(self.historyData);
  for (const verKey of verKeys) {
    // バージョンのkeyに紐づく種別のkeyを取得
    const typeKeys = Object.keys(self.historyData[verKey]);
    // 種別のkeyが存在しない場合、スキップ
    if (typeKeys.length === 0) {
      continue;
    }
    for (const typeKey of typeKeys) {
      // バージョン、種別に紐づく発生順を取得
      const orderKeys = Object.keys(self.historyData[verKey][typeKey]);
      // 種別のkeyがある場合、必ず発生順のkeyは存在する想定だが、念のため確認し、存在しない場合スキップ
      if (orderKeys.length === 0) {
        continue;
      }
      // 発生順のkey配列を持たせたうえで、バージョンと種別の情報を持った履歴オブジェクトを表示用履歴配列に詰める
      const history = {
        displayName: self.$t(`individual.${verKey}_${typeKey}`),
        verKey: verKey,
        typeKey: typeKey,
        orderKeys: orderKeys,
      };
      historyArr.push(history);
    }
  }
  return historyArr;
}