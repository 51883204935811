<div class="col pagination justify-content-end">
  <div class="d-flex align-items-end">
    <a href="javascript:void(0)" v-bind:class="{ 'pange-disabled': !isPrevActive }"
      @click.prevent="onFirstPage()">&laquo;</a>
    <a href="javascript:void(0)" v-bind:class="{ 'pange-disabled': !isPrevActive }" @click.prevent="onPrev()">&lt;</a>
    <li v-for="page in pageItems" :key="page.label">
      <span v-if="page.disable">
        ...
      </span>
      <a v-else @click="goto(page.label)" v-bind:class="{ 'active-label-style': page.active }" style="color:black;">
        {{ page.label }}
      </a>
    </li>
    <a href="javascript:void(0)" v-bind:class="{ 'pange-disabled': !isNextActive }"
      @click.prevent="onNext()">&nbsp;&gt;</a>
    <a href="javascript:void(0)" v-bind:class="{ 'pange-disabled': !isNextActive }"
      @click.prevent="onLastPage()">&raquo;&nbsp;</a>
    <span v-if="inRprService">{{displayCount}}&nbsp;{{ $t('message.label_measured_data_list_item') }}</span>
    <span v-else>{{displayCount}}&nbsp;{{ $t('message.label_pfc_number_unit') }}</span>
  </div>
</div>