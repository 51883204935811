import individualDiagnose from '../../../../share/util/individualDiagnose';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import session from '../../../../share/session';
import { REQUEST_STATUS_COMPLETED } from '../../../../share/util/api/polling';
import { PHASE } from './constants';

/**
 * VIN 読み出したうえで、チェックを行う。
 * @returns 処理の成功可否
 */
export async function checkRegisteredVin(self) {
  const systemId = individualDiagnose.getCurrentSystemId(self);
  const token = session.getToken();
  // VIN読み出し (要求)（バックエンドAPI_POST）
  const response = await individualWorkSupportUtil.pollingWspApi(
    self,
    self.netAppId,
    systemId,
    self.workSupportId,
    {
      id_token: token.id_token,
      type: 'read'
    },
    // エラー発生のコールバック
    (errorType) => { errorType; self.showUnexpectedError(); },
    // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { self.validateErrorResult(result); },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { self.validateErrorResult(result); },
  );

  // 結果に関わらず、初回API呼び出しフラグをfalseに設定
  self.isFirstAPI = false;
  // responseがcompletedの場合
  if (response && response.data.status === REQUEST_STATUS_COMPLETED) {
    // 読み出したVIN
    const registerEcuVin = response.data.user_data.vin;

    // ECUに登録されたVINが既に存在し、validationが正常の場合、重複エラー画面を表示する
    if (registerEcuVin && !self.checkValidate(registerEcuVin)) {
      self.errorMsg = `${self.i18nWsKey}.error_already_registered_vin`;
      self.phase = PHASE.FAILED;
      return false;
    }
    return true;
  } else {
    // responseが存在しないか、ステータスがcompleted以外の場合
    self.showUnexpectedError();
    return false;
  }
}
