<div class="active-test-slider-panel">
  <!-- タイトル -->
  <div class="control-top border">
    <div class="text-center">
      {{ $t(i18nMenuTitleKey) }}
    </div>
  </div>
  <div class="control-bottom">
    <!-- スライダー -->
    <div class="control-left border">
      <div class="slider-div">
        <div class="slider">
          <div>
            <div class="slider-value">
              {{ $t("individual.label_active_test_target") }} {{ dispVal }} {{
              slider.unit }}
            </div>
            <input
              v-model="userInputStep"
              type="range"
              id="inputSlider"
              list="markers"
              :min="slider.range.min"
              :max="slider.range.max"
              :step="slider.range.step"
              :disabled="disabled.input"
              @change="changeSlider($event)"
            />
            <datalist id="markers">
              <option 
                v-for="opt in slider.option"
                id="options"
                :value="opt.value"
              />
            </datalist>
          </div>
          <div class="slider-labels">
            <label>{{ dispMin }}</label>
            <label>{{ dispMax }}</label>
          </div>
        </div>
        <div class="slider-btns">
          <b-button
            variant="primary"
            size="sm"
            @click="upBtn()"
            :disabled="disabled.input"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M7 14l5-5 5 5z" />
            </svg>
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="downBtn()"
            :disabled="disabled.input"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M7 10l5 5 5-5z" />
            </svg>
          </b-button>
        </div>
      </div>
    </div>
    <div class="control-right">
      <div class="control-text border">
        {{ message }}
      </div>
      <b-button
        id="drive-btn"
        variant="primary"
        @click="driveBtn()"
        :disabled="disabled.drive"
      >
        {{ driveBtnLabel }}
      </b-button>
    </div>
  </div>
  <processing-view :processing="displayProcessing" />
</div>
