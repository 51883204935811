import { sortSystems } from "./system";
import config from "../../share/config";
import util from "../../share/util/utils";
import logWrapper from "../../share/logWrapper";
import { EXECUTE_FUNCTION_TYPE_OBD_ALL_DTC, EXECUTE_FUNCTION_TYPE_OBD_INDIVIDUAL, FUNCTION_MAPPING } from "./functions";
import { pushObdTop, getFuncKeyFromName, isMatchCurrentPage, getPageKeyFromFuncKey,
  PAGE_KEY_ALL_DTC, PAGE_KEY_SYSTEM_LIST, PAGE_KEY_INDIVIDUAL_MENU, PAGE_FROM_START } from "../../router/obd";
import { getNodeSelectId } from "../treeUtils";

export const NODE_ID_ALL_DTC = "obd_all_dtc";
export const NODE_ID_INDIVIDUAL = "obd_individual";
export const NODE_ID_INDIVIDUAL_SYSTEM = "obd_individual_system";

/**
 * 全DTCのツリービューを生成＆キャッシュを行う
 * @param {object} component Vue の component を指定する
 */
export const createAllDtcTreeData = (component) => {
  const treeData = {
    name: `${component.$t("fixed_resource.obd_menu_alldtc")}${component.$t(
      "fixed_resource.obd_menu"
    )}`,
    selectId: NODE_ID_ALL_DTC,
    isSelected: false,
    noSelected: false,
    useResource: false, // あらかじめ翻訳結果を渡すので、false にする
  };

  return treeData;
};

/**
 * システム個別診断のツリービューを生成＆キャッシュを行う
 * @param {object} component Vue の component を指定する
 * @param {object} cacheSystemTree キャッシュする場合は関数を指定
 * @param {Array<object>} systems システムリスト
 * @param {object} menuSupport メニューサポート
 */
export const createIndividualTreeData = (component, menuSupport, systems) => {
  const treeData = {
    name: `${component.$t(
      "fixed_resource.obd_menu_individual"
    )}${component.$t("fixed_resource.obd_menu")}`,
    selectId: NODE_ID_INDIVIDUAL,
    isSelected: false,
    noSelected: false,
    useResource: false, // あらかじめ翻訳結果を渡すので、false にする
    children: createObdIndividualNodes(component, menuSupport, systems),
    isIndividualTop: true,
  };

  return treeData;
};

/**
 * Obd 用のノードを作成する
 * @param {object} component Vue の component を指定する
 * @param {object} menuSupport メニューサポート
 * @param {Array<object>} systems システムリスト
 * @returns {object} ノードリスト
 */
const createObdIndividualNodes = (component, menuSupport, systems) => {
  // Node に変換
  const systemNodes = systems.map((system) => {
    const node = {
      name: system.systemName,
      selectId: `${NODE_ID_INDIVIDUAL_SYSTEM}_${system.systemNo}`,
      isSelected: false,
      noSelected: false,
      useResource: false, // 翻訳しないので、false にする
      isSystem: true,
      systemNo: system.systemNo,
    };

    if (menuSupport && menuSupport.systemNo === system.systemNo) {
      const menus = menuSupport.value;
      const children = [];

      FUNCTION_MAPPING.forEach((functionInfo) => {
        if (
          Object.prototype.hasOwnProperty.call(menus, functionInfo.menuSupportKey)&&
          menus[functionInfo.menuSupportKey]
        ) {
          // メニューサポートが ON の機能のみ子ノードとして追加する
          children.push({
            name: functionInfo.messageKey,
            selectId: `obd_${node.systemNo}_${functionInfo.functionKey}`,
            isSelected: false,
            noSelected: false,
            useResource: true,
            isFunction: true,
            function: functionInfo.functionKey,
            systemNo: node.systemNo,
          });
        }
      });
      node.children = children;
    }
    return node;
  });

  // OBD 用関数を使用してソート
  return sortSystems(systemNodes, "name", config.ASC);
};

/**
 * 全DTCツリーが選択されたときの処理
 * @param {object} component Vue の component を指定する
 * @param {string} pageType PAGE_FROM_START か PAGE_FROM_GENERAL を指定
 */
export const clickObdAllDtcTreeNode = (component, pageType) => {
  if (isMatchCurrentPage(component?.$route?.name, PAGE_KEY_ALL_DTC)) {
    // 自画面への遷移の場合、何もしない
    logWrapper.log(`[router/obd] clickObdAllDtcTreeNode: isMatchCurrentPage is true. routeName: ${component?.$route?.name}`);
    return;
  }
  // OBDシステムトップからリダイレクト
  pushObdTop(component, pageType, "allDtc");
};

/**
 * システム個別診断(OBD) ツリーから任意のシステムが選択されたときの処理
 * @param {object} component Vue の component を指定する
 * @param {string} pageType PAGE_FROM_START か PAGE_FROM_GENERAL を指定
 * @param {object} node 選択したノード
 */
export const clickObdIndividualTreeNode = (component, pageType, node) => {
  if (node.data.isIndividualTop) {
    // 親ノードが選択された場合はシステム個別診断画面に遷移する
    if (isMatchCurrentPage(component?.$route?.name, PAGE_KEY_SYSTEM_LIST)) {
      // 自画面への遷移の場合、何もしない
      logWrapper.log(`[router/obd] clickObdIndividualTreeNode(systemList): isMatchCurrentPage is true. routeName: ${component?.$route?.name}`);
      return;
    }
    // OBDシステムトップからリダイレクト
    pushObdTop(component, pageType, "individual");
    return;
  }

  const systemNo = node.data.systemNo;
  if (node.data.isSystem) {
    // システム名ノードが選ばれた場合、該当システムの機能一覧に遷移する
    if (isMatchCurrentPage(component?.$route?.name, PAGE_KEY_INDIVIDUAL_MENU) && component?.$route?.params?.systemNo === systemNo) {
      // 自画面への遷移の場合、何もしない
      logWrapper.log(`[router/obd] clickObdIndividualTreeNode(IndividualMenu): isMatchCurrentPage is true. routeName: ${component?.$route?.name}`);
      return;
    }
    // OBDシステムトップからリダイレクト
    pushObdTop(component, pageType, "individual", systemNo);
    return;
  }

  const funcKey = node.data.function;
  // 機能名ノードが選ばれた場合、該当システムの機能に遷移する
  if (isMatchCurrentPage(component?.$route?.name, getPageKeyFromFuncKey(funcKey)) && component?.$route?.params?.systemNo === systemNo) {
    // 自画面への遷移の場合、何もしない
      logWrapper.log(`[router/obd] clickObdIndividualTreeNode(function): isMatchCurrentPage is true. routeName: ${component?.$route?.name}`);
    return;
  }
  // OBDシステムトップからリダイレクト
  pushObdTop(component, pageType, "individual", systemNo, funcKey);
};

/**
 * 選択されたツリーノードが 全 DTC (OBD) かを判断する関数を生成する。\
 * 現状全 DTC (OBD) ツリーのノードは1つなので true 固定としている。
 * {@link getNodeSelectId} で使用する想定。
 * @returns {(object) => boolean} 条件判定用 function
 */
export const createCheckSelectedNodeObdAllDtc = () => {
  return () => true;
};

/**
 * 選択されたツリーノードが個別システム診断 (OBD) かを判断する関数を生成する。\
 * {@link getNodeSelectId} で使用する想定。
 * @returns {(object) => boolean} 条件判定用 function
 */
export const createCheckSelectedNodeObdSystemList = () => {
  return (node) => node.isIndividualTop;
};

/**
 * 選択されたツリーノードが個別システム診断 (OBD) のシステムかを判断する関数を生成する。\
 * {@link getNodeSelectId} で使用する想定。
 * @param {object} component Vue の component を指定する
 * @returns {(object) => boolean} 条件判定用 function
 */
export const createCheckSelectedNodeObdIndividualMenu = (component) => {
  return (node) =>
    node.isSystem && node.systemNo === component?.$route?.params?.systemNo;
};

/**
 * 選択されたツリーノードが個別システム診断 (OBD) の機能かを判断する関数を生成する。\
 * {@link getNodeSelectId} で使用する想定。
 * @param {object} component Vue の component を指定する
 * @param {object} pageFrom {@link PAGE_FROM_START} もしくは {@link PAGE_FROM_GENERAL} を指定する
 * @returns {(object) => boolean} 条件判定用 function
 */
export const createCheckSelectedNodeObdIndividualFunction = (
  component,
  pageFrom
) => {
  const routeKey = getFuncKeyFromName(component?.$route?.name, pageFrom);
  return (node) =>
    node.isFunction &&
    node.function === routeKey &&
    node.systemNo === component?.$route?.params?.systemNo;
};

/**
 * 現在の Route を元に、ツリービューの状態を適切に設定する。
 * @param {object} component Vue の component を指定する
 * @param {Array<object>} treeList ツリー情報が格納されたリスト
 * @param {Array<object>} refsTreeList this.$refs で得られた system-tree リスト
 * @param {string} funcType ツリーを特定するキー
 * @returns {boolean} 処理を行ったか否か
 */
export const checkRouteChangeAndSelectNode = (
  component,
  treeList,
  refsTreeList,
  currentRouteName
) => {
  if (isMatchCurrentPage(currentRouteName, PAGE_KEY_ALL_DTC)) {
    changeSelectNode(
      component,
      treeList,
      refsTreeList,
      EXECUTE_FUNCTION_TYPE_OBD_ALL_DTC,
      createCheckSelectedNodeObdAllDtc(),
    );
    return true;
  } else if (isMatchCurrentPage(currentRouteName, PAGE_KEY_SYSTEM_LIST)) {
    changeSelectNode(
      component,
      treeList,
      refsTreeList,
      EXECUTE_FUNCTION_TYPE_OBD_INDIVIDUAL,
      createCheckSelectedNodeObdSystemList(),
    );
    return true;
  } else if (isMatchCurrentPage(currentRouteName, PAGE_KEY_INDIVIDUAL_MENU)) {
    changeSelectNode(
      component,
      treeList,
      refsTreeList,
      EXECUTE_FUNCTION_TYPE_OBD_INDIVIDUAL,
      createCheckSelectedNodeObdIndividualMenu(component)
    );
    return true;
  }

  if (getFuncKeyFromName(currentRouteName, PAGE_FROM_START)) {
    changeSelectNode(
      component,
      treeList,
      refsTreeList,
      EXECUTE_FUNCTION_TYPE_OBD_INDIVIDUAL,
      createCheckSelectedNodeObdIndividualFunction(component, PAGE_FROM_START)
    );
    return true;
  }

  return false;
};

/**
 * ツリー管理リストのデータを更新する。
 * @param {object} component Vue の component を指定する
 * @param {Array<object>} treeList ツリー管理リスト
 * @param {string} key ツリー識別子
 * @param {object} treeData ツリーデータ
 * @param {object} judgeVisible ツリーの表示・非表示を判定するコールバック function（デフォルトは常に表示）
 * @param {object} clickNodeCallback クリックされた際に実行するコールバック function（第1引数にクリックされた node が入る）
 * @param {string} headerResourceId ヘッダーのリソース ID（翻訳する場合はこちらを利用）
 * @param {string} headerText ヘッダーのテキスト（翻訳しない場合はこちらを利用）
 */
export const setTreeDataList = (
  component,
  treeList,
  key,
  treeData,
  judgeVisible = () => true,
  clickNodeCallback = () => {},
  headerResourceId = null,
  headerText = null
) => {
  let tree = treeList.find((tree) => tree.key === key);
  if (!tree) {
    // ツリーデータが見つからない場合は配列に追加
    tree = {
      key: key,
      data: {},
      header: "",
      judgeVisible: () => true,
      clickNodeCallback: () => {},
    };
    treeList.push(tree);
  }

  tree.data = treeData;
  if (headerResourceId) tree.header = component.$t(headerResourceId);
  if (headerText) tree.header = headerText;
  if (judgeVisible && typeof judgeVisible === "function")
    tree.judgeVisible = judgeVisible;
  if (clickNodeCallback && typeof clickNodeCallback === "function")
    tree.clickNodeCallback = clickNodeCallback;
};

/**
 * 条件に一致するノードを選択状態＆オープンさせる
 * @param {object} component Vue の component を指定する
 * @param {Array<object>} treeList ツリー情報が格納されたマッピング
 * @param {Array<object>} refsTreeList this.$refs で得られた system-tree リスト
 * @param {string} key ツリーを特定するキー
 * @param {object} conditionCallback 条件判断を行うコールバック function （引数としてノードが渡ってくるので boolean で返すこと）
 */
const changeSelectNode = (
  component,
  treeList,
  refsTreeList,
  key,
  conditionCallback
) => {
  component.$nextTick(() => {
    // 指定された funcType 以外のツリーノードを全て選択解除する
    resetSelectAllOtherTreeNode(treeList, key);

    const treeIndex = treeList.findIndex((tree) => tree.key === key);
    if (treeIndex >= 0) {
      const selectId = getNodeSelectId(
        treeList[treeIndex].data,
        conditionCallback
      );
      refsTreeList &&
        refsTreeList.length > treeIndex &&
        refsTreeList[treeIndex] &&
        refsTreeList[treeIndex].setOpenAndSelectTreeNode(selectId, true);
    }
  });
};

/**
 * 指定されたツリー以外を全て選択解除する。
 * @param {Array<object>} treeList ツリー情報が格納されたマッピング
 * @param {string} key ツリーを特定するキー（未指定の場合は全てのツリーノードを選択解除する）
 */
const resetSelectAllOtherTreeNode = (treeList, key) => {
  treeList.forEach((tree) => {
    // 指定されたツリーについては処理しない
    if (tree.key === key) return;

    // 全てのノードを選択解除
    tree.data = util.relaseTreeSelected(tree.data);
  });
};
