export default {
  props: {
    message: { type: String, default: '', required: false },
    value: { type: Number, default: 0, required: false, },
    variant: { type: String, default: 'primary', required: false },
    max: { type: Number, default: 100, required: false },
    useResource: { type: Boolean, default: false, required: false },
  },

  data() {
    return {
      progressValue: this.value,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        let progress = val;
        if (this.max < val) {
          progress = this.max;
        }
        this.progressValue = progress;
      }
    }
  },
};