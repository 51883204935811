<b-modal v-model="modal_requirement_136" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 確認
      保存データ選択 -->
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 警告
      警告表示 -->
      <template v-if="phase === 'warning'">
        {{ $t('message.common_title_warning') }}
      </template>
      <!-- 実行中
      データ書き込み -->
      <template v-if="phase === 'dataWrite'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 成功
      完了表示 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- エラー
      引継ぎエラー表示, 前提条件エラー表示, 共通エラー -->
      <template v-if="phase === 'handoverError' || phase === 'prerequisitesError' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 保存データ選択 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="col-12 text-left">
                <cstm-table useStripeStyle useSort currentSortkey="registered_at" initSortDir="desc" rowSelect hasTabs
                  :items="dataList" :columns="systemTableColumns" class="support-table mt-2" type="simple"
                  @rowClicked="clickRow" usePopup>
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- 警告表示 -->
        <template v-if="phase === 'warning'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_136_TXT_1`) }}</div>
          </div>
        </template>
        <!-- データ書き込み -->
        <template v-if="phase === 'dataWrite'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_136_TXT_2`) }}</div>
          </div>
        </template>
        <!-- 完了表示 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_136_TXT_3`) }}</div>
          </div>
        </template>
        <!-- 引継ぎエラー表示 -->
        <template v-if="phase === 'handoverError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_136_TXT_4`) }}</div>
          </div>
        </template>
        <!-- 前提条件エラー表示 -->
        <template v-if="phase === 'prerequisitesError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_136_TXT_5`) }}</div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 保存データ選択 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || buttonDisabled" variant="primary" @click="warningTransition"
          class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 警告表示 -->
      <template v-if="phase === 'warning'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="readSupport" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 完了表示, 引継ぎエラー表示, 前提条件エラー表示 -->
      <template v-if="phase === 'success' || phase === 'handoverError' || phase === 'prerequisitesError'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(0)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(1)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>