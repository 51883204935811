<b-modal
  v-model="modal_requirement_119"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop
  no-close-on-esc
  centered
  no-stacking
>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 待機画面, 停止 -->
      <template v-if="phase === 'start' || phase === 'stop'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行中 -->
      <template v-if="phase === 'execute'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- エラー, 共通エラー -->
      <template v-if="phase === 'commonError' || phase === 'error'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'start'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
            $t(`${i18nWsKey}.REQ_WS_119_TXT_1`) }}
          </div>
        </template>
        <!-- 実行中 -->
        <template v-if="phase === 'execute'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
            $t(`${i18nWsKey}.REQ_WS_119_TXT_2_0001`) }}
          </div>
        </template>
        <!-- 停止 -->
        <template v-if="phase === 'stop'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
            $t(`${i18nWsKey}.REQ_WS_119_TXT_3_0002`) }}
          </div>
        </template>
        <!-- エラー -->
        <template v-if="phase === 'error'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
            $t(`${i18nWsKey}.REQ_WS_119_TXT_4`) }}
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'commonError'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
            $t(`individual.error_communication_error`) }}
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 待機画面 -->
      <template v-if="phase === 'start'">
        <div class="d-flex justify-content-center">
          <b-button size="sm" variant="outline-primary" @click="onTerminateClicked" class="mx-2" :disabled="isLoading">
            {{ $t('message.button_polling_alert_terminate') }}
          </b-button>
          <b-button size="sm" variant="primary" @click="onDriveClicked" class="mx-2" :disabled="isLoading">
            {{ $t(`individual.label_button_drive`) }}
          </b-button>
        </div>
      </template>
      <!-- 停止 -->
      <template v-if="phase === 'stop'">
        <b-button size="sm" variant="primary" @click="onStopOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エラー -->
      <template v-if="phase === 'error'">
        <b-button size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'commonError'">
        <b-button size="sm" variant="primary" @click="onCmnErrOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>
