<div class="container-fluid mt-2 mb-2">
  <div class="row mb-2" v-if="bmdSystem || bmdDescription">
    <div class="col-12">
      <div class="row ml-1">
        <div class="col-3">
          <label>{{ $t('message.header_vehicle_table_system_name') }}</label>
        </div>
        <div class="col-9">
          <label style="font-weight: bold;">{{ bmdSystem }}</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row ml-1">
        <div class="col-3">
          <label>{{ $t('message.header_vehicle_dtc_table_description') }}</label>
        </div>
        <div class="col-9">
          <label style="font-weight: bold;">{{ bmdDescription }}</label>
        </div>
      </div>
    </div>
  </div>
  <label style="font-weight: bold;">{{ $t('message.label_vehicle_ffd_select_measurement') }}</label>
  <b-form-select v-model="selectedBMDGroup" size="sm" @change="updateBMDTable" id="bmd_modal"
    class="custom-select custom-select-sm">
    <option v-for="opt in bmdGroupOptions" :value="opt.key">{{ opt.value.label }}</option>
  </b-form-select>
  <div class="mt-2 mb-2" style="display: flex;">
    <div>
      <b-button variant="primary" size="sm" @click="initializeBMDSort()">
        {{ $t('message.button_vehicle_ffd_initialize_sort') }}
      </b-button>
      <b-button class="ml-2" variant="primary" size="sm" @click="csvExport">
        {{ $t('message.file_export') }}
      </b-button>
    </div>
    <div class="ml-2">
      <slot name="didTestBtn"></slot>
    </div>
  </div>
  <cstm-table-modal ref="bmd_tbl" useSort initSortDir="asc" :items="bmdTableItems" :columns="bmdDetailTableColumns"
    type="simple" usePopup useStripeStyle hasTabs>
  </cstm-table-modal>
</div>