import config from '../config';
import api from '@/common/api';

// 例外が発生した場合にこのメソッドを呼ぶことで、サーバにエラー内容を保存する
// type : ファイル名など、識別するための文字
// err : エラーオブジェクト
export const error = (type, err) => {
  if (process.env.VUE_APP_ERROR_ALERT) {
    let message = err.message;
    if (err.stack) {
      message = err.stack;
    }
    alert(message);
  } else {
    const param = {
      type,
      time: new Date().getTime(),
      reason: err.message + ', userAgent: ' + window.navigator.userAgent,
      stack: err.stack
    };
    post(param);
  }
};

// unhandledrejectionが発生した場合にこのメソッドを呼び、サーバにエラー内容を保存する
// event : イベントオブジェクト
export const unhandledrejection = (event) => {
  if (process.env.VUE_APP_ERROR_ALERT) {
    alert(event.reason);
  } else {
    const param = {
      type: 'unhandledrejection',
      time: new Date().getTime(),
      reason: event.reason + ', userAgent: ' + window.navigator.userAgent,
      stack: ''
    };
    post(param);
  }
};

// 利用者の agent 情報を保存する
// agent : window.navigation インタフェースから取得できる agent 情報
export const currentUserAgent = (agent) => {
  const param = {
    type: 'currentUserAgent',
    time: new Date().getTime(),
    reason: agent,
    stack: ''
  };
  post(param);
};

function post(param) {
  try {
    api.postCall(config.ADMIN, "/frontendLogs", param);
  } catch(e) {
    // 例来処理なのでエラーは無視
  }
}

export default {
  error,
  unhandledrejection,
  currentUserAgent,
};
