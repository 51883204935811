<div class="container-fluid" style="margin-top: 150px">
  <div class="home-base-container">
    <div
      class="m-2 home-obd-container"
      v-bind:class="'selectable'"
      v-on:click="openAllDtc()"
    >
    <strong style="font-size: x-large;" class="mt-3 home-menu-label">{{ 
      $t("message.label_function_menu_all_dtc")
    }}</strong>
    <svg-icon iconKey="obdAllDtcIcon"></svg-icon>
    </div>
    <div
      class="m-2 home-obd-container"
      v-bind:class="obdIndividualIconStyle"
      v-on:click="openObdIndividual()"
    >
    <strong style="font-size: x-large;" class="mt-3 home-menu-label">{{
      $t("individual.label_menu_individual_diagnosis")
    }}</strong>
    <svg-icon iconKey="individualIcon"></svg-icon>
    </div>
  </div>
    <!-- 車両情報取得(OBD)中のダイアログ -->
    <b-modal v-model="showVehicleModal" id="modal-vehicle" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered hide-footer>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_label_execution') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="container-fluid mt-2 mb-2 text-center">
        <strong v-html="$t('message.label_vehicle_connect')"></strong>
        <custom-Progress :value="progress"/>
      </div>
    </template>
  </b-modal>
</div>
