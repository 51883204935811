export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: []
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    maxItem: {
      type: Number,
      required: false,
      default: 999
    },
    selectOption: {
      type: Object,
      required: false,
      'default': () => ({})
    },
    menuMaxheight: {
      type: Number,
      required: false,
      default: 200
    }
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: "",
      menuWidth: 200
    };
  },
  created() {
    this.$emit("selected", this.selected);
  },
  mounted() {
    document.addEventListener("click", this.exit, false);
  },
  destroyed() {
    document.removeEventListener("click", this.exit, false);
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      let filter = "";
      if (this.searchFilter) {
        // 正規表現に無効な特殊文字が設定されたら置き換える
        filter = this.searchFilter.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&');
      }
      const regOption = new RegExp(filter, "ig");
      const parent = this;
      this.options.forEach((option) => {
        if (parent.searchFilter.length < 1 || option.name.match(regOption)) {
          if (filtered.length < parent.maxItem) filtered.push(option);
        }
      });
      return filtered;
    }
  },
  methods: {
    initSelect() {
      this.selected = this.selectOption;
      this.searchFilter = this.selected.name;
    },
    selectItem(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit("selected", this.selected);
    },
    showOptions() {
      this.menuWidth = this.$refs.input_form.clientWidth - 24;
      if (!this.disabled) {
        this.searchFilter = "";
        this.optionsShown = true;
      }
    },
    exit(event) {
      // 自分以外から呼ばれているかチェックする 
      if (!event) return;
      const target = (event.target || event.srcElement).closest(".fd__wrapper");
      if (target === this.$refs.input_form) {
        return;
      }

      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = "";
      } else {
        this.searchFilter = this.selected.name;
      }
      if (this.optionsShown) {
        this.$emit("selected", this.selected);
        this.optionsShown = false;
      }
    },
    keyMonitor (event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectItem(this.filteredOptions[0]);
    },
    reset (name) {
      this.searchFilter = name;
    }
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit("filter", this.searchFilter);
    },
    selectOption(value) {
      this.selected = value;
      this.searchFilter = this.selected.name;
    }
  }
};