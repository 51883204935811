<div class="container-fluid ml-n2">

  <!-- Confirmation dialog box(VD1) -->
  <b-modal v-model="modalconfirmregisterimbsystemtype1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div class="text-left w-auto m-4">
        <div>
          <b>{{$t('imb.register_immobilizer_system_exec_imb_system_reg')}}</b>
        </div>
        <div>
          <b>{{$t('imb.common_press_ok_or_cancel')}}</b>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button variant="outline-primary" v-on:click="cancelButtonClick()">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button variant="primary" v-on:click="onConfirmOkButtonClickRegImbType1()" class="ml-4">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Ig On dialog box (VD2) -->
  <b-modal v-model="modalconfirmtogetignitiononregisterimbsystemtype1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" >
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>

    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          <div>
            {{$t('imb.common_check_igni_on')}}
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{$t('imb.common_check_igni_on_done')}}
              <!-- Confirm ignition is turned ON -->
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked" v-on:click="IgOnOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Process on going modal -->
  <b-modal v-model="modalprocessisgoingonregisterimbsystemtype1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" >
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('imb.common_executing')}}
      </div>
    </template>

    <template v-slot:default>
      <div class="container-fluid mt-3 mb-4">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-auto m-2">
        <div class="col-md-12" v-html="imbStatusInfo.error_message">
        </div>
        <div>
          {{$t('imb.common_please_wait')}}
        </div>
      </div>

    </template>
  </b-modal>

  <!-- displays info based on status -->
  <b-modal v-model="modalprocessstatusregisterimbsystemtype1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" >
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
      </div>
    </template>
  </b-modal>

  <!--  Registration Completion modal -->
  <b-modal v-model="modalecuregistrationcompletedregisterimbsystemtype1" size="lg" header-bg-variant="success"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_success') }}
      </div>
    </template>

    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.register_immobilizer_system_success_imb_system_reg')">
        </div>
        <div class="text-center w-100">{{$t('imb.common_press_ok')}}</div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" class="ml-2 mr-2" v-on:click="compleatedOkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Confirm to get ignition off modal  -->
  <b-modal v-model="modalconfirmtogetignitionoffregisterimbsystemtype1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" >
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          <div v-if="isNorthAmerica" v-html="$t('imb.register_immobilizer_system_imb_system_reg_ended_north_america')">
          </div>
          <div v-else v-html="$t('imb.register_immobilizer_system_imb_system_reg_ended')"></div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked" class="checkbox-size-lg">
              {{$t('imb.common_confirm_igni_off_removed_key')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button class="ml-2 mr-2" size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="igOffOkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalquitdisplayregkeyregisterimbsystemtype1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" >
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div class="text-center w-100 mt-3">
        <div v-html="imbStatusInfo.error_message"></div>
        <div v-html="$t('imb.register_immobilizer_system_prep_an_unreg_key')"></div>
        <div>
          {{$t('imb.register_immobilizer_system_note_30_seconds')}}
        </div>

      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onQuitButtonClick()" class="ml-2 mr-2">
          {{ $t('imb.common_quit') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalkeyregisterlimitregisterimbsystemtype1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" >
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div class="text-center w-100 mt-3">
        <div>
          {{$t('imb.register_imb_system_Key_has_registered')}}
        </div>
        <div v-html="imbStatusInfo.error_message">
        </div>
        <div>
          {{$t('imb.register_immobilizer_system_max_num_reg_keys')}}
        </div>
        <div>
          {{$t('imb.common_press_ok')}}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onOkButtonClickRegLimit()" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!--  -->
  <b-modal v-model="modalregisterimmobilizertype1warning" size="lg" header-bg-variant="warning"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" >
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_warning') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="text-center w-100 mt-3">
        <div v-html="imbStatusInfo.error_message">
        </div>
        <div>
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" class="ml-2 mr-2"
          v-on:click="regImbType1OkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>


  <b-modal v-model="modalregisteringmodeundecidedregisterimbsystemtype1" size="lg" header-bg-variant="danger"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" >
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_error')}}
      </div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.error_reg_key_commnction_abnormal')">
        </div>
        <div class="text-center w-100">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" class="ml-2 mr-2"
          v-on:click="regImbType1OkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Error modal  -->
  <b-modal v-model="modalerrfailedregisterimbsystemtype1" size="lg" header-bg-variant="danger"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" >
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_error')}}
      </div>
    </template>

    <template v-slot:default>
      <div v-if="!isCausesAndActionDialog(imbStatusInfo.error_code)">
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div v-else>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
          <div class="col-9">{{ imbStatusInfo.error_code }}</div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_cause_message"></div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_action_message"></div>
        </div>
      </div>
      <div>
        <div class="text-center w-100 mt-2">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="errorOkButtonClickType1()" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

</div>