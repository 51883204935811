<div class="container-fluid ml-n2">
  <!-- Confirm to Register ECU modal  -->
  <b-modal v-model="modalconfirmtostartecuregECUType1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div class="text-left w-auto m-4">
        <div>
          <b>{{$t('imb.register_ecu_exec_ecm_reg')}}</b>
        </div>
        <div>
          <b>{{$t('imb.common_press_ok_or_cancel')}}</b>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button variant="outline-primary" v-on:click="cancelButtonClick()">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button variant="primary" v-on:click="onRegECUType1OkButtonClick()" class="ml-4">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Confirm to get ignition on modal  -->
  <b-modal v-model="modalconfirmtogetignitiononregECUType1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>

    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          <div>
            {{$t('imb.common_check_igni_on')}}
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{$t('imb.common_check_igni_on_done')}}
              <!-- Confirm ignition is turned ON -->
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked" v-on:click="IgOnOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>


  <!-- Process is on going modal -->
  <!-- VD3 two dialogs are there need to change text based on condition  -->
  <b-modal v-model="modalprocessisgoingonregECUType1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('imb.common_executing')}}
      </div>
    </template>

    <template v-slot:default>
      <div class="container-fluid mt-3 mb-4">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100">
        <div class="col-md-12" v-html="imbStatusInfo.error_message">
        </div>
        <div>
          {{$t('imb.common_please_wait')}}
        </div>
      </div>

    </template>
  </b-modal>

  <!-- ECU Registration Completion modal -->
  <b-modal v-model="modalecuregistrationcompletedregECUType1" size="lg" header-bg-variant="success"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_success') }}
      </div>
    </template>

    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.register_ecu_success_ecm_reg')">
        </div>
        <div class="text-center w-100">{{$t('imb.common_press_ok')}}</div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="compleatedOkButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Confirm to get ignition off modal  -->
  <b-modal v-model="modalconfirmtogetignitionoffregECUType1" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          <div v-html="$t('imb.register_ecu_ecm_reg_ended_turn_off_the_igni')">
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{$t('imb.common_confirm_igni_off_removed_key')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button class="ml-2 mr-2" size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="igOffOkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Error modal  -->
  <b-modal v-model="modalerrfailedregECUType1" size="lg" header-bg-variant="danger" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_error')}}
      </div>
    </template>

    <template v-slot:default>
      <div v-if="!isCausesAndActionDialog(imbStatusInfo.error_code)">
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div v-else>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
          <div class="col-9">{{ imbStatusInfo.error_code }}</div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_cause_message"></div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_action_message"></div>
        </div>
      </div>
      <div>
        <div class="text-center w-100 mt-2">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" class="ml-2 mr-2"
          v-on:click="errorOkButtonClick('modal-err-failed-regECUType1','modal-confirm-to-get-ignition-off-regECUType1')">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

</div>