import constants from '../../../common/nodejs/constants';

// 指摘内容は1関数の最大複雑度（20）の超過であるため、処理内容自体の問題はないと判断
// eslint-disable-next-line complexity
export const convertProcessNameToProcessNumber = (processName, vehicleType) => {
  const TYPE1 = 'type1';
  const TYPE2 = 'type2';
  const TYPE3 = 'type3';
  const vehicle_type = getVehicleType(vehicleType);

  let process_number = 0;

  switch (processName) {
    // キー登録本数の読み出し
    case constants.PROCESS_NAME_GET_THE_NUMBER_OF_REGISTERED_KEYS:
      process_number = constants.GET_REGISTERED_KEYS_PROCESS_NO;
      break;
    // アクセスキー登録本数の読み出し
    case constants.PROCESS_NAME_GET_THE_NUMBER_OF_REGISTERED_ACCESS_KEY:
      process_number = constants.GET_REGISTERED_ACCESSS_KEYS_PROCESS_NO;
      break;
    // ECU登録
    case constants.PROCESS_NAME_REGISTER_ECU:
      if (vehicle_type === TYPE1) {
        process_number = constants.REGISTER_ECU_TYPE_1_PROCESS_NO;
      } else if (vehicle_type === TYPE2) {
        process_number = constants.REGISTER_ECU_TYPE_2_PROCESS_NO;
      } else if (vehicle_type === TYPE3) {
        process_number = constants.REGISTER_ECU_TYPE_3_PROCESS_NO;
      }
      break;
    // イモビライザーシステム登録
    case constants.PROCESS_NAME_REGISTER_IMMOBILIZER_SYSTEM:
      if (vehicle_type === TYPE1) {
        process_number = constants.REGISTER_IMB_SYSTEM_TYPE_1_PROCESS_NO;
      } else if (vehicle_type === TYPE2) {
        process_number = constants.REGISTER_IMB_SYSTEM_TYPE_2_PROCESS_NO;
      }
      break;
    // キーレスアクセスイモビライザーシステム登録
    case constants.PROCESS_NAME_REGISTER_KEY_LESS_IMMOBILIZER:
      process_number = constants.REGISTER_KEYLESS_IMB_SYSTEM_TYPE_3_PROCESS_NO;
      break;
    // リモートエンジンスタートCU登録
    case constants.PROCESS_NAME_REGISTER_REMOTE_ENGINE_START_CU:
      if (vehicle_type === TYPE1) {
        process_number = constants.REMOTE_ENGINE_START_CU_TYPE_1_PROCESS_NO;
      } else if (vehicle_type === TYPE2) {
        process_number = constants.REMOTE_ENGINE_START_CU_TYPE_2_PROCESS_NO;
      } else if (vehicle_type === TYPE3) {
        process_number = constants.REMOTE_ENGINE_START_CU_TYPE_3_PROCESS_NO;
      }
      break;
    // テレマティクスCU登録
    case constants.PROCESS_NAME_REGISTER_TELEMATICS_CU:
      if (vehicle_type === TYPE2) {
        process_number = constants.TELEMATICS_CU_TYPE_2_PROCESS_NO;
      } else if (vehicle_type === TYPE3) {
        process_number = constants.TELEMATICS_CU_TYPE_3_PROCESS_NO;
      }
      break;
    // セントラルゲートウェイ登録
    case constants.PROCESS_NAME_REGISTER_CENTRAL_GATEWAY:
      process_number = constants.REGISTER_CENTRAL_GATEWAY_TYPE_2_PROCESS_NO;
      break;
    // イモビライザーキーID消去
    case constants.PROCESS_NAME_DELETE_THE_ID_OF_IMMOBILIZER_KEY:
      if (vehicle_type === TYPE1) {
        process_number = constants.DELETE_ID_OF_IMB_KEY_TYPE_1_PROCESS_NO;
      } else if (vehicle_type === TYPE2) {
        process_number = constants.DELETE_ID_OF_IMB_KEY_TYPE_2_PROCESS_NO;
      }
      break;
    // アクセスキーID消去
    case constants.PROCESS_NAME_DELETE_ACCESS_KEY_ID:
      process_number = constants.DELETE_ID_OF_ACCESS_KEY_TYPE_3_PROCESS_NO;
      break;
    // ステアリングロックCU登録
    case constants.PROCESS_NAME_REGISTER_STEERING_LOCK_CU:
      process_number = constants.REGISTER_STEERING_LOCK_CU_TYPE_3_PROCESS_NO;
      break;
    // コンビネーションメーター登録
    case constants.PROCESS_NAME_REGISTER_COMBINATION_METER:
      if (vehicle_type === TYPE1) {
        process_number = constants.REGISTER_COMBINATION_METER_TYPE_1_PROCESS_NO;
      } else if (vehicle_type === TYPE2) {
        process_number = constants.REGISTER_COMBINATION_METER_TYPE_2_PROCESS_NO;
      }
      break;
    case constants.PROCESS_NAME_GET_THE_REGISTRATION_HISTORY:
      // 登録履歴の読み出し
      process_number = constants.GET_REGISTRATION_HISTORY_TYPE_3_PROCESS_NO;
      break;
    default:
      break;
  }
  return process_number;
};

function getVehicleType(vehicleType) {
  let determinedVehicleType;
  const type2 = ['B2', 'E', 'G', 'I'];
  const type3 = ['D', 'H1', 'H2'];
  if (vehicleType === 'B1') {
    determinedVehicleType = 'type1';
  } else if (type2.includes(vehicleType)) {
    determinedVehicleType = 'type2';
  } else if (type3.includes(vehicleType)) {
    determinedVehicleType = 'type3';
  }
  return determinedVehicleType;
}

module.export = {
  convertProcessNameToProcessNumber: convertProcessNameToProcessNumber,
};
