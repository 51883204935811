<div class="container-fluid ml-n2"> 
  <div class="row mt-3">
    <div class="col-12 individual-container-array-style">

      <div class="m-2 individual-function-container function-hover" v-for="obdSystem of this.sortedSystems"
      v-on:click="initCommunication(obdSystem.systemNo)">
        <div class="function-label text-center">{{ 
          obdSystem.systemName }}
        </div>
      </div>
    </div>
  </div>
</div>