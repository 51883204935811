import { mapState } from 'vuex';
import resource from '../../../share/util/resourceMap';
import util from '../../../share/util/utils';
import cstmTable from '../../../share/components/vue/PaginationTable';

const FFD_I18N_MAP = require('../../../data/ffdi18nKeyMap.json');

export default {
  name: 'ffdModal',
  data() {
    return {
      ffdGroupOptions: [],
      selectedFFDGroup: null,
      ffdTableItems: [],
      currentFFDContents: [],
      showSaveOrder: '',
    };
  },

  props: {
    ffdTableProp: { type: Array, default: [], required: false },
    systemProp: { type: Object, default: {}, required: false },
    // モーダルにシステムと説明を表示する為のProps。未設定の場合は非表示となる
    ffdSystem: { type: String, default: '', required: false },
    ffdDescription: { type: String, default: '', required: false },
    // システムIDをsystemPropに含めていないため別で取得
    systemID: { type: String, default: undefined, required: false },
    // ファイルエクスポートに必要なコード
    code: { type: String, default: '', required: false },
    dataPlayback: { type: Boolean, default: false, required: false }
  },

  components: {
    'cstm-table': cstmTable,
  },

  computed: {
    ffdDetailTableColumns() {
      return [
        { name: 'item', label: 'message.header_vehice_ffd_item', useResource: true },
        { name: 'unit', label: 'message.header_vehice_ffd_unit', useResource: true },
        // FFDの個数に関わらず、detected_orderはキャンセルコード毎にFFDに設定される値
        { name: this.ffdTableProp[0].ffd_data[0].detected_order, label: 'message.header_vehice_ffd_detected' },
      ];
    },
    ...mapState({
      cachedDetails: state => state.cache.projectDetails,
      vehicleInfo: state => state.vehicle.vehicleInfo
    }),
    vinForCsv() {
      const vin = this.dataPlayback ? this.cachedDetails.information.vin : this.vehicleInfo.vehicle.vin.vin;
      return util.escapeForCsv(vin);
    }
  },

  methods: {
    // FFD 一覧の生成
    updateFFDTable: function () {

      // ffd_save_order を基に保存順の文言を作成
      this.showSaveOrder = resource.getCNCStatus(this.ffdTableProp[0].ffd_save_order, this);

      // FFD詳細テーブルを生成
      this.ffdTableItems = resource.getSaveOrderTable(this.ffdTableProp, this.systemID, FFD_I18N_MAP, this, false, true);

      // FFD詳細情報を格納
      this.currentFFDContents = this.ffdTableItems.items;
    },

    // テストデータを生成して返す
    getTestData: function () {
      return this.ffdTableItems;
    },

    /**
     * データ再生画面に必要なファイルエクスポートボタン処理
     * 診断では表示されない。
     */
    csvExport() {
      let header = "";
      let body = "";

      const nameData = this.ffdDetailTableColumns.map(item => item["name"]);
      const headerLabelList = this.ffdDetailTableColumns.map(key => key);
      // VIN/車台番号
      const vinHeader = `"${this.$t('message.label_measured_data_search_vin')}",${this.vinForCsv}\n`;
      // キャンセルコード
      const cancelCode = this.$t('individual.individual_diagnosis_function_cancel_code');
      const cancelCodeHeader = `"${cancelCode}",${util.escapeForCsv(this.systemProp.code)}\n`;

      header = headerLabelList.map(head => {
        let formattedHeader = '';
        if (typeof (head["name"]) === "number") {
          formattedHeader = head["label"];
        }
        formattedHeader = this.$t(head["label"]) ? this.$t(head["label"]) : head;
        return `"${formattedHeader}"`;
      }).join(",") + "\n";
      header = vinHeader + cancelCodeHeader + header;

      body = this.ffdTableItems.items.map((d) => {
        return nameData.map((key) => {
          let retVal = d[key];
          switch (key) {
            case "item":
            case "unit":
              retVal = this.$t(d[key]);
              break;
          }
          return util.escapeForCsv(retVal.toString());
        }).join(",");
      }).join("\n");
      header = header.replaceAll("\n,", "\n");

      const data = header + body;
      const bomFormatData = new Uint8Array([0xEF, 0xBB, 0xBF]); // UTF-8 BOM
      const blob = new Blob([bomFormatData, data], { type: 'text/csv;charset=utf-8' });
      const link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = ('{0}.csv').format("FFD"); // 出力するファイルの名前
      link.style.display = 'none';
      link.click();
      link.remove();
    },
  },

  created() {
    // noop
  },

  mounted() {
    this.updateFFDTable();
  },
};