import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { PHASE } from './constants';

/** データ書き込み */
export async function writeData(self) {
  try {
    self.isLoading = true;

    // 暗証番号照合
    const pinVerifiedRes = await individualWorkSupportUtil.postVerifyPinNumber(
      self,
      String(self.workSupportId).substring(0, 2),
      self.systemId,
      {
        pin_number: self.fixedPinNumber
      },
    );

    if (!pinVerifiedRes || !pinVerifiedRes.data.verified) {
      self.phase = PHASE.ERROR_PIN_NUMBER;
      return;
    }

    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'write',
      },
      // エラー発生のコールバックを実装
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // 正常コールバック
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // 正常終了：選択画面へ遷移
          self.phase = PHASE.EXECUTE;
          break;
        case REQUEST_STATUS_FAILED:
        default:
          // 異常終了：共通エラー
          self.showUnexpectedError();
          return;
      }
    }
  } finally {
    self.isLoading = false;
  }
}