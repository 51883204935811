<!-- カメラ接続仕様設定 -->
<b-modal v-model="modal_requirement_089" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'write'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'abnormalFailed' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行中 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
              </div>
            </div>
          </div>
        </template>
        <!-- カメラ接続設定書き込み画面 -->
        <template v-if="phase === 'write'">
          <div class="row mb-3">
            <div class="col-12">
              <div class="text-center align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.label_table_item`) }}</div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <!-- フロントカメラ -->
                <div class="box-margin-right work-support-body">
                  <div class="box-margin-right work-support-message">{{$t(`individual.row_target_front`) }}</div>
                  <div class="box-margin-right camera">
                    <b-button-group size="sm" class="camera-on-off-buttons">
                      <b-button v-for="(setting,index) in cameraSettingsOptions" :key="index"
                        :variant="front === setting ? 'primary' : 'outline-primary'" @click="updateCameraValue('front',setting)"
                        :class="setting === 'buttonOn' ? 'left-camera-button' : 'right-camera-button'">
                        {{setting === 'buttonOn' ? buttonOnText :buttonOffText }}
                      </b-button>
                    </b-button-group>
                  </div>
                </div>

                <!-- 左サイドカメラ -->
                <div class="box-margin-right work-support-body">
                  <div class="box-margin-right work-support-message">{{$t(`individual.row_target_left`) }}</div>
                  <div class="box-margin-right camera">
                    <b-button-group size="sm" class="camera-on-off-buttons">
                      <b-button v-for="(setting,index) in cameraSettingsOptions" :key="index"
                        :variant="left === setting ? 'primary' : 'outline-primary'" @click="updateCameraValue('left',setting)"
                        :class="setting === 'buttonOn' ? 'left-camera-button' : 'right-camera-button'">
                        {{setting === 'buttonOn' ? buttonOnText :buttonOffText }}
                      </b-button>
                    </b-button-group>
                  </div>
                </div>

                <!-- 右サイドカメラ -->
                <div class="box-margin-right work-support-body">
                  <div class="box-margin-right work-support-message">{{$t(`individual.row_target_right`) }}</div>
                  <div class="box-margin-right camera">
                    <b-button-group size="sm" class="camera-on-off-buttons">
                      <b-button v-for="(setting,index) in cameraSettingsOptions" :key="index"
                        :variant="right === setting ? 'primary' : 'outline-primary'" @click="updateCameraValue('right',setting)"
                        :class="setting === 'buttonOn' ? 'left-camera-button' : 'right-camera-button'">
                        {{setting === 'buttonOn' ? buttonOnText :buttonOffText }}
                      </b-button>
                    </b-button-group>
                  </div>
                </div>

                <!-- リヤカメラ -->
                <div class="box-margin-right work-support-body">
                  <div class="box-margin-right work-support-message">{{$t(`individual.row_target_rear`) }}</div>
                  <div class="box-margin-right camera">
                    <b-button-group size="sm" class="camera-on-off-buttons">
                      <b-button v-for="(setting,index) in cameraSettingsOptions" :key="index"
                        :variant="rear === setting ? 'primary' : 'outline-primary'" @click="updateCameraValue('rear',setting)"
                        :class="setting === 'buttonOn' ? 'left-camera-button' : 'right-camera-button'">
                        {{setting === 'buttonOn' ? buttonOnText :buttonOffText }}
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- エラー -->
        <template v-if="phase === 'abnormalFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`individual.label_communication_error`) }}</div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- カメラ接続設定書込み画面 -->
      <template v-if="phase == 'write'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="writeProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エラー -->
      <template v-if="phase === 'abnormalFailed' || phase === 'failed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>