<b-modal
  v-model="executeModal"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('message.header_label_execution') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed' || phase === 'error' ">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行開始表示 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="box-margin-right obd-onboard-system-test-execute-message" style="white-space: break-spaces;">{{ 
              $t('fixed_resource.obd_function_systemtest_message_result_ok') }}</div>
          </div>
        </template>
        <!-- 実行条件不成立表示 -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right obd-onboard-system-test-execute-message" style="white-space: break-spaces;">{{ 
              $t("fixed_resource.obd_function_systemtest_message_result_ng") }}</div>
          </div>
        </template>
        <!-- 実行不可表示 -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right obd-onboard-system-test-execute-message" style="white-space: break-spaces;">{{ 
              $t("fixed_resource.obd_function_common_message_result_ng_2") }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="phase === 'success' || phase === 'failed' || phase === 'error' ">
        <b-button size="sm" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>