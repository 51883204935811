<b-modal v-model="modal_requirement_MET" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'itemList' || phase === 'settingValueList' || phase === 'retryConfirmation'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'changeSetting' || phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'notSupportDid' || phase === 'writeFailed' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行中 -->
        <template v-if="phase === 'start'">
        </template>
        <!-- 待機 -->
        <template v-if="phase === 'itemList'">
          <div class="row no-gutters">
            <div class="col-12">
              <b-form-select class="form-control setting-list" v-model="selectItems" :select-size="9" :options="itemList">
              </b-form-select>
            </div>
          </div>
        </template>
        <!-- 実行 -->
        <template v-if="phase === 'settingValueList'">
          <div class="row no-gutters">
            <div class="col-12">
              <b-form-select class="form-control setting-list" v-model="selectSettingValue" :select-size="9"
                :options="settingValueList">
              </b-form-select>
            </div>
          </div>
        </template>
        <!-- 設定変更表示 -->
        <template v-if="phase === 'changeSetting'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" v-html="$t(`individual.label_setting_change_MET`)">
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 再実行確認表示 -->
        <template v-if="phase === 'retryConfirmation'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message"
                  v-html="$t(`individual.label_retry_confirmation_MET`)"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 完了表示 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" v-html="$t(`individual.label_success_MET`)"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 非サポートDID表示 -->
        <template v-if="phase === 'notSupportDid'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message" v-html="$t(`individual.label_not_support_did_MET`)"></div>
            </div>
          </div>
        </template>
        <!-- 書込みエラー表示 -->
        <template v-if="phase === 'writeFailed'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message" v-html="$t(`individual.label_write_error_MET`)">
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" v-html="$t(`individual.error_communication_error`)">
            </div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 待機 -->
      <template v-if="phase === 'itemList'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || (!selectItems || !selectItems.did)" variant="primary"
          @click="moveSettingValueEvent" class="mx-2">
          {{ $t('message.button_agreement_confirm') }}
        </b-button>
      </template>
      <!-- 実行 -->
      <template v-if="phase === 'settingValueList'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="returnDisplayEvent" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || !selectSettingValue" variant="primary" @click="writeEvent"
          class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 設定変更表示 -->
      <template v-if="phase === 'changeSetting'">
        <b-button size="sm" variant="primary" @click="moveRetryConfirmationEvent" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 再実行確認表示 -->
      <template v-if="phase === 'retryConfirmation'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="writeEvent" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="returnDisplayEvent" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 完了表示, 非サポートDID表示, 書込みエラー表示, 共通エラー -->
      <template v-if="phase === 'success' || phase === 'notSupportDid'
                        || phase === 'writeFailed' || phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>