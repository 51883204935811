<b-modal v-model="modal_requirement_023" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'igOn' || phase === 'releaseParkingBrake' || phase === 'steeringChk'
              || phase === 'liftUpChk' || phase === 'pRangeSelect' || phase === 'igOff'
              || phase === 'finishInspection' || phase === 'cancel'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'start' || phase === 'dRangeSelect' || phase === 'pRangeMaintain'
              || phase === 'pushBrake' || phase === 'executeMonitor' || phase === 'underInspection'
              || phase === 'stopVehicle'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'caution' || phase === 'warnInterruption'">
        {{ $t('message.common_title_warning') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed' || phase === 'nonconformity'
              || phase === 'rearDiffError' || phase === 'inspectionError' || phase === 'timeOutNotChk'
              || phase === 'timeOutChk' || phase === 'faultDetection'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 車両情報取得開始 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 注意表示 -->
        <template v-if="phase === 'caution'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- エンジン始動確認 -->
        <template v-if="phase === 'igOn'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_3`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- パーキングブレーキ解除確認 -->
        <template v-if="phase === 'releaseParkingBrake'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_5`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- ステアリング状態確認 -->
        <template v-if="phase === 'steeringChk'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_6`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- リフトアップ確認 -->
        <template v-if="phase === 'liftUpChk'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_7`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 点検中断警告表示 -->
        <template v-if="phase === 'warnInterruption'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_8`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- ブレーキペダル踏み込み指示 -->
        <template v-if="phase === 'pushBrake'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_9`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- Pレンジセレクト指示 -->
        <template v-if="phase === 'pRangeSelect'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_10`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- Dレンジセレクト指示 -->
        <template v-if="phase === 'dRangeSelect'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_12`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- Pレンジ維持指示 -->
        <template v-if="phase === 'pRangeMaintain'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_11`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- ストップランプSW情報の取得 -->
        <template v-if="phase === 'executeMonitor'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_13`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 点検中表示 -->
        <template v-if="phase === 'underInspection'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
                  ratioInfo }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 正常終了の表示 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
                  ratioInfo }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- リヤデフ異常の表示 -->
        <template v-if="phase === 'rearDiffError'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
                  ratioInfo }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了の表示 -->
        <template v-if="phase === 'inspectionError'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_17`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 車両停止指示 -->
        <template v-if="phase === 'stopVehicle'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_18`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 終了表示 -->
        <template v-if="phase === 'finishInspection'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_19`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- IG OFF指示 -->
        <template v-if="phase === 'igOff'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t(`${i18nWsKey}.REQ_WS_23_TXT_20`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- ユニット不適合の表示 -->
        <template v-if="phase === 'nonconformity'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_23_TXT_2`) }}</div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
        <!-- 点検中断(エラー) -->
        <template v-if="phase === 'cancel'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_23_TXT_22`) }}</div>
          </div>
        </template>
        <!-- タイムアウトエラー（中断確認なし）/（中断確認あり） -->
        <template v-if="phase === 'timeOutNotChk' || phase === 'timeOutChk'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_23_TXT_21`) }}</div>
          </div>
        </template>
        <!-- 故障検出(エラー) -->
        <template v-if="phase === 'faultDetection'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_23_TXT_4`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 注意表示 -->
      <template v-if="phase === 'caution'">
        <b-button size="sm" variant="outline-primary" @click="inspectionDisplay" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="engineStartConfirmDisplay" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エンジン始動確認 -->
      <template v-if="phase === 'igOn'">
        <b-button size="sm" variant="outline-primary" @click="igOffDisplay" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="readEngineStartFlg" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- パーキングブレーキ解除確認 -->
      <template v-if="phase === 'releaseParkingBrake'">
        <b-button size="sm" variant="outline-primary" @click="igOffDisplay" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="steeringChkDisplay" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- ステアリング状態確認 -->
      <template v-if="phase === 'steeringChk'">
        <b-button size="sm" variant="outline-primary" @click="inspectionDisplay" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="liftUpChkDisplay" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- リフトアップ確認 -->
      <template v-if="phase === 'liftUpChk'">
        <b-button size="sm" variant="outline-primary" @click="inspectionDisplay" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="warnInterruptionDisplay" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 点検中断警告表示 -->
      <template v-if="phase === 'warnInterruption'">
        <b-button size="sm" variant="outline-primary" @click="inspectionDisplay" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="readPushBrakePedal" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- Pレンジセレクト指示 -->
      <template v-if="phase === 'pRangeSelect'">
        <b-button size="sm" variant="outline-primary" @click="inspectionDisplay" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="pRangeMaintainDisplay" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'nonconformity' || phase === 'cancel' || phase === 'timeOutNotChk'
              || phase === 'faultDetection'">
        <b-button size="sm" variant="primary" @click="igOffDisplay" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- タイムアウトエラー（中断確認あり） -->
      <template v-if="phase === 'timeOutChk'">
        <b-button size="sm" variant="primary" @click="inspectionDisplay" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'success' || phase === 'rearDiffError' || phase === 'inspectionError'">
        <b-button size="sm" variant="primary" @click="readStopVehicle" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 終了表示 -->
      <template v-if="phase === 'finishInspection'">
        <b-button size="sm" variant="primary" @click="igOffDisplay" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'igOff'">
        <b-button size="sm" variant="primary" @click="exit(1)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" @click="exit(1)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>