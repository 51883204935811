<div class="row">
  <div class="col-12">
    <label class="sub-line-title-style"
      v-html="$t('message.label_vehicle_dtc_note_modal_information')"></label>
  </div>
  <div class="col-12">
    <div class="row ml-1">
      <div class="col-3">
        <label v-html="$t('message.label_vehicle_dtc_note_modal_vin')"></label>
      </div>
      <div class="col-9">
        <label style="font-weight: bold;">{{ vin }}</label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="row ml-1">
      <div class="col-3">
        <label v-html="$t('message.label_vehicle_dtc_note_modal_vehicle')"></label>
      </div>
      <div class="col-9">
        <label style="font-weight: bold;">{{ vehicleName }}</label>
      </div>
    </div>
  </div>
  <div v-if="hideRegistrationNumber" class="col-12">
    <div class="row ml-1">
      <div class="col-3">
        <label v-html="$t('message.label_vehicle_dtc_note_modal_number')"></label>
      </div>
      <div class="col-9">
        <label style="font-weight: bold;">{{ registrationNumber }}</label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <hr class="hr-line-100">
    <div>
      <label class="sub-line-title-style" v-html="$t('message.label_vehicle_dtc_note_modal_project')"></label>
    </div>
  </div>
  <div class="col-12">
    <div class="row ml-1">
      <div class="col-3">
        <label v-html="$t('message.label_vehicle_dtc_note_modal_project_id')"></label>
      </div>
      <div class="col-9">
        <label style="font-weight: bold;">{{ displayProjectID }}</label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="row ml-1 mt-2">
      <div class="col-3">
        <label v-html="$t('message.label_vehicle_dtc_note_modal_comment')"></label>
      </div>
      <div class="col-9">
        <b-form-textarea type="text" v-model="inputDiagnoseComment" size="sm" rows="3"
        :state="isAlert.diagnoseComment.valid">

        </b-form-textarea>
        <b-form-invalid-feedback>{{ $t(isAlert.diagnoseComment.msg, [isAlert.diagnoseComment.length])
          }}</b-form-invalid-feedback>
      </div>
    </div>
  </div>
  <div class="col-12">
    <hr class="hr-line-100">
    <div>
      <label class="sub-line-title-style"
        v-html="$t('message.label_vehicle_dtc_note_modal_measured_data')"></label>
    </div>
  </div>
  <div class="col-12">
    <div class="row ml-1">
      <div class="col-3">
        <label v-html="$t('message.label_vehicle_dtc_note_modal_function_name')"></label>
      </div>
      <div class="col-9">
        <label style="font-weight: bold;">{{ $t(functionName) }}</label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <b-form-group>
      <div class="row ml-1">
        <div class="col-3">
          <label v-html="$t('message.label_vehicle_dtc_note_modal_data_name')"></label>
          <span class="input-attention-required ml-1" v-html="$t('message.alert_vinz_input_required')"></span>
        </div>
        <div class="col-9">
          <b-form-input type="text" v-model="inputDataName" size="sm" :state="isAlert.dataName.valid">

          </b-form-input>
          <b-form-invalid-feedback>{{ $t(isAlert.dataName.msg, [isAlert.dataName.length])
            }}</b-form-invalid-feedback>
        </div>
      </div>
    </b-form-group>
  </div>
  <div class="col-12">
    <div class="row ml-1 mt-2">
      <div class="col-3">
        <label v-html="$t('message.label_vehicle_dtc_note_modal_note')"></label>
      </div>
      <div class="col-9">
        <b-form-textarea type="text" v-model="inputMemo" size="sm" rows="3" :state="isAlert.memo.valid">

        </b-form-textarea>
        <b-form-invalid-feedback>{{ $t(isAlert.memo.msg, [isAlert.memo.length]) }}</b-form-invalid-feedback>
      </div>
    </div>
  </div>
</div>