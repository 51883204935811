<div class="container-fluid ml-n2">
  <div class="row mt-3">
    <div class="col-12 individual-container-array-style">
      <div class="m-2 individual-function-container function-hover" 
        v-show="menuSupport?.dataMonitor && isInitialized">
        <div class="function-label text-center">{{ 
          $t("fixed_resource.obd_menu_datamonitor") }}
        </div>
      </div>
      <div class="m-2 individual-function-container function-hover"
        v-show="menuSupport?.diagCode && isInitialized"
        v-on:click="openObdDtc()">
        <div class="function-label text-center">{{ 
          $t("fixed_resource.obd_menu_dtc") }}
        </div>
      </div>
      <div class="m-2 individual-function-container function-hover"
        v-show="menuSupport?.onboardMonitorTest && isInitialized"
        v-on:click="openOnboardMonitorTest()">
        <div class="function-label text-center">{{ 
          $t("fixed_resource.obd_menu_monitortest") }}
        </div>
      </div>
      <div class="m-2 individual-function-container function-hover"
        v-show="menuSupport?.onboardSystemTest && isInitialized"
        v-on:click="openObdOnboardSystemTest()">
        <div class="function-label text-center">{{ 
          $t("fixed_resource.obd_menu_systemtest") }}
        </div>
      </div>
      <div class="m-2 individual-function-container function-hover"
        v-show="menuSupport?.vehicleInfo && isInitialized"
        v-on:click="showVehicleInformationAcquisition()">
        <div class="function-label text-center">{{ 
          $t("fixed_resource.obd_menu_infotype") }}
        </div>
      </div>
      <div class="m-2 individual-function-container function-hover"
        v-show="menuSupport?.permanentDtc && isInitialized">
        <div class="function-label text-center">{{ 
          $t("fixed_resource.obd_menu_pdtc") }}
        </div>
      </div>
    </div>
  </div>
  <processing-view :processing="processing"></processing-view>
</div>