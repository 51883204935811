<div class="container-fluid ml-n2">

  <div class="row">
    <div class="col-12 mt-2 text-right">{{$t('imb.menu_vehicle_type')}}{{vehicleTypeDisplayText}}</div>
    <div class="col-12 imb-container-array-style">
      <div v-if="isVisible('MENU_REGISTER_IMB_SYSTEM')" class="m-2 imb-function-container function-hover"
        v-on:click="onRegisterIMBSysButtonClick()">
        <div class="function-label text-center">{{$t('imb.menu_register_imb_system')}}</div>
      </div>
      <div v-if="isVisible('MENU_REGISTER_ECU')" class="m-2 imb-function-container function-hover"
        v-on:click="onRegisterEcuButtonClick()">
        <div class="function-label text-center">{{$t('imb.menu_register_ecu')}}</div>
      </div>
      <div v-if="isVisible('MENU_REGISTER_CGW')" class="m-2 imb-function-container function-hover"
        v-on:click="onRegisterCentralGateway()">
        <div class="function-label text-center">{{$t('imb.menu_register_cgw')}}</div>
      </div>
      <div v-if="isVisible('MENU_REGISTER_COMBINATION_METER')" class="m-2 imb-function-container function-hover"
        v-on:click="onRegisterCombinationMeterButtonClick()">
        <div class="function-label text-center">{{$t('imb.menu_register_combination_meter')}}</div>
      </div>
      <div v-if="isVisible('MENU_DELETE_IMB_KEY_ID')" class="m-2 imb-function-container function-hover"
        v-on:click="onDeleteIdOfImbKeyButtonClick()">
        <div class="function-label text-center">{{$t('imb.menu_delete_imb_key_id')}}</div>
      </div>
      <div v-if="isVisible('MENU_READ_NUM_REGISTERED_KEYS')" class="m-2 imb-function-container function-hover"
        v-on:click="onClickGetRegisterKeys()">
        <div class="function-label text-center">{{$t('imb.menu_read_num_registered_keys')}}</div>
      </div>
      <div v-if="isVisible('MENU_REGISTER_REMOTE_ENGINE_START_CU')" class="m-2 imb-function-container function-hover"
        v-on:click="onClickRegisterRemoteEngineStartCUButton()">
        <div class="function-label text-center">{{$t('imb.menu_register_remote_engine_start_cu')}}</div>
      </div>
      <div v-if="isVisible('MENU_REGISTER_TELEMATICS_CU')" class="m-2 imb-function-container function-hover"
        v-on:click="onClickRegisterTelematicsCUButton()">
        <div class="function-label text-center">{{$t('imb.menu_register_telematics_cu')}}</div>
      </div>
      <div v-if="isVisible('MENU_REGISTER_KEYLESS_ACCESS_IMB')" class="m-2 imb-function-container function-hover"
        v-on:click="onClickGetRegisterKeylessImb()">
        <div class="function-label text-center">{{$t('imb.menu_register_keyless_access_imb')}}</div>
      </div>
      <div v-if="isVisible('MENU_REGISTER_STEERING_LOCK')" class="m-2 imb-function-container function-hover"
        v-on:click="onRegSteeringLockCUType3ButtonClick()">
        <div class="function-label text-center">{{$t('imb.menu_register_steering_lock')}}</div>
      </div>
      <!-- <div v-if="isVisible('hpcu')" class="m-2 imb-function-container function-hover" v-on:click="start('hpcu')">
            <div class="function-label text-center">HPCU登録</div>
          </div> -->
      <div v-if="isVisible('MENU_READ_NUM_REGISTERED_ACCESS_KEYS')" class="m-2 imb-function-container function-hover"
        v-on:click="onGetRegAccessKeyType3ButtonClick()">
        <div class="function-label text-center">{{$t('imb.menu_read_num_registered_access_keys')}}</div>
      </div>
      <div v-if="isVisible('MENU_DELETE_ACCESS_KEY_ID')" class="m-2 imb-function-container function-hover"
        v-on:click="onDeleteIdOfAccessKeyButtonClick()">
        <div class="function-label text-center">{{$t('imb.menu_delete_access_key_id')}}</div>
      </div>
      <div v-if="isVisible('MENU_READ_REGISTRATION_HISTORY')" class="m-2 imb-function-container function-hover"
        v-on:click="onGetRegistrationHistoryButtonClick()">
        <div class="function-label text-center">{{$t('imb.menu_read_registration_history')}}</div>
      </div>
    </div>
  </div>

  <b-modal v-model="modal_return_to_home_screen" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('message.common_title_confirmation') }}</div>
    </template>

    <template v-slot:default>
      <div class="text-center m-4" v-html="$t('error.RETURNED_FROM_PROCESS_SUSPENSION')"></div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onReturnToHomeOkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>

  <imb-get-no-of-reg-keys-type-1-2 :imbStatusInfo="imbStatusInfo" :onClickOkGetRegKeys="onClickOkGetRegKeys"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-get-no-of-reg-keys-type-1-2>

  <imb-get-no-of-reg-keys-type-3 :imbStatusInfo="imbStatusInfo"
    :onConfirmationOkButtonClick="onConfirmationOkButtonClick" :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag"
    :isCausesAndActionDialog="isCausesAndActionDialog" :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-get-no-of-reg-keys-type-3>

  <imb-register-ecu-type-1 :onRegECUType1OkButtonClick="onRegECUType1OkButtonClick"
    :IgOnType1OkButtonClick="IgOnType1OkButtonClick" :errorOkButtonClick="errorOkButtonClick"
    :imbStatusInfo="imbStatusInfo" :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag"
    :isCausesAndActionDialog="isCausesAndActionDialog" :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-ecu-type-1>

  <imb-register-ecu-type-2 :onRegisterECUType2OkButtonClick="onRegisterECUType2OkButtonClick"
    :callIGONAPIOnClickRetryOrOkButton="callIGONAPIOnClickRetryOrOkButton" :errorOkButtonClick="errorOkButtonClick"
    :backEventOnButtonClick="backEventOnButtonClick" :imbStatusInfo="imbStatusInfo"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-ecu-type-2>

  <imb-register-ecu-type-3 :onRegECUType3OkButtonClick="onRegECUType3OkButtonClick"
    :IgOnType3OkButtonClick="IgOnType3OkButtonClick" :IgOnRetryType3OkButtonClick="IgOnRetryType3OkButtonClick"
    :IgOnRetryType3WarningClose="IgOnRetryType3WarningClose" :onErrorType3OkButtonClick="onErrorType3OkButtonClick"
    :imbStatusInfo="imbStatusInfo" :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag"
    :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-ecu-type-3>

  <register-imb-system-type-1 :imbStatusInfo="imbStatusInfo"
    :onConfirmOkButtonClickRegImbType1="onConfirmOkButtonClickRegImbType1"
    :IgOnRegImbSysType1OkButtonClick="IgOnRegImbSysType1OkButtonClick"
    :onNotifyOkButtonPushedRegImbSysAPICall="onNotifyOkButtonPushedRegImbSysAPICall"
    :callendOfKeyRegistrationRegImbSysType1="callendOfKeyRegistrationRegImbSysType1"
    :onErrornotifyEndOfProcessRegImbSys="onErrornotifyEndOfProcessRegImbSys"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </register-imb-system-type-1>

  <register-imb-system-type-2 :imbStatusInfo="imbStatusInfo"
    :startRegType2OnConfirmOkButtonclick="startRegType2OnConfirmOkButtonclick"
    :imbRegImbSysType2confirmOKOrRetryButtonClick="imbRegImbSysType2confirmOKOrRetryButtonClick"
    :callEndOfKeyRegOnOkButtonClick="callEndOfKeyRegOnOkButtonClick"
    :onErrornotifyEndOfProcessRegImbSys="onErrornotifyEndOfProcessRegImbSys"
    :backEventOnCancleButtonClick="backEventOnCancleButtonClick" :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag"
    :isCausesAndActionDialog="isCausesAndActionDialog" :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </register-imb-system-type-2>

  <imb-register-keyless-access-type-3 :registerKeyLessImbOkButtonClick="registerKeyLessImbOkButtonClick"
    :onNotifyOkButtonPushedRegImbSysAPICall="onNotifyOkButtonPushedRegImbSysAPICall"
    :callRegKeylessImbEndOfKeyRegistraion="callRegKeylessImbEndOfKeyRegistraion" :imbStatusInfo="imbStatusInfo"
    :onErrornotifyEndOfProcessRegImbSys="onErrornotifyEndOfProcessRegImbSys"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-keyless-access-type-3>

  <imb-register-remote-engine-start-cu-type-1 :imbStatusInfo="imbStatusInfo"
    :onClickOkButtonRegRemoteEngineStartCUType1="onClickOkButtonRegRemoteEngineStartCUType1"
    :errorOkButtonClick="errorOkButtonClick" :callIGONAPIOnOkButtonClick="callIGONAPIOnOkButtonClick"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog" :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-remote-engine-start-cu-type-1>

  <imb-register-remote-engine-start-cu-type-2 :imbStatusInfo="imbStatusInfo"
    :onClickOkButtonRegRemoteEngineStartCUType2="onClickOkButtonRegRemoteEngineStartCUType2"
    :backEventOnButtonClick="backEventOnButtonClick"
    :onClickRetryOrOkButtonCallIGONAPIRegRemoteEngineStartCuType2="onClickRetryOrOkButtonCallIGONAPIRegRemoteEngineStartCuType2"
    :errorOkButtonClick="errorOkButtonClick" :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag"
    :isCausesAndActionDialog="isCausesAndActionDialog" :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-remote-engine-start-cu-type-2>

  <imb-register-remote-engine-start-cu-type-3
    :onClickOkButtonRegRemoteEngineStartCUType3="onClickOkButtonRegRemoteEngineStartCUType3"
    :imbStatusInfo="imbStatusInfo" :backEventOnButtonClick="backEventOnButtonClick"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog" :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-remote-engine-start-cu-type-3>

  <imb-register-telematics-cu-type-2 :imbStatusInfo="imbStatusInfo" :notifyEndOfProcess="notifyEndOfProcess"
    :onClickOkButtonRegTelematicsCUType2="onClickOkButtonRegTelematicsCUType2" :onClickCallIgOnAPI="onClickCallIgOnAPI"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :onErrorNotifyEndOfProcessTelematicsCuType2="onErrorNotifyEndOfProcessTelematicsCuType2"
    :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-telematics-cu-type-2>

  <imb-register-telematics-cu-type-3 :imbStatusInfo="imbStatusInfo"
    :onClickOkButtonRegTelematicsCUType3="onClickOkButtonRegTelematicsCUType3"
    :onErrornotifyEndOfProcessRegImbSys="onErrornotifyEndOfProcessRegImbSys"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-telematics-cu-type-3>

  <imb-reg-central-gateway-type-2 :imbStatusInfo="imbStatusInfo" :onErrorOkButtonClick="onErrorOkButtonClick"
    :callIGONAPIOnClickOkOrRetryButton="callIGONAPIOnClickOkOrRetryButton"
    :backEventOnCancleButtonClick="backEventOnCancleButtonClick"
    :onRegCentralGateway2OkButtonClick="onRegCentralGateway2OkButtonClick"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-reg-central-gateway-type-2>

  <imb-delete-id-of-imb-key-type-1 :imbStatusInfo="imbStatusInfo"
    :onDeleteIdOfImbKeyType1OkButtonClick="onDeleteIdOfImbKeyType1OkButtonClick"
    :sendCheckNumOfKeys="sendCheckNumOfKeys"
    :onDeleteIdOfImbKeyType1IgOn2OkButtonClick="onDeleteIdOfImbKeyType1IgOn2OkButtonClick"
    :onErrorOkButtonClick="onErrorOkButtonClick" :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag"
    :isCausesAndActionDialog="isCausesAndActionDialog" :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-delete-id-of-imb-key-type-1>

  <imb-delete-id-of-imb-key-type-2 :imbStatusInfo="imbStatusInfo"
    :backEventOnCancleButtonClick="backEventOnCancleButtonClick" :onErrorOkButtonClick="onErrorOkButtonClick"
    :callIGONAPIOnClickOkOrRetryButton="callIGONAPIOnClickOkOrRetryButton"
    :onDeleteIdOfImbKey2OkButtonClick="onDeleteIdOfImbKey2OkButtonClick"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-delete-id-of-imb-key-type-2>

  <imb-delete-id-of-imb-key-type-3 :imbStatusInfo="imbStatusInfo"
    :backEventOnCancleButtonClick="backEventOnCancleButtonClick"
    :onDeleteIdOfImbKey3OkButtonClick="onDeleteIdOfImbKey3OkButtonClick"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-delete-id-of-imb-key-type-3>

  <imb-register-steering-lock-cu-type-3 :imbStatusInfo="imbStatusInfo"
    :backEventOnCancleButtonClick="backEventOnCancleButtonClick"
    :onRegSteeringLockCUType3OkButtonClick="onRegSteeringLockCUType3OkButtonClick"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-steering-lock-cu-type-3>

  <imb-register-combination-meter-type1
    :onRegCombinationMeterType1OkButtonClick="onRegCombinationMeterType1OkButtonClick" :imbStatusInfo="imbStatusInfo"
    :IgOnRegCombinationMeterOkOrRetryButtonClick="IgOnRegCombinationMeterOkOrRetryButtonClick"
    :onErrorOkButtonClick="onErrorOkButtonClick" :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag"
    :isCausesAndActionDialog="isCausesAndActionDialog" :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-combination-meter-type1>

  <imb-register-combination-meter-type2
    :onRegCombinationMeterType2OkButtonClick="onRegCombinationMeterType2OkButtonClick"
    :IgOnRegCombinationMeterOkOrRetryButtonClick="IgOnRegCombinationMeterOkOrRetryButtonClick"
    :backEventOnCancleButtonClick="backEventOnCancleButtonClick" :onErrorOkButtonClick="onErrorOkButtonClick"
    :imbStatusInfo="imbStatusInfo" :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag"
    :isCausesAndActionDialog="isCausesAndActionDialog" :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-register-combination-meter-type2>

  <imb-get-the-registration-history-type3 :onGetRegistrationHistoryOkButtonClick="onGetRegistrationHistoryOkButtonClick"
    :onRegHistoryCancelBtnClick="onRegHistoryCancelBtnClick" :onRegHistoryOkBtnClick="onRegHistoryOkBtnClick"
    :onFinishOkBtnClick="onFinishOkBtnClick" :notifyEndOfProcess="notifyEndOfProcess" :imbStatusInfo="imbStatusInfo"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :isCausesAndActionDialog="isCausesAndActionDialog"
    :changeProcessingStatus="changeProcessingStatus" :modalShowInfo="modalShowInfo" @modalDisplayStatus="handleShow">
  </imb-get-the-registration-history-type3>

</div>