import requirementSpABG001 from '../../../vue/specialFeature/spWorkSupport/RequirementSpABG001.vue';
import requirementSpCOM001 from '../../../vue/specialFeature/spWorkSupport/RequirementSpCOM001.vue';
import requirementSpCOM002 from '../../../vue/specialFeature/spWorkSupport/RequirementSpCOM002.vue';
import requirementSpCOM003 from '../../../vue/specialFeature/spWorkSupport/RequirementSpCOM003.vue';
import requirementSpABG002 from '../../../vue/specialFeature/spWorkSupport/RequirementSpABG002.vue';
import requirementSpBC002 from '../../../vue/specialFeature/spWorkSupport/RequirementSpBC002.vue';
import requirementSpECM001 from '../../../vue/specialFeature/spWorkSupport/RequirementSpECM001.vue';
import requirementSpECM002 from '../../../vue/specialFeature/spWorkSupport/RequirementSpECM002.vue';
import requirementSpECM003 from '../../../vue/specialFeature/spWorkSupport/RequirementSpECM003.vue';
import requirementSpECM004 from '../../../vue/specialFeature/spWorkSupport/RequirementSpECM004.vue';
import requirementSpES003 from '../../../vue/specialFeature/spWorkSupport/RequirementSpES003.vue';
import requirementSpES004 from '../../../vue/specialFeature/spWorkSupport/RequirementSpES004.vue';
import requirementSpES005 from '../../../vue/specialFeature/spWorkSupport/RequirementSpES005.vue';
import requirementSpES006 from '../../../vue/specialFeature/spWorkSupport/RequirementSpES006.vue';
import requirementSpES007 from '../../../vue/specialFeature/spWorkSupport/RequirementSpES007.vue';
import requirementSpDCM003 from '../../../vue/specialFeature/spWorkSupport/RequirementSpDCM003.vue';
import requirementSpMCAM001 from '../../../vue/specialFeature/spWorkSupport/RequirementSpMCAM001.vue';
import requirementSpSMT001 from '../../../vue/specialFeature/spWorkSupport/RequirementSpSMT001.vue';
import requirementSpMVS001 from '../../../vue/specialFeature/spWorkSupport/RequirementSpMVS001.vue';
import requirementSpMET from '../../../vue/specialFeature/spWorkSupport/RequirementSpMET.vue';

/**
 * components指定
 * vueをimportし、components名を設定する
 */
export const wizardComponents = {
  'requirementSpABG1': requirementSpABG001,
  'requirementSpCOM1': requirementSpCOM001,
  'requirementSpCOM2': requirementSpCOM002,
  'requirementSpCOM3': requirementSpCOM003,
  'requirementSpABG2': requirementSpABG002,
  'requirementSpBC2': requirementSpBC002,
  'requirementSpECM1': requirementSpECM001,
  'requirementSpECM2': requirementSpECM002,
  'requirementSpECM3': requirementSpECM003,
  'requirementSpECM4': requirementSpECM004,
  'requirementSpES3': requirementSpES003,
  'requirementSpES4': requirementSpES004,
  'requirementSpES5': requirementSpES005,
  'requirementSpES6': requirementSpES006,
  'requirementSpES7': requirementSpES007,
  'requirementSpDCM3': requirementSpDCM003,
  'requirementSpMCAM1': requirementSpMCAM001,
  'requirementSpSMT1': requirementSpSMT001,
  'requirementSpMVS1': requirementSpMVS001,
  'requirementSpMET': requirementSpMET
};