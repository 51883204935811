<!-- VDCセンサ中点設定モード ダイアログ -->
<b-modal v-model="modal_requirement_112" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <!-- ヘッダー -->
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 待機、確認、実行/実行監視 -->
      <template v-if="phase === 'start' ||
                      phase === 'confirmation'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'execute'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 停止(正常終了) -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- 車輪速超過、舵角センサ値異常、横Gセンサ値異常、
        前後Gセンサ値異常、開始不可、失敗、共通エラー -->
      <template v-if="phase === 'overSpeed' ||
                      phase === 'rudderSensorError' ||
                      phase === 'lateralSensorError' ||
                      phase === 'frontRearSensorError' ||
                      phase === 'unableToStart' ||
                      phase === 'failed'||
                      phase === 'commonFailed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>
  <!-- 文言表示 -->
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                {{ $t(`${i18nWsKey}.REQ_WS_112_TXT_1`) }}
              </div>
            </div>
          </div>
        </template>
        <!-- 確認 -->
        <template v-if="phase === 'confirmation'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_112_TXT_2`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行/実行監視 -->
        <template v-if="phase === 'execute'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_112_TXT_3_0001`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 停止(正常終了) -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="work-support-message">
              <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_112_TXT_4_0002`) }}</div>
            </div>
          </div>
        </template>
        <!-- 車輪速超過 -->
        <template v-if="phase === 'overSpeed'">
          <div class="row no-gutters">
            <div class="work-support-message">
              <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_112_TXT_5`) }}</div>
            </div>
          </div>
        </template>
        <!-- 舵角センサ値異常 -->
        <template v-if="phase === 'rudderSensorError'">
          <div class="row no-gutters">
            <div class="work-support-message">
              <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_112_TXT_6`) }}</div>
            </div>
          </div>
        </template>
        <!-- 横Gセンサ値異常 -->
        <template v-if="phase === 'lateralSensorError'">
          <div class="row no-gutters">
            <div class="work-support-message">
              <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_112_TXT_7`) }}</div>
            </div>
          </div>
        </template>
        <!-- 前後Gセンサ値異常 -->
        <template v-if="phase === 'frontRearSensorError'">
          <div class="row no-gutters">
            <div class="work-support-message">
              <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_112_TXT_8`) }}</div>
            </div>
          </div>
        </template>
        <!-- 開始不可 -->
        <template v-if="phase === 'unableToStart'">
          <div class="row no-gutters">
            <div class="work-support-message">
              <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_112_TXT_9`) }}</div>
            </div>
          </div>
        </template>
        <!-- 失敗 -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="work-support-message">
              <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_112_TXT_10`) }}</div>
            </div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'commonFailed'">
          <div class="w-100">
            <div class="work-support-message">
              {{ $t(`individual.error_communication_error`) }}
            </div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>
  <!-- フッダー -->
  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 確認 -->
      <template v-if="phase === 'confirmation'">
        <b-button size="sm" variant="primary" @click="rudderSensorProcess" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'commonFailed'">
        <b-button size="sm" variant="primary" @click="exit(false)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>