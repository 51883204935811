<!-- アンテナ出力値設定ダイアログ -->
<b-modal v-model="modal_special_requirement_SMT1" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>

  <!-- ********** ヘッダ ********** -->
  <template v-slot:header>
    <div class="text-center w-100">

      <!-- 実行中画面 -->
      <template v-if="phase === 'start'">
        {{ $t(`individual.common_title_execution`) }}
      </template>

      <!-- 実行画面 -->
      <!-- 発信機選択表示画面 -->
      <!-- 出力変更箇所表示画面 -->
      <!-- 設定値変更選択表示画面 -->
      <template v-if="phase === 'execute' ||
                      phase === 'executeSelectTransmitter' ||
                      phase === 'executeOutputChangePoint' ||
                      phase === 'executeSelectValue'">
        {{ $t('message.common_title_confirmation') }}
      </template>

      <!-- 成功画面 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>

      <!-- 失敗画面 -->
      <!-- 通信エラー画面 -->
      <template v-if="phase === 'failedExecute' ||
                      phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>

    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px">

        <!-- 実行画面 -->
        <template v-if="phase === 'execute'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.menu_title`) }}</div>
          </div>
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`individual.label_select_output_change_SMT1`) }}</div>
          </div>
        </template>

        <!-- 発信機選択表示画面 -->
        <template v-if="phase === 'executeSelectTransmitter'">
          <div class="work-support-message">
            <b-form-select id="transmitterFormSelect" style="overflow-x: scroll" v-model="selectTransmitterDid"
              text-field="text" value-field="value" :select-size="6" :options="transmitterList"
              @change="clickTransmitter" class="form-control">
            </b-form-select>
          </div>
        </template>

        <!-- 出力変更箇所表示画面 -->
        <template v-if="phase === 'executeOutputChangePoint'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ displayInfoSelectTransmitter }}</div>
          </div>
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`individual.label_select_change_value_SMT1`) }}</div>
          </div>
        </template>

        <!-- 設定値変更選択表示画面 -->
        <template v-if="phase === 'executeSelectValue'">
          <div class="work-support-message">
            <b-form-select v-model="selectValue" text-field="text" value-field="value" :select-size="9"
              :options="valueList" class="form-control">
            </b-form-select>
          </div>
          <div class="work-support-message mt-3">
            <div style="white-space: break-spaces">{{
              $t(`individual.label_current_setting_value_SMT1`, [displayInfoCurrentValue])
              }}</div>
          </div>
        </template>

        <!-- 成功画面 -->
        <template v-if="phase === 'success'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ displayInfoSelectTransmitter }}</div>
          </div>
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`individual.label_change_success_SMT1`) }}</div>
          </div>
        </template>

        <!-- 失敗画面 -->
        <template v-if="phase === 'failedExecute'">
          <div class="work-support-message">
            <div style="white-space: break-spaces" v-html="$t('individual.error_execute_failed_SMT1')"></div>
          </div>
        </template>

        <!-- 通信エラー画面 -->
        <template v-if="phase === 'failed'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>

      </div>
    </ShowLessBOverlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 実行画面 -->
      <template v-if="phase === 'execute'">
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickExecuteSelectTransmitter"
          class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 発信機選択表示画面 -->
      <template v-if="phase === 'executeSelectTransmitter'">
        <b-button :disabled="isDisabled" size="sm" variant="outline-primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isDisabledSelect" size="sm" variant="primary" @click="clickReadPostGet" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 出力変更箇所表示 -->
      <template v-if="phase === 'executeOutputChangePoint'">
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickExecuteSelectValue" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 設定値変更選択表示 -->
      <template v-if="phase === 'executeSelectValue'">
        <b-button :disabled="isDisabled" size="sm" variant="outline-primary" @click="clickExecute" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isDisabled || !selectValue" size="sm" variant="primary" @click="clickWritePostGet" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 成功画面 -->
      <template v-if="phase === 'success'">
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickExecute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 失敗画面 -->
      <!-- 通信エラー画面 -->
      <template v-if="phase === 'failedExecute' ||
                      phase === 'failed'">
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

    </div>
  </template>

</b-modal>