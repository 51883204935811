<div class="container-fluid ml-n2">
  <processing-view :processing="processing"></processing-view>

  <!-- Register Immobilizer System (type-2) start registration confirmation -->
  <b-modal v-model="registerimbsystype2startregconfirm" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div class="text-left w-auto m-4">
        <div>
          <b>{{$t('imb.register_immobilizer_system_exec_imb_system_reg')}}</b>
        </div>
        <div>
          <b>{{$t('imb.common_press_ok_or_cancel')}}</b>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button variant="outline-primary" @click="cancelButtonClick()">
          {{ $t('message.common_button_cancel')}}
        </b-button>
        <b-button variant="primary" v-on:click="startRegType2OnConfirmOkButtonclick()" class="ml-4">
          {{ $t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) confirm the ignition is on with key -->
  <b-modal v-model="registerimbsystype2confirmigonwithgivenkey" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.register_immobilizer_system_igni_on_switch_key_to_be_reg')">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('imb.common_check_igni_on_done')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary"
          v-on:click="igOnCancelButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_cancel')}}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="igOnOkOrRetryButtonClick('register-imb-sys-type-2-confirm-igon-with-given-key')"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) confirm the ignition is on when key is there already -->
  <b-modal v-model="registerimbsystype2confirmigonwithexistingkey" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          {{$t('imb.register_immobilizer_system_additional_reg_to_vehicle')}}
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          {{$t('imb.common_igni_on_with_previous_key')}}
        </div>
      </div>
      <div>
        <div class="text-center w-100" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('imb.common_check_igni_on_done')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary"
          v-on:click="igOnCancelButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_cancel')}}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="igOnOkOrRetryButtonClick('register-imb-sys-type-2-confirm-igon-with existing-key')"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-e) confirm the ignition is on when key is there already -->
  <b-modal v-model="registerimbsystypeeconfirmigonwithexistingkey" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100">
          {{$t('imb.common_igni_on_with_previous_key')}}
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('imb.common_check_igni_on_done')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary"
          v-on:click="igOnCancelButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_cancel')}}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="igOnOkOrRetryButtonClick('register-imb-sys-type-e-confirm-igon-with existing-key')"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) warning cannot detect IGON -->
  <b-modal v-model="registerimbsystype2warningignoton" size="lg" header-bg-variant="warning"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_warning')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.common_cannot_detect_igni_on')">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="backEventButtonClick('register-imb-sys-type-2-warning-ig-not-on')" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) confirm the ignition is on -->
  <b-modal v-model="registerimbsystype2confirmigonrequired" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.error_imb_ig_switch_is_on')">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('imb.common_check_igni_on_done')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary"
          v-on:click="igOnCancelButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_cancel')}}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="igOnOkOrRetryButtonClick('register-imb-sys-type-2-confirm-igon-required')" class="ml-2 mr-2">
          {{ $t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) process is ongoing Communicating with Body Integrated Unit-->
  <b-modal v-model="registerimbsystype2processisongoingcomBIU" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" hide-footer>
    <template v-slot:header>
      <div class="text-center w-100">{{$t('imb.common_executing')}}</div>
    </template>
    <template v-slot:default>
      <div class="container-fluid mt-3 mb-4">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100" v-html="imbStatusInfo.error_message">
      </div>
      <div class="text-center w-100">
        {{$t('imb.common_please_wait')}}
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (E only) process is ongoing Communicating with Body Integrated Unit-->
  <b-modal v-model="registerimbsyseonlyprocessisongoing" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" hide-footer>
    <template v-slot:header>
      <div class="text-center w-100">{{$t('imb.common_executing')}}</div>
    </template>
    <template v-slot:default>
      <div class="container-fluid mt-3 mb-4">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100" v-html="imbStatusInfo.error_message">
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) confirm the key identification establish -->
  <b-modal v-model="registerimbsystype2confirmkeyidentificationestablish" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3"
          v-html="$t('imb.register_immobilizer_system_key_id_established_register_now')"></div>
      </div>
      <div>
        <div class="text-center w-100" v-html="$t('imb.register_immobilizer_system_igni_off_prep_reg_key_igni_on')">
        </div>
        <div class="text-center w-100">
          {{$t('imb.register_immobilizer_system_note_30_seconds')}}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="callEndOfKeyRegOnOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('imb.common_quit') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) confirm the key is already registered -->
  <b-modal v-model="registerimbsystype2confirmkeyalreadyregistered" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          {{$t('imb.register_immobilizer_system_key_is_already_reg')}}
        </div>
        <div class="col-md-12 text-center" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div>
        <div class="text-center w-100" v-html="$t('imb.register_immobilizer_system_prep_an_unreg_key')"></div>
        <div class="text-center w-100">
          {{$t('imb.register_immobilizer_system_note_30_seconds')}}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="callEndOfKeyRegOnOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('imb.common_quit') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) confirm max no of registered keys -->
  <b-modal v-model="registerimbsystype2confirmmaxnoofregisteredkeys" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          {{$t('imb.register_imb_system_Key_has_registered')}}
        </div>
        <div class="text-center w-100" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div>
        <div class="text-center w-100" v-html="$t('imb.register_immobilizer_system_num_reg_key_max')">
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="callEndOfKeyRegOnOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) confirm the key has been registered -->
  <b-modal v-model="registerimbsystype2confirmkeyhasbeenregistered" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          {{$t('imb.register_imb_system_Key_has_registered')}}
        </div>
        <div class="text-center w-100" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div class="text-center w-100" v-html="$t('imb.register_immobilizer_system_prep_an_unreg_key')"></div>
      <div class="text-center w-100">
        {{$t('imb.register_immobilizer_system_note_30_seconds')}}
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="callEndOfKeyRegOnOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('imb.common_quit') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) process is ongoing confirm Key Identification -->
  <b-modal v-model="registerimbsystype2processisongoingconfirmkeyidentification" size="lg"
    header-bg-variant="secondary" header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')"
    hide-footer>
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div class="container-fluid mt-3 mb-4">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100" v-html="imbStatusInfo.error_message">
      </div>
      <div class="text-center w-100">
        {{$t('imb.common_please_wait')}}
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) confirm the key has already been read -->
  <b-modal v-model="registerimbsystype2confirmkeyhasalreadybeenread" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.register_immobilizer_system_key_confirmed_key_read')">
        </div>
        <div class="text-center w-100" v-html="imbStatusInfo.error_message">

        </div>
      </div>
      <div>
        <div class="text-center w-100" v-html="$t('imb.register_immobilizer_system_igni_off_chg_key_not_read')"></div>
        <div class="text-center w-100">
          {{$t('imb.register_immobilizer_system_note_30_seconds')}}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="callEndOfKeyRegOnOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('imb.common_quit') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) confirm the key confirmed -->
  <b-modal v-model="registerimbsystype2confirmkeyconfirmed" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.register_immobilizer_system_key_confirmed')">
        </div>
        <div class="text-center w-100" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div>
        <div class="text-center w-100" v-html="$t('imb.register_immobilizer_system_igni_off_chg_key_not_read')"></div>
        <div class="text-center w-100">
          {{$t('imb.register_immobilizer_system_note_30_seconds')}}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="callEndOfKeyRegOnOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('imb.common_quit') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) on successful response -->
  <b-modal v-model="registerimbsystype2success" size="lg" header-bg-variant="success" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('imb.common_result')}}</div>
    </template>
    <template v-slot:default>
      <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
      </div>
      <div class="text-center w-100">
        {{$t('imb.common_press_ok')}}
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="successOkButtonClick('register-imb-sys-type-2-success')"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) on error response -->
  <b-modal v-model="registerimbsystype2failure" size="lg" header-bg-variant="danger" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_error')}}</div>
    </template>
    <template v-slot:default>
      <div v-if="!isCausesAndActionDialog(imbStatusInfo.error_code)">
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div v-else>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
          <div class="col-9">{{ imbStatusInfo.error_code }}</div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_cause_message"></div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_action_message"></div>
        </div>
      </div>
      <div>
        <div class="text-center w-100 mt-2">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="errorOkButtonClick('register-imb-sys-type-2-failure')"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalregisterimmobilizertype2warning" size="lg" header-bg-variant="warning"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_warning') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="text-center w-100 mt-3">
        <div v-html="imbStatusInfo.error_message">
        </div>
        <div>
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" class="ml-2 mr-2"
          v-on:click="errorOkButtonClick('modal-register-immobilizer-type2-warning')">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register Immobilizer System (type-2) turn off and confirm ignition -->
  <b-modal v-model="registerimbsystype2confirmigisoff" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div v-if="isNorthAmerica" class="text-center w-100 mt-3"
          v-html="$t('imb.register_immobilizer_system_imb_system_reg_ended_north_america')"></div>
        <div v-else class="text-center w-100 mt-3" v-html="$t('imb.register_immobilizer_system_imb_system_reg_ended')">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{$t('imb.common_confirm_igni_off_removed_key')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked" v-on:click="igOffOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

</div>