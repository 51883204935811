import realWorldDataISA from '../../vue/realWorldData/RealWorldDataISA.vue';
import realWorldDataVsoc from '../../vue/realWorldData/RealWorldDataVsoc.vue';

/**
 * components指定
 * vueをimportし、components名を設定する
 */
export const wizardComponents = {
  'realWorldDataISA': realWorldDataISA,
  'realWorldDataVSOC': realWorldDataVsoc,
};