<!-- 設定保存ダイアログ -->
<b-modal v-model="modal_requirement_063" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'registered'">
        {{ $t('message.common_title_warning') }}
      </template>
      <template v-if="phase === 'inputData'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'invalidData' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面,登録中画面 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 登録済み警告画面 -->
        <template v-if="phase === 'registered'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="w-100 mb-4">
                <div class="text-left align-middle">
                  <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.check_ecu_is_written`) }}</div>
                </div>
                <cstm-table useStripeStyle useSort currentSortkey="applied" initSortDir="asc" hasTabs
                  :items="appliedModelOptionCodes" :columns="systemTableColumns" class="support-table mt-2"
                  type="simple">
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- アプライドモデル・オプションコード入力画面 -->
        <template v-if="phase === 'inputData'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="w-100 mb-4">
                <div class="text-center work-support-message">
                  {{ $t(`${i18nWsKey}.header_input_applied_model_option_code`) }}
                </div>
                <table class="box-margin-right box-margin-top work-support-message" style="width:400px;">
                  <tbody>
                    <tr>
                      <td style="vertical-align: top">{{ $t(displayMsg) }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="appliedModel"
                            :state="validateAppliedModel && !validateAppliedModel.valid" type="text" size="sm"
                            autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="validateAppliedModel">{{ validateAppliedModel.msg
                            }}</b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: top">{{ $t(`${i18nWsKey}.option_code`) }}</td>
                      <td>
                        <b-form-group>
                          <b-form-input v-model="optionCode" :state="validateOptionCode && !validateOptionCode.valid"
                            type="text" size="sm" autocomplete="off">
                          </b-form-input>
                          <b-form-invalid-feedback v-if="validateOptionCode">{{ validateOptionCode.msg
                            }}</b-form-invalid-feedback>
                        </b-form-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
        <!-- 非サポート表示画面 -->
        <template v-if="phase === 'invalidData'">
          <div class="w-100 mb-4">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.label_invalid_data`) }}
            </div>
            <table class="box-margin-right work-support-message" style="width:250px;">
              <tbody>
                <tr>
                  <td>{{ $t(displayMsg) }}</td>
                  <td>{{ appliedModel }}</td>
                </tr>
                <tr>
                  <td>{{ $t(`${i18nWsKey}.option_code`) }}</td>
                  <td>{{ optionCode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <!-- 正常終了画面 -->
        <template v-if="phase === 'success'">
          <div class="w-100 mb-4">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.label_confirm_save_data`) }}</div>
            <table class="box-margin-right work-support-message" style="width:250px;">
              <tbody>
                <tr>
                  <td>{{ $t(displayMsg) }}</td>
                  <td>{{ appliedModel }}</td>
                </tr>
                <tr>
                  <td>{{ $t(`${i18nWsKey}.option_code`) }}</td>
                  <td>{{ optionCode }}</td>
                </tr>
              </tbody>
            </table>
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.label_confirm_ignition_off`) }}</div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitLogic" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 登録済み警告画面 -->
      <template v-if="phase === 'registered'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitLogic" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="inputData" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- アプライドモデル・オプションコード入力画面 -->
      <template v-if="phase === 'inputData'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitLogic" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="execute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'invalidData'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitLogic" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitLogic" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>