<!-- チェックモード移行ダイアログ -->
<b-modal v-model="modal_special_requirement_BC2" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>

  <!-- ********** ヘッダ ********** -->
  <template v-slot:header>
    <div class="text-center w-100">

      <!-- 実行中画面 -->
      <template v-if="phase === 'start'">
        {{ $t(`individual.common_title_execution`) }}
      </template>

      <!-- 実行画面 -->
      <template v-if="phase === 'execute'">
        {{ $t('message.common_title_confirmation') }}
      </template>

      <!-- 成功画面 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>

      <!-- 失敗画面 -->
      <!-- 通信エラー画面 -->
      <template v-if="phase === 'failedExecute' ||
                      phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>

    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px">

        <!-- 実行画面 -->
        <template v-if="phase === 'execute'">
          <div class="work-support-message">
            <div style="white-space: break-spaces" v-html="$t(`individual.label_shift_mode_BC2`, [displayInfoCurrentMode, displayInfoShiftMode])"></div>
          </div>
        </template>

        <!-- 成功画面 -->
        <template v-if="phase === 'success'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{
              $t(`individual.label_shift_success_BC2`, [displayInfoShiftMode])
            }}</div>
          </div>
        </template>

        <!-- 失敗画面 -->
        <template v-if="phase === 'failedExecute'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{
              $t(`individual.error_shift_failed_BC2`, [displayInfoShiftMode])
            }}</div>
          </div>
        </template>

        <!-- 通信エラー画面 -->
        <template v-if="phase === 'failed'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>

      </div>
    </ShowLessBOverlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 実行画面 -->
      <template v-if="phase === 'execute'">
        <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickExecutePostGet" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 成功画面 -->
      <!-- 失敗画面 -->
      <!-- 通信エラー画面 -->
      <template v-if="phase === 'success' ||
                      phase === 'failedExecute' ||
                      phase === 'failed'">
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

    </div>
  </template>

</b-modal>