export default {
  data() {
    return {};
  },

  namespaced: true,

  state: {
    menuSupport: {}, // メニューサポート
    systems: [], // システム一覧
    obdDataId: null, // OBDデータID
  },

  mutations: {
    /**
     * store を初期化する
     * @param {object} state 現在の State
     */
    init(state) {
      state.menuSupport = {};
      state.systems = [];
      state.obdDataId = null;
    },

    /**
     * メニューサポートをセットする。
     * @param {object} state 現在の State
     * @param {object} payload Payload（systemNo: システム番号、value: メニュサポートの値）
     */
    setMenuSupport(state, payload) {
      const tmp = {};
      if (payload.systemNo) tmp.systemNo = payload.systemNo;
      if (payload.value) {
        tmp.value = {
          dataMonitor: !!payload.value.dataMonitor,
          diagCode: !!payload.value.diagCode,
          onboardMonitorTest: !!payload.value.onboardMonitorTest,
          onboardSystemTest: !!payload.value.onboardSystemTest,
          vehicleInfo: !!payload.value.vehicleInfo,
          permanentDtc: !!payload.value.permanentDtc,
        };
      }
      state.menuSupport = tmp;
    },

    /**
     * メニューサポートをクリアする。
     * @param {object} state 現在の State
     */
    clearMenuSupport(state) {
      state.menuSupport = {};
    },

    /**
     * システム情報一覧をセットする。
     * @param {object} state 現在の State
     * @param {Array<object>} payload Payload
     */
    setSystems(state, payload) {
      const tmp = [];
      (payload || []).forEach((element) => {
        if (element.system_name && element.system_no) {
          tmp.push({
            systemName: element.system_name,
            systemNo: element.system_no,
          });
        }
      });
      state.systems = tmp;
    },

    /**
     * OBDデータIDをセットする。
     * @param {object} state 現在の State
     * @param {String} payload Payload
     */
    setObdDataId(state, payload) {
      state.obdDataId = payload;
    },

  },

  actions: {
    /**
     * store をクリアする。
     * @param {object} context Context
     */
    clearObd(context) {
      context.commit("init");
    },

    /**
     * メニューサポートを更新する
     * @param {object} context コンテキスト
     * @param {object} arg 更新するメニューサポート(systemNo: システムNo, value: メニューサポート)
     */
    updateObdMenuSupport(context, arg) {
      context.commit("setMenuSupport", arg);
    },

    /**
     * システム一覧を更新する
     * @param {object} context コンテキスト
     * @param {Array<object>} arg システム一覧
     */
    updateObdSystems(context, arg) {
      context.commit("setSystems", arg);
      context.commit("clearMenuSupport");
    },

    /**
     * OBDデータIDを更新する
     * @param {object} context コンテキスト
     * @param {String} arg OBDデータID
     */
    updateObdDataId(context, arg) {
      context.commit("setObdDataId", arg);
    },

  },
};
