<!-- ID登録 -->
<b-modal v-model="modal_requirement_093" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 待機画面、実行画面-->
      <template v-if="phase === 'start' || phase === 'execution'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行監視画面-->
      <template v-if="phase === 'execution_monitoring'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 停止画面-->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- エラー画面、共通エラー画面 -->
      <template v-if="phase === 'error'|| phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面、実行画面、停止画面、エラー画面-->
        <template v-if="phase === 'start' || phase === 'execution' || phase === 'success' || phase === 'error'">
          <div class="box-margin-right work-support-message">{{ displayInfo }}</div>
        </template>
        <!-- 実行監視画面-->
        <template v-if="phase === 'execution_monitoring'">
          <table class="table table-sm overflow-auto simple-table">
            <tr v-for="(column, i) in tireList" :key="i">
              <th>{{column.label}}</th>
              <td>{{column.value}}</td>
            </tr>
          </table>
        </template>
        <!-- 共通エラー画面 -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 待機画面 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard()" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onClickOkRegistermode()" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 実行画面 -->
      <template v-if="phase === 'execution'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="onClickCancelRegister()" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onClickOkRegister()" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 実行監視画面 -->
      <template v-if="phase === 'execution_monitoring'">
        <b-button size="sm" variant="outline-primary" :disabled="isControlDisabled" @click="onClickCancelExecutionMonitoring()" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
      </template>
      <!-- 停止画面、エラー画面 -->
      <template v-if="phase === 'success' || phase === 'error'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onClickOkExit()" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー画面 -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess()" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>