<!-- VIN登録 -->
<div>
  <b-modal v-model="modal_special_requirement_ECM3" size="md" :header-bg-variant="headerColor" header-text-variant="light"
    no-close-on-backdrop no-close-on-esc centered no-stacking>

    <!-- ********** ヘッダ ********** -->
    <template v-slot:header>
      <div class="text-center w-100">

        <!-- 暗証番号入力表示画面 -->
        <!-- 暗証番号入力画面 -->
        <template v-if="phase === 'start' || phase === 'input'">
          {{ $t('message.common_title_confirmation') }}
        </template>

        <!-- VIN登録確認表示画面 -->
        <template v-if="phase === 'success'">
          {{ $t('message.common_title_success') }}
        </template>

        <!-- 暗証番号入力エラー画面 -->
        <!-- 通信エラー画面 -->
        <template v-if="phase === 'error' || phase === 'failed'">
          {{ $t('message.common_title_error') }}
        </template>

      </div>
    </template>

    <!-- ********** ボディ ********** -->
    <template v-slot:default>
      <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
        <div class="container-fluid" style="min-height: 140px">

          <!-- 暗証番号入力表示画面 -->
          <template v-if="phase === 'start'">
            <div class="work-support-message">
              <div style="white-space: break-spaces" v-html="$t(`individual.label_after_input_please_ok_ECM3`, [displayInfoData])"></div>
            </div>
          </template>

          <!-- 暗証番号入力画面 -->
          <template v-if="phase === 'input'">
            <div class="work-support-message">
              <div style="white-space: break-spaces">{{
                $t(`individual.label_pin_number_ECM3`)
              }}</div>
            </div>
            <div class="work-support-message mt-1">
              <b-form-input type="text" v-model="inputData"
                autocomplete="off" size="sm" maxlength="4" :state="isAlert.data.valid"/>
              <b-form-invalid-feedback>{{ $t(isAlert.data.msg) }}</b-form-invalid-feedback>
            </div>
          </template>

          <!-- 暗証番号入力エラー画面 -->
          <template v-if="phase === 'error'">
            <div class="work-support-message">
              <div style="white-space: break-spaces">{{
                $t(`individual.label_different_pin_number_ECM3`)
              }}</div>
            </div>
          </template>

          <!-- VIN登録確認表示画面 -->
          <template v-if="phase === 'success'">
            <div class="work-support-message">
              <div style="white-space: break-spaces" v-html="$t(`individual.label_registered_vin_ECM3`, [displayInfoVin])"></div>
            </div>
            <div class="work-support-message mt-4">
              <div style="white-space: break-spaces">{{
                $t(`individual.label_match_vehicle_number_ECM3`)
              }}</div>
            </div>
          </template>

          <!-- 通信エラー画面 -->
          <template v-if="phase === 'failed'">
            <div class="work-support-message">
              <div style="white-space: break-spaces">{{ $t(`individual.error_communication_error`) }}</div>
            </div>
          </template>

        </div>
      </ShowLessBOverlay>
    </template>

    <!-- ********** フッタ ********** -->
    <template v-slot:footer>
      <div class="w-100 text-center">

        <!-- 暗証番号入力表示画面 -->
        <template v-if="phase === 'start'">
          <div class="w-100 text-right">
            <b-button size="sm" :disabled="isLoading" variant="primary" @click="clickInput" class="mx-2 mb-2 btn-width-sm rounded-pill">
              {{ $t('individual.label_input') }}
            </b-button>
          </div>
          <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="clickClose" class="mx-2 btn-width-sm">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickComparePostGet" class="mx-2 btn-width-sm">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>

        <!-- 暗証番号入力画面 -->
        <template v-if="phase === 'input'">
          <div class="w-100 text-right">
            <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="clickClear" class="mx-2 mb-2 btn-width-sm rounded-pill">
              {{ $t('message.common_button_clear') }}
            </b-button>
          </div>
          <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="clickStart" class="mx-2 btn-width-sm">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickInputStart" class="mx-2 btn-width-sm">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>

        <!-- 暗証番号入力エラー画面 -->
        <template v-if="phase === 'error'">
          <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickStart" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>

        <!-- VIN登録確認表示画面 -->
        <template v-if="phase === 'success'">
          <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickExitPostGet" class="mx-2">
            {{ $t('individual.common_button_yes') }}
          </b-button>
          <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="clickTransition" class="mx-2">
            {{ $t('individual.common_button_no') }}
          </b-button>
        </template>

        <!-- 通信エラー画面 -->
        <template v-if="phase === 'failed'">
          <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickClose" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>

      </div>
    </template>

  </b-modal>

  <!-- WS.4 再書込画面 表示用別モーダル -->
  <component v-if="currentWizard" @close-wizard="clickClose" :is="currentWizard"
    :i18nWsKey="ws4i18nWsKey" :workSupportId="ws4workSupportId" :isSpecialTransition=isTransition>
  </component>
</div>