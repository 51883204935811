<b-modal v-model="modal_requirement_012" :size="isModalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 登録成功 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- 確認 --><!-- 削除確認 -->
      <template v-if="phase === 'confirmation' || phase === 'delChk'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 削除済み, 書込み失敗, エラー -->
      <template v-if="phase === 'delete' || phase === 'failure' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期画面 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="col-12 text-left">
                <div class="row">
                  <div class="box-margin-right work-support-message">
                    {{ $t(`${i18nWsKey}.label_filter`) }}
                  </div>
                  <div class="col-12 d-flex mt-2">
                    <div class="d-flex w-100" style="align-items: self-end;">
                      <!--登録日（年）-->
                      <div class="w-24 mr-2">
                        <b-form-group class="form-group">
                          <label class="filter_label">{{ $t('message.label_measured_data_search_registered_date')
                            }}</label>
                          <b-form-select v-model="selectedYear" :options="searchYearOptions" @change="searchFilter()" class="custom-select" />
                        </b-form-group>
                      </div>
                      <!--登録日（月）-->
                      <div class="w-24 mr-2">
                        <b-form-group class="form-group">
                          <label class="filter_label">{{ $t('individual.label_custom_read_regdate_month') }}</label>
                          <b-form-select v-model="selectedMonth" :options="searchMonthOptions"
                            @change="searchFilter()" class="custom-select"/>
                        </b-form-group>
                      </div>
                      <!-- 登録者 -->
                      <div class="w-18 mr-2">
                        <b-form-group class="form-group">
                          <label class="filter_label">{{ $t(`${i18nWsKey}.label_filter_registrants`) }}</label>
                          <b-form-input v-model="filterRegistrant" type="text" id="filter-registrant-012"
                            @input="searchFilter" />
                        </b-form-group>
                      </div>
                      <!-- DataID -->
                      <div class="w-18 mr-2">
                        <b-form-group class="form-group">
                          <label class="filter_label">{{ $t(`${i18nWsKey}.label_filter_data_id`) }}</label>
                          <b-form-input v-model="filterDataId" type="text" id="filter-data-id-012" @input="searchFilter" />
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
                <cstm-table useStripeStyle useSort currentSortkey="registered_at" initSortDir="desc" rowSelect hasTabs
                  :items="filteredDataList" :columns="systemTableColumns" class="support-table mt-2" type="simple"
                  @rowClicked="clickRow" @rowButtonClicked="deleteRow" usePopup>
                  <template v-slot:rowButton>
                    {{ $t(`${i18nWsKey}.label_data_delete`) }}
                  </template>
                </cstm-table>
                <b-container fluid>
                  <b-row>
                    <b-col sm="2" class="b-col-title">
                      <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.label_read_data`) }}</div>
                    </b-col>
                    <b-col sm="10">
                      <b-form-textarea type="text" :value="dataIdReadData" class="mt-3 work-support-textarea" readonly
                        size="sm" rows="1" autocomplete="off">
                      </b-form-textarea>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.label_write_confirm`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 削除確認 -->
        <template v-if="phase === 'delChk'">
          <div class="w-100">
            <div class="text-left align-middle">
              <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.label_delete_message`) }}</div>
            </div>
          </div>
        </template>
        <!-- 削除済み -->
        <template v-if="phase === 'delete'">
          <div class="w-100">
            <div class="text-left align-middle">
              <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.label_data_deleted`) }}</div>
            </div>
          </div>
        </template>
        <!-- 登録成功 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="text-left align-middle">
              <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.label_write_success`) }}</div>
            </div>
          </div>
        </template>
        <!-- 確認 -->
        <template v-if="phase === 'confirmation'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.label_write_retry_confirm`) }}</div>
          </div>
        </template>
        <!-- 書込み失敗 -->
        <template v-if="phase === 'failure'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.error_write`) }}</div>
          </div>
        </template>
        <!-- エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t('individual.error_communication_error') }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading || !selectedDestination" variant="primary" @click="executeProcess"
          class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 削除確認 -->
      <template v-if="phase === 'delChk'">
        <b-button size="sm" variant="outline-primary" @click="delCancel" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="delOk" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 削除済み -->
      <template v-if="phase === 'delete'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="backStart" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 登録成功 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 確認 -->
      <template v-if="phase === 'confirmation'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="failureProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="executeProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 書込み失敗 -->
      <template v-if="phase === 'failure'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" @click="exit" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>