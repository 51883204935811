<b-modal
  v-model="modal_special_requirement_ES4"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t(`message.common_title_confirmation`) }}
      </template>
      <template v-if="phase === 'execute'">
        {{ $t(`individual.common_title_execution`) }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed' ||
                      phase === 'failed_org'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_start_ES4`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行 -->
        <template v-if="phase === 'execute'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.label_execute_ES4`) }}</div>
              </div>
              <div class="container-fluid mt-2 mb-2">
                <custom-Progress :value="progress" :max="progressMax" useResource />
              </div>
            </div>
          </div>
        </template>
        <!-- 成功表示 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;" v-html="$t('individual.label_success_ES4')"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 失敗表示 -->
        <template v-if="phase === 'failed_org'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;" v-html="$t('individual.label_failed_ES4')"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 実行監視 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="standbyProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 実行 -->
      <template v-if="phase === 'execute'">
      </template>
      <!-- 成功表示 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 失敗表示 -->
      <template v-if="phase === 'failed_org'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>