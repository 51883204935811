import individualDiagnose from '../../../../../share/util/individualDiagnose';
import individualWorkSupportUtil from '../../../../../share/util/individualWorkSupportUtil';
import session from '../../../../../share/session';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../../share/util/api/polling';
import { PHASE } from './constants';

/**
 * AEBの画像データを読み出す
 * @param {object} targetRecord DLターゲットのレコード情報
 * @param {string} nextReadDid 次に取得するDID値。初回は空
 * @param {string} downloadUuid ダウンロード用Uuid。初回は空
 * @param {boolean} isLastRecord 最後のレコードか否か
 * @param {number} savedTotalFileSize 今まで保存した履歴レコードの合計サイズ
 * @returns response
 */
export async function readImageData(targetRecord, nextReadDid, downloadUuid, isLastRecord, savedTotalFileSize, self) {
  // EUCキーの取得
  const systemId = individualDiagnose.getCurrentSystemId(self);
  const token = session.getToken();
  // データ読み出し (要求)（バックエンドAPI_POST） Getのポーリング処理も行う
  const response = await individualWorkSupportUtil.pollingWspApi(
    self,
    self.netAppId,
    systemId,
    self.workSupportId,
    {
      id_token: token.id_token,
      type: 'start',
      is_special: true,
      aebType: targetRecord.typeKey,
      aebOrder: targetRecord.orderKey,
      downloadUuid: downloadUuid || undefined,
      nextReadDid: nextReadDid || undefined,
      isLastRecord: isLastRecord,
      savedTotalFileSize: savedTotalFileSize
    },
    // エラー発生のコールバック
    (errorType) => { errorType; self.showUnexpectedError(); },
    // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { self.validateErrorResult(result); },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { self.validateErrorResult(result); },
    // リトライカウント720*5秒間隔のintervalで、最大1時間のポーリング時間とする
    720,
    process.env.VUE_APP_RETRY_INTERVAL
  );

  if (response) {
    // ポーリングが正常に完了した場合
    switch (response.data.status) {
      // 成功の場合はレスポンスを返却
      case REQUEST_STATUS_COMPLETED:
        return response;
      case REQUEST_STATUS_FAILED:
      default:
        self.phase = PHASE.ERROR;
        return null;
    }
  }
  return null;
}