const NodeTree = require('./TreeViewNode.vue').default;

export default {
  data: function () {
    return {
      level: 0,
      oldSelectItemId: ''
    };
  },
  props: {
    treeData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    showHeadElements: {
      type: Boolean,
      required: false,
      default: true
    },
    isOnlySelect: {
      type: Boolean,
      required: false,
      default: true
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 600
    },
    isAutoHeight: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  components: {
    "tree-item": NodeTree
  },
  methods: {

    expanded: function (item) {
      this.$emit("expanded-item", item);
    },

    /* eslint-disable max-depth */
    selected: function (item) {

      const selectedItem = item.data;

      // 選択状態を設定する。
      const selected = this.setSelectItem(selectedItem);

      // 階層構造の全ての親と ID を検索する。
      const idList = [];
      const nameList = [];
      idList.push(selectedItem.id);
      nameList.push(selectedItem.name);

      if (selectedItem && selectedItem.parentId) {
        let parentId = selectedItem.parentId;
        for (let i = 0; i <= item.level; i++) {
          const pResult = this.deepSearch(
            this.treeData,
            "id",
            (k, v) => v === parentId
          );

          if (pResult) {
            idList.push(pResult.id);
            nameList.push(pResult.name);
            if (pResult.parentId) {
              parentId = pResult.parentId;
            } else {
              break;
            }
          }
        }
      }

      // 先頭行を非表示にしている場合はここで1列削除する 
      if (!this.showHeadElements) {
        if (idList && idList.length) {
          // listのアイテムが1つしかない場合は削除を実施しない 
          if (idList.length !== 1) {
            idList.pop();
            nameList.pop();
          }
        }
      }

      let result = { data: null, parentChain: null };
      if (selected) {
        result = { data: selectedItem, parentChain: nameList, parentIdChain: idList };
      }
      this.$emit("node-Selected", result);

    },

    setSelectItem: function (data) {

      const selected = false;

      // 過去の選択状態を無効に設定する
      if (this.oldSelectItemId) {

        if (!this.isOnlySelect) {
          // 同じアイテムが選択されていた場合
          if (this.oldSelectItemId === data.selectId) {
            data['isSelected'] = false;
            this.oldSelectItemId = null;
            return selected;
          }
        }

        const pResult = this.deepSearch(
          this.treeData,
          "selectId",
          (k, v) => v === this.oldSelectItemId
        );

        if (pResult) {
          pResult['isSelected'] = false;
        }
      }

      // 選択されたアイテムの選択状態を有効に設定する。
      data['isSelected'] = true;
      this.oldSelectItemId = data.selectId;
      return true;
    },

    deepSearch: function (object, key, predicate) {
      try {
        if (
          Object.prototype.hasOwnProperty.call(object, key) &&
          predicate(key, object[key]) === true
        ) {
          return object;
        }
        /* eslint-disable max-depth */
        for (let i = 0; i < Object.keys(object).length; i++) {
          if (object[Object.keys(object)[i]]) {
            if (typeof object[Object.keys(object)[i]] === "object") {
              const o = this.deepSearch(
                object[Object.keys(object)[i]],
                key,
                predicate
              );
              if (o !== null) {
                return o;
              }
            }
          }
        }
        return null;
      } catch (e) {
        return null;
      }
    },

    /**
     * 指定したIDに合致するノードを探し、ツリーを開いた状態かつ選択状態にする
     * @param {string} selectId セレクトID
     * @param {boolean} isOpenChildTree 子ノードを表示するツリーを開くかどうか
     */
    setOpenAndSelectTreeNode(selectId, isOpenChildTree) {
      this.$refs.treeViewNode.setOpenAndSelectTreeNode(selectId, isOpenChildTree);
    }
  },

  created() {
    if (!this.showHeadElements) {
      this.level = -1;
    }
  },
};