<div class="container">
  <div class="attention-base-container">
    <div class="row text-center">
      <div class="col-12">
        <div class="attention-header">{{ $t('message.header_vehicle_system_repro_attention') }}</div>
      </div>

      <div class="col-12">
        <div class="row row-100">
          <div class="mt-3 mb-3 text-left">
            <label class="sub-title-style">{{ $t('message.message_vehicle_system_repro_attention') }}</label>
          </div>
          <div class="col-12">
            <div class="attention-scroll-box">
              <div class="attention-display-style" v-html="$t('message.message_vehicle_system_attention_sentence_contents')">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3 checkbox-size-lg">
        <b-form-checkbox v-model="attentionIgOn">
          {{ $t('message.label_vehicle_system_repro_ig_on') }}
        </b-form-checkbox>
      </div>

      <!-- キャンセル・OK ボタン -->
      <div class="col-12 text-center mt-4">
        <b-button variant="outline-primary" v-on:click="cancel()" class="ml-2 mr-2 mb-3">
          {{ $t('message.common_button_cancel') }}</b-button>
        <b-button variant="primary" v-on:click="execute()" :disabled="!attentionIgOn" class="ml-2 mr-2 mb-3">
          {{ $t('message.common_button_ok') }}</b-button>
      </div>
    </div>
  </div>

  <processing-view :processing="processing"></processing-view>

</div>