<div class="container-fluid ml-n2">

  <!-- On Register ecu button click(type3) -->
  <b-modal v-model="modalconfirmstartecuregistrationtype3" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div class="text-left w-auto m-4">
        <div>
          <b>
            <div v-html="AddUnderline($t('imb.register_ecu_exec_ecm_reg_ensure_all_doors'))"> </div>
          </b>
        </div>
        <div>
          <b>{{$t('imb.common_press_ok_or_cancel')}}</b>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button variant="outline-primary"
          v-on:click="cancelButtonClick()">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button variant="primary" v-on:click="onRegECUType3OkButtonClick()" class="ml-4">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalregisterecutype3igon" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_confirmation') }}
      </div>
    </template>

    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          <div>
            {{ $t('imb.common_check_igni_on_keyless') }}
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('imb.common_check_igni_on_done_keyless') }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked" v-on:click="igOnOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalregisterecutype3igoff" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>

    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          <div v-html="$t('imb.error_imb_ig_switch_is_on_keyless')">
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('imb.common_check_igni_on_done_keyless') }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked" v-on:click="igOnRetryButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalregisterecutype3progress" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('imb.common_executing') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="container-fluid mt-3 mb-4">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="ml-4">
        <div class="text-center w-100" v-html="$t('imb.register_key_less_immobilizer_process_ecm_reg')">
        </div>
        <div class="text-center w-100">
          {{ $t('imb.common_please_wait') }}
        </div>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalregisterecutype3completed" size="lg" header-bg-variant="success" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_success') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="text-center w-100 mt-3">
        <div v-if="isEurope" v-html="$t('imb.register_ecu_success_ecm_reg_keyless_europe')"></div>
        <div v-else v-html="$t('imb.register_ecu_success_ecm_reg_keyless')"></div>
        <div>
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer="{ ok }">
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="successOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalregisterecutype3warning" size="lg" header-bg-variant="warning" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_warning') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="text-center w-100 mt-3">
        <div v-html="$t('imb.common_cannot_detect_igni_on_keyless')">
        </div>
        <div v-html="$t('imb.common_cannot_detect_igni_on_keyless_start_over')">
        </div>
        <div>
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" class="ml-2 mr-2" v-on:click="igOnWarningButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <b-modal v-model="modalregisterecutype3error" size="lg" header-bg-variant="danger" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_error')}}
      </div>
    </template>
    <template v-slot:default>
      <div v-if="!isCausesAndActionDialog(imbStatusInfo.error_code)">
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div v-else>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
          <div class="col-9">{{ imbStatusInfo.error_code }}</div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_cause_message"></div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_action_message"></div>
        </div>
      </div>
      <div>
        <div class="text-center w-100 mt-2">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="errorOkButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

</div>