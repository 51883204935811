import {
  getFuncKeyFromName,
  isMatchCurrentPage,
  PAGE_FROM_GENERAL,
  PAGE_KEY_ALL_DTC,
  PAGE_KEY_INDIVIDUAL_MENU,
  PAGE_KEY_SYSTEM_LIST,
} from "../../../router/obd";
import { 
  EXECUTE_FUNCTION_TYPE_OBD_ALL_DTC,
  EXECUTE_FUNCTION_TYPE_OBD_INDIVIDUAL
} from "../../../util/obd/functions";
import {
  createCheckSelectedNodeObdAllDtc,
  createCheckSelectedNodeObdSystemList,
  createCheckSelectedNodeObdIndividualFunction,
  createCheckSelectedNodeObdIndividualMenu,
} from "../../../util/obd/tree";
import { RouteTreeAction } from "./routeTreeAction";

/**
 * InformationMain の changeSelectedNode メソッド
 * @callback ConditionCallback
 * @param {object} node チェックするノード
 * @returns {boolean} ノードを選択状態にする場合は true、それ以外は false を返す
 */

/**
 * OBD 関連ページに応じたツリーの処理のリストを生成する
 * @param {object} component Vue の this を指定する
 * @param {(string, ConditionCallback) => void} changeSelectedNode InformationMain の changeSelectedNode メソッドを指定する
 * @returns {RouteTreeAction[]} ページ判定とそれに対応した処理のリスト
 */
export const createObdRouteTreeActions = (component, changeSelectedNode) => [
  new RouteTreeAction(
    (currentRouteName) =>
      isMatchCurrentPage(currentRouteName, PAGE_KEY_ALL_DTC),
    () => {
      changeSelectedNode(
        EXECUTE_FUNCTION_TYPE_OBD_ALL_DTC,
        createCheckSelectedNodeObdAllDtc()
      );
    }
  ),
  new RouteTreeAction(
    (currentRouteName) =>
      isMatchCurrentPage(currentRouteName, PAGE_KEY_SYSTEM_LIST),
    () => {
      changeSelectedNode(
        EXECUTE_FUNCTION_TYPE_OBD_INDIVIDUAL,
        createCheckSelectedNodeObdSystemList()
      );
    }
  ),
  new RouteTreeAction(
    (currentRouteName) =>
      isMatchCurrentPage(currentRouteName, PAGE_KEY_INDIVIDUAL_MENU),
    () => {
      changeSelectedNode(
        EXECUTE_FUNCTION_TYPE_OBD_INDIVIDUAL,
        createCheckSelectedNodeObdIndividualMenu(component)
      );
    }
  ),
  new RouteTreeAction(
    (currentRouteName) =>
      !!getFuncKeyFromName(currentRouteName, PAGE_FROM_GENERAL),
    () => {
      changeSelectedNode(
        EXECUTE_FUNCTION_TYPE_OBD_INDIVIDUAL,
        createCheckSelectedNodeObdIndividualFunction(
          component,
          PAGE_FROM_GENERAL
        )
      );
    }
  ),
];
