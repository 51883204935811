<!-- LNT被毒推定値読込み・GPF学習値読込みダイアログ -->
<b-modal v-model="modal_requirement_002" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>

  <!-- ********** ヘッダ ********** -->
  <template v-slot:header>
    <div class="text-center w-100">

      <!-- 初期画面 -->
      <!-- 保存確認画面 -->
      <template v-if="phase === 'start' ||
                      phase === 'save'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 保存成功画面 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>

      <!-- 再読込み確認画面 -->
      <!-- サポートデータ無し画面 -->
      <!-- 保存失敗画面 -->
      <template v-if="phase === 'retry' ||
                      phase === 'noData' ||
                      phase === 'error'
                      ">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px">

        <!-- 初期画面 -->
        <template v-if="phase === 'start'">
          <div class="work-support-message">{{ $t(`${i18nWsKey}.label_start`) }}</div>
        </template>

        <!-- 再読込み確認画面 -->
        <template v-if="phase === 'retry'">
          <div class="row no-gutters">
            <div class="work-support-message" style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_retry_confirm`)
              }}</div>
          </div>
        </template>

        <!-- 保存確認画面 -->
        <template v-if="phase === 'save'">
          <div class="row no-gutters">
            <b-form-textarea type="text" v-model="readOutData" class="mt-3" readonly no-resize size="sm" rows="5"
              style="white-space: break-spaces; background-color: white">
            </b-form-textarea>
            <div class="work-support-message">{{ $t(`${i18nWsKey}.label_save_confirm`) }}</div>
          </div>
        </template>

        <!-- 保存成功画面 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="work-support-message">
              <div>{{ $t(`${i18nWsKey}.label_save_success`) }}</div>
              <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_next_work`) }}</div>
              <div>{{ $t(`${i18nWsKey}.label_save_date`,[saveDate]) }}</div>
              <div>{{ $t(`${i18nWsKey}.label_data_id`,[saveDataId]) }}</div>
            </div>
          </div>
        </template>

        <!-- サポートデータ無し画面 -->
        <template v-if="phase === 'noData'">
          <div class="row no-gutters">
            <div class="work-support-message">{{ $t(`${i18nWsKey}.error_not_support`) }}</div>
          </div>
        </template>

        <!-- 保存失敗画面 -->
        <template v-if="phase === 'error'">
          <div class="row no-gutters">
            <div class="work-support-message" style="white-space: break-spaces">{{ $t(`${i18nWsKey}.error_failed_save`) }}
            </div>
          </div>
        </template>

      </div>
    </ShowLessBOverlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
        <b-button :disabled="isDisabled" size="sm" variant="outline-primary" @click="clickWizardClose" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickExecute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 再読み込み画面 -->
      <template v-if="phase === 'retry'">
        <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickRetry" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 保存確認画面 -->
      <template v-if="phase === 'save'">
        <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickSave" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- サポートデータなし -->
      <!-- 保存成功画面 -->
      <!-- 保存失敗画面 -->
      <template v-if="phase === 'success' ||
                      phase === 'noData' ||
                      phase === 'error'
                      ">
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>

</b-modal>