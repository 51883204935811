import individualDiagnose from '../../../../share/util/individualDiagnose';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import session from '../../../../share/session';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';
import { PHASE, DESTINATION } from './constants';

/**
 * VIN データ書き込みのうえ、正常に登録されているかチェックする。
 */
export async function writeAndCheckVin(self) {
  const systemId = individualDiagnose.getCurrentSystemId(self);
  const token = session.getToken();
  // VINデータ書き込み実行 (要求)（バックエンドAPI_POST）
  const response = await individualWorkSupportUtil.pollingWspApi(
    self,
    self.netAppId,
    systemId,
    self.workSupportId,
    {
      id_token: token.id_token,
      type: 'write',
      vin: self.inputVin,
      isDomestic: (self.selectedDestination === DESTINATION.DOMESTIC) ? true : false,
      isFirst: self.isSpecialTransition && self.isFirstAPI // 特殊機能からの遷移かつ初回呼び出しの場合、trueを設定
    },
    // エラー発生のコールバック
    (errorType) => { errorType; self.showUnexpectedError(); },
    // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { self.validateErrorResult(result); },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { self.validateErrorResult(result); },
  );
  // 結果に関わらず、初回API呼び出しフラグをfalseに設定
  self.isFirstAPI = false;
  switch (response && response.data.status) {
    // responseがcompletedの場合
    case REQUEST_STATUS_COMPLETED: {
      const registerEcuVin = response.data.user_data.vin;
      self.setVehicleVin(registerEcuVin);
      self.cacheVin(registerEcuVin);

      // 書込み実行で返却されたVINとユーザが入力したVINを比較し、不一致であれば登録失敗とする
      if (registerEcuVin !== self.inputVin) {
        self.errorMsg = `${self.i18nWsKey}.error_failed_writing`;
        self.phase = PHASE.FAILED;
      } else {
        // 一致であれば登録成功表示
        self.phase = PHASE.SUCCESS;
      }
      break;
    }
    // responseがfailedの場合
    case REQUEST_STATUS_FAILED:
      // データ書き込みエラー
      self.errorMsg = `${self.i18nWsKey}.error_failed_writing`;
      self.phase = PHASE.FAILED;
      break;
    default:
      self.showUnexpectedError();
      break;
  }
}