<div class="active-test-control-panel">
  <component
    id="control-panel"
    v-if="currentWizard"
    ref="current_wizard"
    :is="currentWizard"
    :i18nMenuTitleKey="i18nMenuTitleKey"
    :selectedAtFunc="selectedAtFunc"
    :isDriving="isDriving"
    :inExecution="inExecution"
    @toggle-drive="toggleDrive"
    @toggle-execute="toggleExecute"
    @toggle-read-executed="toggleReadExecuted"
    @err-terminate="errTerminate"
    @at-failure="atFailure"
  />
</div>
