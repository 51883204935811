<div class="container-fluid ml-n2">

  <div class="row mt-3">
    <div class="col-12 container-array-style">
      <div class="m-3 function-container function-hover" @click="onUpdateButtonClick()">
        <div class="function-label text-center">{{$t('mac.label_reg_unit_update')}}</div>
      </div>
      <div class="m-3 function-container function-hover" @click="onInspectionButtonClick()">
        <div class="function-label text-center">{{$t('mac.label_reg_unit_inspection')}}</div>
      </div>
      <div class="m-3 function-container function-hover" @click="onRegistrationButtonClick()">
        <div class="function-label text-center">{{$t('mac.label_auth_key_register')}}</div>
      </div>
    </div>
  </div>

  <b-modal v-model="modal_return_to_home_screen" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('message.common_title_confirmation') }}</div>
    </template>

    <template v-slot:default>
      <div class="text-center m-4" v-html="$t('error.RETURNED_FROM_PROCESS_SUSPENSION')"></div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onReturnToHomeOkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
  <mac-key-inspection :macStatusInfo="macStatusInfo" :turnOnIgnition="turnOnIgnition"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :currentDialogId="currentDialogId" :modalShowInfo="modalShowInfo"></mac-key-inspection>
  <mac-key-update :macUpdateInfo="macUpdateInfo" :macUpdateEvent="macUpdateEvent"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :currentDialogId="currentDialogId" :modalShowInfo="modalShowInfo"></mac-key-update>
  <mac-auth-key-registration :authKeyStatusInfo="authKeyStatusInfo" :turnOnIgnition="turnOnIgnition"
    :onSetEndOfProcessingFlag="onSetEndOfProcessingFlag" :currentDialogId="currentDialogId" :modalShowInfo="modalShowInfo"></mac-auth-key-registration>
</div>