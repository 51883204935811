<div class="container-fluid ml-n2">
  <div class="row mt-3">
    <div class="col-12 real-world-data-container-array-style">
      <div v-for="item in realWorldDataItems">
        <div v-if="realWorldDataMenus[item.function]" class="m-2 real-world-data-function-container function-hover"
          v-on:click="clickItem(item)">
          <div class="function-label text-center">{{ item.buttonLabel }}</div>
        </div>
      </div>
    </div>
    <component v-if="currentWizard" @close-wizard="closeWizard" :is="currentWizard">
    </component>
  </div>
</div>