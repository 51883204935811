<div class="active-test-blank-panel">
  <!-- タイトル -->
  <div class="control-top border">
    <div class="text-center">
      {{ $t(i18nMenuTitleKey) }}
    </div>
  </div>
  <!-- 空欄 -->
  <div class="control-bottom">
    <div class="control-left" />
    <div class="control-right">
      <div class="control-text border">
        {{ message }}
      </div>
      <b-button
        id="drive-btn" 
        variant="primary" 
        @click="driveBtn()"
        :disabled="disabled.drive"
      >
        {{ driveBtnLabel }}
      </b-button>
    </div>
  </div>
  <processing-view :processing="displayProcessing" />
</div>
