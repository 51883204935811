<div class="container-fluid ml-n2">
  <!-- Modal confirm to start Combination Meter/Security CM Registration -->
  <b-modal v-model="modalconfirmregCombinationMeterType2" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div class="text-left w-auto m-4">
        <div>
          <b>{{$t('imb.register_combination_meter_exec_combination_meter_reg')}}</b>
        </div>
        <div>
          <b>{{$t('imb.common_press_ok_or_cancel')}}</b>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button variant="outline-primary" v-on:click="cancelButtonClick()">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button variant="primary" class="ml-4" v-on:click="onRegCombinationMeterType2OkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Modal warning for key not found -->
  <b-modal v-model="modalwarningkeynotfoundregCombinationMeterType2" size="lg" header-bg-variant="warning"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_warning')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
        <div class="text-center w-100">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="backEventButtonClick('modal-warning-key-not-found-regCombinationMeterType2')" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Modal confirm to get ignition ON -->
  <b-modal v-model="modalconfirmtogetignitiononregCombinationMeterType2" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          <div>
            {{$t('imb.common_igni_on_with_previous_key')}}
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{$t('imb.common_check_igni_on_done')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary" class="ml-2 mr-2"
          v-on:click="igOnCancelButtonClick()">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" class="ml-2 mr-2" :disabled="!turnIgOnChecked"
          v-on:click="igOnOkOrRetryButtonClick('modal-confirm-to-get-ignition-on-regCombinationMeterType2','modal-process-is-going-on-regCombinationMeterType2')">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Modal confirm to get ignition ON 2nd dialog for low voltage -->
  <b-modal v-model="modalconfirmtogetignitionon2regCombinationMeterType2" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          <div v-html="$t('imb.error_imb_ig_switch_is_on')">
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{$t('imb.common_check_igni_on_done')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary" class="ml-2 mr-2"
          v-on:click="igOnCancelButtonClick()">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" class="ml-2 mr-2" :disabled="!turnIgOnChecked"
          v-on:click="igOnOkOrRetryButtonClick('modal-confirm-to-get-ignition-on2-regCombinationMeterType2','modal-process-is-going-on-regCombinationMeterType2')">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Modal process is on going -->
  <b-modal v-model="modalprocessisgoingonregCombinationMeterType2" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')" hide-footer>
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('imb.common_executing')}}
      </div>
    </template>
    <template v-slot:default>
      <div class="container-fluid mt-3 mb-4">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100">
        <div class="col-md-12" v-html="imbStatusInfo.error_message">
        </div>
        <div>
          {{$t('imb.common_please_wait')}}
        </div>
      </div>
    </template>
  </b-modal>

  <!-- Modal Combination Meter/Security CM Registration completed -->
  <b-modal v-model="modalecuregistrationcompletedregCombinationMeterType2" size="lg" header-bg-variant="success"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_success') }}
      </div>
    </template>

    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.register_combination_meter_success_combination_meter_reg')">
        </div>
        <div class="text-center w-100">{{$t('imb.common_press_ok')}}</div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="successOkButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Confirm to get ignition off modal  -->
  <b-modal v-model="modalconfirmtogetignitionoffregCombinationMeterType2" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_confirmation')}}
      </div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          <div v-html="$t('imb.register_combination_meter_combination_meter_reg_ended')">
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{$t('imb.common_confirm_igni_off_removed_key')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button class="ml-2 mr-2" size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="igOffOkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- Error modal  -->
  <b-modal v-model="modalerrfailedregCombinationMeterType2" size="lg" header-bg-variant="danger"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">
        {{$t('message.common_title_error')}}
      </div>
    </template>

    <template v-slot:default>
      <div v-if="!isCausesAndActionDialog(imbStatusInfo.error_code)">
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div v-else>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
          <div class="col-9">{{ imbStatusInfo.error_code }}</div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_cause_message"></div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_action_message"></div>
        </div>
      </div>
      <div>
        <div class="text-center w-100 mt-2">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" class="ml-2 mr-2"
          v-on:click="onErrorOkButtonClick('modal-err-failed-regCombinationMeterType2','modal-confirm-to-get-ignition-off-regCombinationMeterType2')">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

</div>