import logWrapper from "../../logWrapper";
import handler from "../apiResultValidator";

/** 同期通信失敗エラー */
export const SYNC_REQUEST_ERROR_INVALID_RESPONSE = "sync_invalid_response";

/**
 * 同期 API 呼び出しの共通関数
 * @param {object} self 呼び出し元の this を指定する
 * @param {object} apiCallback API 呼び出し処理のコールバック関数
 * @param {object?} errorCallback エラー発生のコールバック関数
 * @param {object?} checkApiResponseCallback API のレスポンスチェック処理のコールバック関数
 * @param {object?} checkApiErrorResultCallback API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @returns {object} 最終的な レスポンス（エラー発生時は null になる）
 */
export async function executeSyncRequest(
  self,
  apiCallback,
  errorCallback = (errorType) => {
    logWrapper.log("default errorCallback called.", errorType, true);
  },
  checkApiResponseCallback = (apiResponse) => {
    // デフォルトでは data が入っていれば成功とする
    if (apiResponse?.data) return "";

    logWrapper.log(
      "syncRequest.executeSyncRequest: data is not found in response.",
      apiResponse?.data,
      true
    );
    return SYNC_REQUEST_ERROR_INVALID_RESPONSE;
  },
  checkApiErrorResultCallback = () =>
    errorCallback(SYNC_REQUEST_ERROR_INVALID_RESPONSE)
) {
  let errorType;
  let apiResponse;

  try {
    // API コールバックを呼ぶ
    apiResponse = await apiCallback();
    handler.validate(
      handler.validateTypes.all,
      apiResponse,
      self,
      null,
      () => {
        // API レスポンスが正常だった場合

        // レスポンスチェックのコールバックを呼ぶ
        errorType = checkApiResponseCallback(apiResponse);
        if (errorType) {
          // レスポンスチェックでエラー文字列が返ってきた場合は、その文字列でコールバックを呼ぶ
          logWrapper.log(
            "syncRequest.executeSyncRequest: error.",
            errorType,
            true
          );
          errorCallback(errorType);
        }
      },
      (result) => {
        // API レスポンスが異常だった場合
        logWrapper.log(
          "syncRequest.executeSyncRequest: error in validate.",
          true
        );
        errorType = SYNC_REQUEST_ERROR_INVALID_RESPONSE;

        // エラー発生のコールバックを呼ぶ
        checkApiErrorResultCallback(result);
      },
      null,
      false
    );
  } catch (error) {
    // コールバック先等で exception が発生した場合のケア
    // ログ出力した上で、エラー処理を実施する
    logWrapper.log("syncRequest.executeSyncRequest: catch error.", error, true);

    errorType = SYNC_REQUEST_ERROR_INVALID_RESPONSE;

    // エラー発生のコールバックを呼ぶ
    typeof errorCallback === "function" && errorCallback(errorType);
  }

  // エラー発生時は null を返す
  return errorType ? null : apiResponse;
}
