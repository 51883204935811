export const PHASE = {
  START: 'start',
  FAILED: 'failed',
  EXECUTE: 'execute',
  WARN: 'warn',
  DISPLAY_PIN_NUMBER: 'displayPinNumber',
  INPUT_PIN_NUMBER: 'inputPinNumber',
  ERROR_PIN_NUMBER: 'errorPinNumber',
  CHECKING: 'checking',
  INPUT_DATA: 'inputData',
  DISPLAY_INPUT_DATA: 'displayInputData',
  WRITING: 'writing',
  INVALID_DATA: 'invalidData',
  SUCCESS_12_MODEL: 'success12Model'
};