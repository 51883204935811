<b-modal v-model="modal_requirement_004" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'writing'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期画面 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <!-- 仕向 -->
            <div class="col-12">
              <div class="text-left align-middle">
                <span class="box-margin-right">{{ $t(`${i18nWsKey}.label_destination`) }}</span>
              </div>
            </div>
            <div class="col-12">
              <b-form-radio-group size="sm" :disabled="disableDestination" v-model="selectedDestination">
                <div class="col-12 mt-1" v-for="item in destinations" :key="item.key">
                  <b-form-radio :value="item.value">{{ $t(`${i18nWsKey}.${item.key}`) }}</b-form-radio>
                </div>
              </b-form-radio-group>
            </div>
            
            <div class="col-12">
              <hr class="hr-line-100">
            </div>
  
            <!-- VIN登録情報 -->
            <div class="col-12 mt-2">
              <div class="text-left align-middle">
                <span class="box-margin-right">{{ $t(`${i18nWsKey}.label_vin_vehicle_information`) }}</span>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="col-12 text-left align-middle">
                <b-form-group>
                  <b-form-input
                    :placeholder="$t(`${i18nWsKey}.label_input_vin_placeholder`)"
                    v-model="inputVin"
                    style="text-transform: uppercase"
                    :state="validateVin && !validateVin.valid"
                    type="text"
                    size="sm"
                    autocomplete="off">
                  </b-form-input>
                  <b-form-invalid-feedback v-if="validateVin">{{ validateVin.msg }}</b-form-invalid-feedback>
              </b-form-group>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="text-left align-middle danger">
                <span class="box-margin-right">{{ $t(`${i18nWsKey}.label_warn_vin_register`) }}</span>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(errorMsg) }}</div>
          </div>
        </template>
        <!-- API通信中 -->
        <template v-if="phase === 'writing'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(inProgressMsg) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 登録成功 -->
        <template v-if="phase === 'success'">
          <div class="w-100 mb-4">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.label_registered_vin`, [inputVin]) }}</div>
          </div>
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.label_match_vehicle_number`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitProcess" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading || !selectedDestination" variant="primary" @click="registerVin" class="mx-2">
            {{ $t('individual.common_button_register') }}
          </b-button>
      </template>
      <!-- 異常終了(エラー) -->
      <template v-if="phase === 'failed'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="exitProcess" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
      </template>
      <!-- 登録成功 -->
      <template v-if="phase === 'success'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="confirmRegister" class="mx-2">
            {{ $t('individual.common_button_yes') }}
          </b-button>
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="cancelRegister" class="mx-2">
            {{ $t('individual.common_button_no') }}
          </b-button>
      </template>
    </div>
  </template>
</b-modal>