<div :class=getRowStyle(source.index)>
  <div class="item-name">{{ source.name }}</div>
  <div class="item-value">{{ source.value }}</div>
  <div class="item-unit">{{ source.unit }}</div>
  <div class="item-value">{{ source.max }}</div>
  <div class="item-value">{{ source.min }}</div>
  <div class="item-value">{{ source.avg }}</div>
  <div class="item-check checkbox-size-lg">
    <b-form-checkbox v-model="splitCheckValue" 
      @change="handleChange(0, splitCheckValue, source.line)"
      :disabled="source.value===null"
      />
    </div>
  <div class="item-check checkbox-size-lg">
    <b-form-checkbox v-model="unionCheckValue"
      @change="handleChange(1, unionCheckValue, source.line)"
      :disabled="source.value===null"
      />
  </div>
</div>