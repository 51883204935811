/** ツリー機能キー名 */
export const EXECUTE_FUNCTION_TYPE_OBD_ALL_DTC = "obdAllDtc";
export const EXECUTE_FUNCTION_TYPE_OBD_INDIVIDUAL = "obdIndividual";
/** ツリーノード作成用 */
export const FUNCTION_KEY_DATA_MONITOR = "dataMonitor";
export const FUNCTION_KEY_DTC = "obdDtc";
export const FUNCTION_KEY_ONBOARD_MONITOR_TEST = "onboardMonitorTest";
export const FUNCTION_KEY_ONBOARD_SYSTEM_TEST = "onboardSystemTest";
export const FUNCTION_KEY_VEHICLE_INFO = "vehicleInfo";
export const FUNCTION_KEY_P_DTC = "pDtc";

export const FUNCTION_MAPPING = [
  {
    functionKey: FUNCTION_KEY_DATA_MONITOR,
    menuSupportKey: "dataMonitor",
    messageKey: "fixed_resource.obd_menu_datamonitor",
  },
  {
    functionKey: FUNCTION_KEY_DTC,
    menuSupportKey: "diagCode",
    messageKey: "fixed_resource.obd_menu_dtc",
  },
  {
    functionKey: FUNCTION_KEY_ONBOARD_MONITOR_TEST,
    menuSupportKey: "onboardMonitorTest",
    messageKey: "fixed_resource.obd_menu_monitortest",
  },
  {
    functionKey: FUNCTION_KEY_ONBOARD_SYSTEM_TEST,
    menuSupportKey: "onboardSystemTest",
    messageKey: "fixed_resource.obd_menu_systemtest",
  },
  {
    functionKey: FUNCTION_KEY_VEHICLE_INFO,
    menuSupportKey: "vehicleInfo",
    messageKey: "fixed_resource.obd_menu_infotype",
  },
  {
    functionKey: FUNCTION_KEY_P_DTC,
    menuSupportKey: "permanentDtc",
    messageKey: "fixed_resource.obd_menu_pdtc",
  },
];
