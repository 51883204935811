<!-- ブレーキランプ点灯運動 ダイアログ -->
<b-modal v-model="modal_requirement_141" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <!-- ヘッダー -->
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 待機、確認、実行、終了確認、再実行 -->
      <template v-if="phase === 'start' ||
                      phase === 'confirmation'||
                      phase === 'finishConfirmation'||
                      phase === 'retry'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 終了 -->
      <template v-if="phase === 'finish'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'overSpeed' ||
                      phase === 'unableToStart' ||
                      phase === 'unableToEnd' ||
                      phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>
  <!-- 文言表示 -->
  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_141_TXT_1`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 確認、実行 -->
        <template v-if="phase === 'confirmation'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_141_TXT_2`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 終了確認 -->
        <template v-if="phase === 'finishConfirmation'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_141_TXT_3_0002`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 終了 -->
        <template v-if="phase === 'finish'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_141_TXT_4`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 再実行 -->
        <template v-if="phase === 'retry'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_141_TXT_5`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 車輪速超過 -->
        <template v-if="phase === 'overSpeed'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_141_TXT_6`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 開始不可 -->
        <template v-if="phase === 'unableToStart'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_141_TXT_7`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 終了不可 -->
        <template v-if="phase === 'unableToEnd'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="work-support-message">
                <div style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_141_TXT_8`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="work-support-message">
              {{ $t(`individual.error_communication_error`) }}
            </div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>
  <!-- フッダー -->
  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 確認、実行 -->
      <template v-if="phase === 'confirmation'">
        <b-button size="sm" variant="outline-primary" @click="exit(true)" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="confirm" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 終了確認 -->
      <template v-if="phase === 'finishConfirmation'">
        <b-button size="sm" variant="primary" @click="finishProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 再実行 -->
      <template v-if="phase === 'retry'">
        <b-button size="sm" variant="outline-primary" @click="exit(true)" class="mx-2" :disabled="isLoading">
          {{ $t('individual.common_button_no') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="startProcess" class="mx-2" :disabled="isLoading">
          {{ $t('individual.common_button_yes') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" @click="onFailedOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>