import logWrapper from "../../logWrapper";
import handler from "../apiResultValidator";

/** 非同期リクエストのステータス：進行中 */
export const REQUEST_STATUS_IN_PROGRESS = "in_progress";
/** 非同期リクエストのステータス：完了 */
export const REQUEST_STATUS_COMPLETED = "completed";
/** 非同期リクエストのステータス：失敗 */
export const REQUEST_STATUS_FAILED = "failed";

/** POST 失敗エラー */
export const POLLING_ERROR_POST_INVALID_RESPONSE = "post_invalid_response";
/** GET 失敗エラー */
export const POLLING_ERROR_GET_INVALID_RESPONSE = "get_invalid_response";
/** ポーリング回数上限エラー */
export const POLLING_ERROR_RETRY_COUNT_MAX = "polling_count_max";

/**
 * ポーリングの POST レスポンスチェックのデフォルト処理
 * @param {object} postApiResponse POST API 呼び出し時のレスポンス
 * @returns {string} リクエスト ID が含まれていれば空文字、それ以外は {@link POLLING_ERROR_POST_INVALID_RESPONSE} を返す。
 */
export function postResponseCheckDefaultAction(postApiResponse) {
  if (postApiResponse?.data?.request_id) return "";

  logWrapper.log(
    "polling.postResponseCheckDefaultAction: request_id is not found in response.",
    postApiResponse?.data,
    true
  );
  return POLLING_ERROR_POST_INVALID_RESPONSE;
}

/**
 * ポーリングの GET レスポンスチェックのデフォルト処理
 * @param {object} getApiResponse GET API 呼び出し時のレスポンス
 * @returns {string} リクエストステータス {@link REQUEST_STATUS_IN_PROGRESS} / {@link REQUEST_STATUS_COMPLETED} / {@link REQUEST_STATUS_FAILED} のいずれかを返す。
 */
export function getResponseCheckDefaultAction(getApiResponse) {
  switch (getApiResponse?.data?.status) {
    case REQUEST_STATUS_COMPLETED:
    case REQUEST_STATUS_IN_PROGRESS:
      // ポーリング続行・終了はそのまま返す
      return getApiResponse.data.status;
    case REQUEST_STATUS_FAILED:
    default:
      // それ以外はログを出した上で、エラーを返す
      logWrapper.log(
        "polling.getResponseCheckDefaultAction: get response is invalid.",
        getApiResponse?.data,
        true
      );
      return REQUEST_STATUS_FAILED;
  }
}

/**
 * ポーリング処理を行う。
 * @param {object} self this を指定
 * @param {object} postApiResponse POST API のレスポンス
 * @param {object} getApiCallback GET API 呼び出し処理のコールバック関数
 * @param {object} errorCallback エラー発生のコールバック関数
 * @param {object} checkGetApiResponseCallback GET API のレスポンスチェック処理のコールバック関数
 * @param {object} checkGetApiErrorResultCallback GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {number?} pollingRetryCount Option: ポーリングのリトライ回数指定（指定なしの場合は、通常通り環境変数の値を使用）
 * @param {number?} pollingRetryInterval Option: ポーリングのリトライ待機時間（指定なしの場合は、通常通り環境変数の値を使用）
 * @returns {object} 最終的な GET レスポンス（エラー発生時は null になる）
 */
async function polling(
  self,
  postApiResponse,
  getApiCallback,
  errorCallback,
  checkGetApiResponseCallback,
  checkGetApiErrorResultCallback,
  pollingRetryCount = null,
  pollingRetryInterval = null
) {
  return new Promise((resolve) => {
    // ポーリング時のリトライ回数（指定回数ありの場合は、指定回数を設定）
    const retryMaxCount =
      !isNaN(pollingRetryCount) && pollingRetryCount > 0
        ? pollingRetryCount
        : process.env.VUE_APP_RETRY_COUNT;

    // ポーリング時のリトライ待機時間（指定回数ありの場合は、指定回数を設定）
    const retryInterval =
      !isNaN(pollingRetryInterval) && pollingRetryInterval > 0
        ? pollingRetryInterval
        : process.env.VUE_APP_RETRY_INTERVAL;

    let currentRetry = 0;

    let getApiResponse = {};
    let errorType;

    try {
      logWrapper.log(
        `polling.polling: startInterval. retryMaxCount: ${retryMaxCount}, retryInterval: ${retryInterval}`
      );
      const watcher = self.$startInterval(
        async () => {
          // 一定時間ごとに処理を実行し、ポーリング終了時にタイマー停止と resolve コールを行う

          let isPollingFinished = false;

          try {
            // GET API コールバックを呼ぶ
            getApiResponse = await getApiCallback(postApiResponse);
            handler.validate(
              handler.validateTypes.all,
              getApiResponse,
              self,
              null,
              () => {
                // API レスポンスが正常だった場合

                // レスポンスチェックのコールバックを呼ぶ
                const checkGetResponseResult =
                  checkGetApiResponseCallback(getApiResponse);
                switch (checkGetResponseResult) {
                  case REQUEST_STATUS_IN_PROGRESS:
                    // ポーリング続行が返された場合
                    if (++currentRetry >= retryMaxCount) {
                      // リトライ最大回数超過の場合のみ、ポーリング終了
                      isPollingFinished = true;
                      errorType = POLLING_ERROR_RETRY_COUNT_MAX;
                    }
                    break;
                  case REQUEST_STATUS_COMPLETED:
                    // ポーリング終了が返された場合
                    isPollingFinished = true;
                    break;
                  case REQUEST_STATUS_FAILED:
                  default:
                    // それ以外は返ってきた文字列をそのままエラー文字列として格納
                    isPollingFinished = true;
                    errorType = checkGetResponseResult;
                    break;
                }

                if (isPollingFinished) {
                  // タイマー停止を呼ぶ
                  logWrapper.log("polling.polling: stopInterval.");
                  self.$stopInterval(watcher);
                }

                if (errorType) {
                  // エラー発生のコールバックを呼ぶ
                  logWrapper.log("polling.polling: error.", errorType, true);
                  errorCallback(errorType);
                }
              },
              (result) => {
                // API レスポンスが異常だった場合
                logWrapper.log(
                  "polling.polling: validate error. stopInterval.",
                  undefined,
                  true
                );
                errorType = POLLING_ERROR_GET_INVALID_RESPONSE;
                isPollingFinished = true;

                // タイマー停止を呼ぶ
                self.$stopInterval(watcher);

                // GET レスポンス異常のコールバックを呼ぶ
                checkGetApiErrorResultCallback(result);
              },
              null,
              false
            );
          } catch (error) {
            // コールバック先等で exception が発生した場合のケア
            // ログ出力した上で、エラー処理を実施する

            logWrapper.log(
              "polling.polling: catch error in interval. stopInterval.",
              error,
              true
            );

            errorType = POLLING_ERROR_GET_INVALID_RESPONSE;
            isPollingFinished = true;

            // タイマー停止を呼ぶ
            self.$stopInterval(watcher);

            // エラー発生のコールバックを呼ぶ
            typeof errorCallback === "function" && errorCallback(errorType);
          }

          if (isPollingFinished) {
            // ポーリング終了時は非同期処理を完了する
            // エラー発生時は null とする
            resolve(errorType ? null : getApiResponse);
          }
        },
        retryInterval,
        "polling.polling",
        true
      );
    } catch (error) {
      // コールバック先等で exception が発生した場合のケア
      // ログ出力した上で、エラー処理を実施する
      logWrapper.log("polling.polling: catch error.", error, true);

      // エラー発生のコールバックを呼ぶ
      typeof errorCallback === "function" && errorCallback(errorType);

      resolve(null);
    }
  });
}

/**
 * ポーリング処理共通関数
 * @param {object} self 呼び出し元の this を指定する
 * @param {object} postApiCallback POST API 呼び出し処理のコールバック関数
 * @param {object} getApiCallback GET API 呼び出し処理のコールバック関数
 * @param {object?} errorCallback エラー発生のコールバック関数
 * @param {object?} checkPostApiResponseCallback POST API のレスポンスチェック処理のコールバック関数\
 *                                               指定なしの場合は、request_id の存在チェックのみ実施
 * @param {object?} checkGetApiResponseCallback GET API のレスポンスチェック処理のコールバック関数\
 *                                              指定なしの場合は、status のチェックのみ実施
 * @param {object?} checkPostApiErrorResultCallback POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {object?} checkGetApiErrorResultCallback GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック関数
 * @param {object?} setPollingTimeOutCallback ポーリング時のタイムアウト停止コールバック関数\
 *                                            停止時は引数 true、再開時は引数 false で呼ぶ
 * @param {number?} pollingRetryCount ポーリングのリトライ回数指定（指定なしの場合は、通常通り環境変数の値を使用）
 * @param {number?} pollingRetryInterval ポーリングのリトライ待機時間指定（指定なしの場合は、通常通り環境変数の値を使用）
 * @returns {object} 最終的な GET レスポンス（エラー発生時は null になる）
 */
export async function executePolling(
  self,
  postApiCallback,
  getApiCallback,
  errorCallback = (errorType) => {
    logWrapper.log("default errorCallback called.", errorType, true);
  },
  checkPostApiResponseCallback = postResponseCheckDefaultAction,
  checkGetApiResponseCallback = getResponseCheckDefaultAction,
  checkPostApiErrorResultCallback = () =>
    errorCallback(POLLING_ERROR_POST_INVALID_RESPONSE),
  checkGetApiErrorResultCallback = () =>
    errorCallback(POLLING_ERROR_GET_INVALID_RESPONSE),
  setPollingTimeOutCallback = () => {},
  pollingRetryCount = null,
  pollingRetryInterval = null
) {
  let errorType;
  let postApiResponse;

  try {
    logWrapper.log("polling.executePolling: polling start.");

    // POST API コールバックを呼ぶ
    postApiResponse = await postApiCallback();
    handler.validate(
      handler.validateTypes.all,
      postApiResponse,
      self,
      null,
      () => {
        // API レスポンスが正常だった場合

        // レスポンスチェックのコールバックを呼ぶ
        errorType = checkPostApiResponseCallback(postApiResponse);
        if (errorType) {
          // レスポンスチェックでエラー文字列が返ってきた場合は、その文字列でコールバックを呼ぶ
          logWrapper.log(
            "polling.executePolling: error in checkPostApiResponseCallback.",
            errorType,
            true
          );
          errorCallback(errorType);
        }
      },
      (result) => {
        // API レスポンスが異常だった場合
        logWrapper.log(
          "polling.executePolling: error in validate.",
          undefined,
          true
        );
        errorType = POLLING_ERROR_POST_INVALID_RESPONSE;

        // POST レスポンス異常のコールバックを呼ぶ
        checkPostApiErrorResultCallback(result);
      },
      null,
      false
    );
  } catch (error) {
    // コールバック先等で exception が発生した場合のケア
    // ログ出力した上で、エラー処理を実施する
    logWrapper.log("polling.executePolling: catch error.", error, true);

    errorType = POLLING_ERROR_POST_INVALID_RESPONSE;

    // エラー発生のコールバックを呼ぶ
    typeof errorCallback === "function" && errorCallback(errorType);
  }

  if (errorType) {
    // エラー発生時は終了する
    return null;
  }

  try {
    // タイムアウト停止コールバックを true で呼ぶ
    typeof setPollingTimeOutCallback === "function" &&
      setPollingTimeOutCallback(true);

    // ポーリング処理
    return await polling(
      self,
      postApiResponse,
      getApiCallback,
      errorCallback,
      checkGetApiResponseCallback,
      checkGetApiErrorResultCallback,
      pollingRetryCount,
      pollingRetryInterval
    );
  } finally {
    // ポーリング時のタイムアウト停止コールバックが指定されている場合はそれを呼ぶ
    typeof setPollingTimeOutCallback === "function" &&
      setPollingTimeOutCallback(false);

    logWrapper.log("polling.executePolling: polling finish.");
  }
}
