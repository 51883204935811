<!-- 学習値クリアダイアログ -->
<b-modal v-model="modal_requirement_088" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>

  <!-- ********** ヘッダ ********** -->
  <template v-slot:header>
    <div class="text-center w-100">

      <!-- 待機画面 -->
      <!-- 実行画面 -->
      <template v-if="phase === 'start' ||
                      phase === 'execute'">
        {{ $t('message.common_title_confirmation') }}
      </template>

      <!-- 実行監視画面 -->
      <template v-if="phase === 'executing'">
        {{ $t('individual.common_title_execution') }}
      </template>

      <!-- 正常終了画面 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>

      <!-- クリア失敗エラー画面 -->
      <!-- 共通エラー画面 -->
      <template v-if="phase === 'failedExecute' ||
                      phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>

    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px">

        <!-- 待機画面 -->
        <template v-if="phase === 'start'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.REQ_WS_88_TXT_1`) }}</div>
          </div>
        </template>

        <!-- 実行画面 -->
        <template v-if="phase === 'execute'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.REQ_WS_88_TXT_2`) }}</div>
          </div>
        </template>

        <!-- 実行監視画面 -->
        <template v-if="phase === 'executing'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.REQ_WS_88_TXT_3_0001`) }}</div>
          </div>
        </template>

        <!-- 正常終了画面 -->
        <template v-if="phase === 'success'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.REQ_WS_88_TXT_4_1F00`) }}</div>
          </div>
        </template>

        <!-- クリア失敗エラー画面 -->
        <template v-if="phase === 'failedExecute'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.REQ_WS_88_TXT_5_1F01`) }}</div>
          </div>
        </template>

        <!-- 共通エラー画面 -->
        <template v-if="phase === 'failed'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>

      </div>
    </ShowLessBOverlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 待機画面 -->
      <template v-if="phase === 'start'">
        <b-button :disabled="isDisabled" size="sm" variant="outline-primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickExecute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 実行画面 -->
      <template v-if="phase === 'execute'">
        <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickExecuting" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 正常終了画面 -->
      <!-- クリア失敗エラー画面 -->
      <!-- 共通エラー画面 -->
      <template v-if="phase === 'success' ||
                      phase === 'failedExecute' ||
                      phase === 'failed'">
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

    </div>
  </template>

</b-modal>