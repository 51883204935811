<!-- カメラ全調整モード（交換/脱着） -->
<b-modal v-model="modal_requirement_056" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 確認 -->
      <template v-if="phase === 'start_confirm' || phase === 'start_distance_correction' 
      || phase === 'accuracy_rate_test' || phase === 'end_confirmation'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行中 -->
      <template v-if="phase === 'aiming_exe' || phase === 'distance_correction_exe' 
      || phase === 'accuracy_rate_test_exe'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 成功 -->
      <template v-if="phase === 'aiming_success' || phase === 'end_distance_correction' || phase === 'end_accuracy_rate_test'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- エラー -->
      <template v-if="phase === 'aiming_abnormal' || phase === 'timeout_error' 
      || phase === 'service_manual_error' || phase === 'failed' || phase === 'danger'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ displayInfo }}</div>
              </div>
            </div>
          </div>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
        <!-- 終了確認 -->
        <template v-if="phase === 'end_confirmation'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exitCancel()" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
        </template>
        <template v-if="phase === 'start_confirm' || phase === 'start_distance_correction' || phase === 'accuracy_rate_test' || phase === 'aiming_abnormal'">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="moveEndConfirm()" class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
        </template>
        <!-- 開始確認 -->
        <template v-if="phase === 'start_confirm'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="startConfirm()" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- エーミング異常終了 -->
        <template v-if="phase === 'aiming_abnormal'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="aimingAbnormalOKEvent()" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 距離補正開始確認 -->
        <template v-if="phase === 'start_distance_correction'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="startDistanceCorrectionOKEvent()" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 距離補正終了 -->
        <template v-if="phase === 'end_distance_correction'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="endDistanceCorrection()" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- 正解率検査開始確認 -->
        <template v-if="phase === 'accuracy_rate_test'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="accuracyRateTestOKEvent()" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- エーミング正常終了, 正解率検査終了, RoutineStatusによって異常終了とする画面の場合 -->
        <template v-if="phase === 'aiming_success' || phase === 'end_accuracy_rate_test' || phase === 'danger'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="callSuspension()" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!-- エラー系 -->
        <template
          v-if="phase === 'timeout_error' || phase === 'service_manual_error' || phase === 'failed' || phase === 'end_confirmation'">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="endExit()" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </template>
        <!--  -->
    </div>
  </template>
</b-modal>