import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';

/** バリアントデータ取得処理 type: check */
export async function checkVariant(self) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'check'
      },
      // エラー発生のコールバックを実装
      // ここでは利用していないが、引数の errorType にはあらかじめ用意された定数文字列が返ってくる。
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (response) {
      // 正常コールバック
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // 正常終了
          // 12桁型式を取得
          self.twelveDigitModelCode = response.data.user_data.twelve_digit_model_code;
          // 一覧データ取得処理
          self.getVariantData = response.data.user_data.variant_data;
          // アプライドモデル・オプションコード入力画面へ遷移
          self.inputData();
          break;
        case REQUEST_STATUS_FAILED:
        default:
          // 異常終了：共通エラー
          self.showUnexpectedError();
          return;
      }
    }
  } finally {
    self.isLoading = false;
  }
}