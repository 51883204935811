<div class="container-fluid ml-n2">
  <processing-view :processing="processing"></processing-view>

  <!-- register ECU (type-2) confirmation -->
  <b-modal v-model="registerECUtype2" size="lg" header-bg-variant="secondary" header-text-variant="light" no-stacking
    centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div class="text-left w-auto m-4">
        <div>
          <b>{{$t('imb.register_ecu_exec_ecm_reg')}}</b>
        </div>
        <div>
          <b>{{$t('imb.common_press_ok_or_cancel')}}</b>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button variant="outline-primary" @click="cancelButtonClick()">
          {{ $t('message.common_button_cancel')}}
        </b-button>
        <b-button variant="primary" v-on:click="onRegisterECUType2OkButtonClick()" class="ml-4">
          {{ $t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register ECU (type-2) warning for key not found -->
  <b-modal v-model="registerECUtype2warningkeynotfound" size="lg" header-bg-variant="warning"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_warning')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.common_num_reg_key_perform_imb_system_reg')">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="backEventButtonClick('register-ECU-type-2-warning-key-not-found')" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register ECU (type-2) confirm the ignition is on -->
  <b-modal v-model="registerECUtype2confirmigon" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3">
          {{$t('imb.common_igni_on_with_previous_key')}}
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{$t('imb.common_check_igni_on_done')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary"
          v-on:click="igOnCancelButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_cancel')}}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="igOnRetryOrOkButtonClick('register-ECU-type-2-confirm-igon')" class="ml-2 mr-2">
          {{ $t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register ECU (type-2) process is on going for Communicating with Body Integrated Unit -->
  <b-modal v-model="registerECUtype2progresscommBIU" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('imb.common_executing') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="container-fluid mt-3 mb-4">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100" v-html="imbStatusInfo.error_message">
      </div>
      <div class="col-md-12 text-center">
        {{ $t('imb.common_please_wait') }}
      </div>
    </template>
  </b-modal>

  <!-- register ECU (type-2) warning cannot detect IGON -->
  <b-modal v-model="registerECUtype2warningigon" size="lg" header-bg-variant="warning" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_warning')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.common_cannot_detect_igni_on')">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="backEventButtonClick('register-ECU-type-2-warning-ig-on')"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register ECU (type-2) turn on and confirm ignition -->
  <b-modal v-model="registerECUtype2confirmigison" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.error_imb_ig_switch_is_on')">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{$t('imb.common_check_igni_on_done')}}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary"
          v-on:click="igOnCancelButtonClick()" class="ml-2 mr-2">
          {{$t('message.common_button_cancel')}}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="igOnRetryOrOkButtonClick('register-ECU-type-2-confirm-ig-is-on')" class="ml-2 mr-2">
          {{$t('message.common_button_ok')}}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register ECU (type-2) error response -->
  <b-modal v-model="registerECUtype2failure" size="lg" header-bg-variant="danger" header-text-variant="light" no-stacking
    centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_error')}}</div>
    </template>
    <template v-slot:default>
      <div v-if="!isCausesAndActionDialog(imbStatusInfo.error_code)">
        <div class="text-center w-100 mt-3" v-html="imbStatusInfo.error_message">
        </div>
      </div>
      <div v-else>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_code') }}:</div>
          <div class="col-9">{{ imbStatusInfo.error_code }}</div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_cause') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_cause_message"></div>
        </div>
        <div class="col-12 text-left row mt-1">
          <div class="col-3 text-right">{{ $t('message.label_repro_error_action') }}:</div>
          <div class="col-9" v-html="imbStatusInfo.error_action_message"></div>
        </div>
      </div>
      <div>
        <div class="text-center w-100 mt-2">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary"
          v-on:click="errorOkButtonClick('register-ECU-type-2-failure','register-ECU-type-2-confirm-ig-is-off')"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- ECU registration (type-2) succsess response -->
  <b-modal v-model="registerECUtype2success" size="lg" header-bg-variant="success" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('message.common_title_success') }}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.register_ecu_success_ecm_reg')">
        </div>
        <div class="text-center w-100">
          {{ $t('imb.common_press_ok') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="successOkButtonClick()" class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- register ECU (type-2) turn off and confirm ignition -->
  <b-modal v-model="registerECUtype2confirmigisoff" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc @show="modalShowWEvent('show')" @hidden="modalShowWEvent('hide')">
    <template v-slot:header>
      <div class="text-center w-100">{{$t('message.common_title_confirmation')}}</div>
    </template>
    <template v-slot:default>
      <div>
        <div class="text-center w-100 mt-3" v-html="$t('imb.register_ecu_ecm_reg_ended_turn_off_the_igni')">
        </div>
      </div>
      <div>
        <div class="text-center w-100">
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('imb.common_confirm_igni_off_removed_key') }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked" v-on:click="igOffOkButtonClick()"
          class="ml-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

</div>