<div class="container-fluid ml-n2">
  <!-- プラットフォーム世代判別中  -->
  <b-modal v-model="modal_platform_gen_decision" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer>
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('mac.executing') }}</div>
    </template>
    <template v-slot:default>
      <div class="container-fluid mt-3 mb-3">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100 mt-3">{{ $t('mac.discriminating_vehicles_progress') }}</div>
      <div class="text-center w-100 mt-1">{{ $t('mac.plz_wait') }}</div>
    </template>
  </b-modal>

  <!-- 鍵更新実行中  -->
  <b-modal v-model="modal_mac_key_update_progress" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer >
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('mac.executing') }}</div>
    </template>
    <template v-slot:default>
      <div class="container-fluid mt-3 mb-3">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100 mt-3">{{ $t('mac.mac_key_update_in_progress') }}</div>
      <div class="text-center w-100 mt-1">{{ $t('mac.plz_wait') }}</div>
      <div class="text-left">
        <table class="table-align" v-if="!isPlatformGen19epf">
          <div>
            <br>
            <div v-html="$t('mac.message_authentication_cannot_be_performed_during_mac_key_update')"></div>
          </div>
        </table>
      </div>
    </template>
  </b-modal>

  <!-- IG-ON確認 -->
  <b-modal v-model="modal_check_ignition_is_on" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_confirmation') }}
      </div>
    </template>
    <template v-slot:default>
      <div class="m-4">
        <div class="text-center w-100">
          <div>{{ $t('mac.turn_on_ig_SW_vehicle') }}</div>
          <div
            v-if="macUpdateInfo.mac_status == constants.MAC_KEY_IG_ON_REQUEST_AGAIN">
            {{ $t('mac.click_retry') }}
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('mac.confirm_ignition_is_on') }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button class="ml-2 mr-2"
          v-if="macUpdateInfo.mac_status == constants.MAC_KEY_IG_ON_REQUEST_AGAIN"
          size="sm" variant="outline-primary" v-on:click="macUpdateEventOnIgnition(2)">
          {{ $t('mac.quit') }}
        </b-button>
        <b-button class="ml-2 mr-2"
          v-if="macUpdateInfo.mac_status == constants.MAC_KEY_IG_ON_REQUEST_AGAIN"
          size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="macUpdateEventOnIgnition(1)">
          {{ $t('mac.retry') }}
        </b-button>
        <b-button class="ml-2 mr-2"
          v-if="macUpdateInfo.mac_status == constants.MAC_KEY_IG_ON_REQUEST_TWO"
          size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="macUpdateEventOnIgnition(1)">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- アップロード/ダウンロード実行中 -->
  <b-modal v-model="modal_uploading_downloading_in_progress" size="lg" header-bg-variant="secondary"
    header-text-variant="light" no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('mac.executing') }}
      </div>
    </template>
    <template v-slot:default>
      <div class="container-fluid mt-3 mb-4">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100">
        {{ $t('mac.uploading_downloading_in_progress') }}
      </div>
      <div class="text-center w-100">
        {{ $t('mac.plz_wait') }}
      </div>
    </template>
  </b-modal>

  <!-- IG-OFF確認 -->
  <b-modal v-model="modal_check_ignition_is_off" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_confirmation') }}
      </div>
    </template>
    <template v-slot:default>
      <div class="m-4">
        <div class="text-center w-100">
          <div>{{ $t('mac.check_ignition_is_off') }}</div>
          <div v-if="macUpdateInfo.mac_status == constants.MAC_KEY_IG_OFF_REQUEST_AGAIN">
            {{ $t('mac.click_retry') }}
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('mac.confirm_ignition_is_off') }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button class="ml-2 mr-2" v-if="macUpdateInfo.mac_status == constants.MAC_KEY_IG_OFF_REQUEST_AGAIN" size="sm"
          variant="outline-primary" v-on:click="macUpdateEventOnIgnition(4)">
          {{ $t('mac.quit') }}
        </b-button>
        <b-button class="ml-2 mr-2" v-if="macUpdateInfo.mac_status == constants.MAC_KEY_IG_OFF_REQUEST_AGAIN" size="sm"
          variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="macUpdateEventOnIgnition(3)">
          {{ $t('mac.retry') }}
        </b-button>
        <b-button class="ml-2 mr-2" v-if="macUpdateInfo.mac_status == constants.MAC_KEY_IG_OFF_REQUEST" size="sm"
          variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="macUpdateEventOnIgnition(3)">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- 鍵更新完了 -->
  <b-modal v-model="modal_key_update_completed" size="lg" header-bg-variant="success" header-text-variant="light" no-stacking
    centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_success') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="text-center m-4" v-if="isPlatformGen19epf" v-html="$t('mac.mac_key_update_completed')"></div>
      <div class="text-center m-4" v-if="!isPlatformGen19epf">
        <div v-html="$t('mac.mac_key_update_completed_continue_register_key')"></div>
        <br/>
        <table class="table-align">
          <div class="text-left" v-html="$t('mac.mac_key_update_completed_perform_memory_clear_all_units')"></div>
        </table>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onMacUpdateOkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- 鍵更新失敗 -->
  <b-modal v-model="modal_key_update_err_failed" size="lg" header-bg-variant="danger" header-text-variant="light" no-stacking
    centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_error') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="text-center m-4">
        <div v-html="$t(macUpdateInfo.error_message_key, [macUpdateInfo.comm_target_ecu])"></div>
        <div v-if="isEcuError"><br></div>
        <table class="table-align">
          <div class="text-left" v-for="system in macUpdateInfo.systems">
            <!-- 通信エラー -->
            <td class="d-flex" v-if="isDiagCommError">
              <div>{{ system.system_name }}</div>
              <div v-if="!isPlatformGen19epf">
                &nbsp;:&nbsp;{{ system.last_error_response }}
              </div>
            </td>
            <!-- 異常処理ECU一覧 -->
            <td v-if="isEcuError">
              <div>
                {{ system.system_name }}&nbsp;:&nbsp;{{ system.ecu_status }}
              </div>
            </td>
          </div>
        </table>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onMacUpdateOkButtonClick()">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</div>