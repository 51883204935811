<b-modal v-model="modal_requirement_040" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t(`individual.common_title_execution`) }}
      </template>
      <template v-if="phase === 'wait'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed' || phase === 'connectionFailed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
          <div class="row no-gutters">
            <div class="col-12">
              <!--  スピナー表示画面  -->
              <div v-if="phase === 'start'" class="text-left align-middle"></div>
              <!--  待機画面  -->
              <div v-if="phase === 'wait'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_40_TXT_1`) }}</div>
              </div>
              <!-- 正常終了 -->
              <div v-if="phase === 'success'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_40_TXT_2_1000`) }}</div>
              </div>
              <!-- 正常終了（未接続）-->
              <div v-if="phase === 'connectionFailed'" class="text-left align-middle">
                <div class="box-margin-right work-support-message ">{{ $t(`${i18nWsKey}.REQ_WS_40_TXT_3_1001`) }}</div>
              </div>
              <!--  異常終了  -->
              <div v-if="phase === 'failed'" class="text-left align-middle">
                <div class="box-margin-right work-support-message ">{{ $t(`individual.error_communication_error`) }}</div>
              </div>
            </div>
          </div>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center">
      <template v-if="phase === 'wait'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="execute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'success' || phase === 'failed' || phase === 'connectionFailed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exitProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>