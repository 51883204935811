import individualDiagnose from '../../../../../share/util/individualDiagnose';
import individualWorkSupportUtil from '../../../../../share/util/individualWorkSupportUtil';
import session from '../../../../../share/session';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../../share/util/api/polling';
import { PHASE } from './constants';

/** AEB履歴を取得 */
export async function readHistoryData(self) {
  // EUCキーの取得
  const systemId = individualDiagnose.getCurrentSystemId(self);
  const token = session.getToken();
  // 開始処理・セキュリティアクセス (要求)（バックエンドAPI_POST） Getのポーリング処理も行う
  const response = await individualWorkSupportUtil.pollingWspApi(
    self,
    self.netAppId,
    systemId,
    self.workSupportId,
    {
      id_token: token.id_token,
      type: 'standby',
      is_special: true,
    },
    // エラー発生のコールバック
    (errorType) => { errorType; self.showUnexpectedError(); },
    // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { self.validateErrorResult(result); },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { self.validateErrorResult(result); },
  );
  if (response) {
    // ポーリングが正常に完了した場合
    switch (response.data.status) {
      case REQUEST_STATUS_COMPLETED: {
        self.historyData = response.data.user_data;
        // 履歴情報の表示用変換
        const historyArr = self.historyInfoTableDataConvert();
        self.phase = PHASE.HISTORY_VIEW;
        self.displayHistoryInfo = [];
        // 履歴情報表示の一覧を描画
        self.$nextTick(() => {
          self.displayHistoryInfo = historyArr;
        });
        break;
      }
      case REQUEST_STATUS_FAILED:
      default:
        self.showUnexpectedError();
        break;
    }
  }
}