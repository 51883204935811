<b-modal v-model="modal_requirement_080" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="(phase === 'failed') || (phase === 'error')">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期画面 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_80_TXT_1`) }}</div>
          </div>
        </template>
        <!-- 正常終了 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_80_TXT_2_0002`) }}</div>
          </div>
        </template>
        <!-- 失敗表示 -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_80_TXT_3`) }}</div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
        <b-button :disabled="isLoading" size="sm" variant="outline-primary" @click="onStartCancelClicked" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onStartOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 正常終了 -->
      <template v-if="phase === 'success'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onSuccessOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 失敗表示 -->
      <template v-if="phase === 'failed'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onFailedOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了(エラー) -->
      <template v-if="phase === 'error'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>