<div class="container">
  <div class="login-container" v-if="!showMaintenanceScreen">
    <div class="row row-0" style="margin-left: 10px; margin-right: 10px;">

      <div class="col-12 text-center mt-3">
        <label class="sub-title-style">{{ $t('message.header_signin_other_system') }}</label>
      </div>
      <div class="col-12 text-center">
        <label v-if="isExternalError" class="px-4 pb-2 pt-2 label-sm"
          v-html="$t('message.label_external_signin_error')"></label>
        <b-form-group v-for="(item, index) in samlAccountList" :key="index" class="form-group">
          <b-button variant="primary" v-on:click="executeLoginWithAd(item.url, item.provider)">
            {{ item.name }}
          </b-button>
        </b-form-group>
        <label v-html="$t(domainInfo.labelLoginBottom)" v-if="!isExternalError"
          class="px-4 text-left label-ext-sm"></label>
      </div>
      <div class="col-12" v-if="!isExternalError">
        <hr class="hr-line-100">
        <div class="text-center checkbox-size-lg">
          <b-form-checkbox @input="startSkipChecked">
            {{ $t('message.check_skip_start_screen') }}
          </b-form-checkbox>
        </div>
        <div class="mt-3 mb-4 text-center">
          <strong v-html="$t('message.label_skip_start_description')"></strong>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <maintenance-screen @forced-login="executeForcedLogin"></maintenance-screen>
  </div>
  <div class="icp-info">
    <img src="/icp.png">&nbsp;
    <a class="icp-info-sub" :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + securityNumber"
      target="_blank" rel="noopener noreferrer">京公网安备&nbsp;{{securityNumber}}号</a>&nbsp;|
    <a class="icp-info-sub" href="https://beian.miit.gov.cn/" target="_blank">京ICP备{{icpNumber}}</a>
  </div>
</div>