<b-modal
  v-model="modal_requirement_075"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'standby'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'start' || phase === 'cancel' ">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed' || phase === 'error' ">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期画面 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_75_TXT_1`) }}</div>
          </div>
        </template>
        <!-- 正常終了 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_75_TXT_2_0002`) }}</div>
          </div>
        </template>
        <!-- キャンセル表示 -->
        <template v-if="phase === 'cancel'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_75_TXT_3`) }}</div>
          </div>
        </template>
        <!-- 異常終了表示 -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_75_TXT_4_0000`) }}</div>
          </div>
        </template>
        <!-- 通信エラー -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_75_TXT_5`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" variant="outline-primary" @click="onStartCancelClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onStartOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 正常終了 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" variant="primary" @click="onSuccessOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- キャンセル終了 -->
      <template v-if="phase === 'cancel'">
        <b-button size="sm" variant="outline-primary" @click="onCancelNoClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onCancelYesClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了表示 -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" @click="onFailedOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 通信エラー -->
      <template v-if="phase === 'error'">
        <b-button size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>