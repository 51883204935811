import api from '../../common/api';
import session from '../../share/session';
import config from '../../share/config';
import alert from '../../share/alert';
import handler from '../../share/util/apiResultValidator';
import util from '../../share/util/utils';
import logWrapper from '../../share/logWrapper';

export default {
  namespaced: true,

  state: {
    loggedIn: false, //!!session.getToken()
    forceSignIn: false,
    loginParameters: null,
    lastOperatedTime: 0,
    sessionKeepInterval: process.env.VUE_APP_COMMON_SESSION_KEEP_INTERVAL_MIN_TIME,
  },

  mutations: {
    loginWithAd(state) {
      state.loggedIn = true;
      state.forceSignIn = false;
      session.startSessionTimer();
    },

    login(state) {
      state.loggedIn = true;
      state.forceSignIn = false;
    },

    setForceSignIn(state, payload) {
      state.forceSignIn = payload;
    },

    logout(state) {
      state.loggedIn = false;
      state.forceSignIn = false;
      session.stopSessionTimer();
      session.destroyToken();
      session.destroyLoginToken();
      session.destroyUser();
    },

    setLoginParameters(state, payload) {
      state.loginParameters = payload;
    },

    setLatOperatedTime(state, payload) {
      state.lastOperatedTime = payload;
    },

    setIntervalTime(state, payload) {
      state.sessionKeepInterval = payload;
    }
  },

  actions: {
    setForceSignIn(context, payload) {
      context.commit('setForceSignIn', payload);
    },

    // 外部認証サーバーログイン処理
    async externalLogin(context, payload) {
      let result = false;
      // セッションクリア
      session.destroyToken();
      session.destroyLoginToken();
      session.destroyUser();

      const param = {
        code: payload.code,
        domain: payload.domain,
        provider: payload.provider
      };
      const loginResponse = await api.postCall(config.REPRO, '/signin/external', param);
      // APIレスポンスの検証
      const isSuccess = handler.validate(
        handler.validateTypes.response, loginResponse, payload.modal, null, null, null, null, false);

      if (isSuccess) {
        // ログイン成功を設定
        result = true;
        session.saveToken(loginResponse.data);
        session.saveLoginToken(loginResponse.data);
        session.saveUser(loginResponse.data.user_id);
        // ログイン応答内容を保持
        context.commit('setLoginParameters', loginResponse.data);

        // APPにログインしたユーザーの言語情報を設定する。 
        context.dispatch('systems/setLanguage', { language: loginResponse.data.language }, { root: true });
        // SAML連携情報をloginResponseに追加する。
        loginResponse.data["is_external_auth"] = true;
        // ログインユーザー情報を設定する。 
        context.dispatch('userProperty/setUserProperty', loginResponse.data, { root: true });
        // ログインユーザーが利用可能な機能を設定する
        context.dispatch('userProperty/setFunctionAvailability', loginResponse.data.functions, { root: true });
        // ログイン完全の場合、トップ画面の表示
        context.commit("login");

        // Start session expiry timer.
        const interval = convertMinToMilliseconds(loginResponse.data.session_timeout);
        setupSessionTimer(context, payload, interval);
      }
      return result;
    },

    async login(context, payload) {
      session.destroyToken();
      session.destroyLoginToken();
      session.destroyUser();

      const param = {
        user_name: payload.user_name,
        password: payload.password,
        code: payload.code,
        requestToken: payload.requestToken,
        force_signin: context.state.forceSignIn
      };

      let loginSuccess = false;
      try {
        const loginResponse = await api.postCall(config.REPRO, '/signin', param);
        // APIレスポンスの検証
        const isSuccess = handler.validate(
          handler.validateTypes.response, loginResponse, payload.modal, null, null, null, null, false);

        if (isSuccess) {
          // ログイン応答内容を保持
          context.commit('setLoginParameters', loginResponse.data);
          // 新しいパスワード（初期パスワードを変更）を設定する画面が表示される。
          if (loginResponse.data.newPasswordRequired) {
            //session.removeRequestToken(payload.user_name);
            payload.modal.$router.push({
              name: 'completeNewPasswordChallenge',
              params: {
                userNameProp: payload.user_name,
                passwordProp: payload.password
              }
            });
            return loginResponse;
          }

          if (loginResponse.data.initPasswordRequired) {
            session.removeRequestToken(payload.user_name);
            const userInfo = loginResponse.data.user;
            const param = { user_id: userInfo.user_id, first_name: userInfo.first_name, family_name: userInfo.family_name };
            await api.postCall(config.ADMIN, '/initPassword', param);
            return loginResponse;
          }

          // 二段階認証が必要だった場合、二段階認証の画面が表示される。
          if (loginResponse.data.verificationCodeRequired) {
            return loginResponse;
          }

          if (typeof loginResponse.data.newPasswordRequired !== 'undefined' && loginResponse.data.newPasswordRequired) {
            session.removeRequestToken(payload.username);
            payload.router.push({ name: 'completeNewPasswordChallenge', params: { username: param.username, password: param.password } });
            return loginResponse;
          }

          const requestToken = loginResponse.data.requestToken ? loginResponse.data.requestToken : param.requestToken;
          if (requestToken) {
            session.saveRequestToken(payload.user_name, requestToken);
          }

          loginSuccess = true;
          session.saveToken(loginResponse.data);
          session.saveLoginToken(loginResponse.data);
          session.saveUser(loginResponse.data.user_id);

          // APPにログインしたユーザーの言語情報を設定する。 
          context.dispatch('systems/setLanguage', { language: loginResponse.data.language }, { root: true });
          // SAML連携情報をloginResponseに追加する。
          loginResponse.data["is_external_auth"] = false;
          // ログインユーザー情報を設定する。 
          context.dispatch('userProperty/setUserProperty', loginResponse.data, { root: true });
          // ログインユーザーが利用可能な機能を設定する
          context.dispatch('userProperty/setFunctionAvailability', loginResponse.data.functions, { root: true });
          // ログイン状態を有効に設定
          context.commit('login');

          // Set session timeout configuration.
          const interval = convertMinToMilliseconds(loginResponse.data.session_timeout);
          setupSessionTimer(context, payload, interval);

          return loginResponse;
        }

        // 失敗した場合に応答があれば値を返す
        if (loginResponse) {
          return loginResponse;
        }
        // 失敗した場合に値がなければ null を返却
        return null;

      } catch (error) {
        // Logout user incase of any errors.
        if (loginSuccess) {
          api.postCall(config.REPRO, '/signout', { domain: window.location.hostname });
        }
        return null;
      }
    },

    async logout(context, payload) {
      try {
        context.dispatch('app/setProcessingIcon', true, { root: true });
        await context.dispatch('userProperty/saveUserCache', {}, { root: true });
        await api.postCall(config.REPRO, '/signout', { domain: window.location.hostname });
      } catch (error) {
        // console.log('Exception in signout: ', error);
      }

      context.dispatch('app/setProcessingIcon', false, { root: true });

      context.dispatch('app/setInitLogin', false, { root: true });
      context.commit('logout');

      if (payload) {
        // 動作しているタイマーを全て停止する
        payload.$stopAllIntervals();

        // ログイン画面遷移実施情報をチェック
        let moveLogin = true;
        if ('isMoveLogin' in payload) { moveLogin = payload.isMoveLogin; }
        if (moveLogin) {
          await payload.$router.push({ name: 'login' }).catch(err => { }); // eslint-disable-line no-unused-vars
        }
      }
      // セッションタイムアウトによるログアウトの場合、ログアウト時にstoreの初期化を実施するとTypeErrorが起きるため、警告モーダルOKボタン押下時（setupSessionTimer内）に初期化を実施する
      if (!util.hasPropertyCheck(payload, 'isSessionTimeout')) {
        context.dispatch('reset', {}, { root: true });
      }

      // session メンバ変数を初期化
      session.terminateSession();
    },

    async resetSession(context, payload) {

      context.commit('logout');

      if (payload) {
        // 動作しているタイマーを全て停止する
        payload.$stopAllIntervals();
        // ログイン画面に遷移
        await payload.$router.push({ name: 'login' }).catch(err => { }); // eslint-disable-line no-unused-vars
      }
      context.dispatch('reset', {}, { root: true });
    },

    async completeNewPasswordChallenge(context, payload) {
      try {
        const user_name = payload.user_name;
        const param = {
          password: payload.password,
          new_password: payload.newPassword
        };

        const response = await api.postCall(config.ADMIN, `/completeNewPasswordChallenge/${user_name}`, param);
        if (api.isSuccessResponse(response.data)) {
          context.commit('login');
          session.saveToken(response.data);
          session.saveLoginToken(response.data);
          session.saveUser(payload.user_name);
        }
        return response;
      } catch (error) {
        return null;
      }
    },

    stopSessionTimer() {
      // セッションタイマーを停止する
      session.stopSessionTimer();
    },

    resetSessionTimer() {
      // タイムアウト上限の算出
      const sessionTimeout = util.getSessionLimit({});
      // セッションタイムアウトを設定
      session.setSessionTimeOut(convertMinToMilliseconds(sessionTimeout));
      session.startSessionTimer();
    },

    updateLastOperationTime(context, payload) {
      context.commit('setLatOperatedTime', payload);
    },
  }
};

const setupSessionTimer = (context, payload, millisec) => {
  session.stopSessionTimer();
  // タイムアウト時間の取得
  const defaultMin = util.getSessionLimit(context.state.loginParameters);
  const sessionTime = convertMinToMilliseconds(defaultMin);
  // Session timeout 抑制インターバル間隔時間を設定
  let intervalTime = sessionTime / 2;
  if (intervalTime < process.env.VUE_APP_COMMON_SESSION_KEEP_INTERVAL_MIN_TIME) {
    intervalTime = process.env.VUE_APP_COMMON_SESSION_KEEP_INTERVAL_MIN_TIME;
  }
  context.commit("setIntervalTime", intervalTime);
  // セッションタイムアウトの設定
  logWrapper.log("Session Timer has been setup: ", millisec);
  session.setSessionTimerConfig(async () => {
    logWrapper.log("Operation timeout loggedIn: ", context.state.loggedIn);
    if (context.state.loggedIn) {
      // 最終マウス操作時間が記録されている場合、現在時間との差分を計算
      const lastOperation = context.state.lastOperatedTime;
      const wastedTime = lastOperation > 0 ? (new Date().getTime()) - lastOperation : 0;
      // 差分時間がデフォルトのタイムアウト時間未満なら、その差分だけ再度タイマーセット
      if (lastOperation > 0 && wastedTime < sessionTime) {
        // 任意の API にアクセスして API のセッションを更新
        updateApiSession(context);
        // Frontend 操作タイムアウト時間を更新
        const nextTimeoutInterval = sessionTime - wastedTime;
        logWrapper.log("Next Timeout Interval: ", nextTimeoutInterval);
        setupSessionTimer(context, payload, nextTimeoutInterval);
      } else {
        // auth.logout 関数内では Login 画面に移動させない
        const arg = payload.modal;
        arg.isMoveLogin = false;
        arg.isSessionTimeout = null;
        context.dispatch('auth/logout', arg, { root: true });
        // 操作タイムアウトダイアログのメッセージ設定
        const formattedMsg = payload.modal.$t('message.sesion_timeout_message').replace('####', defaultMin);
        const title = payload.modal.$t('message.sesion_timeout_title');
        logWrapper.log("Operation timeout showWarning");
        alert.showWarning(payload.modal, title, formattedMsg, async () => {
          await payload.modal.$router.push({ name: 'login' }).catch(err => { err; });
          context.dispatch('reset', {}, { root: true });
        });
      }
    }
  }, millisec); // Timout in milliseconds
  // session監視タイマー開始
  session.startSessionTimer();
};

// API アクセスセッションを延長するための API アクセス
const updateApiSession = (context) => {
  try {
    api.getCall(config.ADMIN, '/users/' + context.state.loginParameters.user_id + '/caches');
  } catch (err) {
    logWrapper.log("Failed to API access for updating session token. User: ", context.state.loginParameters.user_id);
  }
};

const convertMinToMilliseconds = (timeout) => {
  return timeout * 60000;
};