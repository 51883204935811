<b-modal v-model="modal_requirement_070" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 待機画面 -->
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 目視確認対象選択画面 -->
      <template v-if="phase === 'inputTarget'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行中 -->
      <template v-if="phase === 'inProgress'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 異常終了系 -->
      <template v-if=" (phase === 'commonError')  || (phase === 'startProcessError')
                    || (phase === 'communicationError') || (phase === 'stopProcessError') 
                    || (phase === 'visualConfirmationFailure')">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'start'"> </template>
        <!-- 目視確認対象選択画面 -->
        <template v-if="phase === 'inputTarget'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs useSort rowSelect initSortDir="asc"
                  :items="bodyItems" :columns="systemTableColumns" type="simple"
                  @rowClicked="selectItem">
                </cstm-table>
              </div>
            </div>
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;" v-html="$t('individual.label_target_instruction')"></div>
          </div>
        </template>
        <!-- 開始処理エラー表示 -->
        <template v-if="phase === 'startProcessError'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;" v-html="$t('individual.label_monitoring_error')"></div>
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs useSort rowSelect initSortDir="asc"
                  :items="startProcessErrorBodyItems" :columns="systemTableColumns"
                  type="simple" @rowClicked="selectItem">
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行中 -->
        <template v-if="phase === 'inProgress'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t('individual.label_wait') }}
            </div>
          </div>
        </template>
        <!-- 停止処理エラー表示 -->
        <template v-if="phase === 'stopProcessError'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;" v-html="$t('individual.label_process_error')"></div>
          </div>
        </template>
        <!-- 目視確認失敗 -->
        <template v-if="phase === 'visualConfirmationFailure'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;" v-html="replaceMessage"></div>
          </div>
        </template>
        <!-- 異常終了(通信エラー表示) -->
        <template v-if="phase === 'communicationError'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t('individual.label_communication_error') }}
            </div>
          </div>
        </template>
        <!-- 異常終了(共通エラー表示) -->
        <template v-if="phase === 'commonError'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t('individual.error_communication_error') }}
            </div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 目視確認対象選択画面 -->
      <template v-if="phase === 'inputTarget'">
        <b-button :disabled="isLoading" size="sm" variant="outline-primary" @click="onInputTargetBackClicked"
          class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onInputTargetSelectClicked" class="mx-2"
          :disabled="isDisableButton">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了(停止処理エラー表示) -->
      <template v-if="phase === 'stopProcessError'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onStopProcessErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了(目視確認失敗表示) -->
      <template v-if="phase === 'visualConfirmationFailure'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onVisualConfirmationFailureOkClicked"
          class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了(開始処理エラー表示) -->
      <template v-if="phase === 'startProcessError'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onStartProcessErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了(通信エラー表示) -->
      <template v-if="phase === 'communicationError'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onCommunicationErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了(異常終了表示) -->
      <template v-if="phase === 'commonError'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onCommonErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>