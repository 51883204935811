/** 各フェーズ定義 */
export const PHASE = {
  START: 'start', // 警告表示
  HISTORY_VIEW: 'history-view', // 履歴情報表示
  STORE_HISTORY_VIEW: 'store-history-view', // 保存履歴表示
  ALERT_HISTORY_DATA: 'alert-history-data', // 履歴データ読み出し警告
  READ_HISTORY_DATA: 'read-history-data', // 履歴データ読み出し
  STORE_FILE: 'store-file', // ファイル保存
  SUCCESS: 'success', // 成功表示
  FAILED: 'failed', // 失敗表示
  ERROR: 'error' // 共通エラー
};

export const STANDBY_TYPE = {
  CONTROL_HISTORY: 'controlHistory',
  HALT_FAIL: 'haltFail',
  IG_ON: 'igOn'
};