/**
 * Route に応じたツリー処理を定義するクラス。
 */
export class RouteTreeAction {
  /**
   * ページが合致するかの判定用関数
   */
  judgeRoute = (currentRouteName) => { currentRouteName; return true; };

  /**
   * ツリーの選択処理を行う関数
   */
  treeAction = () => {};

  /**
   * コンストラクタ
   * @param {(string) => boolean} judgeRoute ページが合致するかの判定用関数
   * @param {() => void} treeAction ツリーの選択処理を行う関数
   */
  constructor(judgeRoute, treeAction) {
    if (typeof judgeRoute === "function") this.judgeRoute = judgeRoute;
    if (typeof treeAction === "function") this.treeAction = treeAction;
  }
}
