import logWrapper from "../../logWrapper";
import alert from "../../alert";
import handler from "../apiResultValidator";

/**
 * エラーハンドリングのデフォルト処理\
 * ※タイミングによっては警告ダイアログが表示されない問題（特に wsp）を回避するため、async としている
 * @param {object} self 呼び出し元の this
 * @param {object} result handler.validate で渡ってくるエラー情報
 * @param {object?} logoutCallback ログアウト処理のコールバック関数
 * @param {object?} resetSessionCallback セッションリセット処理のコールバック関数
 * @param {object?} otherErrorCallback 共通処理が実施されなかった際のコールバック関数
 */
export async function apiErrorDefaultAction(
  self,
  result,
  logoutCallback = () => {
    logWrapper.log(
      "errorAction.apiErrorDefaultAction: logoutCallback is not registerd.", undefined, true
    );
  },
  resetSessionCallback = () => {
    logWrapper.log(
      "errorAction.apiErrorDefaultAction: resetSessionCallback is not registerd.", undefined, true
    );
  },
  otherErrorCallback = () => {
    logWrapper.log(
      "errorAction.apiErrorDefaultAction: otherErrorCallback is not registerd.", undefined, true
    );
  }
) {
  const cause = result?.causeType;
  const behaviorType = result?.behaviorType;
  if (
    cause === handler.causeTypes.networkError ||
    cause === handler.causeTypes.invalidSessionToken ||
    cause === handler.causeTypes.clientConnectorNotAliveError
  ) {
    logWrapper.log("errorAction.apiErrorDefaultAction: showWarning.", cause, true);
    // セッションが不正・ネットワークエラー・CC 切断の場合、警告ダイアログを表示する
    alert.showWarning(self, null, result?.message, () => {
      // 条件次第でコールバック関数を呼ぶ
      if (behaviorType === handler.behaviorTypes.logout) {
        logWrapper.log("errorAction.apiErrorDefaultAction: behaviorType is logout.");
        logoutCallback(self);
      } else if (behaviorType === handler.behaviorTypes.resetSession) {
        logWrapper.log("errorAction.apiErrorDefaultAction: behaviorType is resetSession.");
        resetSessionCallback(self);
      }
    });
  } else if (cause === handler.causeTypes.permissionError) {
    logWrapper.log("errorAction.apiErrorDefaultAction: permissionError.", undefined, true);
    // ユーザー権限エラーの場合、エラーダイアログを表示しログアウトする
    alert.showError(self, null, result?.message, () => {
      logWrapper.log("errorAction.apiErrorDefaultAction: call logout.");
      logoutCallback(self);
    });
  } else {
    // それ以外の場合は、エラー発生のコールバック関数を呼ぶ
    logWrapper.log("errorAction.apiErrorDefaultAction: call otherErrorCallback.", cause, true);
    otherErrorCallback(result);
  }
}
