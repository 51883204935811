// HACK: Phase4Step2-2終了間際にESLintのルールが変更になり、ロジックは変更出来なかったため回避。
/* eslint-disable max-lines */
import config from '../config';
import util from './utils';
import logWrapper from '../logWrapper';

const LINE_BG_DISABLE = 'line-bgcolor-gray';
const LINE_BG_WARNING = 'line-bgcolor-yellow';
const PANE_BG_DISABLE = 'pane-bgcolor-gray';
const PANE_BG_WARNING = 'pane-bgcolor-yellow';

// systemInformation の表示メッセージ一覧 
// 以下はキー名がソート対象のキーとなっているため変更する際は注意する
// ----------------------------------------------------------------------------------------------------
const HYPHEN = 'message.common_hyphen'; // ハイフン
const REPROGRAMMABEL = 'message.a_status_vehicle_system_reprogrammable';        // 要
const NOT_REPROGRAMMABEL = 'message.b_status_vehicle_system_not_reprogrammable';     // 不要

// 以下DTCステータス
const DIAG_STATUS_CURRENT = 'message.a_status_vehicle_dtc_status_current'; // 現在
const DIAG_STATUS_LATEST = 'message.b_status_vehicle_dtc_status_latest'; //最新結果
const DIAG_STATUS_CONFIRM_LATEST = 'message.c_status_vehicle_dtc_status_confirm_latest'; //確定/最新結果
const DIAG_STATUS_CONFIRM_TENTATINE_LATEST = 'message.d_status_vehicle_dtc_status_confirm_tentative_latest'; //確定/仮/最新結果
const DIAG_STATUS_TENTATINE_LATEST = 'message.e_status_vehicle_dtc_status_tentative_latest'; //仮/最新結果
const DIAG_STATUS_PAST = 'message.f_status_vehicle_dtc_status_past'; //過去
const DIAG_STATUS_CONFIRM = 'message.g_status_vehicle_dtc_status_confirm'; //確定
const DIAG_STATUS_CONFIRM_TENTATIVE = 'message.h_status_vehicle_dtc_status_confirm_tentative'; //確定/仮
const DIAG_STATUS_TENTATIVE = 'message.i_status_vehicle_dtc_status_tentative'; //仮
const DIAG_STATUS_NO_DTC = 'message.j_status_vehicle_dtc_status_no_dtc'; //ダイアグコードなし
const DIAG_STATUS_CANNOT_CONNECT = 'message.k_status_vehicle_dtc_status_cannot_connect'; //通信不能
const DIAG_STATUS_NOT_SUPPORT = 'message.l_status_vehicle_dtc_status_dtc_not_support'; //ダイアグコード取得非対応
const DIAG_STATUS_CURRENT_PAST = 'individual.m_status_vehicle_dtc_status_current_past'; //現在/過去

// 以下BMCステータス
const BMC_STATUS_NO_CODE = 'individual.status_bmc_status_no_code'; //コードなし

// ----------------------------------------------------------------------------------------------------
const NO_REPRO_DATA = 'message.message_system_no_repro_data';
// const CHECK_CGW_REPRO = 'message.message_check_cgw_and_repro_again';
const CHECK_SYSTEMS = 'message.message_check_target_systems';
const SYSTEM_NOT_EMBEDDED = 'message.message_system_not_embedded';
const NO_DATA_CHECK_CGW = 'message.message_system_no_repro_data_check_cgw';
const CHECK_CGW_AND_SYSTEM = 'message.message_system_check_cgw_and_system';
const CHECK_CGW_NO_SYSTEM = 'message.message_system_check_cgw_and_no_system';
const CGW_DISCONNECT_NOT_EMBEDDED = 'message.message_system_cgw_not_found';
const SWID_NOT_CONSENTED = 'message.message_system_swid_not_consented';
const SWID_NOT_CONSENTED_AND_CHECK_CGW = 'message.message_system_swid_not_consented_and_check_cgw';
const OTHER_SWID_UNSETTED = 'message.message_system_other_swid_unsetted';
const OTHER_SWID_UNSETTED_AND_CHECK_CGW = 'message.message_system_other_swid_unsetted_and_check_cgw';
const CONSENT_HYPHEN = { id: null, order: 99, label: 'message.common_hyphen', hidden: true };
// ----------------------------------------------------------------------------------------------------
const HEX_00 = '00';        // 0
const FLG_FALSE = '0';        // 0
const FLG_TRUE = '1';        // 1
const HEX_FF = 'FF';        // FF
const DIAG_INVALID_STATUS = [config.DTC_STATUS_NO_DTC, config.DTC_STATUS_DTC_NOT_SUPPORT, config.DTC_STATUS_CANNNOT_CONNECT];

// 搭載 / 通信 の状態の組み合わせ
const CONFIG_INSTALLED = { order: 0, label: 'message.a_status_vehicle_system_installation' };
const CONFIG_UNINSTALLED = { order: 1, label: 'message.b_status_vehicle_system_uninstallation' };
const CONFIG_COMMUNICABLE = { order: 0, label: 'message.a_status_vehicle_system_communicatable' };
const CONFIG_UNCOMMUNICABLE = { order: 1, label: 'message.b_status_vehicle_system_not_communicatable' };
const CONFIG_ERROR = { order: 99, label: HYPHEN };
const BASIC_STATE_MAP = [
  { code: 0, inst: { status: config.REPRO_INSTALLED, resource: CONFIG_INSTALLED }, comm: { status: config.REPRO_COMUNICATED, resource: CONFIG_COMMUNICABLE } },
  { code: 1, inst: { status: config.REPRO_INSTALLED, resource: CONFIG_INSTALLED }, comm: { status: config.REPRO_NOT_COMUNICATED, resource: CONFIG_UNCOMMUNICABLE } },
  { code: 2, inst: { status: config.REPRO_INSTALLED, resource: CONFIG_INSTALLED }, comm: { status: config.REPRO_COMUNICATED_ERROR, resource: CONFIG_ERROR } },
  { code: 3, inst: { status: config.REPRO_NOT_INSTALLED, resource: CONFIG_UNINSTALLED }, comm: { status: config.REPRO_COMUNICATED, resource: CONFIG_COMMUNICABLE } },
  { code: 4, inst: { status: config.REPRO_NOT_INSTALLED, resource: CONFIG_UNINSTALLED }, comm: { status: config.REPRO_NOT_COMUNICATED, resource: CONFIG_UNCOMMUNICABLE } },
  { code: 5, inst: { status: config.REPRO_NOT_INSTALLED, resource: CONFIG_UNINSTALLED }, comm: { status: config.REPRO_COMUNICATED_ERROR, resource: CONFIG_ERROR } },
  { code: 6, inst: { status: config.REPRO_INSTALLED_ERROR, resource: CONFIG_ERROR }, comm: { status: config.REPRO_COMUNICATED, resource: CONFIG_COMMUNICABLE } },
  { code: 7, inst: { status: config.REPRO_INSTALLED_ERROR, resource: CONFIG_ERROR }, comm: { status: config.REPRO_NOT_COMUNICATED, resource: CONFIG_UNCOMMUNICABLE } },
  { code: 8, inst: { status: config.REPRO_INSTALLED_ERROR, resource: CONFIG_ERROR }, comm: { status: config.REPRO_COMUNICATED_ERROR, resource: CONFIG_ERROR } }
];
// リプロ有無の状態の組み合わせ
const CONFIG_REPROGRAMMABLE = { status: config.REPRO_REPROGRAMMABLE, resource: { order: 0, label: REPROGRAMMABEL } };
const CONFIG_NOT_REPROGRAMMABLE = { status: config.REPRO_NOT_REPROGRAMMABLE, resource: { order: 1, label: NOT_REPROGRAMMABEL } };
const CONFIG_ERR_REPROGRAMMABLE = { status: config.REPRO_REPROGRAMMABLE_ERROR, resource: { order: 99, label: HYPHEN } };
const REPROGRAM_RESOURCE_MAP = [CONFIG_REPROGRAMMABLE, CONFIG_NOT_REPROGRAMMABLE, CONFIG_ERR_REPROGRAMMABLE];
const REPROGRAM_STATE_MAP = [
  { code: 0, force: CONFIG_REPROGRAMMABLE, opt: CONFIG_NOT_REPROGRAMMABLE, merchant: CONFIG_NOT_REPROGRAMMABLE, restore: CONFIG_NOT_REPROGRAMMABLE },
  { code: 0, force: CONFIG_NOT_REPROGRAMMABLE, opt: CONFIG_REPROGRAMMABLE, merchant: CONFIG_NOT_REPROGRAMMABLE, restore: CONFIG_NOT_REPROGRAMMABLE },
  { code: 0, force: CONFIG_NOT_REPROGRAMMABLE, opt: CONFIG_NOT_REPROGRAMMABLE, merchant: CONFIG_REPROGRAMMABLE, restore: CONFIG_NOT_REPROGRAMMABLE },
  { code: 0, force: CONFIG_NOT_REPROGRAMMABLE, opt: CONFIG_NOT_REPROGRAMMABLE, merchant: CONFIG_NOT_REPROGRAMMABLE, restore: CONFIG_REPROGRAMMABLE },
  { code: 0, force: CONFIG_NOT_REPROGRAMMABLE, opt: CONFIG_REPROGRAMMABLE, merchant: CONFIG_REPROGRAMMABLE, restore: CONFIG_NOT_REPROGRAMMABLE },
  { code: 0, force: CONFIG_NOT_REPROGRAMMABLE, opt: CONFIG_NOT_REPROGRAMMABLE, merchant: CONFIG_REPROGRAMMABLE, restore: CONFIG_REPROGRAMMABLE },
  { code: 0, force: CONFIG_NOT_REPROGRAMMABLE, opt: CONFIG_REPROGRAMMABLE, merchant: CONFIG_REPROGRAMMABLE, restore: CONFIG_REPROGRAMMABLE },
  { code: 0, force: CONFIG_NOT_REPROGRAMMABLE, opt: CONFIG_REPROGRAMMABLE, merchant: CONFIG_NOT_REPROGRAMMABLE, restore: CONFIG_REPROGRAMMABLE },
  { code: 1, force: CONFIG_NOT_REPROGRAMMABLE, opt: CONFIG_NOT_REPROGRAMMABLE, merchant: CONFIG_NOT_REPROGRAMMABLE, restore: CONFIG_NOT_REPROGRAMMABLE },
  { code: 2, force: CONFIG_ERR_REPROGRAMMABLE, opt: CONFIG_ERR_REPROGRAMMABLE, merchant: CONFIG_ERR_REPROGRAMMABLE, restore: CONFIG_ERR_REPROGRAMMABLE },
];

// ユーザーの権限一覧を取得する
export const getAuthGroupList = () => [
  { value: config.GROUP_SYSTEM, text: 'message.common_auth_system' },
  { value: config.GROUP_SERVICE, text: 'message.common_auth_service' },
  { value: config.GROUP_PFC, text: 'message.common_auth_registrants' },
  { value: config.GROUP_REPRO_FILE_ADMIN, text: 'message.common_auth_repro_file_admin' },
  { value: config.GROUP_REPRO_FILE_TESTER, text: 'message.common_auth_repro_file_evaluator' },
  { value: config.GROUP_VARIANT_DATA_FILE_ADMIN, text: 'message.common_auth_variant_data_file_admin' },
  { value: config.GROUP_EVENT_LOG_ADMIN, text: 'message.common_auth_event_log_admin' },
  // 仕様変更によりUserAdminは一旦未使用
  // { value: config.GROUP_USER_ADMIN, text: 'message.common_auth_user_admin' },
  { value: config.GROUP_GENERAL, text: 'message.common_auth_users' }
];
export const getAuthGroupListForSearch = () => {
  const list = getAuthGroupList();
  list.unshift({ value: '', text: '' });
  return list;
};

// 権限区分ごとの表示用リソースを取得する
export const getAuthGroupResource = (auth) => {
  const all = getAuthGroupList();
  const index = all.findIndex(el => el.value === auth);
  if (index !== -1) {
    return all[index].text;
  }
  return null;
};

// 現在のサインインユーザーに応じて、登録・編集できる権限区分の一覧を取得
export const getAuthGroupOptions = (auth) => {
  let ret = [];
  switch (auth) {
    case config.GROUP_SYSTEM:
      ret = getAuthGroupList();
      break;
    case config.GROUP_SERVICE:
      ret = [
        { value: config.GROUP_SERVICE, text: 'message.common_auth_service' },
        { value: config.GROUP_REPRO_FILE_ADMIN, text: 'message.common_auth_repro_file_admin' },
        // 仕様変更によりUserAdminは一旦未使用
        // { value: config.GROUP_USER_ADMIN, text: 'message.common_auth_user_admin' },
        { value: config.GROUP_GENERAL, text: 'message.common_auth_users' }
      ];
      break;
    case config.GROUP_PFC:
      ret = [
        { value: config.GROUP_PFC, text: 'message.common_auth_registrants' }
      ];
      break;
    // 仕様変更によりUserAdminは一旦未使用
    /*case config.GROUP_USER_ADMIN:
      ret = [
        { value: config.GROUP_USER_ADMIN, text: 'message.common_auth_user_admin' },
        { value: config.GROUP_GENERAL, text: 'message.common_auth_users' }
      ];
      break;*/
    case config.GROUP_GENERAL:
      ret = [
        { value: config.GROUP_GENERAL, text: 'message.common_auth_users' }
      ];
      break;
    default:
      break;
  }
  return ret;
};

// ユーザーの有効・無効一覧を取得する
export const getActivationStatus = () => [
  { value: true, status: 'message.option_valid' },
  { value: false, status: 'message.option_abolition' }
];
// ユーザーの有効・無効一覧を取得する
export const getActivationStatusForSearch = () => {
  const list = [
    { value: '', status: '' },
    { value: config.SEARCH_USER_ACTIVE, status: 'message.option_valid' },
    { value: config.SEARCH_USER_ABOLISHED_ALL, status: 'message.option_abolition' },
    { value: config.SEARCH_USER_ABOLISHED_AUTO, status: 'message.option_auto_abolition' }
  ];
  return list;
};

// 長期不使用IDの検索条件一覧を取得する
export const getUnuseTermListForSearch = () => {
  const ret = [
    { value: 0, status: '' },
    { value: 305, status: '305' },
    { value: 335, status: '335' }
  ];
  return ret;
};

/**
 * 診断システム用のシステムの文言キーを取得する
 * @param {string} system システム
 * @returns システムの文言キー
 */
export const getResourceKeyForDiagSystem = (system) => 'diagSytem.' + system;

// DTCシステムテーブルの作成
export const computeAlldtcTable = (dtcList, root) => {
  // テーブル情報がない場合は続行しない
  if (!dtcList) return [];
  return dtcList.map(e => ({
    systemID: e.system,                                       // FFD 一覧取得のためのキー
    name: 'diagSytem.' + e.system,                            // システム名
    status: getDtcStatusResource(e.dtc_status),               // DTC 状態
    dtc: util.isNullOrDefault(e.code, '-'),                   // DTC コード
    description: getDtcDescriptionResource(e, root),          // 説明・故障部位
    igonCount: util.isNullOrDefault(e.igon_time, '-'),        // IGON 回数
    igonTime: util.isNullOrDefault(e.igon_mill_sec, '-'),     // IGON 経過時間
    counterType: getDtcTypeResource(e.type),             // 種別
    buttonVisible: e.has_ffd                                  // FFD 一覧取得のボタン表示・非表示
  }));
};

/**
 * ALL DTCシステムテーブルの作成
 * @param {*} dtcList DTC 情報
 * @param {*} root root
 * @param {*} cssCursorDefault カーソル表示CSS
 * @param {*} isSpecialDtc 特殊機能用のDTC読出しか否か
 * @param {*} isPermanentDtc 特殊機能用のPDTC読出しか否か
 * @returns 
 */
export const computeAllDTCTableItem = (dtcList, root, isDefaultCss, isSpecialDtc = false, isPermanentDtc = false) => {
  // テーブル情報がない場合は続行しない
  if (!dtcList) return [];
  return dtcList.map(e => ({
    systemID: e.system,                                       // FFD 一覧取得のためのキー
    name: 'diagSytem.' + e.system,                            // システム名
    // 特殊機能PDTCの場合は「過去」固定
    status: isPermanentDtc ? DIAG_STATUS_PAST : getDtcStatusResource(e.dtc_status), // DTC 状態
    dtc: util.isNullOrDefault(e.code, '-'),                   // DTC コード
    description: getDtcDescriptionResource(e, root),          // 説明・故障部位
    igonCount: util.isNullOrDefault(e.igon_time, '-'),        // IGON 回数
    igonTime: util.isNullOrDefault(e.igon_mill_sec, '-'),     // IGON 経過時間
    counterType: getDtcTypeResource(e.type),                  // 種別
    gps: isSpecialDtc ? util.isNullOrDefault(e.gps, '-') : undefined,              // GPS(※特殊機能DTCのみ)
    odometer: isSpecialDtc ? getOdometerResource(e.odometer) : undefined,    // オドメータ値(※特殊機能DTCのみ)
    buttonVisible: e.has_ffd,                                 // FFD 一覧取得のボタン表示・非表示
    is_chain_dtc: e.is_chain_dtc,                             // 連鎖DTC該非
    bgStyle: getDtcBgStyle(e.is_chain_dtc, isDefaultCss)      // 連鎖DTC該当の場合、背景色：グレー表示、カーソル表示判定
  }));
};

/**
 * ALL DTC 情報非表示を表すテーブルアイテムを作成する
 * @param {string} dtc DTC 情報
 * @param {string} cssCursorDefault カーソル表示CSS
 * @param {boolean} isSpecialDtc 特殊機能用のDTC読出しか否か
 * @returns テーブルアイテム
 */
export const createEmptyAllDtcTableItem = (dtc, isDefaultCss, isSpecialDtc = false) => {
  return {
    systemID: dtc.system,                                   // FFD 一覧取得のためのキー
    name: 'diagSytem.' + dtc.system,                        // システム名
    status: getDtcStatusResource(dtc.dtc_status),           // DTC 状態
    dtc: '-',                                               // DTC コード
    description: 'message.common_hyphen',                   // 説明・故障部位
    igonCount: '-',                                         // IGON 回数
    igonTime: '-',                                          // IGON 経過時間
    counterType: 'message.common_hyphen',                   // 種別
    gps: isSpecialDtc ? '-' : undefined,                    // GPS(※特殊機能DTCのみ)
    odometer: isSpecialDtc ? '-' : undefined,               // オドメータ値(※特殊機能DTCのみ)
    buttonVisible: false,                                   // FFD 一覧取得のボタン表示・非表示
    is_chain_dtc: false,                                    // 連鎖DTC該非
    bgStyle: getDtcBgStyle(false, isDefaultCss)                // 背景色：白色（デフォルト）表示、カーソル表示判定
  };
};

/**
 * ECU独自仕様 DTC 情報非表示を表すテーブルアイテムを作成する
 * @param {string} dtc DTC 情報
 * @param {string} cssCursorDefault カーソル表示CSS
 * @param {boolean} isSpecialDtc 特殊機能用のDTC読出しか否か
 * @returns テーブルアイテム
 */
const createEmptyUniqueDtcTableItem = (dtc, isDefaultCss, isSpecialDtc = false) => {
  return {
    systemID: dtc.system,                                   // FFD 一覧取得のためのキー
    name: 'diagSytem.' + dtc.system,                        // システム名
    status: getDtcStatusResource(dtc.dtc_status),           // DTC 状態
    dtc: '-',                                               // DTC コード
    description: 'message.common_hyphen',                   // 説明・故障部位
    igonCount: '-',                                         // IGON 回数
    igonTime: '-',                                          // IGON 経過時間
    counterType: 'message.common_hyphen',                   // 種別
    gps: isSpecialDtc ? '-' : undefined,                    // GPS(※特殊機能DTCのみ)
    odometer: isSpecialDtc ? '-' : undefined,               // オドメータ値(※特殊機能DTCのみ)
    buttonVisible: false,                                   // FFD 一覧取得のボタン表示・非表示
    is_chain_dtc: false,                                    // 連鎖DTC該非
    occurrenceDate: '-',                                    // 発生日時（ES独自仕様）
    bgStyle: getDtcBgStyle(false, isDefaultCss)             // 背景色：白色（デフォルト）表示、カーソル表示判定
  };
};

/**
 * 制御作動履歴システムテーブルの作成
 * @param {*} bmcList bmcList
 * @param {*} root root
 * @returns 
 */
export const computeBmcTable = (bmcList, root) => {
  // テーブル情報がない場合は続行しない
  if (!bmcList) return [];
  return bmcList.map(e => ({
    systemID: e.system,                                       // BMD 一覧取得のためのキー
    name: 'diagSytem.' + e.system,                            // システム名
    status: '',                                               // BMC 状態
    bmc: getBmcResource(e.code, e.protocol_type, '-'),        // BMC コード
    description: getBmcDescriptionResource(e, root),          // 説明・故障部位
    igonCount: util.isNullOrDefault(e.igon_time, '-'),        // IGON 回数
    igonTime: util.isNullOrDefault(e.igon_mill_sec, '-'),     // IGON 経過時間
    counterType: getDtcTypeResource(e.type),                  // 種別
    buttonVisible: e.has_bmd,                                 // BMD 一覧取得のボタン表示・非表示
    originalBmc: e.code ? e.code : null                       // 未変換のオリジナルBMC(bmd取得用param)
  }));
};

/**
 * 空の BMC を表すテーブルアイテムを作成する
 * @param {string} emptyStatus 表示ステータス
 * @returns テーブルアイテム
 */
export const createEmptyBmcTableItem = (emptyStatus) => {
  return {
    systemID: '',                                             // BMD 一覧取得のためのキー
    name: '',                                                 // システム名
    status: getBmcStatusResource(emptyStatus),                // BMC 状態（通信不能、コードなし）
    bmc: '-',                                                 // BMC コード
    description: 'message.common_hyphen',                     // 説明・故障部位
    igonCount: '-',                                           // IGON 回数
    igonTime: '-',                                            // IGON 経過時間
    counterType: 'message.common_hyphen',                     // 種別
    buttonVisible: false                                      // BMD 一覧取得のボタン表示・非表示
  };
};

/**
 * 制御作動履歴テーブルのBMCカラムの設定
 * @param {*} bmc bmc
 * @param {*} protocolType protocolType
 * @param {*} defaultValue defaultValue
 */
const getBmcResource = (bmc, protocolType, defaultValue = null) => {
  let result = bmc;
  // 空判定
  if (result === null || result === undefined) {
    return defaultValue;
  }
  // プロトコルが Phase5 の場合、先頭に"X"を付与
  if (protocolType === config.PROTOCOL_PHASE5) {
    result = 'X' + result;
  }
  return result;
};

/**
 * 制御作動履歴テーブルの説明・故障部位カラムの設定
 * @param {*} res res
 * @param {*} arg arg
 * @returns 
 */
const getBmcDescriptionResource = (res, arg) => {
  // 説明引き当て対象
  const baseId = `bmc${res.system}.${res.code}`;
  if (arg.$te(baseId)) {
    return baseId;
  } else {
    // 引き当て対象がない場合、"指定外コード"を表示
    return 'message.status_vehicle_dtc_description_unspecified';
  }
};

/**
 * BMCの状態を設定
 * @param {*} res res
 * @returns bmcStatus
 */
const getBmcStatusResource = (res) => {
  let bmcStatus = '';
  switch (res) {
    // 通信不能
    case config.BMC_STATUS_CANNOT_CONNECT:
      bmcStatus = DIAG_STATUS_CANNOT_CONNECT;
      break;
    //コードなし
    case config.BMC_STATUS_NO_BMC:
      bmcStatus = BMC_STATUS_NO_CODE;
      break;
  }
  return bmcStatus;
};

// DTCの状態のソート順番を設定
const getDtcStatusResource = (res) => {
  let dtcStatus = 'message.status_vehicle_dtc_status_' + res;
  switch (res) {
    // 現在
    case config.DTC_STATUS_CURRENT:
      dtcStatus = DIAG_STATUS_CURRENT; break;
    //最新結果
    case config.DTC_STATUS_LATEST:
      dtcStatus = DIAG_STATUS_LATEST; break;
    //確定/最新結果
    case config.DTC_STATUS_CONFIRM_LATEST:
      dtcStatus = DIAG_STATUS_CONFIRM_LATEST; break;
    //確定/仮/最新結果
    case config.DTC_STATUS_CONFIRM_TENTATIVE_LATEST:
      dtcStatus = DIAG_STATUS_CONFIRM_TENTATINE_LATEST; break;
    //仮/最新結果
    case config.DTC_STATUS_TENTATIVE_LATEST:
      dtcStatus = DIAG_STATUS_TENTATINE_LATEST; break;
    //過去
    case config.DTC_STATUS_PAST:
      dtcStatus = DIAG_STATUS_PAST; break;
    //確定
    case config.DTC_STATUS_CONFIRM:
      dtcStatus = DIAG_STATUS_CONFIRM; break;
    //確定/仮
    case config.DTC_STATUS_CONFIRM_TENTATIVE:
      dtcStatus = DIAG_STATUS_CONFIRM_TENTATIVE; break;
    //仮
    case config.DTC_STATUS_TENTATIVE:
      dtcStatus = DIAG_STATUS_TENTATIVE; break;
    //ダイアグコードなし
    case config.DTC_STATUS_NO_DTC:
      dtcStatus = DIAG_STATUS_NO_DTC; break;
    //通信不能
    case config.DTC_STATUS_CANNNOT_CONNECT:
      dtcStatus = DIAG_STATUS_CANNOT_CONNECT; break;
    //ダイアグコード取得非対応
    case config.DTC_STATUS_DTC_NOT_SUPPORT:
      dtcStatus = DIAG_STATUS_NOT_SUPPORT; break;
    //現在/過去
    case config.DTC_STATUS_CURRENT_PAST:
      dtcStatus = DIAG_STATUS_CURRENT_PAST; break;
    default: break;
  }
  return dtcStatus;
};

// 診断テーブルの説明・故障部位カラムの設定
const getDtcDescriptionResource = (res, arg) => {
  // 指定外コード
  if (res.unspecified_code) return 'message.status_vehicle_dtc_description_unspecified';
  // 不正な DTC 状態
  if (DIAG_INVALID_STATUS.includes(res.dtc_status)) return HYPHEN;
  // 説明引き当て対象
  const baseId = 'dtc.' + res.protocol_type + '_' + res.code;
  if (arg.$te(baseId)) return baseId;
  const referenceId = baseId + '_' + res.reference;
  return referenceId;
};

// 診断テーブルの種別カラムの設定
const getDtcTypeResource = (value) => {
  let counterType = 'message.common_hyphen';
  if (value !== "hyphen") {
    const result = util.isNullOrDefault(value);
    if (result) {
      counterType = 'dtc.count_types_' + util.addPadding(value, 2);
    }
  }
  return counterType;
};

/**
 * 診断テーブルのbackgroundのCSSの設定
 * @param {boolean} isChainDtc 
 * @param {boolean} isDefaultCss 
 * @returns 
 */
const getDtcBgStyle = (isChainDtc, isDefaultCss) => {
  // 連鎖DTCの判定
  const cssChainDtc = isChainDtc ? 'is-chain-dtc-back-color' : '';
  // カーソル表示の判定
  const cssCursor = isDefaultCss ? 'dtc-cursor-default' : '';
  // 生成したCSSを返す
  return `${cssChainDtc} ${cssCursor}`.trim();
};

/**
 * FFD/BMD/キャンセルコードFFD 一覧情報の値を設定
 * data_typeによる変換処理の分岐を追加するときは、individualWorkSupportUtil.jsのgetReadOutData()にも同じ分岐を実装すること
 * @param {Array} ffds ffd配列 
 * @param {string} system システムID
 * @param {object} i18nMap システムIDとi18n用のキー名を紐づける一覧
 * @param {object} root 呼び出し元のthisを設定
 * @param {boolean} isBmd BMDからの呼び出しか否か
 * @param {boolean} isCnc キャンセルコードのFFDからの呼び出しか否か
 * @returns {object} 変換結果
 */
/* eslint-disable max-statements */
/* eslint-disable max-depth */
export const getSaveOrderTable = (ffds, system, i18nMap, root, isBmd = false, isCnc = false) => {
  const param = { items: [], headers: [] };

  if (!ffds || ffds.length < 1) {
    return param;
  }
  // monitor_order を利用して、昇順にオブジェクトを並び替え
  const ffdMonitorOrder = util.sortItems(ffds, 'monitor_order', config.ASC);
  // detected_orderを利用して、上記のオブジェクトのffd_dataを更に並び替える

  // ffdとbmdでキー名が異なるので設定する
  const originData = isBmd ? 'bmd_data' : 'ffd_data';

  ffdMonitorOrder.forEach((item) => {
    item[originData] = util.sortItems(item[originData], 'detected_order', config.ASC);
  });

  // FFD一覧column情報
  const ffdColumList = [];
  // FFD一覧テーブル情報
  const ffdSystemTable = [];
  // HACK: Phase4Step2-2終了間際にESLintのルールが変更になり、ロジックは変更出来なかったため回避。
  /* eslint-disable max-statements */
  /* eslint-disable complexity */
  ffdMonitorOrder.forEach(e => {
    const ffdSystemObj = {};
    let sortNum = 0;
    // 項目・単位以外の表示を設定
    for (let index = 0; index < e[originData].length; index++) {
      let ffdValue = null;
      let ffdResourceID = "";
      const ffdData = e[originData][index];
      let originalValue = ffdData.value;
      // 取得したFFDが NULLの場合は「-」を表示する
      if (originalValue === null) {
        ffdResourceID = 'message.common_hyphen';
        ffdValue = root.$t(ffdResourceID);
      }
      else {
        // data_type = HEX なら翻訳可否をチェックし、不可ならデフォルト表示
        // 自由表記(data_type === 'HEX')の場合、APIからの応答とvalueの値を設定
        if (e.data_type === 'HEX') {
          const i18nKeyName = findFFDi18nKey(i18nMap, system, isCnc);
          const hexDisplay = i18nKeyName + '.' + e.id + '_';
          originalValue = util.addPadding(ffdData.value, 2);
          ffdResourceID = hexDisplay + originalValue;
          // 値からリソースIDを作成してリソースIDの存在チェックする。
          if (!root.$te(ffdResourceID)) {
            // 存在しない場合は 'default' を付与してリソースIDを作成する。 
            ffdResourceID = hexDisplay + 'default';
            // default 付与で再度確認
            // HACK: Phase4Step2-2終了間際にESLintのルールが変更になり、ロジックは変更出来なかったため回避。
            /* eslint-disable max-depth */
            if (!root.$te(ffdResourceID)) {
              // 存在しない場合はリソースIDを空で設定する。
              ffdResourceID = "";
            }
          }
          if (ffdResourceID) {
            ffdValue = root.$t(ffdResourceID);
          }
          else {
            // リソースIDが設定されていない場合はそのままの値を設定 
            ffdValue = originalValue;
          }
        }
        // data_type = Value または ACL2INC なら digits_after_decimal から少数点以下の桁数を調整
        if (e.data_type === 'Value' || e.data_type === 'ACL2INC') {
          // time_formatがtrueの場合変換し表示する
          if (e.time_format.exist) {
            const convertSec = util.convertToSec(ffdData.value, e.time_format.type);
            ffdValue = timeToFormat(convertSec, e.time_format.format);
          }
          else {
            ffdValue = ffdData.value;
            const decimal = e.digits_after_decimal;
            // HACK: Phase4Step2-2終了間際にESLintのルールが変更になり、ロジックは変更出来なかったため回避。
            /* eslint-disable max-depth */
            if (decimal !== null) {
              ffdValue = ffdData.value.toFixed(decimal);
            }
          }
        }
        // data_type = FLG なら 真偽値を設定
        if (e.data_type === 'FLG') {
          const i18nKeyName = findFFDi18nKey(i18nMap, system, isCnc);
          originalValue = util.addPadding(ffdData.value, 2);
          if (originalValue === 'FF' || originalValue === '00') {
            // 'FF' or '00' の場合、真偽値を変換
            ffdResourceID = i18nKeyName + '.' + e.id + '_' + originalValue;
            // '00'かつvalue_spec_bitが存在する(OBD対応がある)場合
            if (originalValue === '00' && ffdData.value_spec_bit) {
              // HACK: Phase4Step2-2終了間際にESLintのルールが変更になり、ロジックは変更出来なかったため回避。
              /* eslint-disable max-depth */
              switch (ffdData.value_spec_bit) {
                case FLG_TRUE:
                  ffdResourceID = ffdResourceID + '_supp_' + HEX_FF; break;
                case FLG_FALSE:
                  ffdResourceID = ffdResourceID + '_supp_' + HEX_00; break;
                default:
                  logWrapper.log('Unexpected FFD spec bit: ', e.id + ', ' + ffdData.value_spec_bit);
                  break;
              }
            }
            ffdValue = root.$t(ffdResourceID);
          } else {
            // 'FF' or '00' 以外の場合、取得値をそのまま表示
            ffdValue = originalValue;
          }
        }
        // data_type = YYYYMMDDhhmmss または PRG_YYYYMMDDhhmm なら 絶対時間を取得
        if (e.data_type === 'YYYYMMDDhhmmss' || e.data_type === 'PRG_YYYYMMDDhhmm') {
          // バックエンドから渡された値はGMTの文字列のため、Dateに変換
          const gmtDate = new Date(ffdData.value);
          // 日付として有効ならLocalTimeに変換
          // 日付として無効な文字列の場合Backendから帰ってきた文字列をそのまま表示する
          const convertType = e.data_type === 'PRG_YYYYMMDDhhmm' ? 'min' : null;
          ffdValue = ffdData.value && gmtDate.toString() !== 'Invalid Date' ? util.timeConverter(gmtDate, null, convertType) : ffdData.value;
        }
        // data_type = decExStrの場合、文字列への変換を行い、引き当てが存在しなかった場合はValueを算出する
        if (e.data_type === 'decExStr') {
          const i18nKeyName = findFFDi18nKey(i18nMap, system, isCnc);
          const hexDisplay = i18nKeyName + '.' + e.id + '_';
          originalValue = util.addPadding(ffdData.value, 2);
          ffdResourceID = hexDisplay + originalValue;
          // 値からリソースIDを作成して文字列変換を行う。
          if (root.$te(ffdResourceID)) {
            ffdValue = root.$t(ffdResourceID);
          } else {
            // 文字列に変換できなかった場合、数値に変換する
            ffdValue = util.convertHexOfDidToInt(e, originalValue);
          }
        }
        // 以下の data_type はそのまま表示
        if (e.data_type === 'ASCII' || e.data_type === 'LatLng_DMM' || e.data_type === 'YYYYMMDDhhmm' ||
            e.data_type === 'min2hhmm' || e.data_type === 'sec2hhmm' || e.data_type === 'sec2hhmmss' || e.data_type === 'decWithDot' ||
            e.data_type === 'YYMMDD' || e.data_type === 'Lat_DEG' || e.data_type === 'Lng_DEG' || e.data_type === 'MFD_BCD1' || e.data_type === 'MFD_BCD2'
        ) ffdValue = ffdData.value;
        // 未対応のdata_type(ffdValueが初期値(null)のままの場合)は、そのまま表示させる
        if (ffdValue === null) ffdValue = ffdData.value;
      }
      // 任意のKey名と値を設定
      const order = ffdData.detected_order;
      ffdSystemObj[order] = ffdValue;
      const resouceIdKey = "resouceId_" + order;
      ffdSystemObj[resouceIdKey] = ffdResourceID;
      const valueKey = "value_" + order;
      ffdSystemObj[valueKey] = originalValue;
      ffdColumList.push(order);
    }
    // 項目の設定
    const i18nKeyName = findFFDi18nKey(i18nMap, system, isCnc);
    ffdSystemObj.item = getItemName(e.id, i18nKeyName, root);
    // 単位の設定
    ffdSystemObj.unit = 'didUnit.' + e.unit;
    // データモニターのソート順をnumberで設定
    ffdSystemObj.monitorSort = sortNum++;
    ffdSystemTable.push(ffdSystemObj);
  });
  param.items = ffdSystemTable;
  param.headers = ffdColumList;
  return param;
};

/**
 * カスタマイズ 一覧情報の値を設定
 * @param cstms 取得したカスタマイズデータ
 * @param systemId システムId 
 * @param prevList カスタマイズ一覧の前回取得値
 * @param route 呼び出し元のオブジェクト(this)
 */
export const getCstmSaveOrderTable = (cstms, systemId, prevList, route) => {
  const param = [];
  if (!cstms || cstms.length < 1) {
    return param;
  }
  return cstms.map(e => ({
    name: route.$t(`cst${systemId}.${e.immutable_id}.menu_title`), // 名称
    immuTableId: e.immutable_id,  // 不変ID
    did: e.id,  // DID 項目
    // valueが存在しない場合、空文字列を設定する
    currentValue: convertCstmDisplayItem(e.id, e.setting.value, systemId, route), // 現在値
    pullDownVisible: e.setting.writable_range ? true : false,        // 設定値プルダウンの有無
    pullDownList: cstmSettingsPulldownList(e.id, e.setting.writable_range, systemId, route), // 設定値
    selectItem: cstmSelectItem(e, prevList, systemId, route),           // プルダウンの設定値
    mapKeyToValue: cstmSettingKeytoValueMap(e.id, e.setting.writable_range, systemId, route),  // KeyをValueに変換するためのMAP
    result: e.setting.writable_range ? cstmResult(e.immutable_id, e.customize_status, prevList) : 'message.common_title_error', // 結果
    showConnectedErrorDialog: e.customize_status === config.CSTM_STATUS_TYPE_ERROR, // 通信不能エラーダイアログ表示有無
    showFailedDialog: e.customize_status === config.CSTM_STATUS_TYPE_FAILURE, // エラーダイアログ表示有無                                                       
    description: route.$te(`cst${systemId}.${e.immutable_id}.menu_description`)
      ? route.$t(`cst${systemId}.${e.immutable_id}.menu_description`) : '-', // 説明
    byteValue: e.setting.byte_value,
  }));
};

/**
 * プルダウンリストを文言対応させて返却する
 * @param id DID
 * @param settingList プルダウンリスト 
 * @param systemId システムId 
 * @param route 呼び出し元のオブジェクト(this)
 */
const cstmSettingsPulldownList = (id, settingList, systemId, route) => {
  // プルダウンリストが存在しない場合、空配列を返す
  if (!settingList) {
    return [];
  }
  const writeList = settingList.map(e => convertCstmDisplayItem(id, e, systemId, route));
  return writeList;
};

/**
 * プルダウンの選択値を返却する
 * @param cstmData 取得したカスタマイズデータ
 * @param prevList カスタマイズ一覧の前回取得値
 * @param systemId システムId 
 * @param route 呼び出し元のオブジェクト(this)
 */
const cstmSelectItem = (cstmData, prevList, systemId, route) => {
  // カスタマイズステータスが成功か初期状態の場合、現在地を選択状態にする
  if (cstmData.customize_status === config.CSTM_STATUS_TYPE_SUCCESS || cstmData.customize_status === config.CSTM_STATUS_TYPE_NOT_AVAILABLE) {
    return convertCstmDisplayItem(cstmData.id, cstmData.setting.value, systemId, route);
  }

  // カスタマイズステータスが失敗か通信異常で、
  // 前回のデータから対応する不変IDがある場合、前回のプルダウンの選択値を引き継いで返却する
  const prevData = prevList.find(prev => prev.immuTableId === cstmData.immutable_id);
  if (prevData) {
    return prevData.selectItem;
  }

  // それ以外はプルダウンの先頭を選択値とする
  return convertCstmDisplayItem(cstmData.id, cstmData.setting.writable_range[0], systemId, route);
};

/**
 * valueに対応する文言に変換する
 * @param id DID
 * @param value 変換するvalue
 * @param systemId システムId 
 * @param route 呼び出し元のオブジェクト(this)
 */
export const convertCstmDisplayItem = (id, value, systemId, route) => {
  // valueが存在しない場合、空文字列を返す
  if (!value) {
    return '';
  }

  const isDefaultKey = route.$te(`cstDid${systemId}.${id}_default`);
  const customKey = `cstDid${systemId}.${id}_${value}`;
  // Valueと組み合わせたKeyが存在する場合、その文言を返却する
  if (route.$te(customKey)) {
    return route.$t(customKey);
    // Valueと組み合わせたKeyが存在せず、defaultKeyが存在する場合、その文言を返却する
  } else if (isDefaultKey) {
    return route.$t(`cstDid${systemId}.${id}_default`);
  } else {
    // どちらも存在しない場合、16進数を文字列化して返却する
    return util.hexToString(value);
  }
};

/**
 * 文言とvalue(16進数の設定値)のMapを作成する
 * @param id DID
 * @param settingList プルダウンリスト 
 * @param systemId システムId 
 * @param route 呼び出し元のオブジェクト(this)
 */
const cstmSettingKeytoValueMap = (id, settingList, systemId, route) => {
  const cstmMap = {};
  // プルダウンリストが存在しない場合、空を返す
  if (!settingList) {
    return cstmMap;
  }
  settingList.map(write_value => {
    cstmMap[convertCstmDisplayItem(id, write_value, systemId, route)] = write_value;
  });
  return cstmMap;
};

/**
 * 文言とvalue(16進数の設定値)のMapから、文言に対応するvalue(16進数の設定値)を取り出す
 * @param word 文言
 * @param cstmMap 文言とvalue(16進数の設定値)のMap
 * @return value(16進数の設定値)
 */
export const getCstmSettingValue = (word, mapKeyToValue) => {
  return mapKeyToValue[word];
};

/**
 * 結果文言を取得する
 * @param immuTableId 不変ID
 * @param statusId ステータスID 
 * @param prevList カスタマイズ一覧の前回取得値
 */
const cstmResult = (immuTableId, statusId, prevList) => {
  // customize結果表示リソース
  let status = '';
  switch (statusId) {
    case config.CSTM_STATUS_TYPE_SUCCESS:
      status = 'individual.status_customize_result_success';
      break;
    case config.CSTM_STATUS_TYPE_FAILURE:
      status = 'individual.status_customize_result_failure';
      break;
    case config.CSTM_STATUS_TYPE_ERROR:
      status = 'individual.status_customize_result_error';
      break;
    case config.CSTM_STATUS_TYPE_NOT_AVAILABLE: {
      // notAvailableの時、対象のデータが前回までに書込みを行っている場合は、
      // 以前のステータス表示したままにさせる為に、前回のステータスを返却する
      const prevData = prevList.find(prev => prev.immuTableId === immuTableId);
      status = (prevData && prevData.result) || 'message.common_hyphen';
      break;
    }
    default:
      status = 'message.common_hyphen';
      break;
  }
  return status;
};

const timeToFormat = (arg, type) => {
  let result;
  let hour = 0;
  let min = 0;

  switch (type) {
    case "hrs, min":
    case "hrs,min":
      if (!arg || arg === 0) {
        result = "{0}hrs,{1}min".format(hour, min);
        return result;
      }
      hour = Math.floor(arg / 3600);
      min = Math.floor(arg / 60) % 60;
      result = "{0}hrs,{1}min".format(hour, min);
  }
  return result;
};

// 項目表示の設定
const getItemName = (id, systemName, arg) => {
  let displayItem = systemName + '.' + id;
  // 項目表示のIDがresourceになかった場合特別対応で、固定値を設定。それでもresourceになかった場合ID表示
  if (!(arg.$te(displayItem))) {
    displayItem = displayItem + '_default';
  }
  return displayItem;
};

const findFFDi18nKey = (ffdi18nKeyMap, systemId, isCnc = false) => {
  // key が見つからない場合は画面表示で判断させるためそのまま表示する。
  // キャンセルコードから呼び出した場合、専用のprefixを設定する
  let result = (isCnc ? 'cncDid' : '') + systemId;
  const index = ffdi18nKeyMap.maps.findIndex(el => systemId === el.systemId);
  if (index !== -1) {
    result = (isCnc ? 'cncDid' : '') + ffdi18nKeyMap.maps[index].i18nKey;
  }
  return result;
};

/* eslint-disable max-statements*/
/* eslint-disable complexity */
// 診断画面のシステムステータス一覧を取得する
export const getVehicleSystemStatus = (tbl, suFlow) => {

  const result = { vehicleSystemList: [] };
  if (!tbl) return result;

  // 市場措置リプロの中で1件でもSWID承認が未設定のシステムが存在するか
  const isAnySwidUnsetted = tbl.some(item => item.reprogrammable_info.force.swid_consent === config.STATUS_CONSENT_UNSETTED);

  tbl.forEach((el) => {
    // リプロ可否判定
    const content = computeTableDisplay({
      isRprForce: el.reprogrammable_info.force.reprogrammable_status,
      isRprOpt: el.reprogrammable_info.optional.reprogrammable_status,
      isRprMerchant: el.reprogrammable_info.merchantability.reprogrammable_status,
      isRprResore: el.reprogrammable_info.restore.reprogrammable_status,
      isInstalled: el.installation_status,
      communicatable: el.communication_status,
      hasSwidForceUnset: suFlow && el.is_su ? isAnySwidUnsetted : false,
      swidConsent: {
        force: el.reprogrammable_info.force.swid_consent,
        optional: el.reprogrammable_info.optional.swid_consent,
        merchant: el.reprogrammable_info.merchantability.swid_consent,
        restore: el.reprogrammable_info.restore.swid_consent
      },
      outOfSu: !el.is_su
    }, suFlow);

    // US 法規対象車両の UI 設定
    if (suFlow) {
      content.bg = {
        swid: { force: null, optional: null, merchant: null, restore: null },
        hwid: null,
        // 法規適合または、車両は法規対象なのに ECU は法規対象外の場合は、背景色なしで初期化
        romid: (el.is_su && !el.su_conform_flag) ? { force: 'bk-danger', optional: 'bk-danger', merchant: 'bk-danger', restore: 'bk-danger' } : { force: '', optional: '', merchant: '', restore: '' },
        other: (el.is_su && !el.su_conform_flag) ? 'bk-danger' : ''
      };
      // SWID 承諾状況の設定
      const swidStateList = [
        { typeState: el.reprogrammable_info.force, reprotype: 'force', rprResource: 'rprForce' },
        { typeState: el.reprogrammable_info.optional, reprotype: 'optional', rprResource: 'rprOptional' },
        { typeState: el.reprogrammable_info.merchantability, reprotype: 'merchant', rprResource: 'rprMerchant' },
        { typeState: el.reprogrammable_info.restore, reprotype: 'restore', rprResource: 'rprRestore' }
      ];
      // リプロ有無、初期値として「‐」を設定
      content.rprForce = { order: 99, label: 'message.common_hyphen' };
      content.rprOptional = { order: 99, label: 'message.common_hyphen' };
      content.rprMerchant = { order: 99, label: 'message.common_hyphen' };
      content.rprRestore = { order: 99, label: 'message.common_hyphen' };
      // リプロファイルがある場合
      const reprogrammableList = swidStateList.filter(state => state.typeState.reprogrammable_status === config.REPRO_REPROGRAMMABLE);
      if (reprogrammableList.length > 0) {
        for (const e of reprogrammableList) {
          content[e.rprResource] = { order: 0, label: 'message.a_status_vehicle_system_reprogrammable' };
          // リプロファイル有りで、SWID 承認が「未設定」なら、リプロ可否の判別不可（ハイフン）
          // リプロファイル有りで、SWID 承認が「非承諾」なら、リプロ不可
          if (e.typeState.swid_consent === config.STATUS_UPDATE_REJECT) {
            content.exec[e.reprotype] = config.STATUS_RPR_DISABLED;
          }
          else if (e.typeState.swid_consent === config.STATUS_CONSENT_UNSETTED) {
            content.exec[e.reprotype] = '';
          }
          // 法規対象 ECU の場合、リプロ有で SWID 承諾が「非承諾」なら SWID セルの背景を黄
          // 法規対象 ECU の場合、リプロ有で SWID 承諾が「未設定」なら SWID セルの背景を緑
          // 法規対象 ECU の場合、リプロ有で SWID 承諾が「承諾」なら SWID セルの背景を青
          if (el.is_su && el.su_conform_flag) {
            if (e.typeState.swid_consent === config.STATUS_UPDATE_REJECT) {
              content.bg.swid[e.reprotype] = 'bk-rejected';
            } else if (e.typeState.swid_consent === config.STATUS_UPDATE_CONSENT) {
              content.bg.swid[e.reprotype] = 'bk-consented';
            } else {
              content.bg.swid[e.reprotype] = 'bk-unset';
            }
          }
        }
      }
      const notReprogrammableList = swidStateList.filter(state => state.typeState.reprogrammable_status === config.REPRO_NOT_REPROGRAMMABLE);
      // リプロファイルがない場合
      if (notReprogrammableList.length > 0) {
        for (const e of notReprogrammableList) {
          content[e.rprResource] = { order: 1, label: 'message.b_status_vehicle_system_not_reprogrammable' };
        }
      }

      // 法規対象ECUかつ法規不適合の場合、法規不適法表示する(赤表示)
      if (el.is_su && !el.su_conform_flag) {
        content.bg.swid.force = 'bk-danger';
        content.bg.swid.optional = 'bk-danger';
        content.bg.swid.merchant = 'bk-danger';
        content.bg.swid.restore = 'bk-danger';
      }

      // リプロ可否
      const REPRO_ENABLED = { order: 0, label: 'message.status_vehicle_system_reprogrammable' };
      const REPRO_DISABLED = { order: 1, label: 'message.status_vehicle_system_not_reprogrammable' };
      const REPRO_DEFAULT = { order: 99, label: 'message.common_hyphen' };
      content.exec.force = content.exec.force === config.STATUS_RPR_ENABLED ? REPRO_ENABLED : content.exec.force === config.STATUS_RPR_DISABLED ? REPRO_DISABLED : REPRO_DEFAULT;
      content.exec.optional = content.exec.optional === config.STATUS_RPR_ENABLED ? REPRO_ENABLED : content.exec.optional === config.STATUS_RPR_DISABLED ? REPRO_DISABLED : REPRO_DEFAULT;
      content.exec.merchant = content.exec.merchant === config.STATUS_RPR_ENABLED ? REPRO_ENABLED : content.exec.merchant === config.STATUS_RPR_DISABLED ? REPRO_DISABLED : REPRO_DEFAULT;
      content.exec.restore = content.exec.restore === config.STATUS_RPR_ENABLED ? REPRO_ENABLED : content.exec.restore === config.STATUS_RPR_DISABLED ? REPRO_DISABLED : REPRO_DEFAULT;

      // SWID 承諾
      if (!el.is_su) {
        // 法規対象外 ECUなら、SWID 承諾のセルにボタンを表示しない（ハイフン）
        content.swidConsent.force = getConsentHyphen(el.id);
        content.swidConsent.optional = getConsentHyphen(el.id);
        content.swidConsent.merchant = getConsentHyphen(el.id);
        content.swidConsent.restore = getConsentHyphen(el.id);
      }
      if (notReprogrammableList.length > 0) {
        // リプロ無しなら、SWID 承諾のセルにボタンを表示しない（ハイフン）
        for (const e of notReprogrammableList) {
          content.swidConsent[e.reprotype] = getConsentHyphen(el.id);
        }
      }
      if (reprogrammableList.length > 0) {
        for (const e of reprogrammableList) {
          if (e.typeState.swid_consent === config.STATUS_UPDATE_CONSENT) {
            content.swidConsent[e.reprotype] = { id: el.id, order: 0, label: 'message.status_vehicle_system_consent' };
          }
          else if (e.typeState.swid_consent === config.STATUS_UPDATE_REJECT) {
            content.swidConsent[e.reprotype] = { id: el.id, order: 1, label: 'message.status_vehicle_system_not_consent' };
          }
          else if (e.typeState.swid_consent === config.STATUS_CONSENT_UNSETTED) {
            content.swidConsent[e.reprotype] = { id: el.id, order: 2, label: 'message.status_vehicle_system_not_set_yet' };
          }
          else {
            content.swidConsent[e.reprotype] = getConsentHyphen(el.id);
          }
        }
      }
      else {
        content.swidConsent.force = getConsentHyphen(el.id);
        content.swidConsent.optional = getConsentHyphen(el.id);
        content.swidConsent.merchant = getConsentHyphen(el.id);
        content.swidConsent.restore = getConsentHyphen(el.id);
      }
      // HWID 承諾
      if (!el.is_su) {
        // 法規対象外 ECU なら、HWID 承諾のセルにボタンを表示しない（ハイフン）
        content.bg.hwid = null;
      } else {
        // 法規対象 ECU
        content.bg.hwid = el.su_conform_flag ? null : 'bk-danger';
      }
      // SU 法規対象車の SU 法規非対象ECU表示制御
      if (!el.is_su) {
        el.hwid = '';
        el.swid = el.is_ccu ? el.swid : '';
        el.target_romid = el.is_ccu ? '-' : el.target_romid;
        el.target_cid = el.is_ccu ? '-' : el.target_cid;
        el.target_swid = el.is_ccu ? el.target_swid : '';
        const execList = [
          { exe: content.exec.force, reprotype: 'force' }, { exe: content.exec.optional, reprotype: 'optional' }, { exe: content.exec.merchant, reprotype: 'merchant' }, { exe: content.exec.restore, reprotype: 'restore' }
        ];
        for (const e of execList) {
          if (e.exe.order === 0) {
            if (el.is_ccu) {
              content.bg.swid[e.reprotype] = 'bk-consented'; // CCU なら SWID 背景色を青
            } else {
              content.bg.romid[e.reprotype] = 'bk-consented';// 上記以外なら CID/ROMID 背景色を青
            }
          }
        }
      }
    }
    result.vehicleSystemList.push({
      id: el.id,                                // システムID
      targetId: computeTargetId(suFlow, el.target_romid, el.target_cid, el.target_swid),
      reproExecutable: content.exec,            // リプロ可否
      marketReproResource: content.rprForce,    // リプロ有無の表示用リソース（市場措置 - 強制）
      otherReproResource: content.rprOptional,  // リプロ有無の表示用リソース（その他 - 任意）
      updateReproResource: content.rprMerchant, // リプロ有無の表示用リソース（機能向上 - 商品性）
      restoreReproResource: content.rprRestore, // リプロ有無の表示用リソース（戻し - バージョン復元）
      installationResource: content.ins,        // 搭載・非搭載の表示用リソース
      communicableResource: content.com,        // 通信有・無の表示用リソース
      swid: suFlow ? !el.swid ? config.COMMON_HYPHEN_VALUE : el.swid : null,  // SWID
      hwid: suFlow ? !el.hwid ? config.COMMON_HYPHEN_VALUE : el.hwid : null,  // HWID
      forceSwidConsent: suFlow ? content.swidConsent.force : null,            // SWID 更新承諾（市場措置 - 強制）
      optionalSwidConsent: suFlow ? content.swidConsent.optional : null,      // SWID 更新承諾（その他 - 任意）
      updateSWidConsent: suFlow ? content.swidConsent.merchant : null,        // SWID 更新承諾（機能向上 - 商品性）
      restoreSwidConsent: suFlow ? content.swidConsent.restore : null,        // SWID 更新承諾（戻し - バージョン復元）
      // リプロ要・不要（市場措置 - 強制）
      marketReproInfo: {
        id: el.reprogrammable_info.force.pfc_file_id,
        isTwoStepChild: el.reprogrammable_info.force.is_two_step_pfc_child,
        nextMessage: content.msg.force,       // 次画面に表示するメッセージ
        nextPaneBg: content.nextBg.force,     // 次画面の背景色
      },
      // リプロ要・不要（その他 - 任意）
      otherReproInfo: {
        id: el.reprogrammable_info.optional.pfc_file_id,
        isTwoStepChild: el.reprogrammable_info.optional.is_two_step_pfc_child,
        nextMessage: content.msg.optional,
        nextPaneBg: content.nextBg.optional
      },
      // リプロ要・不要（機能向上 - 商品性）
      updateReproInfo: {
        id: el.reprogrammable_info.merchantability.pfc_file_id,
        isTwoStepChild: el.reprogrammable_info.merchantability.is_two_step_pfc_child,
        nextMessage: content.msg.merchant,
        nextPaneBg: content.nextBg.merchant
      },
      // リプロ要・不要（戻し - バージョン復元）
      restoreReproInfo: {
        id: el.reprogrammable_info.restore.pfc_file_id,
        isTwoStepChild: el.reprogrammable_info.restore.is_two_step_pfc_child,
        nextMessage: content.msg.restore,
        nextPaneBg: content.nextBg.restore
      },
      fieldSpec: content.spc,                               // Field specification
      bgStyle: content.bg,                                  // 背景色のスタイル
      isSuSystem: suFlow && el.is_su,                       // SU法規対象システムかどうか
      suConform: suFlow && el.is_su && el.su_conform_flag,  // 法規適合判定
      needInquiry: content.inq,                             // 再度 Inquiry が必要かどうか
      idResponseType: el.id_res_type,                       // CID/ROMID 取得要求の結果(ok, ng, no)
      showDetail: suFlow ? content.detail : false,          // 詳細ボタン表示・非表示
      isAnySwidUnsetted: suFlow && el.is_su ? isAnySwidUnsetted : false,  // SWID承認状態が未設定のシステムが存在するかどうか
      isRelatedRepro: el.related_repro_flag,                // 関連リプロ対象システムであるかの値
      isCcuSystem: el.is_ccu
    });
  });
  return result;
};

/**
 * ECU の状態に応じてテーブル表示内容と背景色を決定
 * @param ecu 搭載 / 通信 / リプロ有無 の組み合わせ
 * @param su SU 法規
 * @returns ECU の状態に応じたテーブル表示の設定
 */
const computeTableDisplay = (ecu, su) => {
  // 搭載 / 通信
  const bacisMaps = BASIC_STATE_MAP.filter(e => e.inst.status === ecu.isInstalled && e.comm.status === ecu.communicatable);
  const baseCode = bacisMaps.length > 0 ? bacisMaps[0].code : 8;

  // 搭載 / 通信セルの文言
  const getBasicStateResource = () => {
    if (bacisMaps.length > 0) {
      return {
        inst: su ? bacisMaps[0].inst.resource : bacisMaps[0].inst.resource.label,
        comm: su ? bacisMaps[0].comm.resource : bacisMaps[0].comm.resource.label
      };
    } else {
      return { inst: su ? CONFIG_ERROR : CONFIG_ERROR.label, comm: su ? CONFIG_ERROR : CONFIG_ERROR.label };
    }
  };

  // リプロ有無セルの文言
  const getReprogrammableResource = (arg) => {
    const maps = REPROGRAM_RESOURCE_MAP.filter(e => e.status === arg);
    if (maps.length > 0) {
      return su ? maps[0].resource : maps[0].resource.label;
    } else {
      return su ? { order: 99, label: HYPHEN } : HYPHEN;
    }
  };

  const ret = {
    ins: getBasicStateResource().inst,                                          // 搭載・非搭載
    com: getBasicStateResource().comm,                                          // 通信有無
    rprForce: getReprogrammableResource(ecu.isRprForce),                        // リプロ有無（強制）
    rprOptional: getReprogrammableResource(ecu.isRprOpt),                       // リプロ有無（任意）
    rprMerchant: getReprogrammableResource(ecu.isRprMerchant),                  // リプロ有無（商品性）
    rprRestore: getReprogrammableResource(ecu.isRprResore),                     // リプロ有無（戻し）
    exec: {                                                                     // リプロ実行可否
      force: su ? config.STATUS_RPR_DISABLED : false,
      optional: su ? config.STATUS_RPR_DISABLED : false,
      merchant: su ? config.STATUS_RPR_DISABLED : false,
      restore: su ? config.STATUS_RPR_DISABLED : false
    },
    spc: {},                                                                    // specificatioin for style in target unit table
    bg: '',
    inq: false,                                                                 // 再度 Inquiry が必要かどうか
    msg: { force: '', optional: '', merchant: '', restore: '' },                // 次画面で表示するメッセージ
    nextBg: { force: '', optional: '', merchant: '', restore: '' },             // 次画面の背景色
    detail: { force: false, optional: false, merchant: false, restore: false }, // 詳細ボタン表示・非表示
    swidConsent: { force: ecu.swidConsent.force, optional: ecu.swidConsent.optional, merchant: ecu.swidConsent.merchant, restore: ecu.swidConsent.restore },
  };

  // リプロ有無ステータスに応じた Field Spec の設定
  const FIELD_SPEC_MAP = [{
    conditions: [{ base: 0, rpr: 0 }, { base: 1, rpr: 0 }, { base: 2, rpr: 0 }],
    func: () => {
      ret.spc = {
        nameForDefaultTbl: [ecu.isRprForce, ecu.isRprOpt].includes(config.REPRO_REPROGRAMMABLE) ? config.LIST_ITEM_HIGHLIGHT : '',
        nameForUpdateTbl: ecu.isRprMerchant === config.REPRO_REPROGRAMMABLE ? config.LIST_ITEM_HIGHLIGHT : '',
        nameForRestoreTbl: ecu.isRprResore === config.REPRO_REPROGRAMMABLE ? config.LIST_ITEM_HIGHLIGHT : '',
        marketReproResource: ecu.isRprForce === config.REPRO_REPROGRAMMABLE ? config.LIST_ITEM_HIGHLIGHT : '',
        otherReproResource: ecu.isRprOpt === config.REPRO_REPROGRAMMABLE ? config.LIST_ITEM_HIGHLIGHT : '',
        updateReproResource: ecu.isRprMerchant === config.REPRO_REPROGRAMMABLE ? config.LIST_ITEM_HIGHLIGHT : '',
        restoreReproResource: ecu.isRprResore === config.REPRO_REPROGRAMMABLE ? config.LIST_ITEM_HIGHLIGHT : ''
      };
    }
  }];

  // テーブルレコードの背景色の設定
  const TBL_RECORD_BG_MAP = [{
    conditions: [
      { base: 0, rpr: 2 }, { base: 1, rpr: 0 }, { base: 1, rpr: 1 }, { base: 1, rpr: 2 }, { base: 2, rpr: 0 },
      { base: 2, rpr: 1 }, { base: 2, rpr: 2 }, { base: 3, rpr: 0 }, { base: 3, rpr: 1 }, { base: 3, rpr: 2 },
      { base: 6, rpr: 0 }, { base: 6, rpr: 1 }, { base: 6, rpr: 2 }
    ],
    func: () => { ret.bg = su ? ret.bg : LINE_BG_WARNING; }
  }, {
    conditions: [
      { base: 4, rpr: 0 }, { base: 4, rpr: 1 }, { base: 4, rpr: 2 }, { base: 5, rpr: 0 }, { base: 5, rpr: 1 },
      { base: 5, rpr: 2 }, { base: 5, rpr: 3 }, { base: 7, rpr: 0 }, { base: 7, rpr: 1 }, { base: 7, rpr: 2 },
      { base: 8, rpr: 0 }, { base: 8, rpr: 1 }, { base: 8, rpr: 2 }
    ],
    func: () => { ret.bg = su ? ret.bg : LINE_BG_DISABLE; }
  }];

  // 個別 Inquiry の設定（SU法規対象車では個別 Inquiry を実施しない）
  const SINGLE_IQUIRY_MAP = [{
    conditions: [
      { base: 1, rpr: 2 }, { base: 2, rpr: 2 }, { base: 3, rpr: 2 }, { base: 4, rpr: 2 },
      { base: 5, rpr: 2 }, { base: 6, rpr: 2 }, { base: 7, rpr: 2 }, { base: 8, rpr: 2 }
    ],
    func: () => { ret.inq = !su; }
  }];

  // 次画面の背景色の設定
  const NEXT_BG_MAP = [{
    conditions: [
      { base: 0, rpr: 2 }, { base: 1, rpr: 1 }, { base: 3, rpr: 1 }, { base: 4, rpr: 1 },
      { base: 5, rpr: 1 }, { base: 6, rpr: 1 }, { base: 7, rpr: 1 }, { base: 8, rpr: 0 },
      { base: 8, rpr: 1 }
    ],
    func: () => {
      ret.nextBg.force = PANE_BG_WARNING;
      ret.nextBg.optional = PANE_BG_WARNING;
      ret.nextBg.merchant = PANE_BG_WARNING;
      ret.nextBg.restore = PANE_BG_WARNING;
    }
  }, {
    conditions: [
      { base: 1, rpr: 2 }, { base: 2, rpr: 1 }, { base: 2, rpr: 2 }, { base: 3, rpr: 2 },
      { base: 4, rpr: 2 }, { base: 5, rpr: 2 }, { base: 6, rpr: 2 }, { base: 7, rpr: 2 },
      { base: 8, rpr: 2 }
    ],
    func: () => {
      ret.nextBg.force = PANE_BG_DISABLE;
      ret.nextBg.optional = PANE_BG_DISABLE;
      ret.nextBg.merchant = PANE_BG_DISABLE;
      ret.nextBg.restore = PANE_BG_DISABLE;
    }
  }, {
    conditions: [{ base: 2, rpr: 0 }],
    func: () => {
      ret.nextBg.force = ecu.isRprForce === config.REPRO_REPROGRAMMABLE ? PANE_BG_WARNING : PANE_BG_DISABLE;
      ret.nextBg.optional = ecu.isRprOpt === config.REPRO_REPROGRAMMABLE ? PANE_BG_WARNING : PANE_BG_DISABLE;
      ret.nextBg.merchant = ecu.isRprMerchant === config.REPRO_REPROGRAMMABLE ? PANE_BG_WARNING : PANE_BG_DISABLE;
      ret.nextBg.restore = ecu.isRprResore === config.REPRO_REPROGRAMMABLE ? PANE_BG_WARNING : PANE_BG_DISABLE;
    }
  }];

  // 次画面のメッセージの設定
  const NEXT_MSG_MAP = [{
    conditions: [{ base: 0, rpr: 1 }],
    func: () => {
      ret.msg.force = NO_REPRO_DATA;
      ret.msg.optional = NO_REPRO_DATA;
      ret.msg.merchant = NO_REPRO_DATA;
      ret.msg.restore = NO_REPRO_DATA;
    }
  }, {
    conditions: [{ base: 0, rpr: 2 }, { base: 1, rpr: 2 }],
    func: () => {
      ret.msg.force = CHECK_SYSTEMS;
      ret.msg.optional = CHECK_SYSTEMS;
      ret.msg.merchant = CHECK_SYSTEMS;
      ret.msg.restore = CHECK_SYSTEMS;
    }
  }, {
    conditions: [
      { base: 1, rpr: 1 }, { base: 2, rpr: 1 }, { base: 3, rpr: 1 }, { base: 4, rpr: 1 },
      { base: 5, rpr: 1 }, { base: 6, rpr: 1 }, { base: 7, rpr: 1 },
    ],
    func: () => {
      ret.msg.force = NO_DATA_CHECK_CGW;
      ret.msg.optional = NO_DATA_CHECK_CGW;
      ret.msg.merchant = NO_DATA_CHECK_CGW;
      ret.msg.restore = NO_DATA_CHECK_CGW;
    }
  }, {
    conditions: [{ base: 2, rpr: 2 }, { base: 3, rpr: 2 }, { base: 6, rpr: 2 }],
    func: () => {
      ret.msg.force = CHECK_CGW_AND_SYSTEM;
      ret.msg.optional = CHECK_CGW_AND_SYSTEM;
      ret.msg.merchant = CHECK_CGW_AND_SYSTEM;
      ret.msg.restore = CHECK_CGW_AND_SYSTEM;
    }
  }, {
    conditions: [{ base: 4, rpr: 2 }],
    func: () => {
      ret.msg.force = SYSTEM_NOT_EMBEDDED;
      ret.msg.optional = SYSTEM_NOT_EMBEDDED;
      ret.msg.merchant = SYSTEM_NOT_EMBEDDED;
      ret.msg.restore = SYSTEM_NOT_EMBEDDED;
    }
  }, {
    conditions: [{ base: 5, rpr: 2 }, { base: 7, rpr: 2 }],
    func: () => {
      ret.msg.force = CHECK_CGW_NO_SYSTEM;
      ret.msg.optional = CHECK_CGW_NO_SYSTEM;
      ret.msg.merchant = CHECK_CGW_NO_SYSTEM;
      ret.msg.restore = CHECK_CGW_NO_SYSTEM;
    }
  }, {
    conditions: [{ base: 8, rpr: 0 }, { base: 8, rpr: 1 }, { base: 8, rpr: 2 }],
    func: () => {
      ret.msg.force = su ? (ecu.isRprForce === config.REPRO_REPROGRAMMABLE ? ret.msg.force : NO_REPRO_DATA) : CGW_DISCONNECT_NOT_EMBEDDED;
      ret.msg.optional = su ? (ecu.isRprOpt === config.REPRO_REPROGRAMMABLE ? ret.msg.optional : NO_REPRO_DATA) : CGW_DISCONNECT_NOT_EMBEDDED;
      ret.msg.merchant = su ? (ecu.isRprMerchant === config.REPRO_REPROGRAMMABLE ? ret.msg.merchant : NO_REPRO_DATA) : CGW_DISCONNECT_NOT_EMBEDDED;
      ret.msg.restore = su ? (ecu.isRprResore === config.REPRO_REPROGRAMMABLE ? ret.msg.restore : NO_REPRO_DATA) : CGW_DISCONNECT_NOT_EMBEDDED;
    }
  }, {
    conditions: [
      { base: 0, rpr: 0 }, { base: 1, rpr: 0 }, { base: 2, rpr: 0 }, { base: 3, rpr: 0 },
      { base: 4, rpr: 0 }, { base: 5, rpr: 0 }, { base: 6, rpr: 0 }, { base: 7, rpr: 0 }
    ],
    func: () => {
      ret.msg.force = ecu.isRprForce === config.REPRO_REPROGRAMMABLE ? ret.msg.force : NO_REPRO_DATA;
      ret.msg.optional = ecu.isRprOpt === config.REPRO_REPROGRAMMABLE ? ret.msg.optional : NO_REPRO_DATA;
      ret.msg.merchant = ecu.isRprMerchant === config.REPRO_REPROGRAMMABLE ? ret.msg.merchant : NO_REPRO_DATA;
      ret.msg.restore = ecu.isRprResore === config.REPRO_REPROGRAMMABLE ? ret.msg.restore : NO_REPRO_DATA;
    }
  }];

  // リプロ可否の設定
  const ENABLE_REPRO_MAP = [{
    conditions: [
      { base: 0, rpr: 1 }, { base: 1, rpr: 1 }, { base: 2, rpr: 1 }, { base: 4, rpr: 1 },
      { base: 5, rpr: 1 }, { base: 8, rpr: 1 }
    ],
    func: () => {
      ret.exec.force = su ? null : ret.exec.force;
      ret.exec.optional = su ? null : ret.exec.optional;
      ret.exec.merchant = su ? null : ret.exec.merchant;
      ret.exec.restore = su ? null : ret.exec.restore;
    }
  }, {
    conditions: [
      { base: 0, rpr: 0 }, { base: 1, rpr: 0 }, { base: 2, rpr: 0 }, { base: 3, rpr: 0 },
      { base: 4, rpr: 0 }, { base: 5, rpr: 0 }, { base: 6, rpr: 0 }, { base: 7, rpr: 0 }
    ],
    func: () => {
      ret.exec.force = ecu.isRprForce === config.REPRO_REPROGRAMMABLE ? (su ? config.STATUS_RPR_ENABLED : true) : (su ? null : ret.exec.force);
      ret.exec.optional = ecu.isRprOpt === config.REPRO_REPROGRAMMABLE ? (su ? config.STATUS_RPR_ENABLED : true) : (su ? null : ret.exec.optional);
      ret.exec.merchant = ecu.isRprMerchant === config.REPRO_REPROGRAMMABLE ? (su ? config.STATUS_RPR_ENABLED : true) : (su ? null : ret.exec.merchant);
      ret.exec.restore = ecu.isRprResore === config.REPRO_REPROGRAMMABLE ? (su ? config.STATUS_RPR_ENABLED : true) : (su ? null : ret.exec.restore);
    }
  }, {
    conditions: [
      { base: 8, rpr: 0 }
    ],
    func: () => {
      ret.exec.force = ecu.isRprForce === config.REPRO_REPROGRAMMABLE ? (su ? config.STATUS_RPR_ENABLED : false) : (su ? null : ret.exec.force);
      ret.exec.optional = ecu.isRprOpt === config.REPRO_REPROGRAMMABLE ? (su ? config.STATUS_RPR_ENABLED : false) : (su ? null : ret.exec.optional);
      ret.exec.merchant = ecu.isRprMerchant === config.REPRO_REPROGRAMMABLE ? (su ? config.STATUS_RPR_ENABLED : false) : (su ? null : ret.exec.merchant);
      ret.exec.restore = ecu.isRprResore === config.REPRO_REPROGRAMMABLE ? (su ? config.STATUS_RPR_ENABLED : false) : (su ? null : ret.exec.restore);
    }
  }];

  // 詳細ボタン表示の設定
  const DETAIL_BTN_AVAILABLE_MAP = [{
    conditions: [
      { base: 0, rpr: 0 }, { base: 1, rpr: 0 }, { base: 2, rpr: 0 }, { base: 3, rpr: 0 }, { base: 4, rpr: 0 },
      { base: 5, rpr: 0 }, { base: 6, rpr: 0 }, { base: 7, rpr: 0 }, { base: 8, rpr: 0 }
    ],
    func: () => {
      ret.detail.force = su ? ecu.isRprForce === config.REPRO_REPROGRAMMABLE : true;
      ret.detail.optional = su ? ecu.isRprOpt === config.REPRO_REPROGRAMMABLE : true;
      ret.detail.merchant = su ? ecu.isRprMerchant === config.REPRO_REPROGRAMMABLE : true;
      ret.detail.restore = su ? ecu.isRprResore === config.REPRO_REPROGRAMMABLE : true;
    }
  }, {
    conditions: [
      { base: 0, rpr: 1 }, { base: 0, rpr: 2 }, { base: 1, rpr: 1 }, { base: 2, rpr: 1 },
      { base: 3, rpr: 1 }, { base: 3, rpr: 2 }, { base: 4, rpr: 1 }, { base: 4, rpr: 2 },
      { base: 5, rpr: 1 }, { base: 5, rpr: 2 }, { base: 6, rpr: 1 }, { base: 6, rpr: 2 },
      { base: 7, rpr: 1 }, { base: 7, rpr: 2 }, { base: 8, rpr: 1 }, { base: 8, rpr: 2 }
    ],
    func: () => {
      ret.detail.force = !su;
      ret.detail.optional = !su;
      ret.detail.merchant = !su;
      ret.detail.restore = !su;
    }
  }, {
    conditions: [{ base: 1, rpr: 2 }, { base: 2, rpr: 2 }],
    func: () => {
      // SU法規対象車のSU非対象ECUは、リプロ有のみ詳細ボタンを表示
      ret.detail.force = !(su && ecu.outOfSu);
      ret.detail.optional = !(su && ecu.outOfSu);
      ret.detail.merchant = !(su && ecu.outOfSu);
      ret.detail.restore = !(su && ecu.outOfSu);
    }
  }];
  const swidConsentList = [
    { swidConsent: ecu.swidConsent.force, isRepro: ecu.isRprForce, reprotype: 'force' },
    { swidConsent: ecu.swidConsent.optional, isRepro: ecu.isRprOpt, reprotype: 'optional' },
    { swidConsent: ecu.swidConsent.merchant, isRepro: ecu.isRprMerchant, reprotype: 'merchant' },
    { swidConsent: ecu.swidConsent.restore, isRepro: ecu.isRprResore, reprotype: 'restore' },
  ];
  // 許諾ステータスに応じた設定
  const PROPERTIES_BY_CONSENT = [{
    conditions: [{ base: 0, rpr: 0 }],
    func: () => {
      for (const e of swidConsentList) {
        if (e.swidConsent === config.STATUS_CONSENT_UNSETTED || e.swidConsent === config.STATUS_UPDATE_REJECT) {
          ret.msg[e.reprotype] = e.isRepro === config.REPRO_REPROGRAMMABLE ? SWID_NOT_CONSENTED : NO_REPRO_DATA;
          ret.nextBg[e.reprotype] = PANE_BG_WARNING;
        }
        else {
          // 市場措置の場合のみ、他 ECU に未設定があれば警告メッセージを設定
          if (e.reprotype === 'force') {
            ret.msg[e.reprotype] = e.isRepro === config.REPRO_REPROGRAMMABLE ? (ecu.hasSwidForceUnset ? OTHER_SWID_UNSETTED : ret.msg[e.reprotype]) : NO_REPRO_DATA;
            if (ecu.hasSwidForceUnset) {
              ret.nextBg[e.reprotype] = PANE_BG_WARNING;
            }
          }
          // その他・機能向上・復元は、他 ECU に未設定があってもリプロ実行が可能
          else {
            ret.msg[e.reprotype] = e.isRepro === config.REPRO_REPROGRAMMABLE ? ret.msg[e.reprotype] : NO_REPRO_DATA;
          }
        }
      }
    }
  }, {
    conditions: [
      { base: 1, rpr: 0 }, { base: 2, rpr: 0 }, { base: 3, rpr: 0 }, { base: 4, rpr: 0 },
      { base: 5, rpr: 0 }, { base: 6, rpr: 0 }, { base: 7, rpr: 0 }, { base: 8, rpr: 0 }
    ],
    func: () => {
      ret.nextBg.force = baseCode === 2 ? ret.nextBg.force : PANE_BG_WARNING;
      ret.nextBg.optional = baseCode === 2 ? ret.nextBg.optional : PANE_BG_WARNING;
      ret.nextBg.merchant = baseCode === 2 ? ret.nextBg.merchant : PANE_BG_WARNING;
      ret.nextBg.restore = baseCode === 2 ? ret.nextBg.restore : PANE_BG_WARNING;
      for (const e of swidConsentList) {
        if (e.swidConsent === config.STATUS_CONSENT_UNSETTED || e.swidConsent === config.STATUS_UPDATE_REJECT) {
          ret.msg[e.reprotype] = e.isRepro === config.REPRO_REPROGRAMMABLE ? SWID_NOT_CONSENTED_AND_CHECK_CGW : NO_REPRO_DATA;
        }
        else {
          // 市場措置だった場合、未設定フラグの確認
          // 他ECUにSWIDの「未設定」が存在していた場合リプロは不可にする文言を設定
          if (e.reprotype === 'force') {
            ret.msg[e.reprotype] = e.isRepro === config.REPRO_REPROGRAMMABLE ? (ecu.hasSwidForceUnset ? OTHER_SWID_UNSETTED_AND_CHECK_CGW : ret.msg[e.reprotype]) : NO_REPRO_DATA;
          }
          // その他・商品性・戻しは他ECUのSWID許諾が「未設定」でも依存しない（リプロが有りの場合リプロ可、リプロなしの場合リプロ不可の文言を設定）
          else {
            ret.msg[e.reprotype] = e.isRepro === config.REPRO_REPROGRAMMABLE ? ret.msg[e.reprotype] : NO_REPRO_DATA;
          }
          if (!su) {
            ret.nextBg[e.reprotype] = (e.isRepro === config.REPRO_REPROGRAMMABLE && baseCode !== 8) ? '' : ret.nextBg[e.reprotype];
          }
          else {
            ret.nextBg[e.reprotype] = (e.reprotype === 'force' && ecu.hasSwidForceUnset) ? ret.nextBg[e.reprotype] : '';  // ''は正常系と判断
          }
        }
      }
    }
  }];

  const stateList = [ecu.isRprForce, ecu.isRprOpt, ecu.isRprMerchant, ecu.isRprResore];
  const rprMaps = REPROGRAM_STATE_MAP.filter(e => {
    const tmpStates = [e.force.status, e.opt.status, e.merchant.status, e.restore.status];
    for (let i = 0; i < stateList.length; i++) {
      if (tmpStates[i] !== stateList[i]) return false;
    }
    return true;
  });

  const doFunc = (code) => {
    const COMPUTE_FUN_MAPS = [
      SINGLE_IQUIRY_MAP, FIELD_SPEC_MAP, TBL_RECORD_BG_MAP, NEXT_BG_MAP, NEXT_MSG_MAP,
      ENABLE_REPRO_MAP, DETAIL_BTN_AVAILABLE_MAP, PROPERTIES_BY_CONSENT
    ];
    for (const m of COMPUTE_FUN_MAPS) {
      const funcs = m.filter(p => p.conditions.some(c => c.base === baseCode && c.rpr === code));
      if (funcs.length > 0) funcs[0].func();
    }
  };

  if (rprMaps.length > 0) {
    // 各条件ごとの画面構成用演算
    doFunc(rprMaps[0].code);
  } else {
    // 予期しない Force, Optional, Merchant, Restore のステータス組み合わせ
    if (stateList.includes(config.REPRO_REPROGRAMMABLE_ERROR)) {
      // 1 つでも error があれば Force, Optional, Merchant, Restore すべてエラーとして表示
      doFunc(2);
    } else {
      // エラーなしなら、すべてを not_reprogrammable として表示
      doFunc(1);
    }
  }
  return ret;
};

// Vehicle 画面に表示する ID を設定
const computeTargetId = (suFlow, romid, cid, swid) => {
  // SU 法規対象車：CID/ROMID と SWID は別のカラム
  // SU法規非対象車：CID/ROMID/SWID は同じカラム
  const tmp = suFlow ? (romid || cid) : (romid || cid || swid);
  return tmp || config.COMMON_HYPHEN_VALUE;
};

const getConsentHyphen = (arg) => {
  const ret = CONSENT_HYPHEN;
  ret.id = arg;
  return ret;
};

// Prepare converted display names from supported language list
export const getConvertedDisplayNames = (languageList) => {
  const displayNamesList = [];
  for (let index = 0; index < languageList.length; ++index) {
    const language = languageList[index];
    displayNamesList.push({ value: language.value, label: `message.label_master_data_display_name_${language.value}` });
  }
  return displayNamesList;
};

export const ssoLanguageDisplay = (ssoLanguageInformation, ssoTypeInformation) => {
  // SUBARUユーザーの場合下記を通る
  if (ssoTypeInformation === config.SBR_DOMAIN) {
    return null;
  }
  // SSO連携ユーザーの場合下記を通る
  if (ssoTypeInformation !== config.SOA_DOMAIN && ssoLanguageInformation === "fr") {
    const ssoSwitchingLanguage = {
      ssoDisplayLanguage: 'Compte d’affiliation',
      ssoHeaderTitle: 'Connexion avec votre compte d’affiliation ou votre compte SSM5'
    };
    return ssoSwitchingLanguage;
  }
  else {
    const ssoSwitchingLanguage = {
      ssoDisplayLanguage: 'Affiliation account',
      ssoHeaderTitle: 'Sign in with your affiliation account or SSM5 account'
    };
    return ssoSwitchingLanguage;
  }
};

// テーブル内のアイテムの表示を以下のように変更する
// リプログラミングファイル有無：「リプロ無し」または「-」
// 部品交換許諾：「-」
// SWID許諾：「-」
// 詳細ボタン表示：「-」
// 背景色：法規適合のシステムは白色
export const setFakeTableDisplay = (tbl) => {
  const ret = JSON.parse(JSON.stringify(tbl));
  ret.forEach((el) => {
    if (el.isSuSystem) {
      el.marketReproInfo.id = null;
      el.otherReproInfo.id = null;
      el.updateReproInfo.id = null;
      el.restoreReproInfo.id = null;

      el.marketReproResource = el.marketReproResource.order === 0 ? { order: 1, label: 'message.b_status_vehicle_system_not_reprogrammable' } : el.marketReproResource;
      el.otherReproResource = el.otherReproResource.order === 0 ? { order: 1, label: 'message.b_status_vehicle_system_not_reprogrammable' } : el.otherReproResource;
      el.updateReproResource = el.updateReproResource.order === 0 ? { order: 1, label: 'message.b_status_vehicle_system_not_reprogrammable' } : el.updateReproResource;
      el.restoreReproResource = el.restoreReproResource.order === 0 ? { order: 1, label: 'message.b_status_vehicle_system_not_reprogrammable' } : el.restoreReproResource;

      el.forceSwidConsent = getConsentHyphen(el.id);
      el.optionalSwidConsent = getConsentHyphen(el.id);
      el.updateSWidConsent = getConsentHyphen(el.id);
      el.restoreSwidConsent = getConsentHyphen(el.id);

      el.reproExecutable.force = { order: 99, label: 'message.common_hyphen' };
      el.reproExecutable.optional = { order: 99, label: 'message.common_hyphen' };
      el.reproExecutable.merchant = { order: 99, label: 'message.common_hyphen' };
      el.reproExecutable.restore = { order: 99, label: 'message.common_hyphen' };

      el.showDetail.force = false;
      el.showDetail.optional = false;
      el.showDetail.merchant = false;
      el.showDetail.restore = false;

      el.marketReproResource = el.marketReproResource === config.SYSTEM_STATUS_REPROGRAMMABLE ? config.SYSTEM_STATUS_NOT_REPROGRAMMABLE : el.marketReproResource;
      el.otherReproResource = el.otherReproResource === config.SYSTEM_STATUS_REPROGRAMMABLE ? config.SYSTEM_STATUS_NOT_REPROGRAMMABLE : el.otherReproResource;
      el.updateReproResource = el.updateReproResource === config.SYSTEM_STATUS_REPROGRAMMABLE ? config.SYSTEM_STATUS_NOT_REPROGRAMMABLE : el.updateReproResource;
      el.restoreReproResource = el.restoreReproResource === config.SYSTEM_STATUS_REPROGRAMMABLE ? config.SYSTEM_STATUS_NOT_REPROGRAMMABLE : el.restoreReproResource;

      if (el.suConform) {
        el.fieldSpec = "";
        el.bgStyle.swid.force = "";
        el.bgStyle.swid.optional = "";
        el.bgStyle.swid.merchant = "";
        el.bgStyle.swid.restore = "";
        el.bgStyle.romid.force = "";
        el.bgStyle.romid.optional = "";
        el.bgStyle.romid.merchant = "";
        el.bgStyle.romid.restore = "";
      }
    }
  });
  return ret;
};

// RxSWIN 読込みで発生するエラーに応じた表示文言を引き当て
export const getRxswinErrorMessageSet = (err) => {
  const arr = [
    { code: '4020', resource: 'message.message_home_failed_to_read_rxswin_before_repro', hasTail: true },
    { code: '4021', resource: 'message.message_home_failed_to_write_rxswin', hasTail: true },
    { code: '4022', resource: 'message.message_home_failed_to_read_rxswin_after_repro', hasTail: true },
    { code: '4300', resource: 'message.message_home_no_enough_voltage_with_exit' },
    { code: '4301', resource: 'message.message_home_no_enough_voltage_without_exit' }
  ];
  const ret = { lead: '', tail: '' };
  const tmp = arr.filter(e => e.code === err);
  const target = tmp.length > 0 ? tmp[0] : {};
  ret.lead = target.resource || '';
  // RxSWIN 関連のエラーのみ対応手順を表示
  ret.tail = target.resource && target.hasTail ? 'message.message_home_operation_rxswin_process_failure' : '';
  return ret;
};

/**
 * 一覧に表示する為のDTCTableを作成する
 * @param {array} dtcSystems DTC情報List
 * @param {string} systemId システム名
 * @param {object} route 呼び出し元のオブジェクト(this)
 * @param {boolean} isDefaultCss 一覧のカーソル表示判定
 * @param {boolean} isSpecialDtc 特殊機能用のDTC読出しか否か
 * @param {boolean} isPermanentDtc 特殊機能用のPDTC読出しか否か
 * @returns DTCのテーブルアイテム
 */
export const createDtcTable = (dtcSystems, systemId, route, isDefaultCss = false, isSpecialDtc = false, isPermanentDtc = false) => {

  // DTC が 0 個の場合も DTC 無しと表示する
  if (dtcSystems.length <= 0) {
    const dtcInfo = {
      system: systemId,
      dtc_status: config.DTC_STATUS_NO_DTC
    };
    // ES独自仕様対応
    const noDtcTableItem =
      util.isSystemES(systemId)
        ? createEmptyUniqueDtcTableItem(dtcInfo, isDefaultCss, isSpecialDtc)
        : createEmptyAllDtcTableItem(dtcInfo, isDefaultCss, isSpecialDtc);
    return [noDtcTableItem];
  }

  // DTCなし・通信不能・DTC取得非対応の情報が含まれる場合は、取り出す
  const emptySystems = dtcSystems.filter(dtc =>
    dtc.dtc_status === config.DTC_STATUS_NO_DTC ||
    dtc.dtc_status === config.DTC_STATUS_CANNNOT_CONNECT ||
    dtc.dtc_status === config.DTC_STATUS_DTC_NOT_SUPPORT
  );

  // DTCなし・通信不能・DTC取得非対応の情報が含まれていない情報も取り出しておく
  const notEmptySystems = dtcSystems.filter(dtc =>
    dtc.dtc_status !== config.DTC_STATUS_NO_DTC &&
    dtc.dtc_status !== config.DTC_STATUS_CANNNOT_CONNECT &&
    dtc.dtc_status !== config.DTC_STATUS_DTC_NOT_SUPPORT
  );

  let createTableItem = [];
  // DTCなし・通信不能・DTC取得非対応のシステムの場合は、コード以降を表示しない
  if (emptySystems.length > 0) {
    // ES独自仕様対応
    if (util.isSystemES(systemId)) {
      emptySystems.map((system) => {
        createTableItem.push(createEmptyUniqueDtcTableItem(system, isDefaultCss, isSpecialDtc));
      });
    } else {
      emptySystems.map((system) => {
        createTableItem.push(createEmptyAllDtcTableItem(system, isDefaultCss, isSpecialDtc));
      });
    }
  }

  // DTCなし・通信不能・DTC取得非対応以外の要素は通常のテーブルに変換する
  if (notEmptySystems.length > 0) {
    // ES独自仕様対応
    const tableItem =
      util.isSystemES(systemId)
        ? computeUniqueDTCTableItem(notEmptySystems, route, isDefaultCss, isSpecialDtc)
        : computeAllDTCTableItem(notEmptySystems, route, isDefaultCss, isSpecialDtc, isPermanentDtc);

    createTableItem = createTableItem.concat(tableItem);
  }

  return createTableItem;
};

/**
 *  一覧に表示する為のBMCTableを作成する
 * @param {array} bmcSystems BMC情報List
 * @param {object} route 呼び出し元のオブジェクト(this)
 * @returns BMCのテーブルアイテム
 */
export const createBmcTable = (bmcSystems, route) => {
  // BMC が 0 個の場合 コードなしと表示する
  if (bmcSystems.length <= 0) {
    return createEmptyBmcTableItem(config.BMC_STATUS_NO_BMC);
  }
  // コードなし以外の場合は通常のテーブルに変換する
  return computeBmcTable(bmcSystems, route);
};

/**
 * DID(FFD/BMD)項目番号の表示文言変換
 * @param {object} route 呼び出し元のオブジェクト(this)
 * @param {number} headerNum 項目番号
 * return 変換文言
 */
export const changeDidHeaders = (route, headerNum) => {
  // 数値判定
  if (isNaN(headerNum)) return headerNum;
  // 表示変換
  switch (true) {
    case headerNum > 0:
      // {0}回後 を表示
      return route.$t('message.status_vehicle_did_order_later', [headerNum]);
    case headerNum < 0:
      // {0}回前 を表示
      return route.$t('message.status_vehicle_did_order_past', [Math.abs(headerNum)]);
    default:
      return headerNum;
  }
};

/**
 * キャンセルコードの取得結果テーブルを生成する
 *
 * @param {array} cancelCodeResultItems
 * @param {object} route 呼び出し元のオブジェクト(this)
 * @param {string} i18nCncKey
 * @returns CNCのテーブルアイテム
 *
 */
export const createCancelCodeResultTable = (cancelCodeResultItems, route, i18nKey) => {
  return cancelCodeResultItems
    .map(e => {
      const descriptionKey = route.$te(`${i18nKey}.${e.code}`) ?
        `${i18nKey}.${e.code}` :
        'message.status_vehicle_dtc_description_unspecified';

      return {
        no: e.no,
        status: getCNCStatus(e.status, route),
        code: util.isNullOrDefault(e.code, '-'),
        description: route.$t(descriptionKey),
        descriptionKey,
        igonCount: util.isNullOrDefault(e.igon_time, '-'),
        igonTime: util.isNullOrDefault(e.igon_mill_sec, '-'),
        counterType: getDtcTypeResource(e.type),
        buttonVisible: e.has_ffd
      };
    }).filter(e => e.code !== '00');
};

/**
 * 再始動キャンセルコード(TM)の取得結果テーブルを生成する
 *
 * @param {array} cancelCodeResultItems
 * @param {object} route 呼び出し元のオブジェクト(this)
 * @param {string} i18nCncKey
 * @returns CNCのテーブルアイテム
 *
 */
export const createCancelCodeResultTableTM = (cancelCodeResultItems, route, i18nKey) => {
  // 指定外コード群格納用配列
  const notSpecifiedCodes = [];

  // 指定外コードの判別
  cancelCodeResultItems.forEach((item) => {
    // コードに紐づくi18nKeyが取得できなかった場合、指定外コードとみなす
    if (!route.$te(`${i18nKey}.${item.code}`)) {
      if (notSpecifiedCodes.indexOf(item.code) === -1) {
        notSpecifiedCodes.push(item.code);
      }
    }
  });

  return cancelCodeResultItems
    .filter(e => !notSpecifiedCodes.includes(e.code))
    .map(e => {
      const descriptionKey = `${i18nKey}.${e.code}`;

      return {
        no: e.no,
        status: getCNCStatus(e.status, route),
        code: util.isNullOrDefault(e.code, '-'),
        description: route.$t(descriptionKey),
        descriptionKey,
        igonCount: util.isNullOrDefault(e.igon_time, '-'),
        igonTime: util.isNullOrDefault(e.igon_mill_sec, '-'),
        counterType: getDtcTypeResource(e.type),
        buttonVisible: e.has_ffd
      };
    });
};

/**
 * キャンセルコード(RAB)の取得結果テーブルを生成する
 *
 * @param {array} cancelCodeResultItems
 * @param {object} route 呼び出し元のオブジェクト(this)
 * @param {string} i18nCncKey
 * @returns CNCのテーブルアイテム
 *
 */
export const createCancelCodeResultTableRAB = (cancelCodeResultItems, route, i18nKey) => {
  return cancelCodeResultItems
    .map(e => {
      const descriptionKey = route.$te(`${i18nKey}.${e.code}`) ?
        `${i18nKey}.${e.code}` :
        'message.status_vehicle_dtc_description_unspecified';

      // バックエンドから渡されたabsolute_timeはGMTの文字列のため、Dateに変換
      const gmtDate = new Date(e.absolute_time);

      let absoluteTime = '';

      if (!e.absolute_time) {
        absoluteTime = null;
      } else if (gmtDate.toString() === 'Invalid Date') {
        // 日付として無効な文字列の場合Backendから帰ってきた文字列をそのまま表示する
        absoluteTime = e.absolute_time;
      } else {
        // LocalTimeに変換
        absoluteTime = util.timeConverter(gmtDate);
      }

      return {
        no: e.no,
        status: getCNCStatus(e.status, route),
        code: util.isNullOrDefault(e.code, '-'),
        description: route.$t(descriptionKey),
        descriptionKey,
        igonCount: util.isNullOrDefault(e.igon_time, '-'),
        igonTime: util.isNullOrDefault(e.igon_mill_sec, '-'),
        counterType: getDtcTypeResource(e.type),
        absoluteTime: util.isNullOrDefault(absoluteTime, '-'),
        buttonVisible: e.has_ffd
      };
    }).filter(e => e.code !== '00');
};

/**
  * キャンセルコードの取得結果テーブルを生成する(特殊機能)
  * @param {array} cancelCodeResultItems
  * @param {object} route 呼び出し元のオブジェクト(this)
  * @param {string} i18nCncKey
  * @returns DTCのテーブルアイテム
  *
  */
export const createSpCancelCodeResultTable = (spCancelCodeResultItems, route, i18nKey) => {
  return spCancelCodeResultItems
    .map(e => {
      const descriptionKey = route.$te(`${i18nKey}.${e.code}`) ?
        `${i18nKey}.${e.code}` :
        'message.status_vehicle_dtc_description_unspecified';

      return {
        no: e.no,
        status: '-',
        code: util.isNullOrDefault(e.code, '-'),
        description: route.$t(descriptionKey),
        descriptionKey,
        igonCount: util.isNullOrDefault(e.igon_time, '-'),
        lksStatus: util.isNullOrDefault(e.lks_status, '-'),
        lksMode: util.isNullOrDefault(e.lks_mode, '-'),
        occurrenceTime: util.isNullOrDefault(e.occurrence_time, '-'),
        gps: util.isNullOrDefault(e.gps, '-'),
        odometer: getOdometerResource(e.odo)
      };
    }).filter(e => e.code !== '00');
};

/**
 * キャンセルコード(ES)の取得結果テーブルを生成する
 *
 * @param {array} cancelCodeResultItems
 * @param {object} route 呼び出し元のオブジェクト(this)
 * @param {string} i18nCncKey
 * @returns DTCのテーブルアイテム
 *
 */
export const createCancelCodeResultTableES = (cancelCodeResultItems, route, i18nKey) => {
  return cancelCodeResultItems
    .map(e => {
      const descriptionKey = route.$te(`${i18nKey}.${e.code}`) ?
        `${i18nKey}.${e.code}` :
        'message.status_vehicle_dtc_description_unspecified';

      return {
        no: e.no,
        status: '-',
        code: util.isNullOrDefault(e.code, '-'),
        description: route.$t(descriptionKey),
        descriptionKey,
        igCounter: util.isNullOrDefault(e.igon_time, '-'),
        lksStatus: util.isNullOrDefault(e.lks_status, '-'),
        lksMode: util.isNullOrDefault(e.lks_mode, '-'),
        occurrenceTime: util.isNullOrDefault(e.occurrence_time, '-'),
        buttonVisible: e.has_ffd
      };
    }).filter(e => e.code !== '00');
};

/**
 * データ再生のキャンセルコード（ES）用テーブル生成
 *
 * @param {array} cancelCodeResultItems
 * @param {object} route 呼び出し元のオブジェクト(this)
 * @param {string} i18nCncKey
 * @returns CNCのテーブルアイテム
 *
 */
export const createCancelCodeResultTableDataRecES = (cancelCodeResultItems, route, i18nKey) => {
  return cancelCodeResultItems
    .map(e => {
      const descriptionKey = route.$te(`${i18nKey}.${e.code}`) ?
        `${i18nKey}.${e.code}` :
        'message.status_vehicle_dtc_description_unspecified';

      return {
        no: e.no,
        status: '-',
        code: util.isNullOrDefault(e.code, '-'),
        description: route.$t(descriptionKey),
        descriptionKey,
        igonCount: util.isNullOrDefault(e.igon_time, '-'),
        igonTime: util.isNullOrDefault(e.igon_mill_sec, '-'),
        counterType: getDtcTypeResource(e.type),
        buttonVisible: e.has_ffd
      };
    }).filter(e => e.code !== '00');
};

/**
 * キャンセルコードの呼び出し元による分岐
 * 
 * @param {string} i18nCncKey
 * @param {string|Number} status
 */
const getCNCStatus = (status, route) => {
  const dispstatus = getDtcStatusResource(status);

  // RCR分岐
  if (route.$te(dispstatus)) {
    return route.$t(dispstatus);
  }

  // 共通分岐
  return (status === 0) ?
    route.$t('individual.status_vehicle_bmd_order_latest') :
    route.$t('message.status_vehicle_did_order_past', [status]);
};

/**
 * オドメータ値のフォーマット
 * オドメータ値が0の場合'-'ではなく0.0で表示する際に使用
 * @param {number} オドメータ値
 * @returns フォーマット結果
 */
const getOdometerResource = (value) => {
  if (value === null || value === undefined || value === '') {
    return '-';
  }
  return value;
};

/**
 * 空の CNC を表すテーブルアイテムを作成する
 * @param {string} root
 * @returns テーブルアイテム
 */
export const createEmptyCancelCodeResultTable = (root) => {
  return {
    no: '',                                                           // CNC 一覧取得のためのキー
    status: root.$t(DIAG_STATUS_CANNOT_CONNECT),                      // CNC 状態（通信不能）
    code: '-',                                                        // 状態コード 
    description: '-',                                                 // 説明・故障部位
    descriptionKey: 'message.common_hyphen',                          // 説明・故障部位
    igonCount: '-',                                                   // IGON 回数
    igonTime: '-',                                                    // IGON 経過時間
    counterType: 'message.common_hyphen',                             // 種別
    buttonVisible: false                                              // CNC 一覧取得のボタン表示・非表示
  };
};

/**
 * 空の CNC を表すテーブルアイテムを作成する
 * @param {string} root
 * @returns テーブルアイテム
 */
export const createEmptyCancelCodeResultTableRAB = (root) => {
  return {
    no: '',                                                           // CNC 一覧取得のためのキー
    status: root.$t(DIAG_STATUS_CANNOT_CONNECT),                      // CNC 状態（通信不能）
    code: '-',                                                        // 状態コード 
    description: '-',                                                 // 説明・故障部位
    descriptionKey: 'message.common_hyphen',                          // 説明・故障部位
    igonCount: '-',                                                   // IGON 回数
    igonTime: '-',                                                    // IGON 経過時間
    counterType: 'message.common_hyphen',                             // 種別
    absoluteTime: '-',                                                // 絶対時間
    buttonVisible: false                                              // CNC 一覧取得のボタン表示・非表示
  };
};

/**
 * 空の CNC を表すテーブルアイテムを作成する(ES)
 * @param {string} root
 * @returns テーブルアイテム
 */
export const createEmptyCancelCodeResultTableES = (root) => {
  return {
    no: '',                                                           // CNC 一覧取得のためのキー
    status: root.$t(DIAG_STATUS_CANNOT_CONNECT),                      // CNC 状態（通信不能）
    code: '-',                                                        // 状態コード 
    description: '-',                                                 // 説明・故障部位
    descriptionKey: 'message.common_hyphen',                          // 説明・故障部位
    igCounter: '-',                                                   // IGカウンタ
    lksStatus: '-',                                                   // LKS状態
    lksMode: '-',                                                     // LKSモード
    occurrenceTime: '-',                                              // 発生日時
    buttonVisible: false                                              // CNC 一覧取得のボタン表示・非表示
  };
};

/**
  * 空の CNC を表すテーブルアイテムを作成する(特殊機能)
  * @param {array} cancelCodeResultItems
  * @param {object} route 呼び出し元のオブジェクト(this)
  * @param {string} i18nCncKey
  * @returns DTCのテーブルアイテム
  */
export const createEmptySpCancelCodeResultTable = (root) => {
  return {
    no: '',
    status: root.$t(DIAG_STATUS_CANNOT_CONNECT),
    code: '-',
    description: '-',                                                 // 説明・故障部位
    descriptionKey: 'message.common_hyphen',                          // 説明・故障部位
    igonCount: '-',
    lksStatus: '-',
    lksMode: '-',
    occurrenceTime: '-',
    gps: '-',
    odometer: '-'
  };
};

// プラン名の名称リストをサポート言語数分用意する
export const getDisplayPlanNames = (languageList) => {
  const displayNames = [];
  for (let index = 0; index < languageList.length; ++index) {
    const language = languageList[index];
    displayNames.push({
      value: language.value,
      label: `message.label_plan_edit_name_${language.value}`,
    });
  }
  return displayNames;
};

/**
 * ECU独自仕様 DTCシステムテーブルの作成
 * @param {*} dtcList DTC 情報
 * @param {*} root root
 * @param {*} cssCursorDefault カーソル表示CSS
 * @param {*} isSpecialDtc 特殊機能用のDTC読出しか否か
 * @returns 
 */
export const computeUniqueDTCTableItem = (dtcList, root, isDefaultCss = false, isSpecialDtc = false) => {
  // テーブル情報がない場合は続行しない
  if (!dtcList) return [];
  return dtcList.map(e => ({
    systemID: e.system,                                           // FFD 一覧取得のためのキー
    name: 'diagSytem.' + e.system,                                // システム名
    status: getDtcStatusResource(e.dtc_status),                   // DTC 状態
    dtc: util.isNullOrDefault(e.code, '-'),                       // DTC コード
    description: getDtcDescriptionResource(e, root),              // 説明・故障部位
    igonCount: util.isNullOrDefault(e.igon_time, '-'),            // IGON 回数
    igonTime: util.isNullOrDefault(e.igon_mill_sec, '-'),         // IGON 経過時間
    counterType: getDtcTypeResource(e.type),                      // 種別
    gps: isSpecialDtc ? util.isNullOrDefault(e.gps, '-') : undefined,              // GPS(※特殊機能DTCのみ)
    odometer: isSpecialDtc ? getOdometerResource(e.odometer) : undefined,    // オドメータ値(※特殊機能DTCのみ)
    occurrenceDate: util.isNullOrDefault(e.occurrence_date, '-'), // 発生日時
    buttonVisible: e.has_ffd,                                     // FFD 一覧取得のボタン表示・非表示
    is_chain_dtc: e.is_chain_dtc,                                 // 連鎖DTC該非
    bgStyle: getDtcBgStyle(e.is_chain_dtc, isDefaultCss)          // 連鎖DTC該当の場合、背景色：グレー表示、カーソル表示判定
  }));
};

// 現在のリプロ種別を取得する
export const getReproTypeResource = (type, status) => {
  if (type === config.REPRO_TYPE_FORCE)
    return status === 'opt' ? { label: 'message.option_repro_type_force', value: config.REPRO_TYPE_FORCE } : 'message.message_repro_type_force';
  if (type === config.REPRO_TYPE_OPTIONAL)
    return status === 'opt' ? { label: 'message.option_repro_type_optional', value: config.REPRO_TYPE_OPTIONAL } : 'message.message_repro_type_optional';
  if (type === config.REPRO_TYPE_MERCHANTABILITY)
    return status === 'opt' ? { label: 'message.option_repro_type_merchantability', value: config.REPRO_TYPE_MERCHANTABILITY } : 'message.message_repro_type_merchantability';
  if (type === config.REPRO_TYPE_RESTORE)
    return status === 'opt' ? { label: 'message.option_repro_type_restore', value: config.REPRO_TYPE_RESTORE } : 'message.message_repro_type_restoration';
  if (type === config.REPRO_TYPE_NOT_SET)
    return status === 'opt' ? { label: 'message.option_repro_type_unselected', value: config.REPRO_TYPE_NOT_SET } : 'message.common_hyphen';
  else
    // 上記のどれでもない場合は hyphen表示(基本存在しない)
    return 'message.common_hyphen';
};

export default {
  getAuthGroupList,
  getAuthGroupListForSearch,
  getAuthGroupOptions,
  getAuthGroupResource,
  getActivationStatus,
  getActivationStatusForSearch,
  getUnuseTermListForSearch,
  getVehicleSystemStatus,
  getConvertedDisplayNames,
  ssoLanguageDisplay,
  setFakeTableDisplay,
  getRxswinErrorMessageSet,
  getResourceKeyForDiagSystem,
  computeAlldtcTable,
  getSaveOrderTable,
  computeAllDTCTableItem,
  createEmptyAllDtcTableItem,
  computeBmcTable,
  createEmptyBmcTableItem,
  createDtcTable,
  changeDidHeaders,
  getCstmSaveOrderTable,
  getCstmSettingValue,
  convertCstmDisplayItem,
  createBmcTable,
  getBmcStatusResource,
  createCancelCodeResultTable,
  createCancelCodeResultTableTM,
  createCancelCodeResultTableRAB,
  createCancelCodeResultTableES,
  createCancelCodeResultTableDataRecES,
  createSpCancelCodeResultTable,
  getCNCStatus,
  createEmptyCancelCodeResultTable,
  createEmptyCancelCodeResultTableRAB,
  createEmptyCancelCodeResultTableES,
  createEmptySpCancelCodeResultTable,
  getDisplayPlanNames,
  computeUniqueDTCTableItem,
  getReproTypeResource,
  // 以下の関数はテストのために公開している関数
  getDtcDescriptionResource,
  getBmcDescriptionResource
};