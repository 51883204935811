<div class="container data-monitor-configure-graph">
  <b-overlay :show="modalLoading" opacity="0.8" spinner-variant="primary" rounded="lg">

    <div class="tabs-container">
      <b-tabs>
        <b-tab v-for="(tab, index) in tabKey()" :key="index"
            @click="currentTab = (tabKey()[index])"
            :active="currentTab === (tabKey()[index])"
            :title="displayPartsInfo[tabKey()[index]].tab"
          >
        </b-tab>
      </b-tabs>

      <div v-if="currentTab === tabKey()[tab().RANGE]" key="tab1" class="tab-content">
        <div class="dialog-item-label mt-2">
          {{ displayPartsInfo[currentTab].itemLabel }}
          <br>
          <b-form-select v-model = "targetItemIndex"
              class="custom-select custom-select-sm"
              :options = "selectedGraphList.map((x, i) => {return {'value': i, 'text': x.menu_title}})"
              :disabled="validate()"
              @change = "handleSelectionChanged" />
        </div>
        <div class="dialog-item-label mt-3">
          {{ displayPartsInfo[currentTab].rangeLabel }}
          <br>
          <b-form-radio-group size="sm" v-model="selectedRange"
            @change = "handleChangeRadioButton"
          >
            <b-form-radio class = "dialog-item-value-label"
                :value="range().DEFAULT"
                :disabled="isOnlyDefaultRange(selectedConversion) || validate()"
                >
              {{ displayPartsInfo[currentTab].optionLabel.defaultRange }}
            </b-form-radio>
            <br>
            <b-form-radio class = "dialog-item-value-label"
                :value="range().AUTO"
                :disabled="isOnlyDefaultRange(selectedConversion) || validate()"
                >
              {{ displayPartsInfo[currentTab].optionLabel.autoRange }}
            </b-form-radio>
            <br>
            <b-form-radio class = "dialog-item-value-label"
                :value="range().MANUAL"
                :disabled="isOnlyDefaultRange(selectedConversion) || validate()"
                >
              {{ displayPartsInfo[currentTab].optionLabel.manRange }}
            </b-form-radio>
          </b-form-radio-group>
          <br>
          <div class = "dialog-manual-range" v-if="selectedRange !== range().AUTO">
            <div class = "ml-4 dialog-item-value-label">
              {{ displayPartsInfo[currentTab].manualMinLabel }}
              <br>
              <div class = "dialog-manual-range">
                <b-form-input
                    v-model="manualMinValue"
                    :disabled="(selectedRange !== range().MANUAL) || (isAlert.manualMaxValue.valid === false)"
                    :placeholder="(selectedConversion.graph_range_display.min) ? getDigitValue(selectedConversion.graph_range_display.min) : getDigitValue(0)"
                    type="number"
                    :step="getStepValue()"
                    class=""
                    size="sm"
                    :state="isAlert.manualMinValue.valid"
                    @input = "handleMinValueChanged()" />
                <div class="ml-2 mt-1">
                  {{ selectedConversion.unit }}
                </div>
                <b-form-invalid-feedback>{{ $t(isAlert.manualMinValue.msg, [isAlert.manualMinValue.length]) }}</b-form-invalid-feedback>
              </div>
            </div>
            <div class="ml-4 dialog-item-value-label">
              {{ displayPartsInfo[currentTab].manualMaxLabel }}
              <br>
              <div class = "dialog-manual-range">
                <b-form-input
                    v-model="manualMaxValue"
                    :disabled="(selectedRange !== range().MANUAL) || (isAlert.manualMinValue.valid === false)"
                    :placeholder="(selectedConversion.graph_range_display.max) ? getDigitValue(selectedConversion.graph_range_display.max) : getDigitValue(0)"
                    type="number"
                    :step="getStepValue()"
                    class=""
                    size="sm"
                    :state="isAlert.manualMaxValue.valid"
                    @input = "handleMaxValueChanged()" />
                <div class = "ml-2 mt-1">
                  {{ selectedConversion.unit }}
                </div>
                <b-form-invalid-feedback>{{ $t(isAlert.manualMaxValue.msg, [isAlert.manualMaxValue.length]) }}</b-form-invalid-feedback>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentTab === tabKey()[tab().TIME_GRID]" key="tab2" class="tab-content">
        <div class="dialog-item-label mt-2">
          {{ displayPartsInfo[currentTab].label }}
        </div>
        <div class="dialog-time">
          <b-form-select v-model = "xAxisGridWidth"
            class="custom-select custom-select-sm mr-1"
            :options = "displayPartsInfo[currentTab].list.map((x) => {return {'value': x, 'text': String(x, 10)}})" />
          <div class="mt-1">
            {{ displayPartsInfo[currentTab].unit }}
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</div>