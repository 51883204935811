<div class="container-fluid ml-n2">
    <!-- プラットフォーム世代判別中  -->
    <b-modal v-model="modal_key_inspection_platform_gen_decision" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer>
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('mac.executing') }}</div>
    </template>
    <template v-slot:default>
      <div class="container-fluid mt-3 mb-3">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100 mt-3">{{ $t('mac.discriminating_vehicles_progress') }}</div>
      <div class="text-center w-100 mt-1">{{ $t('mac.plz_wait') }}</div>
    </template>
  </b-modal>

  <!-- 鍵検証実行中  -->
  <b-modal v-model="modal_key_inspection_progress" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer>
    <template v-slot:header>
      <div class="text-center w-100">{{ $t('mac.executing') }}</div>
    </template>

    <template v-slot:default>
      <div class="container-fluid mt-3 mb-3">
        <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
      </div>
      <div class="text-center w-100 mt-3">{{ $t('mac.mac_inspection_progress') }}</div>
      <div class="text-center w-100 mt-1">{{ $t('mac.plz_wait') }}</div>
    </template>
  </b-modal>

  <!-- IG-ON確認  -->
  <b-modal v-model="modal_key_inspection_ig_on" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_confirmation') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="m-4">
        <div class="text-center w-100">
          <div>
            {{ $t('mac.turn_on_ig_SW_vehicle') }}
          </div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('mac.confirm_ignition_is_on') }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="macInspectionEventOnIgnition('ok')">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- IG-ON再確認 -->
  <b-modal v-model="modal_key_inspection_ig_on_retry" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_confirmation') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="m-4">
        <div class="text-center w-100">
          <div>{{ $t('mac.turn_on_ig_SW_vehicle') }}</div>
          <div>{{ $t('mac.click_retry') }}</div>
          <div class="checkbox-size-lg">
            <b-form-checkbox v-model="turnIgOnChecked">
              {{ $t('mac.confirm_ignition_is_on') }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="outline-primary"
          v-on:click="macInspectionEventOnIgnition('cancel')" class="ml-2 mr-2">
          {{ $t('mac.quit') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
          v-on:click="macInspectionEventOnIgnition('ok')" class="ml-2 mr-2">
          {{ $t('mac.retry') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- 鍵検証失敗 -->
  <b-modal v-model="modal_key_inspection_err_failed" size="lg" header-bg-variant="danger" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_error') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="text-center m-4">
        <div v-html="$t(macStatusInfo.error_message_key, [macStatusInfo.comm_target_ecu])"></div>
        <div v-if="macStatusInfo.error_code == errorCodes.MAC_SPECIFIC_DTC_ERROR_CODE || macStatusInfo.error_code == errorCodes.FAILED_ERROR_CODE">
          <br>
        </div>
        <table class="table-align">
          <div class="text-left" v-for="system in macStatusInfo.systems">
            <!-- 診断通信エラー -->
            <td class="d-flex" v-if="macStatusInfo.error_code == errorCodes.DIAG_COMM_ERROR_CODE">
              <div>{{ system.system_name }}</div>
              <div v-if="!macStatusInfo.platform_gen || macStatusInfo.platform_gen == constants.MAC_KEY_PLATFORM_GEN_25EPF">
                &nbsp;:&nbsp;{{ system.last_error_response }}
              </div>
            </td>
            <!-- 異常処理ECU一覧 -->
            <td v-if="macStatusInfo.error_code == errorCodes.FAILED_ERROR_CODE">
              <div>{{ system.system_name }}&nbsp;:&nbsp;{{ system.ecu_status }}</div>
            </td>
            <!-- DTC問い合わせ 異常処理一覧 -->
            <td v-if="macStatusInfo.error_code == errorCodes.MAC_SPECIFIC_DTC_ERROR_CODE">
              <div>
                {{ system.system_name }}&nbsp;:&nbsp;{{ system.dtc_detection_status }}
              </div>
            </td>
          </div>
        </table>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onOkButtonClick(macStatusInfo.error_code)">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- 鍵検証完了 -->
  <b-modal v-model="modal_key_inspection_completed" size="lg" header-bg-variant="success" header-text-variant="light"
    no-stacking centered no-close-on-backdrop no-close-on-esc>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.common_title_success') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="text-center m-4" v-html="$t('mac.mac_key_inspection_completed')">
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" v-on:click="onOkButtonClick(errorCodes.SUCCESS)">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</div>