<b-modal
  v-model="modal_requirement_118"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'sensorValue' || phase === 'executing' ">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'overSpeedError' || phase === 'pressureSensorError' || phase === 'brakeReleaseError' || phase === 'brakeForceReleaseError' || phase === 'error' ">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期画面 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_118_TXT_1`) }}</div>
          </div>
        </template>
        <!-- センサ値判定 -->
        <template v-if="phase === 'sensorValue'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_118_TXT_2`) }}</div>
          </div>
        </template>
        <!-- 実行監視 -->
        <template v-if="phase === 'executing'">
            <div class="w-100">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
                $t(`${i18nWsKey}.REQ_WS_118_TXT_3_0001`) }}</div>
            </div>
          </template>
        <!-- 正常終了 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_118_TXT_4_0002`) }}</div>
          </div>
        </template>
        <!-- 車輪速超過表示 -->
        <template v-if="phase === 'overSpeedError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_118_TXT_5`) }}</div>
          </div>
        </template>
        <!-- 圧力センサ値異常表示 -->
        <template v-if="phase === 'pressureSensorError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_118_TXT_6`) }}</div>
          </div>
        </template>
        <!-- ブレーキ解除失敗表示 -->
        <template v-if="phase === 'brakeReleaseError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              displayInfo }}</div>
          </div>
        </template>
        <!-- ブレーキ強制解除失敗表示 -->
        <template v-if="phase === 'brakeForceReleaseError'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_118_TXT_8`) }}</div>
          </div>
        </template>
        <!-- 通信エラー表示(共通エラー) -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 初期画面 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" variant="outline-primary" @click="onStandbyCancelClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onStandbyOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 正常終了 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" variant="primary" @click="onSuccessOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 圧力センサ値異常表示、ブレーキ解除失敗表示、ブレーキ強制解除失敗表示 -->
      <template v-if="phase === 'pressureSensorError' || phase === 'brakeReleaseError' || phase === 'brakeForceReleaseError' ">
        <b-button size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'error'">
        <b-button size="sm" variant="primary" @click="onCommonErrorOkClicked" class="mx-2" :disabled="isLoading">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>