<!-- レーダ軸点検ダイアログ -->
<b-modal v-model="modal_requirement_087" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>

  <!-- ********** ヘッダ ********** -->
  <template v-slot:header>
    <div class="text-center w-100">

      <!-- 開始確認画面 -->
      <!-- バンパー交換確認画面 -->
      <!-- 新品バンパー交換確認画面 -->
      <template v-if="phase === 'confirmStart' ||
                      phase === 'confirmBumperChange' ||
                      phase === 'confirmNewBumperChange'">
        {{ $t('message.common_title_confirmation') }}
      </template>

      <!-- 実行監視画面 -->
      <template v-if="phase === 'executing'">
        {{ $t('individual.common_title_execution') }}
      </template>

      <!-- 正常範囲表示画面 -->
      <!-- レーダ反射強度確認表示画面 -->
      <template v-if="phase === 'success' ||
                      phase === 'successConfirm'">
        {{ $t('message.common_title_success') }}
      </template>

      <!-- レーダ反射強度閾値表示画面 -->
      <template v-if="phase === 'confirmThreshold'">
        {{ $t('message.common_title_confirmation') }}
      </template>

      <!-- 点検失敗エラー画面 -->
      <!-- 点検環境不備エラー画面 -->
      <!-- 共通エラー画面 -->
      <template v-if="phase === 'failedExecute' ||
                      phase === 'failedEnvironment' ||
                      phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>

    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px">

        <!-- 開始確認画面 -->
        <template v-if="phase === 'confirmStart'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_check_confirm_start`) }}</div>
          </div>
        </template>

        <!-- バンパー交換確認画面 -->
        <template v-if="phase === 'confirmBumperChange'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_confirm_bumper`) }}</div>
          </div>
        </template>

        <!-- 新品バンパー交換確認画面 -->
        <template v-if="phase === 'confirmNewBumperChange'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_confirm_new_bumper`) }}</div>
          </div>
        </template>

        <!-- 実行監視画面 -->
        <template v-if="phase === 'executing'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_please_wait`) }}</div>
          </div>
        </template>

        <!-- 正常範囲表示画面 -->
        <template v-if="phase === 'success'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_check_success`) }}</div>
          </div>
        </template>

        <!-- レーダ反射強度確認表示画面 -->
        <template v-if="phase === 'successConfirm'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_confirm_reflection_intensity`) }}</div>
          </div>
        </template>

        <!-- レーダ反射強度閾値表示画面 -->
        <template v-if="phase === 'confirmThreshold'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_reflection_intensity`, [displayInfoThreshold]) }}</div>
          </div>
          <div style="max-height: 550px; overflow-y: auto; border: solid 1px;">
            <!-- テーブル部品 -->
            <cstm-table id="cstm-table" useStripeStyle hasTabs type="simple"
              :items="vehicleThresholdList" :columns="vehicleThresholdTableColumns">
            </cstm-table>
          </div>
          <div style="padding-left: 20px; padding-right: 10px;">
            <!-- 注意事項 -->
            <span style="white-space: break-spaces">{{ $t(`${i18nWsKey}.label_reflection_intensity_attention`) }}</span>
          </div>
        </template>

        <!-- 点検失敗エラー画面 -->
        <template v-if="phase === 'failedExecute'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.error_check_failed_execute`, [displayInfoDeg]) }}</div>
          </div>
        </template>

        <!-- 点検環境不備エラー画面 -->
        <template v-if="phase === 'failedEnvironment'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`${i18nWsKey}.error_check_failed_environment`) }}</div>
          </div>
        </template>

        <!-- 共通エラー画面 -->
        <template v-if="phase === 'failed'">
          <div class="work-support-message">
            <div style="white-space: break-spaces">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>

      </div>
    </ShowLessBOverlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 開始確認画面 -->
      <template v-if="phase === 'confirmStart'">
        <b-button :disabled="isDisabled" size="sm" variant="outline-primary" @click="clickClose" class="mx-2">
          {{ $t('individual.common_button_no') }}
        </b-button>
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickConfirmBumperChange" class="mx-2">
          {{ $t('individual.common_button_yes') }}
        </b-button>
      </template>

      <!-- バンパー交換確認画面 -->
      <template v-if="phase === 'confirmBumperChange'">
        <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="clickExecuting(false)" class="mx-2">
          {{ $t('individual.common_button_no') }}
        </b-button>
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickConfirmNewBumperChange" class="mx-2">
          {{ $t('individual.common_button_yes') }}
        </b-button>
      </template>

      <!-- 新品バンパー交換確認画面 -->
      <template v-if="phase === 'confirmNewBumperChange'">
        <b-button size="sm" :disabled="isDisabled" variant="outline-primary" @click="clickExecuting(true)" class="mx-2">
          {{ $t('individual.common_button_no') }}
        </b-button>
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickExecuting(false)" class="mx-2">
          {{ $t('individual.common_button_yes') }}
        </b-button>
      </template>

      <!-- 正常範囲表示画面 -->
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- レーダ反射強度確認表示画面 -->
      <template v-if="phase === 'successConfirm'">
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickConfirmThreshold" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- レーダ反射強度閾値表示 -->
      <template v-if="phase === 'confirmThreshold'">
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 点検失敗エラー画面 -->
      <!-- 点検環境不備エラー画面 -->
      <!-- 共通エラー画面 -->
      <template v-if="phase === 'failedExecute' ||
                      phase === 'failedEnvironment' ||
                      phase === 'failed'">
        <b-button size="sm" :disabled="isDisabled" variant="primary" @click="clickClose" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

    </div>
  </template>

</b-modal>