<b-modal v-model="modal_requirement_131" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 待機/TVV保存/中止 画面 -->
      <template v-if="phase === 'start' || phase === 'check' || phase === 'cancel'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- TVV保存完了 画面 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- TVV未登録/TVV保存失敗 画面 -->
      <template v-if="phase === 'unregistered' || phase === 'failed' || phase === 'error'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機画面 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                  $t('individual.label_read_message_ws131') }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- TVV保存 -->
        <template v-if="phase === 'check'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
                  v-html="$t(`individual.label_save_message_ws131`)"></div>
              </div>
              <custom-table useStripeStyle hasTabs :items="tvvTableItems" :columns="tvvTableReadColumns"
                class="mt-2 ml-2" type="simple" usePopup>
              </custom-table>
            </div>
          </div>
        </template>
        <!-- TVV保存完了画面 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">
                  <div>{{ $t('individual.label_comp_save_message_ws131') }}</div>
                  <div>{{ $t(`individual.label_save_date`, [saveDate]) }}</div>
                  <div>{{ $t(`individual.label_data_id`, [saveDataId]) }}</div>
                </div>
              </div>
              <custom-table useStripeStyle hasTabs :items="tvvTableItems" :columns="tvvTableSaveColumns"
                class="mt-2 ml-2" type="simple" usePopup>
              </custom-table>
            </div>
          </div>
        </template>
        <!-- 中止画面 -->
        <template v-if="phase === 'cancel'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t('individual.label_stop_save_message_ws131') }}</div>
          </div>
        </template>
        <!-- TVV未登録表示画面 -->
        <template v-if="phase === 'unregistered'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t('individual.label_not_registration_message_ws131') }}</div>
          </div>
        </template>
        <!-- TVV保存失敗画面 -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t('individual.label_failed_save_message_ws131') }}</div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 待機画面 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="readTvvLockFlg" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- TVV保存画面 -->
      <template v-if="phase === 'check'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="cancelTransition" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="saveTvvData" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- TVV保存完了/中止/TVV未登録表示/TVV保存失敗 画面 -->
      <template v-if="phase === 'success' || phase === 'cancel' || phase === 'unregistered' || phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">{{
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'error'">
        <b-button size="sm" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>