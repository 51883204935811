/**
 * 再帰的に各ノードについて、conditionCallback をチェックし、true になるノードの selectId を取得する
 * @param {object} node ツリーのノード
 * @param {(object) => boolean} conditionCallback 条件判断を行うコールバック function （引数としてノードが渡ってくるので boolean で返すこと）
 * @returns {string} 条件に合致したノードの selectId
 */
export const getNodeSelectId = (node, conditionCallback) => {
  let targetSelectId = null;

  // 再帰的に children に対して実行する
  (node.children || []).forEach((childrenNode) => {
    const selectId = getNodeSelectId(childrenNode, conditionCallback);
    // childrenのノードからセレクトIDが返却された場合のみ、返り値として設定する
    if (selectId) {
      targetSelectId = selectId;
    }
  });

  if (!targetSelectId) {
    // 子ノードに選択状態がなければ、自分のノードを見る

    // コールバック function で true/false を判定する
    // !! により、万が一 boolean 以外が返ってきた場合にも boolean を格納するようにしている
    const isConditionTrue = !!(conditionCallback && conditionCallback(node));
    // ノードが条件に合致する場合、セレクトIDを返す
    if (isConditionTrue) {
      targetSelectId = node.selectId;
    }
  }

  return targetSelectId;
};
