<div class="container-fluid p-0" style="height: 100%">
  <div class="row no-gutters obd-base-background">
    <div
      v-bind:class="[{ 'col-3': isTreeViewOpen }, { 'obd-base-width': !isTreeViewOpen }]"
    >
      <div>
        <div v-show="isTreeViewOpen" class="obd-base-tree-separator float-left" style="width: 90%;">
          <div v-for="(tree, i) in filteredTreeList" :key="i">
            <div v-if="tree.judgeVisible()">
              <div class="obd-base-tree-header-div">
                <span class="tree-header-label float-left" :class="{ 'obd-label-size-90': isLangRu }">{{ tree.header }}</span>
              </div>
              <div>
                <system-tree ref="trees" v-bind:tree-data="tree.data" v-bind:showHeadElements="true" isAutoHeight
                  @node-Selected="tree.clickNodeCallback">
                </system-tree>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isTreeViewShow">
          <div class="obd-base-div-arrow-radius" @click="switchTreeDisplay()">
            <svg
              v-if="isTreeViewOpen"
              viewBox="0 0 24 24"
              class="obd-base-arrow-icon"
            >
              <path d="M14 7l-5 5 5 5V7z" class="icon-color" />
              <path d="M24 0v24H0V0h24z" fill="none" />
            </svg>
            <svg v-else viewBox="0 0 24 24" class="obd-base-arrow-icon">
              <path d="M10 17l5-5-5-5v10z" class="icon-color" />
              <path d="M0 24V0h24v24H0z" fill="none" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div
      v-bind:class="[{ 'col-9': isTreeViewOpen }, { 'col': !isTreeViewOpen }]"
      style="width: 90%"
    >
      <div class="col-12 row" :id="idScrollTabBlock"></div>
      <router-view />
    </div>
  </div>
</div>
