<div class="container-fluid ml-n2">
  <div class="col-12 mt-2">
    <label class="sub-title-style">{{ $t(selectedSystemName) }}</label>
  </div>
  <div class="row mt-3">
    <div class="col-12 special-feature-container-array-style">
      <div v-for="item in spListItems" class="m-2 special-feature-function-container function-hover"
        v-on:click="clickItem(item.route_name)">
        <div class="function-label text-center">{{$t(item.name)}}</div>
      </div>
    </div>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>