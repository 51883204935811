import logWrapper from "../logWrapper";

/**
 * ページ遷移を行う。
 * @param {object} component Vue の this を指定する
 * @param {string} name 遷移先のページ名
 * @param {object} query query（必要な場合のみ）
 * @param {object} param param（必要な場合のみ）
 */
export const pushAction = (component, name, query = null, params = null) => {
  try {
    const pushParam = { name: name };
    if (query) pushParam["query"] = query;
    if (params) pushParam["params"] = params;
    component.$router.push(pushParam);
  } catch (e) {
    logWrapper.log(`[router/obd] catch in router push. name: ${name}, e: ${e}`);
  }
};
