// i18n wrapper function of javascript . 
// i18nのラッパー関数
// i18nがnullになってしまうOSSの不具合を回避するためのwrapper

export default {
  install(Vue) {
    const _$t = Vue.config.globalProperties.$t;
    const _$te = Vue.config.globalProperties.$te;
    Vue.config.globalProperties._$t = _$t;
    Vue.config.globalProperties._$te = _$te;

    function getMessage(self, targetFunction, args, usei18n$te = false) {
      if (args.length > 0 && !args[0]) {
        // 空のキーが指定された場合はデフォルトのメッセージを返す
        return usei18n$te ? false : args[0];
      }
      if (args[0].indexOf(`-`) !== -1) {
        // システム名にハイフン（'-'）が含まれるもの（例としてセントラルECU ：C-ECU）は、
        // execute\src\i18nResources.jsでアンダーバー（'_'）に置き換えている（ビルドエラー防止のため）。
        // Resource IDにハイフンが含まれる場合、ピリオドで分割し先頭（システム名に該当）のハイフンをアンダーバーに置換する。
        const words = args[0].split(`.`);
        words[0] = words[0].replaceAll(`-`, `_`);
        args[0] = words.join(`.`);
      }
      if (self.$i18n) {
        if (args.length > 0 && !args[0]) {
          // 空のキーが指定された場合はデフォルトのメッセージを返す
          return usei18n$te ? false : args[0];
        } else {
          return targetFunction.apply(self, args);
        }
      } else {
        //console.log("$i18n is NULL");
        return targetFunction.apply(self.$root, args);
      }
    }

    // i18n $t function のラップ関数
    Vue.config.globalProperties.$t = function () {
      return getMessage(this, _$t, arguments);
    };

    // i18n $te function のラップ関数
    Vue.config.globalProperties.$te = function () {
      const result = getMessage(this, _$te, arguments, true);
      return result;
    };
  }
};