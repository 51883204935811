import utils from "../../share/util/utils";

// 以下DTCステータス
const DIAG_STATUS_CONFIRM = 'fixed_resource.g_status_vehicle_dtc_status_confirm';
const DIAG_STATUS_PENDING = 'fixed_resource.i_status_vehicle_dtc_status_tentative';
const DIAG_STATUS_PERMANENT = 'fixed_resource.m_status_vehicle_dtc_status_permanent';
const DIAG_STATUS_NO_DTC = 'message.j_status_vehicle_dtc_status_no_dtc';
const DIAG_STATUS_CANNOT_CONNECT = 'message.k_status_vehicle_dtc_status_cannot_connect';

/**
 * 一覧に表示する為のDTCTableを作成する
 * @param {array} dtcSystems DTC情報List
 * @param {string} systemId システムID
 * @param {string} systemName システム名
 * @param {object} root 呼び出し元のオブジェクト(this)
 * @returns
 */
export const createObdDtcTable = (dtcSystems, systemId, systemName, root) => {
  // DTC が 0 個の場合も DTC 無しと表示する
  if (dtcSystems.length <= 0) {
    const dtcInfo = {
      system: systemId,
      dtc_status: 'no_dtc',
    };
    const noDtcTableItem = createEmptyObdDtcTableItem(dtcInfo, systemName, root);
    return [noDtcTableItem];
  }

  // DTCなし・通信不能・DTC取得非対応の情報が含まれる場合は、取り出す
  const emptySystems = dtcSystems.filter(dtc =>
    dtc.dtc_status === 'no_dtc' ||
    dtc.dtc_status === 'cannot_connect'
  );

  // DTCなし・通信不能・DTC取得非対応の情報が含まれていない情報も取り出しておく
  const notEmptySystems = dtcSystems.filter(dtc =>
    dtc.dtc_status !== 'no_dtc' &&
    dtc.dtc_status !== 'cannot_connect'
  );

  let createTableItem = [];
  // DTCなし・通信不能システムの場合は、コード以降を表示しない
  if (emptySystems.length > 0) {
    emptySystems.map((system) => {
      createTableItem.push(createEmptyObdDtcTableItem(system, systemName, root));
    });
  }

  // DTCなし・通信不能・DTC取得非対応以外の要素は通常のテーブルに変換する
  if (notEmptySystems.length > 0) {
    const tableItem = computeObdDTCTableItem(notEmptySystems, systemName, root);
    createTableItem = createTableItem.concat(tableItem);
  }

  return createTableItem;
};


/**
 * OBD DTC共通システムテーブルの作成
 * @param {array} dtcList DTC 情報
 * @param {string} systemName システム名
 * @param {object} root root
 * @returns 
 */
const computeObdDTCTableItem = (dtcList, systemName, root) => {
  // テーブル情報がない場合は続行しない
  if (!dtcList) return [];
  return dtcList.map(e => ({
    systemID: e.system,                                       // FFD 一覧取得のためのキー
    name: systemName,                                         // システム名
    status: getDtcStatusResource(e.dtc_status, root),         // DTC 状態
    dtc: utils.isNullOrDefault(e.code, '-'),                  // DTC コード
    description: getDtcDescriptionResource(e, root),          // 説明・故障部位
    igonCount: '-',                                           // IGON 回数
    igonTime: '-',                                            // IGON 経過時間
    counterType: 'message.common_hyphen',                     // 種別
    buttonVisible: e.has_ffd,                                 // FFD 一覧取得のボタン表示・非表示
  }));
};

/**
 * OBD DTC 情報非表示を表すテーブルアイテムを作成する
 * @param {array} dtcList DTC 情報
 * @param {string} systemName システム名
 * @param {object} root root
 * @returns
 */
const createEmptyObdDtcTableItem = (dtc, systemName, root) => {
  return {
    systemID: dtc.system,                                   // FFD 一覧取得のためのキー
    name: systemName,                                       // システム名
    status: getDtcStatusResource(dtc.dtc_status, root),     // DTC 状態
    dtc: '-',                                               // DTC コード
    description: 'message.common_hyphen',                   // 説明・故障部位
    igonCount: '-',                                         // IGON 回数
    igonTime: '-',                                          // IGON 経過時間
    counterType: 'message.common_hyphen',                   // 種別
    buttonVisible: false,                                   // FFD 一覧取得のボタン表示・非表示
  };
};

// DTCの状態を設定
const getDtcStatusResource = (status, root) => {
  let dtcStatus = status;
  switch (status) {
    // Confirmed
    case 'confirmed':
      dtcStatus = root.$t(DIAG_STATUS_CONFIRM); break;
    // Pending
    case 'pending':
      dtcStatus = root.$t(DIAG_STATUS_PENDING); break;
    // Confirmed/Pending
    case 'confirmed_pending':
      dtcStatus = `${root.$t(DIAG_STATUS_CONFIRM)}/${root.$t(DIAG_STATUS_PENDING)}`; break;
    // Permanent
    case 'permanent':
      dtcStatus = root.$t(DIAG_STATUS_PERMANENT); break;
    //ダイアグコードなし
    case 'no_dtc':
      dtcStatus = root.$t(DIAG_STATUS_NO_DTC); break;
    //通信不能
    case 'cannot_connect':
      dtcStatus = root.$t(DIAG_STATUS_CANNOT_CONNECT); break;
    default: break;
  }
  return dtcStatus;
};

// 診断テーブルの説明・故障部位カラムの設定
const getDtcDescriptionResource = (res, arg) => {
  // 説明引き当て対象
  const baseId = 'dtc.' + res.protocol_type + '_' + res.code;
  if (arg.$te(baseId)) return baseId;
  const referenceId = baseId + '_' + res.reference;
  return referenceId;
};
