import error from '../../../common/nodejs/errors';
import constants from '../../../common/nodejs/constants';

// HACK: 1関数の最大複雑度（20）の超過による指摘。Array.find()等の条件分岐へのリファクタを検討する必要がある
// eslint-disable-next-line complexity
export const getErrorMessageKey = (instance, errorcode) => {
  let err_msg = '';

  switch (errorcode) {
    case error.IMB_SSM_COMMUNICATION_ABNORMALITY_ERROR:
      err_msg = instance.$t('imb.error_reg_key_commnction_abnormal');
      break;
    case error.IMB_ECM_REGISTRATION_FAILED_ERROR:
      err_msg = instance.$t('imb.error_imb_ecm_registration_failed');
      break;
    case error.IMB_NO_SECURITY_ACCESS_ERROR:
      err_msg = instance.$t('imb.error_imb_no_security_access');
      break;
    case error.IMB_BODY_INTEGRATED_SECURITY_ACCESS_FAILURE_ERROR:
      err_msg = instance.$t('imb.error_imb_body_integrated_security_access_failure') + '<br>' +
      instance.$t('imb.error_imb_body_integrated_security_access_diagnose');
      break;
    case error.IMB_CANNOT_CONFIRM_IGON_ERROR:
      err_msg = instance.$t('imb.common_cannot_detect_igni_on');
      break;
    case error.IMB_UNSET_FALURE_ERROR:
      err_msg = instance.$t('imb.error_imb_unset_falure') + '<br>' +
      instance.$t('imb.error_imb_unset_diagnose');
      break;
    case error.IMB_NUM_OF_REGISTERED_KEYS_EXCEEDED:
      err_msg = instance.$t('imb.error_number_of_key_registrations_exceeded');
      break;
    case error.IMB_KEY_REG_UNSUCCESSFUL_ERROR:
      // NOTE: Immobilizer.jsで呼び出す際に、this.$t((getErrorMessageKey(instance, error.IMB_KEY_REG_UNSUCCESSFUL_ERROR)), [{0}に入れる値]));としなければ、
      // errorcodeに紐づいたi18nの定義の引数{0}に値が入らなくなるため、getErrorMessageKeyの返り値は翻訳前の値にする必要がある。
      err_msg = 'imb.error_reg_key_unsuccesful';
      break;
    case error.IMB_KEY_DELETION_ERROR:
      // NOTE: Immobilizer.jsで呼び出す際に、this.$t((getErrorMessageKey(instance, error.IMB_KEY_DELETION_ERROR)), [{0}に入れる値]));としなければ、
      // errorcodeに紐づいたi18nの定義の引数{0}に値が入らなくなるため、getErrorMessageKeyの返り値は翻訳前の値にする必要がある。
      err_msg = 'imb.error_reg_key_deletion';
      break;
    case error.IMB_ONE_KEY_MUST_BE_REGISTERED:
      // NOTE: Immobilizer.jsで呼び出す際に、this.$t((getErrorMessageKey(instance, error.IMB_ONE_KEY_MUST_BE_REGISTERED)), [{0}に入れる値]));としなければ、
      // errorcodeに紐づいたi18nの定義の引数{0}に値が入らなくなるため、getErrorMessageKeyの返り値は翻訳前の値にする必要がある。
      err_msg = 'imb.error_reg_access_key_must_be_registered';
      break;
    case error.IMB_ONE_KEY_MUST_BE_REGISTERED_TYPE_1:
      err_msg = instance.$t('imb.delete_the_id_of_immobilizer_key_num_reg_key_1_atleast_1_reg', [1]) + '<br>' + '<br>' +
      instance.$t('imb.delete_the_id_of_immobilizer_key_num_reg_key_cannot_deleted');
      break;
    case error.ERROR_COMBINATION_METER_SECURITY_CM_REGISTRATION_FAILURE:
      err_msg = instance.$t('imb.error_combination_meter_security_cm_registration_failure') + '<br>' +
      instance.$t('imb.error_combination_meter_security_cm_registration_diagnose');
      break;
    case error.ERROR_COMBINATION_METER_SECURITY_CM_IS_NOT_COMPLETED:
      err_msg = instance.$t('imb.combination_meter_security_cm_is_not_completed') + '<br>' +
      instance.$t('imb.combination_meter_security_cm_is_perfrom');
      break;
    case error.ERROR_IMMOBILIZER_REGISTRATION_ENDED:
      err_msg = instance.$t('imb.register_immobilizer_system_imb_system_reg_ended');
      break;
    case error.IMB_SYSTEM_RESTART_ERROR:
      err_msg = instance.$t('imb.error_imb_system_restart');
      break;
    case error.IMB_ACCESS_KEY_IS_NOT_STILL_REGISTERED:
      err_msg = instance.$t('imb.error_imb_access_key_is_not_still_registered');
      break;
    case error.IMB_SSM_COMMUNICATION_ERROR:
      err_msg = instance.$t('imb.error_imb_ssm_communication_error');
      break;
    case error.IMB_CANNOT_CONFIRM_CURRENT_STATUS_ERROR:
      err_msg = instance.$t('imb.error_cannot_confirm_current_status');
      break;
    case error.IMB_CANNOT_CONFIRM_ACCESS_KEY_ERROR:
      err_msg = instance.$t('imb.error_cannot_confirm_access_key');
      break;
    case error.IMB_TIMEOUT_DURING_ACCESS_KEY_COLLATION_ERROR:
      err_msg = instance.$t('imb.error_time_out_during_access_key_collation');
      break;
    case error.IMB_TIMEOUT_OCCURRED_DURING_ACCESS_KEY_REGISTRATION_ERROR:
      err_msg = instance.$t('imb.error_time_out_occurred_during_access_key_registration');
      break;
    case error.IMB_NUMBER_OF_ACCESSKEY_REGISTRATIONS_EXCEEDED_ERROR:
      err_msg = instance.$t('imb.error_number_of_accesskey_registrations_exceeded');
      break;
    case error.IMB_TIMEOUT_DURING_IGNITION_SWITCH_OFF_ERROR:
      err_msg = instance.$t('imb.error_time_out_during_ignition_switch_off');
      break;
    case error.IMB_TIMEOUT_DURING_IGNITION_SWITCH_ON_ERROR:
      err_msg = instance.$t('imb.error_time_out_during_ignition_switch_on');
      break;
    case error.IMB_ABNORMAL_TERMINATION_ERROR:
      // NOTE: Immobilizer.jsで呼び出す際に、this.$t((getErrorMessageKey(instance, error.IMB_ABNORMAL_TERMINATION_ERROR)), [{0}に入れる値]));としなければ、
      // errorcodeに紐づいたi18nの定義の引数{0}に値が入らなくなるため、getErrorMessageKeyの返り値は翻訳前の値にする必要がある。
      err_msg = 'imb.error_abnormal_termination';
      break;
    case error.IMB_CANNOT_JUDGE_REGISTER_MODE_ERROR:
      err_msg = instance.$t('imb.error_cannot_judge_register_mode');
      break;
    case error.IMB_COMMUNICATION_FAILURE_ERROR:
      err_msg = instance.$t('imb.common_comm_failure_bet_cm_and_ssm');
      break;
    case error.IMB_SECURITY_ACCESS_FAILURE_ERROR:
      err_msg = instance.$t('imb.common_cm_security_access_failure');
      break;
    case error.IMB_MODE_TRANSITION_FAILURE_ERROR:
      err_msg = instance.$t('imb.register_telematics_cu_mode_transition_failure_diagnose');
      break;
    case error.IMB_FAILED_TO_REGISTRATION_ERROR:
      err_msg = instance.$t('imb.common_failed_to_reg');
      break;
    case error.IMB_UNSUCCESSFUL_COMBINATION_METER_SECURITY_CM_REGISTRATION:
      err_msg = instance.$t('imb.register_combination_meter_unsuccessful_combination_meter_reg');
      break;
    case error.ERROR_UNSUCCESSFUL_CGR_MAY_NOT_BE_REGISTERED:
      err_msg = instance.$t('imb.register_central_gateway_unsuccessful_central_gateway_reg');
      break;
    case error.IMB_VEHICLE_NOT_SUPPORTED_ERROR:
      err_msg = instance.$t('imb.error_vehicle_not_supported');
      break;
    case error.IMB_REGISTRATION_HISTORY_COMMUNICATION_ERROR:
      err_msg = instance.$t('imb.error_communication_failure');
      break;
    case error.IMB_ACCESS_KEY_ID_DELETION_ERROR:
      err_msg = instance.$t('imb.error_imb_access_key_id_deletion');
      break;
    case error.IMB_TIMEOUT_OCCURED_DURING_REGISTRATION_ERROR:
      err_msg = instance.$t('imb.error_imb_timeout_occured_during_registration');
      break;
    case error.IMB_REMOTE_ENGINE_START_CM_REGISTRATION_UNSUCCESSFUL_ERROR:
      err_msg = instance.$t('imb.error_remote_engine_start_cm_registration_unsuccessful');
      break;
    case error.IMB_TELEMATICS_CM_REGISTARTION_UNSUCCESSFUL_ERROR:
      err_msg = instance.$t('imb.error_telematics_cm_registartion_unsuccessful');
      break;
    case error.IMB_CENTRAL_GATEWAY_REGISTRATION_FAILURE_ERROR:
      err_msg = instance.$t('imb.error_central_gateway_registration_failure');
      break;
    case error.IMB_CENTRAL_GATEWAY_NOT_COMPLETE_ERROR:
      err_msg = instance.$t('imb.error_central_gateway_not_complete');
      break;
    default:
      return '';
  }
  return err_msg;
};

// HACK: 1関数の最大複雑度（20）の超過による指摘。Array.find()等の条件分岐へのリファクタを検討する必要がある
// eslint-disable-next-line complexity
export const getErrorMessageKeyIocpRcidStatus = (iocp_rcid, vehicle_type, system_name) => {
  let err_msg = '';
  if(system_name === "EGI" || system_name === "EGI_OLD"){
    if (vehicle_type === 'H1' || vehicle_type === 'H2' || vehicle_type === 'D') {
      switch (iocp_rcid) {
        case constants.IMB_IOCP_RCID_CODE_MISMATCHED_BETWEEN_KEYLESS_ACCESS_CM_OR_ID:
          err_msg = 'imb.error_imb_iocp_rcid_code_mismatched_between_keyless_access_cm_or_id_h1_h2';
          break;
        case constants.IMB_IOCP_RCID_COMMUNICATION_FAILED_BETWEEN_ECM_KEYLESS_ACCESS:
          err_msg = 'imb.error_imb_iocp_rcid_communication_failed_between_ecm_keyless_access_h1_h2';
          break;
        case constants.IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_EEPROM_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_engine_control_module_eeprom_failure_h1_h2';
          break;
        case constants.IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_immobilizer_eeprom_failure_h1_h2_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_engine_control_module_failure_h1_h2_b1';
          break;
        default:
          return '';
      }
    }else{
      switch (iocp_rcid) {
        case constants.IMB_IOCP_RCID_ANTENNA_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_antenna_failure_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_CODE_MISMATCHED_BETWEEN_KEYLESS_ACCESS_CM_OR_ID:
          err_msg = 'imb.error_imb_iocp_rcid_identification_code_mismatch_between_biu_and_ecm_b1';
          break;
        case constants.IMB_IOCP_RCID_COMMUNICATION_FAILED_BETWEEN_ECM_KEYLESS_ACCESS:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_ecm_and_imb_cm_b1';
          break;
        case constants.IMB_IOCP_RCID_CENTRAL_GATEWAY_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_combination_meter_security_cm_failure_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_TRANSPONDER_COMM_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_imb_key_and_imb_cm_b1';
          break;
        case constants.IMB_IOCP_RCID_TRANSPONDER_IDENTIFICATION_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_key_identification_mismatch_b1';
          break;
        case constants.IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_EEPROM_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_engine_control_module_eeprom_failure_h1_h2';
          break;
        case constants.IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_immobilizer_eeprom_failure_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_ecm_and_imb_cm_and_imb_eeprom_failure_b1';
          break;
        case constants.IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_engine_control_module_failure_h1_h2_b1';
          break;
        case constants.IMB_IOCP_RCID_COMBINATION_METER_SECURITY_CM_COMMUNICATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_combination_meter_security_cm_comm_failure_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_combination_meter_security_cm_identification_failure_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_REMOTE_ENGINE_START_CM_REGISTRATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_remote_engine_start_cm_registration_failure_b1';
          break;
        default:
          return '';
      }
    }
  }else{
    if (vehicle_type === 'B1') {
      switch (iocp_rcid) {
        case constants.IMB_IOCP_RCID_NOT_PERFORMED:
          err_msg = 'imb.error_imb_iocp_rcid_preparation_prep_incorrect_b1';
          break;
        case constants.IMB_IOCP_RCID_OTHER_FUNCTIONS_RUNNING:
          err_msg = 'imb.error_imb_iocp_rcid_other_functions_running_i_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_antenna_failure_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_CODE_MISMATCHED_BETWEEN_KEYLESS_ACCESS_CM_OR_ID:
          err_msg = 'imb.error_imb_iocp_rcid_identification_code_mismatch_between_biu_and_ecm_b1';
          break;
        case constants.IMB_IOCP_RCID_COMMUNICATION_FAILED_BETWEEN_ECM_KEYLESS_ACCESS:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_ecm_and_imb_cm_b1';
          break;
        case constants.IMB_IOCP_RCID_CENTRAL_GATEWAY_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_combination_meter_security_cm_failure_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_TRANSPONDER_COMM_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_imb_key_and_imb_cm_b1';
          break;
        case constants.IMB_IOCP_RCID_TRANSPONDER_IDENTIFICATION_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_key_identification_mismatch_b1';
          break;
        case constants.IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_EEPROM_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_engine_control_module_eeprom_failure_h1_h2';
          break;
        case constants.IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_immobilizer_eeprom_failure_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_ecm_and_imb_cm_and_imb_eeprom_failure_b1';
          break;
        case constants.IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_engine_control_module_failure_h1_h2_b1';
          break;
        case constants.IMB_IOCP_RCID_COMBINATION_METER_SECURITY_CM_COMMUNICATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_combination_meter_security_cm_comm_failure_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_combination_meter_security_cm_identification_failure_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_REMOTE_ENGINE_START_CM_REGISTRATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_remote_engine_start_cm_registration_failure_b1';
          break;
        default:
          return '';
      }

    } else if (vehicle_type === 'B2') {
      switch (iocp_rcid) {
        case constants.IMB_IOCP_RCID_PREPARATION_INCORRECT:
          err_msg = 'imb.error_imb_iocp_rcid_not_perform_i_g_b2';
          break;
        case constants.IMB_IOCP_RCID_NOT_PERFORMED:
          err_msg = 'imb.error_imb_iocp_rcid_preparation_incorrect_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_OTHER_FUNCTIONS_RUNNING:
          err_msg = 'imb.error_imb_iocp_rcid_other_functions_running_i_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_antenna_failure_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_CENTRAL_GATEWAY_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_combination_meter_security_cm_failure_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_TRANSPONDER_COMM_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_transponder_communication_failure_i_g_e_b2';
          break;
        case constants.IMB_IOCP_RCID_TRANSPONDER_IDENTIFICATION_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_transponder_identification_failure_i_g_e_b2';
          break;
        case constants.IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_immobilizer_eeprom_failure_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_immobilizer_eeprom_failure_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_COMBINATION_METER_SECURITY_CM_COMMUNICATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_combination_meter_security_cm_comm_failure_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_combination_meter_security_cm_identification_failure_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_REMOTE_ENGINE_START_CM_REGISTRATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_remote_engine_start_cm_registration_failure_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_COMM_FAILURE_BET_IMB_CM_AND_RES_CM:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_imb_cm_and_remote_engine_start_cm_i_g_e';
          break;
        case constants.IMB_IOCP_RCID_TELEMATICS_CM_REG_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_telematics_cm_registration_failure_i_g_b2';
          break;
        case constants.IMB_IOCP_RCID_COMM_FAILURE_BET_IMMOBILIZER_CM_AND_TELEMATICS_CM:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_imb_cm_and_telematics_cm_i_g_b2';
          break;
        case constants.IMB_IOCP_RCID_TIMEOUT:
          err_msg = 'imb.error_imb_iocp_rcid_timeout_i_g_b2';
          break;
        default:
          return '';
      }

    } else if (vehicle_type === 'E') {
      switch (iocp_rcid) {
        case constants.IMB_IOCP_RCID_NOT_PERFORMED:
          err_msg = 'imb.error_imb_iocp_rcid_preparation_incorrect_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_cm_non_volatile_memory_Failure_i_e';
          break;
        case constants.IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_transponder_identification_failure_i_g_e_b2';
          break;
        case constants.IMB_IOCP_RCID_COMBINATION_METER_SECURITY_CM_COMMUNICATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_transponder_communication_failure_i_g_e_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_antenna_failure_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_COMM_FAIL_BETWEEN_IMB_CM_AND_RES_CM:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_imb_cm_and_remote_engine_start_cm_i_g_e';
          break;
        case constants.IMB_IOCP_RCID_COMM_FAILURE_BETWEEN_IMB_CM_AND_REMOTE_ENGINE_START_CM:
          err_msg = 'imb.error_imb_iocp_rcid_remote_engine_start_cm_registration_failure_i_g_e_b1_b2';
          break;
        default:
          return '';
      }

    } else if (vehicle_type === 'G') {
      switch (iocp_rcid) {
        case constants.IMB_IOCP_RCID_PREPARATION_INCORRECT:
          err_msg = 'imb.error_imb_iocp_rcid_not_perform_i_g_b2';
          break;
        case constants.IMB_IOCP_RCID_NOT_PERFORMED:
          err_msg = 'imb.error_imb_iocp_rcid_preparation_incorrect_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_OTHER_FUNCTIONS_RUNNING:
          err_msg = 'imb.error_imb_iocp_rcid_other_functions_running_i_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_antenna_failure_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_CENTRAL_GATEWAY_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_central_gateway_failure_i_g';
          break;
        case constants.IMB_IOCP_RCID_TRANSPONDER_COMM_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_transponder_communication_failure_i_g_e_b2';
          break;
        case constants.IMB_IOCP_RCID_TRANSPONDER_IDENTIFICATION_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_transponder_identification_failure_i_g_e_b2';
          break;
        case constants.IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_immobilizer_eeprom_failure_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_IMMOBILIZER_EEPROM_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_immobilizer_eeprom_failure_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_COMBINATION_METER_SECURITY_CM_COMMUNICATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_central_gateway_failure_service_manual_g';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_central_gateway_identification_failure_g';
          break;
        case constants.IMB_IOCP_RCID_REMOTE_ENGINE_START_CM_REGISTRATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_remote_engine_start_cm_registration_failure_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_COMM_FAILURE_BET_IMB_CM_AND_RES_CM:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_imb_cm_and_remote_engine_start_cm_i_g_e';
          break;
        case constants.IMB_IOCP_RCID_TELEMATICS_CM_REG_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_telematics_cm_registration_failure_i_g_b2';
          break;
        case constants.IMB_IOCP_RCID_COMM_FAILURE_BET_IMMOBILIZER_CM_AND_TELEMATICS_CM:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_imb_cm_and_telematics_cm_i_g_b2';
          break;
        case constants.IMB_IOCP_RCID_TIMEOUT:
          err_msg = 'imb.error_imb_iocp_rcid_timeout_i_g_b2';
          break;
        default:
          return '';
      }

    } else if (vehicle_type === 'I') {
      switch (iocp_rcid) {
        case constants.IMB_IOCP_RCID_PREPARATION_INCORRECT:
          err_msg = 'imb.error_imb_iocp_rcid_not_perform_i_g_b2';
          break;
        case constants.IMB_IOCP_RCID_NOT_PERFORMED:
          err_msg = 'imb.error_imb_iocp_rcid_preparation_incorrect_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_OTHER_FUNCTIONS_RUNNING:
          err_msg = 'imb.error_imb_iocp_rcid_other_functions_running_i_g_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAIL:
          err_msg = 'imb.error_imb_iocp_rcid_cm_non_volatile_memory_Failure_i_e';
          break;
        case constants.IMB_IOCP_RCID_ENGINE_CONTROL_MODULE_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_transponder_identification_failure_i_g_e_b2';
          break;
        case constants.IMB_IOCP_RCID_COMBINATION_METER_SECURITY_CM_COMMUNICATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_transponder_communication_failure_i_g_e_b2';
          break;
        case constants.IMB_IOCP_RCID_ANTENNA_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_antenna_failure_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_COMM_FAIL_BETWEEN_IMB_CM_AND_RES_CM:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_imb_cm_and_remote_engine_start_cm_i_g_e';
          break;
        case constants.IMB_IOCP_RCID_COMM_FAILURE_BETWEEN_IMB_CM_AND_REMOTE_ENGINE_START_CM:
          err_msg = 'imb.error_imb_iocp_rcid_remote_engine_start_cm_registration_failure_i_g_e_b1_b2';
          break;
        case constants.IMB_IOCP_RCID_COMMUNICATION_FAILURE_BETWEEN_IMB_CM_AND_TELEMATICS_CM:
          err_msg = 'imb.error_imb_iocp_rcid_comm_failure_between_imb_cm_and_telematics_cm_i_g_b2';
          break;
        case constants.IMB_IOCP_RCID_TELEMATICS_CM_REGISTRATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_telematics_cm_registration_failure_i_g_b2';
          break;
        case constants.IMB_IOCP_RCID_CENTRAL_GATEWAY_COMMUNICATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_central_gateway_comm_failure_i';
          break;
        case constants.IMB_IOCP_RCID_CENTRAL_GATEWAY_IDENTIFICATION_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_central_gateway_identification_failure_i';
          break;
        case constants.IMB_IOCP_RCID_CENTERAL_GATE_FAILURE:
          err_msg = 'imb.error_imb_iocp_rcid_central_gateway_failure_i_g';
          break;
        case constants.IMB_IOCP_RCID_TIMEOUT:
          err_msg = 'imb.error_imb_iocp_rcid_timeout_i_g_b2';
          break;
        default:
          return '';
      }

    }
  }
  
  return err_msg;

};

export const getErrorMessageCauseAndAction = (errorcode) => {
  let err_cause = '';
  let err_action = '';

  switch (errorcode) {
    case error.IMB_CANNOT_OPEN_DEVICE_ERROR:
      err_cause = 'imb.error_imb_cannot_open_device_cause';
      err_action = 'imb.error_imb_cannot_open_device_action';
      break;
    case error.IMB_CANNOT_CONNECT_DEVICE_ERROR:
      err_cause = 'imb.error_imb_cannot_connect_device_cause';
      err_action = 'imb.error_imb_cannot_connect_device_action';
      break;
    case error.IMB_SYSTEM_ERROR:
      err_cause = 'imb.error_imb_system_cause';
      err_action = 'imb.error_imb_system_action';
      break;
    case error.IMB_CENTARL_GATEWAY_AUTHORIZATION_FAILURE_CLOUD_ERROR:
      err_cause = 'imb.error_centarl_gateway_authorization_failure_cloud_cause';
      err_action = 'imb.error_centarl_gateway_authorization_failure_cloud_action';
      break;
    case error.IMB_CENTRAL_GATEWAY_CLOUD_NO_RESPONSE_AUTHORIZATION_ERROR:
      err_cause = 'imb.error_central_gateway_cloud_no_response_authorization_cause';
      err_action = 'imb.error_central_gateway_cloud_no_response_authorization_action';
      break;
    default:
      return '';
  }
  return { 'err_cause': err_cause, 'err_action': err_action };
};

module.export = {
  getErrorMessageKey: getErrorMessageKey,
  getErrorMessageKeyIocpRcidStatus: getErrorMessageKeyIocpRcidStatus,
  getErrorMessageCauseAndAction: getErrorMessageCauseAndAction,
};