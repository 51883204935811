// 指摘内容は1ファイルの最大行数（500行）の超過であるため、処理内容自体の問題はないと判断。
/* eslint-disable max-lines */
import error from '@/common/nodejs/errors';
import constants from '@/common/nodejs/constants';
import api from '@/common/api';

const COMMON_ECU_ERROR_STATUS_LIST = [
  { resultCode: '0010', messageKey: 'ecu_status_other_execution_condition_not_met' },
  { resultCode: '0011', messageKey: 'ecu_status_function_cannot_execute_same_time' },
  { resultCode: '8100', messageKey: 'ecu_status_other_engine_speed_condition_not_met' },
  { resultCode: '8101', messageKey: 'ecu_status_engine_speed_too_high' },
  { resultCode: '8102', messageKey: 'ecu_status_engine_speed_too_low' },
  { resultCode: '8105', messageKey: 'ecu_status_engine_rotating' },
  { resultCode: '8106', messageKey: 'ecu_status_engine_in_stop' },
  { resultCode: '810A', messageKey: 'ecu_status_engine_rotation_time_too_short' },
  { resultCode: '8110', messageKey: 'ecu_status_other_vehicle_speed_condition_not_met' },
  { resultCode: '8111', messageKey: 'ecu_status_vehicle_speed_too_high' },
  { resultCode: '8112', messageKey: 'ecu_status_vehicle_speed_too_low' },
  { resultCode: '8120', messageKey: 'ecu_status_other_water_temperature_condition_not_met' },
  { resultCode: '8121', messageKey: 'ecu_status_engine_water_temperature_too_high' },
  { resultCode: '8122', messageKey: 'ecu_status_engine_water_temperature_too_low' },
  { resultCode: '8130', messageKey: 'ecu_status_other_battery_voltage_condition_not_met' },
  { resultCode: '8131', messageKey: 'ecu_status_battery_voltage_too_high' },
  { resultCode: '8132', messageKey: 'ecu_status_battery_voltage_too_low' },
  { resultCode: '8200', messageKey: 'ecu_status_outside_temperature_too_high' },
  { resultCode: '8201', messageKey: 'ecu_status_outside_temperature_too_low' },
  { resultCode: '8300', messageKey: 'ecu_status_acceleration_pedal_pressure_too_much' },
  { resultCode: '8301', messageKey: 'ecu_status_acceleration_pedal_pressure_not_enough' },
  { resultCode: '8400', messageKey: 'ecu_status_transmission_not_in_neutral' },
  { resultCode: '8401', messageKey: 'ecu_status_transmission_not_in_gear' },
  { resultCode: '8500', messageKey: 'ecu_status_brake_pedal_not_pressed' },
  { resultCode: '8600', messageKey: 'ecu_status_not_delivery_mode' },
  { resultCode: '8601', messageKey: 'ecu_status_delivery_mode_judgement' },
  { resultCode: '8800', messageKey: 'ecu_status_learning_not_completed' },
  { resultCode: '8801', messageKey: 'ecu_status_learning' },
];

const ECU_UPDATE_FAILED_STATUS = { resultCode: 'FFFF', messageKey: 'ecu_status_update_failed' };

const ECU_INSPECTION_ERROR_STATUS_LIST = [
  { resultCode: '0000', messageKey: 'ecu_status_not_executed' },
  { resultCode: '0001', messageKey: 'ecu_status_execution' },
  { resultCode: '0002', messageKey: 'ecu_status_success' },
  { resultCode: 'F000', messageKey: 'ecu_status_start_routine_not_sent' },
  { resultCode: 'F001', messageKey: 'ecu_status_start_routine_send_failed' },
  { resultCode: 'F100', messageKey: 'ecu_status_start_routine_no_response' },
  { resultCode: 'F200', messageKey: 'ecu_status_request_routine_results_not_sent' },
  { resultCode: 'F201', messageKey: 'ecu_status_request_routine_results_send_failed' },
  { resultCode: 'F300', messageKey: 'ecu_status_request_routine_results_no_response' },
  { resultCode: 'F400', messageKey: 'ecu_status_request_routine_results_resend_no_response' },
  { resultCode: 'F401', messageKey: 'ecu_status_request_routine_results_resend_failed' },
  { resultCode: 'FFFF', messageKey: 'ecu_status_verification_failed' },
];

const ECU_INSPECTION_ERROR_STATUS_RANGE_LIST = [
  { resultCodeMin: 'F110', resultCodeMax: 'F17F', messageKey: 'ecu_status_start_routine_error_code' },
  { resultCodeMin: 'F310', resultCodeMax: 'F37F', messageKey: 'ecu_status_request_routine_results_error_code' },
  { resultCodeMin: 'F410', resultCodeMax: 'F47F', messageKey: 'ecu_status_request_routine_results_resend_error_code' },
];

const ECU_REGISTRATION_ERROR_STATUS_LIST = [
  { resultCode: '0020', messageKey: 'mac.error_message_verification_result_mismatch', endDigit: '' },
  { resultCode: '0021', messageKey: 'mac.error_message_data_communication_module_key_distribution', endDigit: '12' },
  { resultCode: '0022', messageKey: 'mac.error_message_data_communication_module_key_distribution', endDigit: '13' },
  { resultCode: '0023', messageKey: 'mac.error_message_data_communication_module_key_distribution', endDigit: '21' },
  { resultCode: '0024', messageKey: 'mac.error_message_data_communication_module_key_distribution', endDigit: '22' },
  { resultCode: '0025', messageKey: 'mac.error_message_data_communication_module_key_distribution', endDigit: '24' },
  { resultCode: '0026', messageKey: 'mac.error_message_data_communication_module_key_distribution', endDigit: '31' },
  { resultCode: '0027', messageKey: 'mac.error_message_data_communication_module_key_distribution', endDigit: '33' },
  { resultCode: '0028', messageKey: 'mac.error_message_data_communication_module_key_distribution', endDigit: '72' },
  { resultCode: '0029', messageKey: 'mac.error_message_data_communication_module_key_distribution', endDigit: '7F' },
  { resultCode: '002A', messageKey: 'mac.error_message_key_distribution_timeout', endDigit: '' },
  { resultCode: '0031', messageKey: 'mac.error_message_data_communication_module_key_verification', endDigit: '12' },
  { resultCode: '0032', messageKey: 'mac.error_message_data_communication_module_key_verification', endDigit: '13' },
  { resultCode: '0033', messageKey: 'mac.error_message_data_communication_module_key_verification', endDigit: '21' },
  { resultCode: '0034', messageKey: 'mac.error_message_data_communication_module_key_verification', endDigit: '22' },
  { resultCode: '0035', messageKey: 'mac.error_message_data_communication_module_key_verification', endDigit: '24' },
  { resultCode: '0036', messageKey: 'mac.error_message_data_communication_module_key_verification', endDigit: '31' },
  { resultCode: '0037', messageKey: 'mac.error_message_data_communication_module_key_verification', endDigit: '33' },
  { resultCode: '0038', messageKey: 'mac.error_message_data_communication_module_key_verification', endDigit: '72' },
  { resultCode: '0039', messageKey: 'mac.error_message_data_communication_module_key_verification', endDigit: '7F' },
  { resultCode: '003A', messageKey: 'mac.error_message_key_verification_timeout', endDigit: '' },
];

const MAC_KEY_UPDATE_PROGRESS_LIST = [
  {
    // 鍵更新中
    macStatus: constants.MAC_STATUS_EXECUTING,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-mac-key-update-progress',
        stopWatching: false
      }
    ]
  },
  {
    // 書き込みシーケンスエラー(4023)
    macStatus: constants.MAC_KEY_NOT_FOUND_SEQUENCE,
    progress: [
      {
        errCode: error.MAC_KEY_SEQUENCE_NOT_FOUND,
        errMsgKey: api.getErrorMessageForCode(error.MAC_KEY_SEQUENCE_NOT_FOUND),
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // JSONファイル異常(7022)
    macStatus: constants.MAC_KEY_INVALID_JSON,
    progress: [
      {
        errCode: error.MAC_KEY_JSON_FILE_INCORRECT,
        errMsgKey: 'mac.system_error',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // ユニット登録非サポート(7012)
    macStatus: constants.MAC_KEY_NOT_SUPPORTED_ECU,
    progress: [
      {
        errCode: error.VEH_NOT_ELIGIBLE_ERROR_CODE,
        errMsgKey: 'mac.veh_not_eligible_for_unit_reg',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // 電源電圧NG(7013)
    macStatus: constants.MAC_KEY_LOW_VOLTAGE,
    progress: [
      {
        errCode: error.VEH_LOW_VOLTAGE_ERROR_CODE,
        errMsgKey: 'mac.veh_voltage_low',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // IGN-ON指示
    macStatus: constants.MAC_KEY_IG_ON_REQUEST_TWO,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-check-ignition-is-on',
        stopWatching: true
      }
    ]
  },
  {
    // 診断通信エラー(7011)
    macStatus: constants.MAC_KEY_READ_DATA_FAILURE,
    progress: [
      {
        errCode: error.DIAG_COMM_ERROR_CODE,
        errMsgKey: 'mac.diag_comm_error',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // 再IGN-ON指示
    macStatus: constants.MAC_KEY_IG_ON_REQUEST_AGAIN,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-check-ignition-is-on',
        stopWatching: true
      }
    ]
  },
  {
    // アップロード/ダウンロード
    macStatus: constants.MAC_KEY_UPLOAD_DOWNLOAD,
    progress: [
      {
        // アップロード/ダウンロード エラー(7020)
        errCode: error.MAC_KEY_UPLOAD_DOWNLOAD_ERROR,
        errMsgKey: 'mac.upload_download_error',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      },
      {
        // アップロード/ダウンロード タイムアウト(7021)
        errCode: error.MAC_KEY_UPLOAD_DOWNLOAD_TIMEOUT,
        errMsgKey: 'mac.upload_download_timeout',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      },
      { // アップロード/ダウンロード 実行中
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-uploading-downloading-in-progress',
        stopWatching: false
      },
    ]
  },
  {
    macStatus: constants.MAC_KEY_INVALID_PROCESS,
    progress: [
      {
        // 鍵更新 異常処理ECU一覧(7016)
        errCode: error.MAC_KEY_FAILED_TO_UPDATE,
        errMsgKey: 'mac.failed_to_update_mac_key',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // IGN-OFF指示
    macStatus: constants.MAC_KEY_IG_OFF_REQUEST,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-check-ignition-is-off',
        stopWatching: true
      }
    ]
  },
  {
    // 再IGN-OFF指示
    macStatus: constants.MAC_KEY_IG_OFF_REQUEST_AGAIN,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-check-ignition-is-off',
        stopWatching: true
      }
    ]
  },
  {
    // デバイスオープンエラー(7000)
    macStatus: constants.MAC_KEY_DEVICE_OPEN_ERROR,
    progress: [
      {
        errCode: error.MAC_KEY_DEVICE_OPEN_ERROR,
        errMsgKey: 'mac.device_open_error',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // デバイス接続エラー(7001)
    macStatus: constants.MAC_KEY_UNABLE_TO_CONNECT,
    progress: [
      {
        errCode: error.MAC_KEY_UNABLE_TO_CONNECT,
        errMsgKey: 'mac.unable_to_connect',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // システムエラー(7003)
    macStatus: constants.MAC_KEY_SYSTEM_ERROR,
    progress: [
      {
        errCode: error.MAC_KEY_SYSTEM_ERROR_ONE,
        errMsgKey: 'mac.system_error',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // 鍵更新失敗
    macStatus: constants.MAC_STATUS_FAILED,
    progress: [
      {
        // クラウドエラー応答によるCGW認証エラー(7007)
        errCode: error.MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED_ERR_RETURNED_FROM_CLOUD,
        errMsgKey: 'mac.central_gateway_authentication_failed_err_returned_from_cloud',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      },
      {
        // クラウド無応答によるCGW認証エラー(7008)
        errCode: error.MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED_NO_RESPONSE_FROM_CLOUD,
        errMsgKey: 'mac.central_gateway_authentication_failed_no_response_from_cloud',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      },
      {
        // 該当なし→システムエラー(7003)
        errCode: error.MAC_KEY_SYSTEM_ERROR_ONE,
        errMsgKey: 'mac.system_error',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // 鍵管理マスタ無応答(7023)
    macStatus: constants.MAC_KEY_NO_RESPONSE_FROM_KEY_MANAGEMENT_MASTER,
    progress: [
      {
        errCode: error.NO_RESPONSE_FROM_MAC_KEY_MANAGEMENT_MASTER,
        errMsgKey: 'mac.no_response_from_key_management_master',
        dialogId: 'modal-key-update-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // 鍵更新完了
    macStatus: constants.MAC_KEY_COMPLETED,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-key-update-completed',
        stopWatching: true
      }
    ]
  },
];

const AUTH_KEY_REGISTRATION_PROGRESS_LIST = [
  {
    // 鍵登録中
    macStatus: constants.MAC_STATUS_EXECUTING,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-key-registration-progress',
        stopWatching: false
      }
    ]
  },
  {
    // IGN-ON指示
    macStatus: constants.MAC_KEY_IG_ON_REQUEST_TWO,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-key-registration-ig-on',
        stopWatching: true
      }
    ]
  },
  {
    // 再IGN-ON指示
    macStatus: constants.MAC_KEY_IG_ON_REQUEST_AGAIN,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-key-registration-ig-on-retry',
        stopWatching: true
      }
    ]
  },
  {
    // 鍵登録失敗
    macStatus: constants.MAC_STATUS_FAILED,
    progress: [
      {
        // デバイスオープンエラー(7000)
        errCode: error.MAC_KEY_DEVICE_OPEN_ERROR,
        errMsgKey: 'mac.device_open_error',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // デバイス接続エラー(7001)
        errCode: error.MAC_KEY_UNABLE_TO_CONNECT,
        errMsgKey: 'mac.unable_to_connect',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // システムエラー(7003)
        errCode: error.MAC_KEY_SYSTEM_ERROR_ONE,
        errMsgKey: 'mac.system_error',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // クラウドエラー応答によるCGW認証エラー(7007)
        errCode: error.MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED_ERR_RETURNED_FROM_CLOUD,
        errMsgKey: 'mac.central_gateway_authentication_failed_err_returned_from_cloud',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // クラウド無応答によるCGW認証エラー(7008)
        errCode: error.MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED_NO_RESPONSE_FROM_CLOUD,
        errMsgKey: 'mac.central_gateway_authentication_failed_no_response_from_cloud',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // 診断通信エラー(7011)
        errCode: error.DIAG_COMM_ERROR_CODE,
        errMsgKey: 'mac.diag_comm_error',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // 電源電圧NG(7013)
        errCode: error.VEH_LOW_VOLTAGE_ERROR_CODE,
        errMsgKey: 'mac.veh_voltage_low',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // JSONファイル異常(7022)
        errCode: error.MAC_KEY_JSON_FILE_INCORRECT,
        errMsgKey: 'mac.system_error',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // 通信対象ユニット無応答(7025)
        errCode: error.NO_RESPONSE_FROM_COMMUNICATION_TARGET_UNIT,
        errMsgKey: 'mac.no_response_from_communication_target_unit',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // 機器間認証用鍵登録非サポート(7026)
        errCode: error.AUTH_KEY_VEH_NOT_ELIGIBLE_ERROR,
        errMsgKey: 'mac.veh_not_eligible_for_auth_key_register',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // 機器間認証用鍵登録 異常通知(7027)
        errCode: error.AUTH_KEY_ABNORMAL_NOTIFICATION,
        errMsgKey: 'mac.failed_to_register_auth_key',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // 書き込みシーケンスエラー(4023)
        errCode: error.MAC_KEY_SEQUENCE_NOT_FOUND,
        errMsgKey: api.getErrorMessageForCode(error.MAC_KEY_SEQUENCE_NOT_FOUND),
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      },
      {
        // 該当なし→システムエラー(7003)
        errCode: error.MAC_KEY_SYSTEM_ERROR_ONE,
        errMsgKey: 'mac.system_error',
        dialogId: 'modal-key-registration-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // 鍵登録完了
    macStatus: constants.MAC_STATUS_SUCCESS,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-key-registration-completed',
        stopWatching: true
      }
    ]
  },
];

const MAC_KEY_INSPECTION_PROGRESS_LIST = [
  {
    // 鍵検証中
    macStatus: constants.MAC_STATUS_EXECUTING,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-key-inspection-progress',
        stopWatching: false
      }
    ]
  },
  {
    // IGN-ON指示
    macStatus: constants.MAC_KEY_IG_ON_REQUEST_TWO,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-key-inspection-ig-on',
        stopWatching: true
      }
    ]
  },
  {
    // 再IGN-ON指示
    macStatus: constants.MAC_KEY_IG_ON_REQUEST_AGAIN,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-key-inspection-ig-on-retry',
        stopWatching: true
      }
    ]
  },
  {
    // 鍵検証失敗
    macStatus: constants.MAC_STATUS_FAILED,
    progress: [
      {
        // デバイスオープンエラー(7000)
        errCode: error.MAC_KEY_DEVICE_OPEN_ERROR,
        errMsgKey: 'mac.device_open_error',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // デバイス接続エラー(7001)
        errCode: error.MAC_KEY_UNABLE_TO_CONNECT,
        errMsgKey: 'mac.unable_to_connect',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // システムエラー(7003)
        errCode: error.MAC_KEY_SYSTEM_ERROR_ONE,
        errMsgKey: 'mac.system_error',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // クラウドエラー応答によるCGW認証エラー(7007)
        errCode: error.MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED_ERR_RETURNED_FROM_CLOUD,
        errMsgKey: 'mac.central_gateway_authentication_failed_err_returned_from_cloud',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // クラウド無応答によるCGW認証エラー(7008)
        errCode: error.MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED_NO_RESPONSE_FROM_CLOUD,
        errMsgKey: 'mac.central_gateway_authentication_failed_no_response_from_cloud',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // 診断通信エラー(7011)
        errCode: error.DIAG_COMM_ERROR_CODE,
        errMsgKey: 'mac.diag_comm_error',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // ユニット登録非サポート(7012)
        errCode: error.VEH_NOT_ELIGIBLE_ERROR_CODE,
        errMsgKey: 'mac.veh_not_eligible_for_unit_reg',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // 電源電圧NG(7013)
        errCode: error.VEH_LOW_VOLTAGE_ERROR_CODE,
        errMsgKey: 'mac.veh_voltage_low',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // 異常処理ECU一覧(7017)
        errCode: error.FAILED_ERROR_CODE,
        errMsgKey: 'mac.mac_inspection_failed',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // DTC問い合わせ異常処理ECU一覧(7018)
        errCode: error.MAC_SPECIFIC_DTC_ERROR_CODE,
        errMsgKey: 'mac.ecu_detected',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // MAC鍵更新未実施(7019)
        errCode: error.NO_KEY_UPDATE_ERROR_CODE,
        errMsgKey: 'mac.mac_key_update_not_implement',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // JSONファイル異常(7022)
        errCode: error.MAC_KEY_JSON_FILE_INCORRECT,
        errMsgKey: 'mac.system_error',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // 鍵管理マスタ無応答(7023)
        errCode: error.NO_RESPONSE_FROM_MAC_KEY_MANAGEMENT_MASTER,
        errMsgKey: 'mac.no_response_from_key_management_master',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // 書き込みシーケンスエラー(4023)
        errCode: error.MAC_KEY_SEQUENCE_NOT_FOUND,
        errMsgKey: api.getErrorMessageForCode(error.MAC_KEY_SEQUENCE_NOT_FOUND),
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      },
      {
        // 該当なし→システムエラー(7003)
        errCode: error.MAC_KEY_SYSTEM_ERROR_ONE,
        errMsgKey: 'mac.system_error',
        dialogId: 'modal-key-inspection-err-failed',
        stopWatching: true
      }
    ]
  },
  {
    // 鍵検証完了
    macStatus: constants.MAC_STATUS_SUCCESS,
    progress: [
      {
        errCode: error.SUCCESS,
        errMsgKey: null,
        dialogId: 'modal-key-inspection-completed',
        stopWatching: true
      }
    ]
  },
];

/**
 * ペアリング情報からMAC鍵機能が実行中か否かを判定する
 * @param {String} macStatus ペアリング情報から抜粋したMAC鍵実行状況
 * @returns 判定結果
 */
export const isRunningMacKey = (macStatus) => {
  if (macStatus !== undefined && macStatus === 'executing') {
    return true;
  }
  return false;
};

/**
 * 鍵更新で発生した異常処理ECUのステータスを表示用に変換する
 * @param {Object} system ECU情報
 * @param {String} errorCode エラーコード
 * @param {String} platformGen プラットフォーム世代
 * @returns {String} 表示用ECUステータス
 */
export const convertUpdateEcuStatus = (system, errorCode, platformGen) => {
  let status = '';
  // 鍵更新 異常記録ありの場合
  if (errorCode === error.MAC_KEY_FAILED_TO_UPDATE) {
    if (system.mac_exec_status === 'update_error') {
      if (platformGen === constants.MAC_KEY_PLATFORM_GEN_25EPF) {
        // 16進数のステータスを対応する文言に変換
        const statusList = [...COMMON_ECU_ERROR_STATUS_LIST, ECU_UPDATE_FAILED_STATUS];
        const errorStatus = statusList.find(list => list.resultCode === system.mac_key_update_result);
        status = errorStatus ? `mac.${errorStatus.messageKey}` : '';
      } else {
        status = 'mac.ecu_status_update_failed';
      }
    } else if (system.mac_exec_status === 'timeout') {
      status = 'mac.ecu_status_timeout';
    } else if (system.mac_exec_status === 'success') {
      status = 'mac.ecu_status_success';
    } else if (system.mac_exec_status === 'inspection_error') {
      status = 'mac.ecu_status_result_verification_failed';
    }
  }
  return status;
};

/**
 * 鍵検証で発生した異常処理ECUのステータスを表示用に変換する
 * @param {Object} system ECU情報
 * @param {String} errorCode エラーコード
 * @param {String} platformGen プラットフォーム世代
 * @returns {{messageKey: String, endDigit: String}} 表示用ECUステータス
 */
export const convertInspectionEcuStatus = (system, errorCode, platformGen) => {
  let status = { messageKey: '', endDigit: '' };
  if (errorCode === error.FAILED_ERROR_CODE) {
    if (system.mac_exec_status === 'inspection_error') {
      if (platformGen === constants.MAC_KEY_PLATFORM_GEN_25EPF) {
        status = convert25epfErrorStatus(system.mac_key_inspection_result);
      } else {
        status = { messageKey: 'mac.ecu_status_verification_failed', endDigit: '' };
      }
    } else if (system.mac_exec_status === 'timeout') {
      status = { messageKey: 'mac.ecu_status_timeout', endDigit: '' };
    } else if (system.mac_exec_status === 'success') {
      status = { messageKey: 'mac.ecu_status_success', endDigit: '' };
    }
  }
  return status;
};

/*
* 25ePFの鍵検証異常処理ECUのステータスを表示用に変換する
* @param {String} inspectionResult ネガティブレスポンスコード
* @returns 表示用ECUステータス
*/
const convert25epfErrorStatus = (inspectionResult) => {
  // 16進数の鍵検証結果応答値を対応する文言に変換
  const statusList = [...COMMON_ECU_ERROR_STATUS_LIST, ...ECU_INSPECTION_ERROR_STATUS_LIST];
  let errorStatus = {};
  // 表示文言が固定値の場合の抽出フロー
  errorStatus = statusList.find(list => list.resultCode === inspectionResult);
  if (errorStatus) {
    return { messageKey: `mac.${errorStatus.messageKey}`, endDigit: '' };
  }
  // 表示文言が可変の場合の抽出フロー（表示文言に鍵検証結果応答値下2桁が付与される）
  errorStatus = ECU_INSPECTION_ERROR_STATUS_RANGE_LIST.find(
    list => list.resultCodeMin <= inspectionResult.toUpperCase()
      && list.resultCodeMax >= inspectionResult.toUpperCase()
  );
  if (errorStatus) {
    const endDigit = inspectionResult.substring(2);
    return { messageKey: `mac.${errorStatus.messageKey}`, endDigit: endDigit };
  }
  return { messageKey: '', endDigit: '' };
};

/**
 * 機器間認証用鍵登録で発生した異常処理のステータスを表示用に変換する
 * @param {Object} system ECU情報
 * @param {String} errorCode エラーコード
 * @returns {{messageKey: String, endDigit: String}} 表示用ECUステータス
 */
export const convertRegistrationEcuStatus = (system, errorCode) => {
  let status = { messageKey: '', endDigit: '' };
  if (errorCode === error.AUTH_KEY_ABNORMAL_NOTIFICATION) {
    // 16進数のステータスを対応する文言に変換
    const extractedStatus = ECU_REGISTRATION_ERROR_STATUS_LIST.find(list => list.resultCode === system.mac_key_registration_result);
    if (extractedStatus) {
      status = { messageKey: extractedStatus.messageKey, endDigit: extractedStatus.endDigit };
    }
  }
  return status;
};

/**
 * mac_statusとerror_codeをもとに対応する画面情報を返す
 * @param {Object} updateInfo 現在のステータス
 * @param {String} currentFunction 実行中の機能(MAC鍵更新/MAC鍵検証/認証用鍵登録) 
 * @returns 画面情報
 */
export const getMacKeyProgress = (updateInfo, currentFunction) => {
  let progressList = [];
  if (currentFunction === constants.MAC_KEY_PROCESS_UPDATE) {
    progressList = MAC_KEY_UPDATE_PROGRESS_LIST;
  } else if (currentFunction === constants.MAC_KEY_PROCESS_INSPECTION) {
    progressList = MAC_KEY_INSPECTION_PROGRESS_LIST;
  } else if (currentFunction === constants.MAC_KEY_PROCESS_REGISTRATION) {
    progressList = AUTH_KEY_REGISTRATION_PROGRESS_LIST;
  }
  let progress = {};
  // mac_statusが一致する状態を抽出
  const extractedStatus = progressList.find(l => l.macStatus === updateInfo.mac_status);
  if (extractedStatus.progress.length === 1) {
    progress = extractedStatus.progress[0];
  } else {
    // 同一mac_status内で複数のエラーが発生する場合、エラーコードが一致するものを抽出する
    const extractedProgress = extractedStatus.progress.filter(p => p.errCode === updateInfo.error_code);
    if (extractedProgress.length === 1) {
      progress = extractedProgress[0];
    } else {
      // 一致するエラーコードが無い場合、システムエラーに倒す
      const lastIndex = extractedStatus.progress.length - 1;
      progress = extractedStatus.progress[lastIndex];
    }
  }
  return progress;
};

export default {
  isRunningMacKey,
  convertUpdateEcuStatus,
  convertInspectionEcuStatus,
  convertRegistrationEcuStatus,
  getMacKeyProgress,
};