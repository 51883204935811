<b-modal v-model="modal_requirement_102" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'startConfirmation' || phase === 'selectTransmitter'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'start' || phase === 'testTransmitter'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'communicationFailed' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_102_TXT_1`) }}</div>
          </div>
        </template>
        <!-- 確認表示 -->
        <template v-if="phase === 'startConfirmation'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_102_TXT_2`) }}</div>
          </div>
        </template>
        <!-- 発信機選択 -->
        <template v-if="phase === 'selectTransmitter'">
          <div class="row no-gutters">
            <div class="col-12">
              <b-form-select class="form-control transmitter-list" v-model="selectedTransmitter"
              :select-size="9" :options="transmitterList">
              </b-form-select>
            </div>
          </div>
        </template>
        <!-- 発信機テスト -->
        <template v-if="phase === 'testTransmitter'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ displayInfo }}</div>
          </div>
        </template>
        <!-- 通信エラー -->
        <template v-if="phase === 'communicationFailed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_102_TXT_4`) }}</div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 確認表示 -->
      <template v-if="phase === 'startConfirmation'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="moveSelectTransmitter" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 発信機選択 -->
      <template v-if="phase === 'selectTransmitter'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="exitEvent" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading || !selectedTransmitter" @click="writeEvent" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 発信機テスト -->
      <template v-if="phase === 'testTransmitter'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="writeEvent" class="mx-2">
          {{ $t('message.button_polling_alert_terminate') }}
        </b-button>
      </template>
      <!-- 各エラー画面 -->
      <template v-if="phase === 'communicationFailed' || phase === 'failed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exitEvent" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>