import session from '../../../../share/session';
import individualWorkSupportUtil from '../../../../share/util/individualWorkSupportUtil';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../share/util/api/polling';

/**
 * 終了処理 type: exit
 * @param {*} existence 0:エラー遷移あり, 1:エラー遷移なし
 * @returns
 */
export async function exitLogic(existence, self) {
  try {
    self.isLoading = true;
    //バックエンドAPI POST,GET
    const token = session.getToken();
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      self.systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'exit'
      },
      // エラー発生のコールバックを実装
      // ここでは利用していないが、引数の errorType にはあらかじめ用意された定数文字列が返ってくる。
      (errorType) => { errorType; self.showUnexpectedError(); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバックを定義する（validateErrorResult の中は同様のため、省略する）
      (result) => { self.validateErrorResult(result); },
    );

    if (existence === 0) {
      // エラー遷移あり
      if (response) {
        // 正常コールバック
        switch (response.data.status) {
          case REQUEST_STATUS_COMPLETED:
            // 正常終了：機能終了
            self.closeWizard();
            break;
          case REQUEST_STATUS_FAILED:
          default:
            // 異常終了：汎用エラー
            self.showUnexpectedError();
            return;
        }
      }
    } else {
      // エラー遷移なし：機能終了
      self.closeWizard();
      return;
    }
  } finally {
    self.isLoading = false;
  }
}