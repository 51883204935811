<div class="container">
  <div class="login-container" v-if="!showMaintenanceScreen">
    <div class="row row-0" style="margin-left: 10px; margin-right: 10px;" v-if="displayLoginScreen">
      <div class="col-12 text-center">
        <label v-if="domainInfo.type === 'sbr'" class="label-sub-header">
          {{ headerTitle }}
        </label>
        <label v-else class="label-sub-header">
          {{ ssoHeaderTitle }}
        </label>
        <hr class="hr-line-100">
      </div>

      <div class="col-12">
        <!-- Verification code input pane -->
        <div v-if="verificationCodeRequired">
          <div class="row">
            <div class="col-12 text-center">
              <label class="sub-title-style">
                {{ $t('message.label_msg_enter_verification_code') }}
              </label>
            </div>
            <div class="col-12 text-center">
              <label class="label-sm">{{ $t('message.label_user_id') }}</label>
              <label style="font-weight: bold;">&nbsp;&nbsp;{{ user_name }}</label>
            </div>
            <div class="col-12">
              <b-form-group class="form-group">
                <div class="mx-auto" style="max-width: 500px;">
                  <label class="label-sm">{{ $t('message.label_verification_code') }}</label>
                  <b-form-input type="text" id="id-verification-code" v-model="verificationCode" autocomplete="off"
                    size="sm" />
                </div>
              </b-form-group>
            </div>
            <div class="col-12 button-login-container mb-4">
              <b-button variant="outline-primary" v-on:click="onTapCancelButton()">
                {{ $t('message.common_button_back') }}
              </b-button>
              <b-button :disabled="verificationCode === ''" variant="primary" class="ml-4"
                v-on:click="executeVerifyLogin()">{{ $t("message.button_signin") }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- Normal login pane -->
        <div v-else>
          <!-- SUBARU login pane -->
          <div v-if="domainInfo.type ==='sbr'">
            <div class="row">
              <!-- Components on the left side of pane -->
              <div class="col text-center">
                <label class="sub-title-style">{{ $t('message.header_signin_other_system') }}</label>
                <label v-html="$t(domainInfo.labelLoginTop)" class="px-4 pb-2 pt-2 text-left label-sm"></label>
                <b-form-group v-for="(item, index) in samlAccountList" :key="index" class="form-group">
                  <b-button variant="primary" v-on:click="executeLoginWithAd(item.url, item.provider)">
                    {{ item.name }}
                  </b-button>
                </b-form-group>
                <label v-html="$t(domainInfo.labelLoginBottom)" class="px-4 text-left label-ext-sm mb-4"></label>
              </div>

              <div class="center-line-style"></div>

              <!-- Components on the right side of pane -->
              <div class="col-6">
                <div class="row">
                  <div class="col-12 text-center">
                    <label class="sub-title-style">{{ $t('message.header_signin_ssm5_account') }}</label>
                  </div>
                  <div class="col-12">
                    <div class="mx-auto" style="max-width: 480px;">
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t('message.label_user_id') }}</label>
                        <b-form-input type="text" id="id-username" v-model="user_name" autocomplete="off" size="sm" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mx-auto" style="max-width: 480px;">
                      <b-form-group class="form-group">
                        <label class="label-sm">{{ $t('message.label_user_password') }}</label>
                        <b-form-input type="password" id="id-password" v-model="password" size="sm" />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-12 text-center mt-3 mb-3">
                    <b-button variant="primary" :disabled="!isManadatoryFilled" v-on:click="executeLogin()">
                      {{ $t("message.button_signin") }}
                    </b-button>
                  </div>
                  <div class="col-12 text-center mb-4">
                    <a href="javascript:void(0)" @click.prevent="didTapInitPassword()">
                      {{ $t("message.link_init_password") }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- CC接続ショートカットUIは一旦削除する -->
            <!-- <div class="col-12">
              <hr class="hr-line-100">
            </div>
            <div class="col-12 checkbox-size-lg">
              <b-form-checkbox @input="startSkipChecked">
                {{ $t('message.check_skip_start_screen') }}
              </b-form-checkbox>
            </div>
            <div class="col-12 mt-3 mb-4">
              <strong v-html="$t('message.label_skip_start_description')"></strong>
            </div> -->
          </div>
          <!-- Ohter login pane (SOA,SCI,SOA_TP, SCI_TP) -->
          <div v-else>
            <div class="row">
              <!-- Components on the left side of pane -->
              <div class="col text-center">
                <label class="col-12 sub-title-style">{{ ssoDisplayLanguage }}</label>
                <label v-html="$t(domainInfo.labelLoginTop)" class="px-4 pb-2 pt-2 text-left label-sm"></label>
                <b-form-group v-for="(item, index) in samlErrorLoginBtnList" :key="index" class="form-group">
                  <b-button variant="primary" v-on:click="executeLoginWithAd(item.url, item.provider)">
                    {{ item.name }}
                  </b-button>
                </b-form-group>
                <label v-html="$t(domainInfo.labelLoginBottom)" class="px-4 text-left label-ext-sm"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <maintenance-screen @forced-login="executeForcedLogin"></maintenance-screen>
  </div>
  <processing-view :processing="processing"></processing-view>
</div>