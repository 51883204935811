<b-modal v-model="modal_requirement_095" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 実行中 -->
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 成功 地図Ver読出し -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- エラー 共通エラー -->
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 地図Ver読出し -->
        <template v-if="phase === 'start' || phase === 'success'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;" :hidden="isHidden">{{
            $t(`${i18nWsKey}.menu_title`) }}</div>
          <div class="no-gutters">
            <cstm-table useStripeStyle useSort currentSortkey="map" initSortDir="asc" rowSelect hasTabs :items="items"
              :columns="tableColumns" class="support-table mt-2" type="simple" :hidden="isHidden" usePopup></cstm-table>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 地図Ver読出し -->
      <template v-if="phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(0)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(1)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>