/** !!注意!! 基本的に本コントロールは使用しないこと **/
// Vue3 にバージョンアップし b-overlay で show を 高速で true/false 切り替えると _leaveCb の  transition error となる
// https://github.com/vuejs/router/issues/341
// 本カスタムコントロールは b-overlay の show を高速で切り替えても nop するコントロールです

// show 切り替えスキップ(nop)間隔
const msec = 100;

export default {
  name: 'ShowLessBOverlay',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary'
    },
    opacity: {
      type: String,
      default: "0.85"
    }
  },

  data() {
    return {
      isShow: false,
      isProcessing: false,
      stackShow: false
    };
  },

  watch: {
    show: {
      immediate: true,
      async handler(newValue) {
        this.stackShow = newValue;
        // const now = new Date();
        // const timestamp = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`;
        // console.log(`[${timestamp}] Value : ${newValue} isProcessing : ${this.isProcessing}`);
        // isProcessing 中は show の変更イベントが来ても捨てる
        if (!this.isProcessing) {
          this.isProcessing = true;
          await this.sleep(msec);
          // 最後にstackされた情報で true/false を設定
          if (this.isShow !== this.stackShow) {
            this.isShow = this.stackShow;
          }
          this.isProcessing = false;
        }
      }
    }
  },

  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  computed: {
    // nop
  },

  mounted() {
    // nop
  },

  created() {
    // nop
  },
};