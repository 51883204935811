<b-modal v-model="modal_requirement_090" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">
  <!-- ヘッダー -->
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 確認
      待機 -->
      <template v-if="phase === 'start'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行中
      実行監視 -->
      <template v-if="phase === 'wait'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 成功
        停止 -->
      <template v-if="phase === 'execute_success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- 異常
        停止 -->
      <template v-if="phase === 'execute_error'">
        {{ $t('message.common_title_error') }}
      </template>
      <!-- エラー
      共通エラー -->
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_90_TXT_1`) }}</div>
          </div>
        </template>

        <!-- 実行監視 -->
        <template v-if="phase === 'wait'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_90_TXT_2_0001`) }}</div>
          </div>
        </template>

        <!-- 停止 -->
        <template v-if="phase === 'execute_success' || phase === 'execute_error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(displayInfo) }}</div>
          </div>
        </template>

        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
              v-html="$t(`individual.error_communication_error`)"></div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 待機 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="executeProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 停止 -->
      <template v-if="phase === 'execute_success' || phase === 'execute_error'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>