<div class="container-fluid mt-2 mb-2">
  <div class="row mb-2" v-if="ffdSystem || ffdDescription">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-3">
          {{ $t('individual.label_system') }}
        </div>
        <div class="col-9">
          {{ ffdSystem }}
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-3">
          {{ $t('individual.label_cnc_description_reason') }}
        </div>
        <div class="col-9">
          {{ ffdDescription }}
        </div>
      </div>
      <div class="ml-2">
        <slot name="didTestBtn"></slot>
      </div>
      <div class="row mb-2">
        <div class="col col-md-4">
          <b-form-input :value="showSaveOrder" type="text" class="w-100" size="sm" disabled />
        </div>
      </div>
      <div class="mt-2 mb-2" style="display: flex;">
        <b-button class="ml-2" variant="primary" size="sm" @click="csvExport">
        {{ $t('message.file_export') }}
        </b-button>
      </div>
      <cstm-table initSortDir="asc" :items="currentFFDContents" :columns="ffdDetailTableColumns" type="simple" usePopup
        useStripeStyle hasTabs>
      </cstm-table>
    </div>
  </div>
</div>