const nomal = 'nomal';
const gray = 'gray';

export default {
  props: {
    isCollapse: { type: Boolean, default: false, required: false },
    noHeader: { type: Boolean, default: false, required: false },
    noBorder: { type: Boolean, default: false, required: false },
    headerTitle: { type: String, default: '', required: false },
    headerStyle: { type: String, default: nomal, required: false },
    accordionId: { type: String, default: 'my-accordion', required: false },
  },
  data() {
    return {
      isOpened: false,
      isGray: false,
      isNomal: true
    };
  },

  watch: {
    headerStyle: {
      immediate: true,
      handler(val) {
        if (val === gray) {
          this.isNomal = false;
          this.isGray = true;
        }
        else {
          // defaultはnomal スタイルを設定する。 
          this.isNomal = true;
          this.isGray = false;
        }
      }
    }
  },

  methods: {
    changeSearchHeaderIcon() {
      this.isOpen = !this.isOpen;
    },
    changeState(state) {
      this.isOpen = state;
    }
  },
  computed: {
    isOpen: {
      get() {
        return this.isOpened;
      },
      set(headerState) {
        if (this.isOpened !== headerState) {
          this.$emit('expandHeader', headerState);
          this.isOpened = headerState;
        }
      }
    }
  },
  created() {
    this.isOpened = this.isCollapse;
  },
};