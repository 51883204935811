export default {
  namespaced: true,

  state: {
    // 選択ユニット
    unitInfo: null,
    // 選択ユニットの詳細情報 (対象)
    unitDetailInfo: null,
  },

  mutations: {
    // 選択ユニット情報
    setTargetUnit(state, payload) {
      state.unitInfo = payload;
    },

    // 選択ユニットの詳細情報を設定 
    setUnitDetailInfo(state, payload) {
      state.unitDetailInfo = payload;
    },
  },

  actions: {
    // * @param unitId: unitのIDを指定
    // * @param name: ユニット名
    // * @param fileId: PFCのファイルID
    // * @param publishmentMode: 公開・非公開   
    setTargetUnit(context, payload) {
      context.commit('setTargetUnit', payload);
    },

    setUnitDetailInfo(context, payload) {
      context.commit('setUnitDetailInfo', payload);
    },
  }
};