<div class="container-fluid ml-n2">
  
  <div class="row mt-3">
    <div class="col-12 special-feature-container-array-style">
      <div  v-for="item in dtcListItems" class="m-2 special-feature-function-container function-hover"
        v-on:click="clickItem(item.systemId)">
        <div class="function-label text-center" :class="{ 'label-ext-sm': isLangRu }">{{item.name}}</div>
      </div>
    </div>
  </div>

  <processing-view :processing="processing"></processing-view>
</div>