<b-modal v-model="modal_requirement_024" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking :hide-footer="phase === 'writing'">
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 確認
      初期, リフトアップ指示, 暖機・冷却運転指示, IGOFF指示,IGOFF待ち,Pレンジセレクト指示, 
      エンジン始動指示, IGOFF指示(2), IGOFF待ち(2), エンジン始動指示(2) -->
      <template v-if="phase === 'start' || phase === 'liftUp' || 
      phase === 'heatCool' || phase === 'heatCool2' || phase === 'igoff' || phase === 'igoffWait' || phase === 'egiStart' ||
      phase === 'stopIgoff' || phase === 'stopIgoffWait' || phase === 'stopEgiStart' || phase === 'pRangeSelect'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行中
      監視, AT学習準備, Pレンジ維持指示, Dレンジセレクト指示, ブレーキペダル開放指示,ブレーキ踏み込み指示/ブレーキペダル踏み込み指示
      AWD制御学習中表示, 出力クラッチ制御学習中表示, Dレンジ学習中表示, シフト制御学習中表示（Dレンジ）,
      Rレンジセレクト指示, シフト制御学習中表示（Rレンジ）, Nレンジセレクト指示 -->
      <template v-if="phase === 'wait' || phase === 'pRangeMaintain' || phase === 'dRangeSelect' ||
      phase === 'brakePedalOpen' || phase === 'awdControl' || phase === 'brakeStepOn' ||
      phase === 'clutchControl' || phase === 'dRangeLearning' || phase === 'shiftDRange' ||
      phase === 'rRangeSelect' || phase === 'shiftRRange' || phase === 'nRangeSelect'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 成功
      正常終了の表示 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- エラー
      仮故障表示, 失敗表示, 異常終了(エラー), 異常終了の表示, その他エラー -->
      <template v-if="phase === 'failureJudgmentDisplay' || phase === 'failure' || phase === 'failed' || phase === 'error'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 初期 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_1`) }}</div>
          </div>
        </template>
        <!-- 仮故障表示 -->
        <template v-if="phase === 'failureJudgmentDisplay'">
          <div class="w-100">
            <div class="text-left align-middle">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.REQ_WS_24_TXT_20`) }}</div>
            </div>
          </div>
        </template>
        <!-- リフトアップ指示 -->
        <template v-if="phase === 'liftUp'">
          <div class="w-100">
            <div class="text-left align-middle">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(`${i18nWsKey}.REQ_WS_24_TXT_3`) }}</div>
            </div>
          </div>
        </template>
        <!-- 暖機・冷却運転指示 -->
        <template v-if="phase === 'heatCool' || phase === 'heatCool2'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ inputMsg }}
            </div>
          </div>
        </template>
        <!-- 監視, AT学習準備 -->
        <template v-if="phase === 'wait'">
          <div class="w-100">
            <div class="text-left align-middle">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(displayInfo) }}</div>
            </div>
          </div>
        </template>
        <!-- 失敗表示 -->
        <template v-if="phase === 'failure'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_21`) }}</div>
          </div>
        </template>
        <!-- IGOFF指示 -->
        <template v-if="phase === 'igoff'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_5_0002`) }}</div>
          </div>
        </template>
        <!-- IGOFF待ち -->
        <template v-if="phase === 'igoffWait'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_5_0002`) }}</div>
          </div>
        </template>
        <!-- エンジン始動指示 -->
        <template v-if="phase === 'egiStart'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_6`) }}</div>
          </div>
        </template>
        <!-- ブレーキ踏み込み指示/ブレーキペダル踏み込み指示 -->
        <template v-if="phase === 'brakeStepOn'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_7`) }}</div>
          </div>
        </template>
        <!-- Pレンジセレクト指示 -->
        <template v-if="phase === 'pRangeSelect'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_8`) }}</div>
          </div>
        </template>
        <!-- Pレンジ維持指示 -->
        <template v-if="phase === 'pRangeMaintain'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_9`) }}</div>
          </div>
        </template>
        <!-- Dレンジセレクト指示 -->
        <template v-if="phase === 'dRangeSelect'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_10`) }}</div>
          </div>
        </template>
        <!-- ブレーキペダル開放指示 -->
        <template v-if="phase === 'brakePedalOpen'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_12`) }}</div>
          </div>
        </template>
        <!-- AWD制御学習中表示 -->
        <template v-if="phase === 'awdControl'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_13`) }}</div>
          </div>
        </template>
        <!-- Dレンジ学習中表示 -->
        <template v-if="phase === 'dRangeLearning'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_15`) }}</div>
          </div>
        </template>
        <!-- 出力クラッチ制御学習中表示 -->
        <template v-if="phase === 'clutchControl'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_14`) }}</div>
          </div>
        </template>
        <!-- シフト制御学習中表示（Dレンジ）-->
        <template v-if="phase === 'shiftDRange'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_16`) }}</div>
          </div>
        </template>
        <!-- Rレンジセレクト指示-->
        <template v-if="phase === 'rRangeSelect'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_17`) }}</div>
          </div>
        </template>
        <!-- シフト制御学習中表示（Rレンジ） -->
        <template v-if="phase === 'shiftRRange'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_16`) }}</div>
          </div>
        </template>
        <!-- Nレンジセレクト指示 -->
        <template v-if="phase === 'nRangeSelect'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_18`) }}</div>
          </div>
        </template>
        <!-- IGOFF指示(2) -->
        <template v-if="phase === 'stopIgoff'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_5_0002`) }}</div>
          </div>
        </template>
        <!-- IGOFF待ち(2) -->
        <template v-if="phase === 'stopIgoffWait'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_5_0002`) }}</div>
          </div>
        </template>
        <!-- エンジン始動指示(2) -->
        <template v-if="phase === 'stopEgiStart'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_6`) }}</div>
          </div>
        </template>
        <!-- 正常終了の表示 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_24_TXT_19`) }}</div>
          </div>
        </template>
        <!-- 異常終了の表示、その他エラー表示 -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(displayInfo) }}</div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 初期 -->
      <template v-if="phase === 'start'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="failureProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 仮故障表示, 失敗, 正常終了の表示, 異常終了の表示 その他エラー -->
      <template
        v-if="phase === 'failureJudgmentDisplay' || phase === 'failure' || phase === 'error' || phase === 'success'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(0)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- リフトアップ指示 -->
      <template v-if="phase === 'liftUp'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exit(1)" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="learningValueClear" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 暖機・冷却運転指示(1) -->
      <template v-if="phase === 'heatCool'">
        <b-button size="sm" variant="outline-primary" @click="exit(1)" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
      </template>
      <!-- 暖機・冷却運転指示(2) -->
      <template v-if="phase === 'heatCool2'">
        <b-button size="sm" variant="outline-primary" @click="exit(0)" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
      </template>
      <!-- エンジン始動指示 -->
      <template v-if="phase === 'egiStart'">
        <b-button size="sm" :disabled="isLoading || !buttonDisabled" variant="primary" @click="egiStartProcess"
          class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- Pレンジセレクト指示 -->
      <template v-if="phase === 'pRangeSelect'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="exit(0)" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="pRangeMaintainProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- エンジン始動指示(2) -->
      <template v-if="phase === 'stopEgiStart'">
        <b-button size="sm" :disabled="isLoading || !buttonDisabled" variant="primary" @click="confirmationProcess"
          class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了(エラー) -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="exit(1)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>