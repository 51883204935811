export default {
  props: {
    processing: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      // no params
    };
  },
  created() {
    // noop
  },
  mounted() {
    // noop
  },
  computed: {
    // no params
  },
  methods: {
    // noop
  },
  watch: {
      // noop
  }
};