<div class="control-panel-table">
  <table class="table table-sm overflow-auto" v-bind:class="getTableStyle()">
    <tr>
      <th
        v-for="(column, i) in columns"
        :key="i"
        v-bind:class="{ 'column-center' : column.settingButton }"
        @click="sort(column)"
      >
        <div v-if="!useSort && !column.useSort">
          <span v-if="column.useHtmlBind">{{ column.label }}</span>
          <span v-else>{{ $t(column.label) }}</span>
        </div>
        <div v-else class="table-header-cursor">
          <span v-if="column.useHtmlBind">{{ column.label }}</span>
          <span v-else>{{ $t(column.label) }}</span>
          <span v-if="currentSort == column.name">{{ getSortIcon }}</span>
        </div>
      </th>
    </tr>
    <tr
      v-for="(data, index) in (sortedActivity)"
      :key="index"
      v-bind:class="{ 'body-event-none' : !rowSelect }"
      @click="rowClicked(data)"
    >
      <td
        v-for="(column, i) in columns"
        :key="i"
        v-bind:class="getTdStyle(data.bgStyle, index)"
        @click="dataClicked(column.useDataSelect, index, i, column.name, $event.target.textContent)"
      >

        <div v-if="column.settingButton" class="use-button">
          <b-button
            class="mr-2 sm-button"
            size="sm"
            variant="primary"
            @click="handleLeftBtnClick($event, data)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <path d="M14 7l-5 5 5 5V7z" />
              <path d="M24 0v24H0V0h24z" fill="none" />
            </svg>
          </b-button>
          <div
            :id="`popover${index}${i}`"
            v-bind:class="getColumnClasses(data, column.name)"
            @mouseover="onMouseHover(`popover${index}${i}`, column.name, data[column.name])"
          >{{ data[column.name] }}</div>
          <b-button
            class="ml-2 sm-button "
            size="sm"
            variant="primary"
            @click="handleRightBtnClick($event, data)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <path d="M10 17l5-5-5-5v10z" />
              <path d="M0 24V0h24v24H0z" fill="none" />
            </svg>
          </b-button>
        </div>

        <div v-else>
          <span
            :id="`popover${index}${i}`"
            v-bind:class="getColumnClasses(data, column.name)"
            @mouseover="onMouseHover(`popover${index}${i}`, column.name, data[column.name])"
          >{{ data[column.name] }}</span>
        </div>
        <div v-if="column.useButton">
          <b-button
            v-if="data.buttonVisible"
            variant="outline-primary"
            size="sm"
            class="type-button"
            @click="buttonClicked($event, data)"
          >
            <slot name="rowButton"></slot>
          </b-button>
          <span v-else>-</span>
        </div>
        <div v-if="column.usePulldown">
          <b-form-select
            v-if="data.pullDownVisible"
            size="sm"
            v-model="data.selectItem"
            :disabled="disabled"
          >
            <option v-for="item in data.pullDownList" :value="item"
              >{{ item }}</option
            >
          </b-form-select>
          <span v-else>-</span>
        </div>
        <b-popover
          v-if="usePopup && popup.name"
          triggers="hover"
          :target="`popover${index}${i}`"
          :content="popup.name"
          :variant="popup.variant"
        >
        </b-popover>
      </td>
    </tr>
  </table>
</div>
