<div class="active-test-button-panel">
  <!-- タイトル -->
  <div class="control-top border">
    <div class="text-center">
      {{ $t(i18nMenuTitleKey) }}
    </div>
  </div>
  <!-- ボタン -->
  <div class="control-bottom">
    <div class="control-left border">
      <div class="item-div">
        <div class="item-title">
          {{ $t("individual.header_active_test_table_item") }}
        </div>
        <div class="item-btn-div">
          <b-button
            v-for="btn in btns"
            variant="primary"
            :class="btn.style"
            :key="btn.name"
            :disabled="btn.disabled"
            @click="btnClick(btn)"
          >
            {{ btn.name }}
          </b-button>
        </div>
      </div>
    </div>
    <div class="control-right">
      <div class="control-text border" >
        {{ message }}
      </div>
      <b-button 
        id="drive-btn" 
        variant="primary" 
        @click="clickDriveBtn()"
        :disabled="btnDisabled.drive"
      >
        {{ driveBtnLabel }}
      </b-button>
    </div>
  </div>
  <processing-view :processing="displayProcessing" />
</div>
