<b-modal v-model="modal_special_requirement_ECM1" size="lg" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template
        v-if="phase === 'start' || phase === 'currentData' || phase === 'input' || phase === 'execute' || phase === 'instantaneous' || phase === 'average' || phase === 'stop_instantaneous' || phase === 'stop_average'">
        {{ $t(`message.common_title_confirmation`) }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t(`message.common_title_error`) }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 待機 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs rowSelect initSortDir="acs"
                  :items="parameterBodyItems" :columns="parameterTableColumns" type="simple" @rowClicked="selectItem">
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- パラメータ現在値表示 -->
        <template v-if="phase === 'currentData'">
          <div class="col-12">
            <div class="text-left align-middle">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                $t(selectedItem.itemName) }}</div>
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
                selectedItem.itemValue }}</div>
            </div>
          </div>
        </template>
        <!-- パラメータ入力 -->
        <template v-if="phase === 'input'">
          <div class="w-100">
            <div class="text-left align-middle">
              <div class="text-left align-middle">
                <b-form-group>
                  <b-form-input v-model="inputData" :state="validateParam && !validateParam.valid" type="text" size="sm"
                    autocomplete="off"></b-form-input>
                  <b-form-invalid-feedback v-if="validateParam">{{ validateParam.msg
                    }}</b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行確認 -->
        <template v-if="phase === 'execute'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs rowSelect initSortDir="acs"
                  :items="parameterBodyItems" :columns="parameterTableColumns" type="simple">
                </cstm-table>
              </div>
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
                  v-html="$t(`individual.label_start_measure_ECM1`)"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- 瞬時燃費表示 -->
        <template v-if="phase === 'instantaneous' || phase === 'stop_instantaneous' ">
          <div class="w-100" id="instantaneous_print">
            <div class="text-left align-middle">
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
                v-html="$t(`individual.label_instantaneous_fuel_consumption_value_new_line_ECM1`, [instantaneousFuelConsumptionDisplay])">
              </div>
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
                v-html="$t(`individual.label_max_ECM1`, [instantaneousFuelConsumptionMaxDisplay])"></div>
              <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
                v-html="$t(`individual.label_min_ECM1`, [instantaneousFuelConsumptionMinDisplay])"></div>
            </div>
          </div>
        </template>
        <!-- 平均燃費表示 -->
        <template v-if="phase === 'average' || phase === 'stop_average' ">
          <div class="w-100" id="average_print">
            <div style="max-height: 265px; overflow-y: auto;">
              <!-- テーブル部品 -->
              <cstm-table id="cstm-table" useStripeStyle hasTabs :items="fuelEfficiencyBodyItems"
                :columns="fuelEfficiencyTableColumns" type="simple">
              </cstm-table>
            </div>
            <div class="box-margin-right work-support-message txt-instantaneous" style="white-space: break-spaces;"
              v-html="$t(`individual.label_instantaneous_fuel_consumption_value_ECM1`, [instantaneousFuelConsumptionDisplay])">
            </div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;"
              v-html="$t(`individual.error_communication_error`)"></div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 待機 -->
      <template v-if="phase === 'start'">
        <div class="btn-upper-right">
          <b-button size="sm" :disabled="isDisableButton" pill variant="primary" @click="onStartInputClicked"
            class="mt-2 mr-2">
            {{ $t(`individual.label_input_ECM1`) }}
          </b-button>
        </div>
        <div>
          <b-button size="sm" variant="outline-primary" @click="onStartCancelClick" class="mt-2 mr-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" variant="primary" @click="onStartExecuteClick" class="mt-2 mr-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
      <!-- パラメータ現在値表示 -->
      <template v-if="phase === 'currentData'">
        <div class="btn-upper-right">
          <b-button size="sm" pill variant="primary" @click="onCurrentDataInputClicked" class="mt-2 mr-2">
            {{ $t(`individual.label_input_ECM1`) }}
          </b-button>
        </div>
        <div>
          <b-button size="sm" variant="outline-primary" @click="onCurrentDataCancelClicked" class="mt-2 mr-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" variant="primary" @click="onCurrentDataOkClicked" class="mt-2 mr-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
      <!-- パラメータ入力 -->
      <template v-if="phase === 'input'">
        <div class="btn-upper-right">
          <b-button size="sm" pill variant="outline-primary" @click="onInputClearClicked" class="mt-2 mr-2">
            {{ $t('message.common_button_clear') }}
          </b-button>
        </div>
        <div>
          <b-button size="sm" variant="outline-primary" @click="onInputCancelClicked" class="mt-2 mr-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button size="sm" variant="primary" @click="onInputOkClicked" class="mt-2 mr-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
      <!-- 実行確認 -->
      <template v-if="phase === 'execute'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="onExecuteCancelClicked"
          class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onExecuteOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 瞬時燃費表示(実行) -->
      <template v-if="phase === 'instantaneous'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onInstantaneousHoldClicked" class="mx-2">
          {{ $t(`individual.label_hold_ECM1`) }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onInstantaneousAverageClicked" class="mx-2">
          {{ $t(`individual.label_average_fuel_consumption_ECM1`) }}
        </b-button>
      </template>
      <!-- 平均燃費表示(実行) -->
      <template v-if="phase === 'average'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onAverageHoldClicked" class="mx-2">
          {{ $t(`individual.label_hold_ECM1`) }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onAverageInstantaneousClicked" class="mx-2">
          {{ $t(`individual.label_instantaneous_fuel_consumption_ECM1`) }}
        </b-button>
      </template>
      <!-- 瞬時燃費表示(停止) -->
      <template v-if="phase === 'stop_instantaneous'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onStopInstantaneousStartClicked"
          class="mt-2 mr-2">
          {{ $t(`individual.label_start_ECM1`) }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onStopInstantaneousAverageClicked"
          class="mt-2 mr-2">
          {{ $t(`individual.label_average_fuel_consumption_ECM1`) }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onStopInstantaneousPrintClicked"
          class="mt-2 mr-2">
          {{ $t('message.button_repro_print') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onStopInstantaneousFinishClicked"
          class="mt-2 mr-2">
          {{ $t('message.button_polling_alert_terminate') }}
        </b-button>
      </template>
      <!-- 平均燃費表示(停止) -->
      <template v-if="phase === 'stop_average'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onStopAverageStartClicked"
          class="mt-2 mr-2">
          {{ $t(`individual.label_start_ECM1`) }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onStopAverageInstantaneousClicked"
          class="mt-2 mr-2">
          {{ $t(`individual.label_instantaneous_fuel_consumption_ECM1`) }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onStopAveragePrintClicked"
          class="mt-2 mr-2">
          {{ $t('message.button_repro_print') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onStopAverageFinishClicked"
          class="mt-2 mr-2">
          {{ $t('message.button_polling_alert_terminate') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="onErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>