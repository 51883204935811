import individualDiagnose from '../../../../../share/util/individualDiagnose';
import individualWorkSupportUtil from '../../../../../share/util/individualWorkSupportUtil';
import session from '../../../../../share/session';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../../share/util/api/polling';
import { showUnexpectedError, validateErrorResult } from './utils';

/**
 * 終了処理
 * @param {object} self this インスタンス
 */
export async function exitLogic(self) {
  try {
    // スピナー表示
    self.isLoading = true;

    // EUCキーの取得
    const systemId = individualDiagnose.getCurrentSystemId(self);
    const token = session.getToken();
    // 終了処理 (要求)（バックエンドAPI_POST） Getのポーリング処理も行う
    const response = await individualWorkSupportUtil.pollingWspApi(
      self,
      self.netAppId,
      systemId,
      self.workSupportId,
      {
        id_token: token.id_token,
        type: 'terminate',
        is_special: true
      },
      // エラー発生のコールバック
      (errorType) => { errorType; showUnexpectedError(self); },
      // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
      (result) => { validateErrorResult(result, self); },
      // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
      (result) => { validateErrorResult(result, self); },
    );

    if (response) {
      // ポーリングが正常に完了した場合
      switch (response.data.status) {
        case REQUEST_STATUS_COMPLETED:
          // Wizardを閉じる
          self.closeWizard();
          break;
        case REQUEST_STATUS_FAILED:
        default:
          showUnexpectedError(self);
          break;
      }
    }
  } finally {
    self.isLoading = false;
  }
}