<b-modal
  v-model="modal_requirement_085"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking
  >
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t(`individual.common_title_execution`) }}
      </template>
      <template v-if="phase === 'execute'">
        {{ $t(`message.common_title_confirmation`) }}
      </template>
      <template v-if="phase === 'change'">
        {{ $t(`message.common_title_confirmation`) }}
      </template>
      <template v-if="phase === 'input'">
        {{ $t(`message.common_title_confirmation`) }}
      </template>
      <template v-if="phase === 'confirmation'">
        {{ $t(`message.common_title_confirmation`) }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 実行中 -->
        <template v-if="phase === 'start'">
        </template>
        <!-- 仕向地変更確認 -->
        <template v-if="phase === 'execute'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ message }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 仕向地変更 -->
        <template v-if="phase === 'change'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ message }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 仕向地入力 -->
        <template v-if="phase === 'input'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_85_TXT_3`) }}</div>
                <b-form-input type="text" v-model="inputData" autocomplete="off" size="sm" maxlength="4"/>
              </div>
            </div>
          </div>
        </template>
        <!-- 初期化実行確認 -->
        <template v-if="phase === 'confirmation'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`${i18nWsKey}.REQ_WS_85_TXT_4`) }}</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 共通エラー -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 実行中 -->
      <template v-if="phase === 'start'">
      </template>
      <!-- 仕向地変更確認 -->
      <template v-if="phase === 'execute'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="confirmInitProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="confirmProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 仕向地変更 -->
      <template v-if="phase === 'change'">
        <div class="w-100 text-right ">
          <b-button size="sm" :disabled="isLoading" variant="primary" @click="changeInputProcess" class="mx-2 mb-2 btn-width-sm rounded-pill">
            {{ $t(`individual.label_input`) }}
          </b-button>
        </div>
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="changeCancelProcess" class="mx-2 btn-width-sm">
          {{ $t('message.common_button_cancel') }}
        </b-button> 
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="changeOkProcess" class="mx-2 btn-width-sm">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 仕向地入力 -->
      <template v-if="phase === 'input'">
        <div class="w-100 text-right ">
          <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="inputClearProcess" class="mx-2 mb-2 btn-width-sm rounded-pill">
            {{ $t('message.common_button_clear') }}
          </b-button>
        </div>
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="inputCancelProcess" class="mx-2 btn-width-sm">
          {{ $t('message.common_button_cancel') }}
        </b-button> 
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="inputOkProcess" class="mx-2 btn-width-sm">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 初期化実行確認 -->
      <template v-if="phase === 'confirmation'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="initCancelProcess" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button> 
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="initOkProcess" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>