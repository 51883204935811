import logWrapper from '../../logWrapper';
import util from '../../util/utils';

// Unique timer function. 
// 独自のタイマー関数
// setIntervalの内部で実行された関数の処理を待ち、その後のタイマー間隔で処理が実行されます。
// ※注意１：処理完了を待ってから次の処理が実行されるので、処理の実行間隔は「内部処理 + タイマー間隔」となります。
// ※注意２：本タイマー処理は startInterval 関数が呼ばれた場合、１回目の処理実行は即実行されます。

export default {
  install(vue) {

    const getUniqueStr = (myStrong) => {
      let strong = 1000;
      if (myStrong) strong = myStrong;
      return new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
    };

    const checkTimer = (timeObject) => {
      const index = vue.config.globalProperties.$intervals.findIndex(item => item.id === timeObject.id);
      if (index === -1) {
        // timer停止中
        return false;
      }

      if(vue.config.globalProperties.$intervals[index].isSuspend){
        // timer一時停止中
        return false;
      }
      return true;
    };

    const intervalRepeater = async (timeObject, callback, interval, isIntervalFirst = false) => {
      for (; ;) {
        const index = vue.config.globalProperties.$intervals.findIndex(item => item.id === timeObject.id);
        if (index === -1) {
          // 配列にIDが存在しなければ処理を終了する。
          logWrapper.log('Interval ID not found: ', timeObject.id);
          return;
        }

        // 一時停止中は関数を実行しない 
        if (vue.config.globalProperties.$intervals[index].isSuspend) {
          await util.sleep(interval);
        }
        else {
          if(isIntervalFirst){
            // sleep を先に実行して間隔を確保してから 本処理 を実行する
            await util.sleep(interval);
            if(checkTimer(timeObject)){
              await Promise.all([callback()]);
            }
          } else {
            // 本処理と sleep を同時実行して最低間隔を確保する
            await Promise.all([callback(), util.sleep(interval)]);
          }
        }
      }
    };

    // ID一覧
    vue.config.globalProperties.$intervals = [];
    vue.config.globalProperties.$startInterval = (func, intervalMilliSec, log = "", isIntervalFirst = false) => {
      if (typeof (process.env.VUE_APP_DISABLE_SET_INTERVAL) !== 'undefined') {
        return null;
      }

      // UniqID発行
      const id = getUniqueStr();
      const isSuspend = false;
      const timeObject = { id, isSuspend };
      vue.config.globalProperties.$intervals.push(timeObject);

      logWrapper.log('StartInterval ' + log + ": ", timeObject.id);

      intervalRepeater(timeObject, async () => {
        await func();
      }, Number(intervalMilliSec), isIntervalFirst);

      return timeObject;
    };

    // タイマー再開処理
    vue.config.globalProperties.$resumeInterval = (timeObject) => {
      if (timeObject) {
        const index = vue.config.globalProperties.$intervals.findIndex(item => item.id === timeObject.id);
        if (index !== -1) {
          vue.config.globalProperties.$intervals[index].isSuspend = false;
        }
      }
    };

    // タイマー一時停止処理
    vue.config.globalProperties.$pauseInterval = (timeObject) => {
      if (timeObject) {
        const index = vue.config.globalProperties.$intervals.findIndex(item => item.id === timeObject.id);
        if (index !== -1) {
          vue.config.globalProperties.$intervals[index].isSuspend = true;
        }
      }
    };

    // タイマー停止処理
    vue.config.globalProperties.$stopInterval = (timeObject) => {
      if (timeObject) {
        const index = vue.config.globalProperties.$intervals.findIndex(item => item.id === timeObject.id);
        if (index === -1) {
          // 配列にIDが存在しなければ処理は終了済み
          return;
        }
        logWrapper.log('StopInterval ', timeObject.id);
        vue.config.globalProperties.$intervals = vue.config.globalProperties.$intervals.filter(i => i.id !== timeObject.id);
      }
    };

    // 全タイマー停止処理
    vue.config.globalProperties.$stopAllIntervals = () => {
      vue.config.globalProperties.$intervals = [];
    };
  }
};