/** headerタイトルの文言とkeyのMap */
export const headerTitleResources = {
  login: "message.header_signin",
  blank: "",
  termsAgreement: "message.header_terms_agreement",
  changePassword: "message.header_change_password",
  changePasswordChallenge: "message.header_change_password",
  initPassword: "message.header_forgot_password",
  home: "message.header_home_menu",
  systemTable: "message.header_vehicle_system_table",
  systemInformation: "message.header_vehicle_system_information",
  attention: "message.header_vehicle_system_repro",
  execution: "message.header_vehicle_system_repro",
  result: "message.header_repro_result",
  suSystemTable: "message.header_vehicle_system_table",
  usbVerificationFailed: "message.header_vehicle_system_information",
  diagProjectList: "message.header_measured_data",
  diagProjectDetails: "message.header_measured_data",
  dataRecollection: "message.header_measures_recollection",
  oldBrowserError: "",
  macKey: "message.header_vehicle_system_table",
  immobilizer: "message.header_vehicle_system_table",
  specialFeatureEcuTable: "message.header_vehicle_system_table",
  individualEcuTable: "message.header_vehicle_system_table",
  realWorldDataTable: "message.header_vehicle_system_table",
};
