<div class="container-fluid p-0" style="height: 100%">
  <div class="row no-gutters bg-white">
    <div :class="[{ 'col-3': isTreeViewOpen }, { 'width-40': !isTreeViewOpen }]">
      <!-- v-if 表示制御の場合、表示切替のたびに DOM 生成のイベントが走るため v-show を使用すること -->
      <div v-show="isTreeViewOpen" class="tree-seperator float-left">
        <div class="tree-header-div">
          <span class="tree-header-label float-left">{{ $t('message.header_vehicle_system_tree_diagnose') }}</span>
        </div>
        <div>
          <data-tree :tree-data="treeData" :showHeadElements="false" isAutoHeight
            @node-Selected="selectMeasuredData" />
        </div>
      </div>
      <div>
        <div class="div-arrow-radius" @click="switchTreeDisplay()">
          <svg v-if="isTreeViewOpen" viewBox="0 0 24 24" class="arrow-icon">
            <path d="M14 7l-5 5 5 5V7z" class="icon-color" />
            <path d="M24 0v24H0V0h24z" fill="none" />
          </svg>
          <svg v-else viewBox="0 0 24 24" class="arrow-icon">
            <path d="M10 17l5-5-5-5v10z" class="icon-color" />
            <path d="M0 24V0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>
    </div>
    <div :class="[{ 'col-9': isTreeViewOpen }, { 'col': !isTreeViewOpen }]">
      <div class="row" style="width: 100%;">
        <div class="col-12 text-right mt-3">
          <svg class="icon-style" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512">
            <g>
              <path class="st0"
                d="M34.313,203.5c2.297-5.031,6.703-13.297,14.422-27.813c3.547-6.688,7.516-14.063,11.578-21.625H24.719C11.078,154.063,0,165.109,0,178.781C0,192.438,11.078,203.5,24.719,203.5H34.313z" />
              <path class="st0"
                d="M487.281,154.063h-35.594c4.078,7.563,8.031,14.938,11.578,21.625c7.719,14.516,12.109,22.781,14.422,27.813h9.594c13.641,0,24.719-11.063,24.719-24.719C512,165.109,500.922,154.063,487.281,154.063z" />
              <path class="st0"
                d="M39.391,417.781c0,18.406,14.938,33.328,33.328,33.328c18.406,0,33.313-14.922,33.313-33.328v-31.516H39.391V417.781z" />
              <path class="st0"
                d="M405.938,417.781c0,18.406,14.938,33.328,33.344,33.328s33.328-14.922,33.328-33.328v-31.516h-66.672V417.781z" />
              <path class="st0"
                d="M467.875,209.688c1.688,0.5-61.688-115.813-64.719-122.094c-8-16.656-27.781-26.703-47.063-26.703c-22.281,0-84.344,0-84.344,0s-93.563,0-115.859,0c-19.297,0-39.031,10.047-47.063,26.703
                  c-3.031,6.281-66.375,122.594-64.703,122.094c0,0-20.5,20.453-22.063,22.094c-8.625,9.266-8,17.281-8,25.313c0,0,0,75.281,0,92.563c0,17.266,3.078,26.719,23.438,26.719h437c20.359,0,23.453-9.453,23.453-26.719c0-17.281,0-92.563,0-92.563
                  c0-8.031,0.609-16.047-8.047-25.313C488.406,230.141,467.875,209.688,467.875,209.688z M96.563,174.016c0,0,40.703-73.313,43.109-78.109c4.109-8.188,15.828-14.125,27.813-14.125h177.031c12,0,23.703,5.938,27.813,14.125
                  c2.422,4.797,43.125,78.109,43.125,78.109c3.75,6.75,0.438,19.313-10.672,19.313H107.219C96.109,193.328,92.813,180.766,96.563,174.016z M91.125,337.063c-20.656,0-37.406-16.734-37.406-37.391
                  c0-20.672,16.75-37.406,37.406-37.406s37.391,16.734,37.391,37.406C128.516,320.328,111.781,337.063,91.125,337.063zM312.781,347.172c0,2.734-2.219,4.953-4.938,4.953H204.172c-2.734,0-4.953-2.219-4.953-4.953V301.5
                  c0-2.703,2.219-4.906,4.953-4.906h103.672c2.719,0,4.938,2.203,4.938,4.906V347.172z M420.875,337.063c-20.656,0-37.422-16.734-37.422-37.391c0-20.672,16.766-37.406,37.422-37.406s37.406,16.75,37.406,37.406S441.531,337.063,420.875,337.063z" />
            </g>
          </svg>
          <label v-if="isDomestic" class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_cn') }}
            {{ cachedDetails.information.vin }}</label>
          <label v-else class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_vin') }}
            {{ cachedDetails.information.vin }}</label>
          <label v-if="isDomestic" class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_cl') }}
            {{ mkHeaderValue(cachedDetails.information.classification) }}</label>
          <label v-else class="mr-2 vehicle-info-style">{{ $t('message.label_vehicle_system_my') }}
            {{ mkHeaderValue(cachedDetails.information.model_year) }}</label>
          <label class="vehicle-info-style" :class="{ 'mr-2': isDomestic }">
            {{ $t('message.label_vehicle_system_model') }} {{ mkHeaderValue(cachedDetails.information.vehicle_name) }}
          </label>
          <label v-if="isDomestic" class="vehicle-info-style">
            {{ $t('message.label_vehicle_system_applied_model') }} {{ mkHeaderValue(cachedDetails.information.applied_model) }}
          </label>
        </div>
        <div :class="isActive.dataMonitor || isActive.activeTest ? 'col-12' : 'col-12 mb-2'">
          <b-tabs>
            <b-tab :title="$t(navItemLabel)" active></b-tab>
          </b-tabs>
        </div>
        <div v-if="!(isActive.allDtc || isActive.workSupport)">
          <div :class="isActive.dataMonitor || isActive.activeTest ? 'col-12 mt-1' : 'col-12 mt-2'">
            <label class="sub-title-style">{{ $t(displayEcuName) }}</label>
          </div>
          <div class="col-12 mt-3" v-show="!(isActive.dataMonitor || isActive.activeTest)"></div>
        </div>
        <div v-else-if="isActive.workSupport">
          <div class="col-12 mt-2">
            <label class="sub-title-style">{{ $t(displayItemName) }}</label>
          </div>
          <div class="col-12 mt-3"></div>
        </div>
          <allDtc-paging v-show="isActive.allDtc" ref="all_dtc" :testModeVisible="testModeVisible" @csvExport="csvExport"
            @openMemoModal="openMemoModal" @openFFDModal="openFFDModal" @dtcTestDataExport="dtcTestDataExport" />
          <dtc-paging v-show="isActive.diagCode" ref="diag_code" :testModeVisible="testModeVisible" @csvExport="csvExport" @openMemoModal="openMemoModal" @openFFDModal="openFFDModal" @dtcTestDataExport="dtcTestDataExport"/>
          <readinessCode-paging v-show="isActive.readiness" ref="readiness_code" @csvExport="csvExport"/>
          <vehicleControlHistory-paging v-show="isActive.controlHistory" ref="vehicle_control_history" :testModeVisible="testModeVisible" @csvExport="csvExport" @openMemoModal="openMemoModal" @openBMDModal="openBMDModal" @bmcTestDataExport="bmcTestDataExport"/>
          <customize-paging v-show="isActive.customize" ref="customize" @openMemoModal="openMemoModal"/>
          <cancelCode-paging v-show="isActive.cancelCode" ref="cancel_code" :testModeVisible="testModeVisible" @csvExport="csvExport" @openMemoModal="openMemoModal" @didTestDataExport="didTestDataExport"/>
          <workSupport-paging v-show="isActive.workSupport" ref="work_support" @csvExport="csvExport"
            @openMemoModal="openMemoModal" @openDetailFDModal="openDetailFDModal" />
          <dataMonitor-paging v-show="isActive.dataMonitor || isActive.activeTest" ref="data_monitor" :isActiveTest="isActive.activeTest"
            @csvExport="csvExport" @openMemoModal="openMemoModal" @backToDetails="backToDetails" />
        <div class="col-12 text-right mb-2" v-show="!isActive.dataMonitor && !isActive.activeTest">
          <b-button variant="primary" size="sm" v-on:click="backToDetails">
            {{ $t('message.common_button_back') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>

  <!-- プロジェクトファイル変更ダイアログ -->
  <b-modal v-if="modalMemo" v-model="modalMemo" size="lg" header-bg-variant="secondary" header-text-variant="light"
    no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_dtc_note_modal') }}
      </div>
    </template>

    <memo-modal ref="project_memo" :projectId="projectId" :measureId="measuredId"
    @closeNoteModal="closeNoteModal" :functionNameKey="navItemLabel" :destinationAPI="destinationAPI"
    :modalLoading="isMemoDialogLoading" @update-loading="isMemoDialogLoading = $event" />

    <template v-slot:footer>
      <button type="button" :disabled="isMemoDialogLoading" v-on:click="clearMemo()"
        class="btn clear-button ml-3 btn-outline-primary btn-sm rounded-pill">{{ $t("message.common_button_clear") }}
      </button>
      <div class="text-center w-100">
        <b-button size="sm" :disabled="isMemoDialogLoading" variant="outline-primary" @click="modalMemo=false" class="ml-2 mr-2">
          {{ $t("message.common_button_cancel") }}
        </b-button>
        <b-button size="sm" :disabled="isMemoDialogLoading" variant="primary" @click="saveMemo()" class="ml-2 mr-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- FFD 一覧画面 -->
  <b-modal v-if="modalFfd" v-model="modalFfd" size="huge" header-bg-variant="secondary" header-text-variant="light"
    no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <!-- FFDタイトルの設定 -->
    <template v-slot:header>
      <div class="text-center w-100">{{ ffdTitle }}</div>
    </template>

    <ffd-modal :ffdTableProp="ffdTable" :systemProp="selectedSystem" :ffdSystem="ffdSystem" :ffdDescription="ffdDescription"
      :dataPlayback="dataPlayback" ref="ffd_modal">
      <template v-slot:didTestBtn>
        <b-button v-if="testModeVisible" class="clear-button" variant="primary" size="sm" @click="didTestDataExport()">
          Export DID Data
        </b-button>
      </template>
    </ffd-modal>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" @click="modalFfd=false" class="ml-2 mr-2">
          {{ $t("message.common_button_ok") }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- BMD 一覧画面 -->
  <b-modal v-if="modalBmd" v-model="modalBmd" size="huge" header-bg-variant="secondary" header-text-variant="light"
    no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>

    <template v-slot:header>
      <div class="text-center w-100">{{ bmdTitle }}</div>
    </template>

    <bmd-modal :bmdTableProp="bmdTable" :systemProp="selectedSystem" :bmdSystem="bmdSystem"
      :bmdDescription="bmdDescription" :dataPlayback="dataPlayback" ref="bmd_modal" >
      <template v-slot:didTestBtn>
        <b-button v-if="testModeVisible" class="clear-button" variant="primary" size="sm" @click="didTestDataExport()">
          Export DID Data
        </b-button>
      </template>
    </bmd-modal>

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" @click="modalBmd=false" class="ml-2 mr-2">
          {{ $t("message.common_button_ok") }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <!-- 詳細FD画面 -->
  <b-modal v-if="modalDetailFd" v-model="modalDetailFd" size="huge" header-bg-variant="secondary"
    header-text-variant="light" no-stacking
    no-close-on-backdrop no-close-on-esc centered scrollable>
    <!-- 詳細FD画面タイトルの設定 -->
    <template v-slot:header>
      <div class="text-center w-100">{{ fdTitle }}</div>
    </template>

    <detail-fd-modal :detailFdTableProp="detailFdTable" :selectedStatus="status" ref="detail_fd_modal" />

    <template v-slot:footer>
      <div class="text-center w-100">
        <b-button size="sm" variant="primary" @click="modalDetailFd=false" class="ml-2 mr-2">
          {{ $t("message.common_button_ok") }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
</div>