<b-modal v-model="modal_requirement_071" size="lg" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="(phase === 'standby') || (phase === 'progress')">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="(phase === 'update') || (phase === 'create')">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template
        v-if="(phase === 'failed') || (phase === 'start_error') || (phase === 'stop_error') || (phase === 'communication_error') || (phase === 'error')">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- パラメータ更新画面 -->
        <template v-if="phase === 'update'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs rowSelect :items="itemsPhaseUpdate"
                  :columns="systemColumnsPhaseUpdate" type="simple" @rowClicked="onUpdateRowClicked">
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- 実行中 -->
        <template v-if="phase === 'progress'">
          <div class="row no-gutters">
            <div class="col-12 ">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message" style="white-space: break-spaces;"> {{
                  $t(`individual.label_writing_calibration_data`) }} </div>
              </div>
            </div>
          </div>
        </template>
        <!-- マップ生成処理開始画面 -->
        <template v-if="phase === 'create'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 265px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs :items="itemsPhaseCreate"
                  :columns="systemColumnsPhaseCreate" type="simple">
                </cstm-table>
              </div>
              <div v-html="$t(`individual.label_guide_message`)"></div>
            </div>
          </div>
        </template>
        <!-- キャリブレーション失敗 -->
        <template v-if="phase === 'failed'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
            $t(calibrationErrorMessage) }}
          </div>
        </template>
        <!-- 開始処理エラー画面 -->
        <template v-if="phase === 'start_error'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;" 
                v-html="$t(`individual.label_calibration_error`, [$t(startProcessErrorMessage)])"></div>
        </template>
        <!-- 停止処理エラー画面 -->
        <template v-if="phase === 'stop_error'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;" v-html="$t('individual.label_process_error')"></div>
        </template>
        <!-- 通信エラー画面 -->
        <template v-if="phase === 'communication_error'">
          <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
            $t(`individual.label_communication_error`) }}
          </div>
        </template>
        <!-- 共通エラー画面 -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- パラメータ更新画面 -->
      <template v-if="phase === 'update'">
        <div class="d-flex justify-content-end">
          <b-button :disabled="isLoading || isMathButtonDisabled" size="sm" variant="primary"
            @click="onUpdateChangeValueClicked('subtract')" class="mx-2">
            {{ $t(`individual.label_minus`) }}
          </b-button>
          <b-button :disabled="isLoading || isMathButtonDisabled" size="sm" variant="primary"
            @click="onUpdateChangeValueClicked('add')" class="mx-2">
            {{ $t(`individual.label_plus`) }}
          </b-button>
        </div>
        <br />
        <div class="d-flex justify-content-center">
          <b-button :disabled="isLoading" size="sm" variant="outline-primary" @click="onUpdateCancelClicked"
            class="mx-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button :disabled="isLoading" size="sm" variant="primary" @click="onUpdateOkClicked" class="mx-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
      <!-- マップ生成処理開始画面 -->
      <template v-if="phase === 'create'">
        <div class="d-flex flex-wrap justify-content-center">
          <b-button :disabled="isLoading" size="sm" variant="primary" @click="onCreateReenterClicked"
            class="mt-2 mr-2">
            {{ $t(`individual.label_button_reenter`) }}
          </b-button>
          <b-button :disabled="isLoading" size="sm" variant="outline-primary" @click="onCreateCancelClicked"
            class="mt-2 mr-2">
            {{ $t('message.common_button_cancel') }}
          </b-button>
          <b-button :disabled="isLoading" size="sm" variant="primary" @click="onCreateOkClicked" class="mt-2 mr-2">
            {{ $t('message.common_button_ok') }}
          </b-button>
        </div>
      </template>
      <!-- キャリブレーション失敗 -->
      <template v-if="phase === 'failed'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onFailedOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 開始処理エラー画面 -->
      <template v-if="phase === 'start_error'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onStartErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 停止処理エラー画面 -->
      <template v-if="phase === 'stop_error'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onStopErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 通信エラー画面 -->
      <template v-if="phase === 'communication_error'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onCommunicationErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 共通エラー画面 -->
      <template v-if="phase === 'error'">
        <b-button :disabled="isLoading" size="sm" variant="primary" @click="onErrorOkClicked" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>