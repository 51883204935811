import exam from '../../../util/inputValidator';
import config from '../../../config';

export default {
  props: {
    vin: { type: String, default: "" },
    vehicleName: { type: String, default: "" },
    displayProjectID: { type: String, default: "" },
    registrationNumber: { type: String, default: "", required: true },
    functionName: { type: String, default: "" },
    dataName: { type: String, default: "" },
    memo: { type: String, default: "" },
    hideRegistrationNumber: { type: Boolean, default: true },
  },

  data() {
    return {
      inputDiagnoseComment: '',
      inputDataName: '',
      inputMemo: '',
      isAlert: {
        dataName: { valid: null, msg: '' },
        diagnoseComment: { valid: null, msg: '' },
        memo: { valid: null, msg: '' },
      },
    };
  },

  methods: {
    /** プロジェクト保存画面の入力検証処理 */
    validate() {
      // 検証結果キャッシュの初期化
      this.clearAlert();
      const resultPool = [];

      // データ名の必須チェック
      this.isAlert.dataName = exam.excuteValidateCommons(this.inputDataName, true, 0, config.MEMOINFO_DETA_NAME_MAX_LEN);
      resultPool.push(this.isAlert.dataName.valid);

      // 診断コメントの文字数チェック
      this.isAlert.diagnoseComment = exam.excuteValidateCommons(this.inputDiagnoseComment, false, 0, config.MEMOINFO_MAX_LEN);
      resultPool.push(this.isAlert.diagnoseComment.valid);

      // メモの文字数チェック
      this.isAlert.memo = exam.excuteValidateCommons(this.inputMemo, false, 0, config.MEMOINFO_MAX_LEN);
      resultPool.push(this.isAlert.memo.valid);

      const faults = resultPool.filter(el => el === false);
      return faults.length === 0;
    },

    /** 入力検証アラートのクリア */
    clearAlert: function () {
      this.isAlert = {
        dataName: { valid: null, msg: '' },
        diagnoseComment: { valid: null, msg: '' },
        memo: { valid: null, msg: '' },
      };
    },
  },

  mounted() {
    this.clearAlert();
    this.inputDataName = this.dataName;
    this.inputMemo = this.memo;
  },

};