import config from '../../../share/config';
import util from '../../../share/util/utils';

// 印刷画面のFont情報
const PRINT_FILL_STYLE = "black";
const PRINT_FONT = "10px Georgia";

export default {
	name: 'diagPrintModal',
	data() {
		return {
			printImageWidth: 516,
			printImageHeight: 559,
			printHtmlContent: null
		};
	},

	props: {
		userNameProp: { type: String, default: '', required: false },
		vehicleModelProp: { type: String, default: '', required: false },
		vehicleVinProp: { type: String, default: '', required: false },
		allDtcTableProp: { type: Object, default: {}, required: false }
	},

	methods: {

		setup: function () {
			// print 画面の DOM 情報をクリア
			this.printHtmlContent = null;
			const self = this; // thisを変数に代入
			self.canvas = self.$refs.canRef;
			self.ctx = self.canvas.getContext('2d');
			// src の前に self.img をつけて new Image() の後に移動
			self.img = new Image();
			self.img.src = require('@/assets/img/print_formart_v236.png');
			self.img.onload = function () {
				// this を self に代入したことで、エラーが出ない
				self.ctx.drawImage(self.img, 0, 0);
				// Font情報を設定
				self.ctx.fillStyle = PRINT_FILL_STYLE;
				self.ctx.font = PRINT_FONT;
				// 点検日の設定
				const inspectionDate = util.timeConverter(Date.now(), null, 'day');
				self.ctx.fillText(inspectionDate, 430, 21);
				// 担当者の設定
				const userName = self.userNameProp ? self.userNameProp : '-';
				self.ctx.fillText(userName, 430, 35);

				// 車両情報の設定
				self.ctx.fillText((self.vehicleModelProp || '-'), 305, 147);
				self.ctx.fillText((self.vehicleVinProp || '-'), 405, 147);
				const possionMap = [
					{ x: 294, y: 385 },	// その他 type:1
					{ x: 294, y: 195 },	// 動力関係 type:2
					{ x: 21, y: 440 },	// 制御/安全関係 type:3
					{ x: 21, y: 195 },	// Eyesight関係 type:4
				];
				// Vuex で保持されている情報を優先的に使用
				const tbl = self.allDtcTableProp ? JSON.parse(JSON.stringify(self.allDtcTableProp.data)) : [];

				// 各システムを仕様どおりに並べ直す
				const data = util.groupBy(tbl, 'reference');
				const dtcListPrintItems = Object.keys(data)
					.map((k) => { return { key: k, value: data[k] }; })
					.sort((a, b) => { return b.value.length - a.value.length; });

				const printItems = [];
				dtcListPrintItems.forEach((items) => {
					const dataStatus = util.groupBy(items.value, 'dtc_status');
					const statusItems = Object.keys(dataStatus)
						.map((k) => { return { key: k, value: dataStatus[k] }; })
						.sort((a, b) => { return b.value.length - a.value.length; });
					statusItems.forEach((statusItem) => {
						let tempItem = {};
						if (statusItem.value.length !== 0) {
							let names = null;
							statusItem.value.forEach((name) => {
								if (!names) {
									names = name.code;
								} else {
									names = names + ", " + name.code;
								}
							});
							statusItem.value[0].code = names;
							tempItem = statusItem.value[0];
						}
						else {
							tempItem = statusItem.value[0];
						}
						printItems.push(tempItem);
					});
				});

				printItems.forEach((item) => {
					if (item.dtc_status === config.DTC_STATUS_DTC_NOT_SUPPORT) return;
					let state = item.code;
					state = item.dtc_status === config.DTC_STATUS_NO_DTC ? "正常" : state;
					state = item.dtc_status === config.DTC_STATUS_CANNNOT_CONNECT ? "-" : state;
					const text = "{0}   {1}".format((self.$t('diagSytem.' + item.system)), state);
					switch (item.form_group) {
						case 1:
							self.ctx.fillText(text, possionMap[0].x, possionMap[0].y);
							possionMap[0].y = possionMap[0].y + 20;
							break;
						case 2:
							self.ctx.fillText(text, possionMap[1].x, possionMap[1].y);
							possionMap[1].y = possionMap[1].y + 20;
							break;
						case 3:
							self.ctx.fillText(text, possionMap[2].x, possionMap[2].y);
							possionMap[2].y = possionMap[2].y + 20;
							break;
						case 4:
							self.ctx.fillText(text, possionMap[3].x, possionMap[3].y);
							possionMap[3].y = possionMap[3].y + 20;
							break;
					}
				});

				// 印刷プレビューHTML作成
				const dataUrl = self.canvas.toDataURL();
				self.printHtmlContent = '<!DOCTYPE html>';
				self.printHtmlContent += '<html>';
				self.printHtmlContent += '<head><title>帳票印刷</title></head>';
				self.printHtmlContent += '<body><img src="' + dataUrl + '"></body>';
				self.printHtmlContent += '</html>';
			};
		},

		print: function () {
			// 印刷プレビュー画面表示
			const printWin = window.open('', '', 'width=1065, height=565, toolbar=0, scrollbars=0, status=0');
			printWin.document.write(this.printHtmlContent);
			// プレビュー画面を直ぐ表示すると画像が表示されないのでDOMの準備ができるまで待つ
			this.$nextTick(() => {
				printWin.document.close();
				printWin.focus();
				printWin.print();
				printWin.close();
			});
		}
	},

	created() {
		// noop
	},

	mounted() {
		// // 画像サイズを取得&設定
		// const printImageData = new Image();
		// printImageData.src = require('@/assets/print_formart.png');
		// printImageData.onload = function () {
		//   this.printImageWidth = printImageData.width;
		//   this.printImageHeight = printImageData.height;
		// };
		this.setup();
	},
};