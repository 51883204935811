<!-- LNT交換ダイアログ -->
<b-modal v-model="modal_requirement_031" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 選択 -->
      <!-- 確認 -->
      <template v-if="phase === 'start' ||
                      phase === 'confirmation'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- 実行監視 -->
      <template v-if="phase === 'watch'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <!-- 成功 -->
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <!-- リセット失敗 -->
      <!-- 共通エラー -->
      <template v-if="phase === 'fail' ||
                      phase === 'connectionFailed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <!-- ********** ボディ ********** -->
  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">

        <!-- 選択 -->
        <template v-if="phase === 'start'">
          <div class="row no-gutters">
            <b-form-select class="form-control" v-model="selectedMode" :options="modeList"
              :select-size="6"></b-form-select>
            <div class="box-margin-right work-support-message">{{$t(`${i18nWsKey}.REQ_WS_31_TXT_1`)}}</div>
          </div>
        </template>

        <!-- 確認 -->
        <template v-if="phase === 'confirmation'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message">{{$t(`${i18nWsKey}.REQ_WS_31_TXT_2`)}}</div>
          </div>
        </template>

        <!-- 実行監視 -->
        <template v-if="phase === 'watch'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message">{{$t(`${i18nWsKey}.REQ_WS_31_TXT_3_0000`)}}</div>
          </div>
        </template>

        <!-- 成功 -->
        <template v-if="phase === 'success'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_31_TXT_4_0002`) }}</div>
          </div>
        </template>

        <!-- リセット失敗 -->
        <template v-if="phase === 'fail'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`${i18nWsKey}.REQ_WS_31_TXT_5_0010`) }}</div>
            </div>
          </template>

        <!-- 共通エラー -->
        <template v-if="phase === 'connectionFailed'">
          <div class="row no-gutters">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <!-- ********** フッタ ********** -->
  <template v-slot:footer>
    <div class="w-100 text-center ">

      <!-- 待機 -->
      <template v-if="phase === 'start'">
        <b-button :disabled="isDisabled" size="sm" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickShowConfirmation" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 確認 -->
      <template v-if="phase === 'confirmation'">
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickExecute" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 成功 -->
      <!-- リセット失敗 -->
      <template v-if="phase === 'success' ||
                      phase === 'fail'">
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickExit('transition')" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>

      <!-- 共通エラー -->
      <template v-if="phase === 'connectionFailed'">
        <b-button :disabled="isDisabled" size="sm" variant="primary" @click="clickExit('ignore')" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>