import config from "../../share/config";
import { compare } from "../../share/util/utils";

/**
 * システム名がデフォルト形式 ECU($***) であるかを確認し、デフォルト形式であれば *** を取り出した結果を取得する。
 * @param {string} input 入力文字列
 * @returns {string} デフォルト形式であれば *** を取り出した結果
 */
const getDefaultSystemExp = (input) => {
  const re = /^ECU\(\$(.+)\)$/;
  const ret = re.exec(input);
  return (ret && ret.length === 2 && ret[1]) || null;
};

/**
 * 2つのシステム名の大小の比較を行う。
 * @param {object} a 1つ目
 * @param {object} b 2つ目
 * @param {string} sortDir 昇順か降順か（デフォルトは降順）
 * @returns {int} 昇順の場合は 1 つ目の方が大きい場合に 1、降順の場合は 2 つ目の方が大きい場合に 1 を返す。同じ場合は 0を返す。
 */
export const compareSystemName = (a, b, sortDir = config.DESC) => {
  const defaultSystemA = getDefaultSystemExp(a);
  const defaultSystemB = getDefaultSystemExp(b);
  if (defaultSystemA || defaultSystemB) {
    // デフォルトシステム名が含まれている場合は、大きい（昇順で後ろ）と判断するため、デフォルトシステムでないものを空文字にしてから比較する
    const tmpA = defaultSystemA || "";
    const tmpB = defaultSystemB || "";
    return compare(tmpA, tmpB, sortDir);
  } else {
    const tmpA = a || "";
    const tmpB = b || "";
    return compare(tmpA, tmpB, sortDir);
  }
};

/**
 * システム名のソートを行う。
 * @param {Array<Object>} items ソートを行うオブジェクトのリスト
 * @param {string} sortKey ソートを行うキー
 * @param {string} sortDir 昇順か降順か（デフォルトは降順）
 * @returns {Array<Object>} ソート後のリスト
 */
export const sortSystems = (items, sortKey, sortDir = config.DESC) => {
  if (items) {
    return [...items].sort((a, b) =>
      compareSystemName(
        (a && a[sortKey]) || "",
        (b && b[sortKey]) || "",
        sortDir
      )
    );
  }
  return items;
};
