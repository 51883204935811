<template>
  <!-- Dialog.vue は ProcessingIcon の次に表示 -->
  <b-modal
    id="modal-dialog"
    v-model="isShowModal"
    header-text-variant="light"
    :header-bg-variant="params.variant"
    centered
    no-stacking
    scrollable
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    style="z-index: 9000;"
  >
    <template #header>
      <div class="text-center w-100">
        {{ params.title }}
      </div>
    </template>

    <template #default>
      <div v-if="type === 'object'">
        <div
          class="m-4"
          style="font-weight: bold"
          v-html="message"
        />
        <div class="ml-5">
          <div
            v-for="(el, i) in messageList"
            :key="i"
            style="font-weight: bold"
          >
            <span>{{ el.label }}</span>
            <span>&nbsp;:&nbsp;</span>
            <span v-html="el.message" />
          </div>
        </div>
      </div>
      <div
        v-else
        class="m-4"
        style="font-weight: bold"
        v-html="message"
      />
    </template>

    <template #footer>
      <div class="text-center w-100">
        <b-button
          v-if="!params.okOnly"
          variant="outline-primary"
          @click="onClick($event, params.cancel)"
        >
          {{ params.cancelButtonText }}
        </b-button>
        <b-button
          variant="primary"
          @click="onClick($event, params.ok)"
          class="ml-4"
        >
          {{ params.okButtonText }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Modal from "./index";

export default {
  data() {
    return {
      isShowModal: false,
      params: {},
      message: "",
      messageList: "",
      type: "string",
    };
  },

  watch: {
    "params.text"(val) {
      if (val && typeof val === "object") {
        this.type = "object";
        this.message = val.mainMessage;
        this.messageList = val.messageList;
      } else {
        this.type = "string";
        this.message = val;
      }
    },
  },

  beforeMount() {
    Modal.event.config.globalProperties.$bus.on(
      "toggle",
      this.handleToggleEvent
    );
  },

  beforeUnmount() {
    Modal.event.config.globalProperties.$bus.off(
      "toggle",
      this.handleToggleEvent
    );
  },

  methods: {
    handleToggleEvent(data) {
      if (!data) {
        this.isShowModal = false;
        return;
      }

      if (data.state) {
        this.params = data.params;
        this.isShowModal = true;
      } else {
        this.isShowModal = false;
      }
    },

    onClick(event, button, source = "click") {
      if (button && typeof button === "function") {
        // 渡された関数を実行
        button(event, { source });
      }
      this.isShowModal = false;
    },
  },
};
</script>

<style>
</style>