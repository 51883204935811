import config from '../../share/config';

export default {
  namespaced: true,

  state: {
    headerType: 'none',
    headerTitleID: '',
    flowNavigationType: config.NAVIGATION_NONE,
    breadCrumbs: [],
    clientHeight: 0,
    isSkipStart: false,
    initLogin: false,
    mainContainerStyle: "common-main",
    viewProcessMode: config.PROCESS_IDLING,   // Repro 実行中・非実行中の判別
    processingIcon: false,
    isTimeoutLocked: false,
    cloudConnectorId: "",
    directMove: {},
  },

  mutations: {

    setHeaderType(state, payload) {
      state.headerType = payload;
    },

    setHeaderTitleID(state, payload) {
      state.headerTitleID = payload;
    },

    setFlowNavigationType(state, payload) {
      state.flowNavigationType = payload;
    },

    setBreadCrumbs(state, payload) {
      const index = state.breadCrumbs.findIndex(item => item.name === payload.name);
      // 1つ前のkey(name)が同じものかチェックする
      if (index !== -1 && index === (state.breadCrumbs.length - 1)) {
        state.breadCrumbs[index] = payload;
        state.breadCrumbs[index].isCurrent = true;
      }
      else {
        if (state.breadCrumbs && state.breadCrumbs.length > 0) {
          const oldleve = state.breadCrumbs[state.breadCrumbs.length - 1].level;
          const leve = payload.level;

          if (oldleve < leve) {
            state.breadCrumbs.push(payload);
          }
          else if (oldleve >= leve) {
            const count = (oldleve - leve) + 1;
            state.breadCrumbs.splice((leve - 1), count, payload);
          }
        }
        else {
          state.breadCrumbs.push(payload);
        }

        for (let i = 0, len = state.breadCrumbs.length; i < len; ++i) {
          state.breadCrumbs[i].isCurrent = false;
          if (i === (state.breadCrumbs.length - 1)) {
            state.breadCrumbs[i].isCurrent = true;
          }
        }
      }
    },

    setclientHeight(state, payload) {
      state.clientHeight = payload;
    },

    setIsSkipStart(state, payload) {
      state.isSkipStart = payload;
    },

    setViewProcessMode(state, payload) {
      state.viewProcessMode = payload;
    },

    setInitLogin(state, payload) {
      state.initLogin = payload;
    },

    setMainContainerStyle(state, payload) {
      state.mainContainerStyle = payload;
    },

    setProcessingIcon(state, payload) {
      state.processingIcon = payload;
    },

    setIsTimeoutLocked(state, payload) {
      state.isTimeoutLocked = payload;
    },

    setQueryParamCCID(state, payload) {
      state.cloudConnectorId = payload;
    },

    setDirectMove(state, payload) {
      state.directMove = payload;
    },

  },

  actions: {

    setHeaderType(context, payload) {
      context.commit('setHeaderType', payload);
    },

    setHeaderTitleID(context, payload) {
      context.commit('setHeaderTitleID', payload);
    },

    setFlowNavigationType(context, payload) {
      context.commit('setFlowNavigationType', payload);
    },

    setBreadCrumbs(context, payload) {
      context.commit('setBreadCrumbs', payload);
    },

    setclientHeight(context, payload) {
      context.commit('setclientHeight', payload);
    },

    setIsSkipStart(context, payload) {
      context.commit('setIsSkipStart', payload);
    },

    setViewProcessMode(context, payload) {
      context.commit('setViewProcessMode', payload);
    },

    setInitLogin(context, payload) {
      context.commit('setInitLogin', payload);
    },

    setMainContainerStyle(context, payload) {
      context.commit('setMainContainerStyle', payload);
    },

    setProcessingIcon(context, payload) {
      context.commit('setProcessingIcon', payload);
    },

    // 操作タイムアウトをカウントするかどうかのフラグを設定する
    setIsTimeoutLocked(context, payload) {
      context.commit('setIsTimeoutLocked', payload);
    },

    // query parameterで渡されたCCID情報を設定する。
    setQueryParamCCID(context, payload) {
      context.commit('setQueryParamCCID', payload);
    },

    setDirectMove(context, payload) {
      context.commit('setDirectMove', payload);
    },
  }
};

