<b-modal v-model="modal_requirement_100" size="md" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t(`individual.common_title_execution`) }}
      </template>
      <template
        v-if="phase === 'wait' || phase === 'areaSelect' || phase === 'updateWait' || phase === 'updateSuccess' || phase === 'usbDisconnect'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'success'">
        {{ $t('message.common_title_success') }}
      </template>
      <template
        v-if="phase === 'usbUnconnected' || phase === 'updateNotApplicable' || phase === 'vehicleAbnormality' || phase === 'updateFailed' || phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
          <div class="row no-gutters">
            <div class="col-12">
              <div v-if="phase === 'areaSelect'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_100_TXT_2`) }}</div>
                <div class="row no-gutters">
                  <div class="w-100">
                    <div style="max-height: 265px; overflow-y: auto;">
                      <!-- テーブル部品 -->
                      <cstm-table id="cstm-table" useStripeStyle hasTabs rowSelect initSortDir="asc" :items="bodyItems"
                        :columns="systemTableColumns" type="simple" @rowButtonClicked="executeMapSelect($event)"
                        usePopup>
                        <template v-slot:rowButton>{{ $t(`${i18nWsKey}.label_table_select`) }}</template>
                      </cstm-table>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="phase === 'wait'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_100_TXT_1`) }}</div>
              </div>
              <div v-if="phase === 'updateWait'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ progress }}</div>
              </div>
              <div v-if="phase === 'updateSuccess'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_100_TXT_4_101A`) }}</div>
              </div>
              <div v-if="phase === 'usbDisconnect'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_100_TXT_5_1000`) }}</div>
              </div>
              <div v-if="phase === 'success'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_100_TXT_6_1001`) }}</div>
              </div>
              <div v-if="phase === 'usbUnconnected'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_100_TXT_7_1001`) }}</div>
              </div>
              <div v-if="phase === 'updateNotApplicable'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_100_TXT_8_1003`) }}</div>
              </div>
              <div v-if="phase === 'vehicleAbnormality'" class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ $t(`${i18nWsKey}.REQ_WS_100_TXT_9`) }}</div>
              </div>
              <div v-if="phase === 'updateFailed'" class="text-left align-middle">
                <div class="box-margin-right work-support-message ">{{ updateFailureReference }}</div>
              </div>
              <div v-if="phase === 'failed'" class="text-left align-middle">
                <div class="box-margin-right work-support-message ">{{ $t(`individual.error_communication_error`) }}
                </div>
              </div>
            </div>
          </div>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="phase === 'wait'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="exit(0)" class="mx-2">
          {{ $t('individual.label_button_finish') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="executeStartProcess" class="mx-2">
          {{ $t('individual.label_button_start') }}
        </b-button>
      </template>
      <template v-if="phase === 'updateSuccess'">
        <b-button size="sm" variant="outline-primary" :disabled="isLoading" @click="updateSuccess" class="mx-2">
          {{ $t('individual.common_button_no') }}
        </b-button>
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="mapSelect" class="mx-2">
          {{ $t('individual.common_button_yes') }}
        </b-button>
      </template>
      <template v-if="phase === 'usbDisconnect'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="usbDisconnect" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template
        v-if="phase === 'success' || phase === 'usbUnconnected' || phase === 'updateNotApplicable' || phase === 'vehicleAbnormality' || phase === 'updateFailed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exit(0)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" :disabled="isLoading" @click="exit(1)" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>