export default {
  namespaced: true,

  state: {
    is_bin_mot_reuse: false,
    // reproSystems: [],
    systems: [],
    download: {},
    preProgram: {},
    postProgram: {},
    resultStatus: {},
  },

  mutations: {
    setProgress(state, payload) {
      const systems = [];
      if (payload.systems) {
        payload.systems.forEach(element => {
          if (!element.already_updated) {
            systems.push({
              system_name: element.name || element.system_name,
              repro_progress: Math.round(element.repro_progress),
              remaining_repro_time: element.remaining_repro_time || 0
            });
          }
        });
      }
      state.systems = systems;
      state.download = payload.download;
      state.preProgram = payload.preProgram;
      state.postProgram = payload.postProgram;
    },

    setPFCReuseStatus(state, payload) {
      state.is_bin_mot_reuse = payload;
    },

    setResultStatus(state, payload) {
      state.resultStatus = payload;
    },
  },

  actions: {
    setResult(context, payload) {
      context.commit('setResult', payload);
    },

    setProgress(context, payload) {
      context.commit('setProgress', payload);
    },

    setPFCReuseStatus(context, payload) {
      context.commit('setPFCReuseStatus', payload);
    },

    setResultStatus(context, payload) {
      context.commit('setResultStatus', payload);
    },
  }
};
