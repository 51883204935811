/** 各フェーズ定義 */
export const PHASE = {
  START: 'start',
  WRITING: 'writing',
  FAILED: 'failed',
  SUCCESS: 'success'
};

export const DESTINATION = {
  DOMESTIC: 'Domestic',
  NA: 'NorthAmerica',
  CH: 'China',
  OTHER: 'Other'
};