// setInterval wrapper function of javascript . 
// setIntervalのラッパー関数
// setIntervalの内部で実行された関数の処理を待たず、次タイマー間隔で処理が実行されます。
// ※注意１：setIntervalの内部処理時間がタイマーの間隔を超えた場合、次の処理が実行されます。
// ※注意２：本タイマー処理は setInterval 関数が呼ばれた場合、１回目の処理実行は設定されたタイマー間隔後に実行されます。

export default {
  install(vue) {
    vue.config.globalProperties.$intervals = [];
    vue.config.globalProperties.$setInterval = (func, intervalMilliSec) => {
      if (typeof (process.env.VUE_APP_DISABLE_SET_INTERVAL) !== 'undefined') {
        return null;
      }
      const id = setInterval(() => {
        func();
      }, Number(intervalMilliSec));
      vue.config.globalProperties.$intervals.push(id);
      return id;
    };
    vue.config.globalProperties.$clearInterval = (id) => {
      if (id) {
        clearInterval(id);
        vue.config.globalProperties.$intervals = vue.config.globalProperties.$intervals.filter((i) => i !== id);
      }
    };
    vue.config.globalProperties.$clearAllIntervals = () => {
      vue.config.globalProperties.$intervals.forEach(clearInterval);
      vue.config.globalProperties.$intervals = [];
    };
  }
};