<b-modal v-model="modal_requirement_076" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start'">
        {{ $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'list' || phase === 'outputFile' || phase === 'alarmDetail' || phase === 'detail'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 一覧表示 -->
        <template v-if="phase === 'list'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 300px; overflow-y: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs useSort initSortDir="asc" currentSortkey="itemID"
                  :items="bodyItems" :columns="listTableColumns" type="simple">
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- 詳細表示 -->
        <template v-if="phase === 'detail' || phase === 'alarmDetail'">
          <div class="row no-gutters">
            <div class="w-100">
              <div style="max-height: 395px; overflow: auto;">
                <!-- テーブル部品 -->
                <cstm-table id="cstm-table" useStripeStyle hasTabs useSort initSortDir="asc" currentSortkey="itemID"
                  :items="bodyItems" :columns="detailTableColumns" type="simple" class="text-nowrap mt-2 ml-2"
                  fixedScroll>
                </cstm-table>
              </div>
            </div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 一覧画面 -->
      <template v-if="phase === 'list'">
        <b-button size="sm" variant="primary" @click="onclickSave" class="col-5 mt-2 mr-2">
          {{ $t(`individual.label_button_save_ws76`) }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onAlarmDetailClicked"
          :class="['col-5 mt-2 mr-2', checkButtonClassAlarmDetail]" :disabled="isDisableAlarmDetail">
          {{ $t(`individual.label_button_alarm`) }}
        </b-button>
        <b-button size="sm" variant="primary" @click="onDetailClicked" :class="['col-5 mt-2 mr-2', checkButtonClassDetail]"
          :disabled="isDisableDetail">
          {{ $t(`individual.label_button_detail`) }}
        </b-button>
        <b-button size="sm" variant="primary" @click="closeWizard" class="col-5 mt-2 mr-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 詳細 -->
      <template v-if="phase === 'detail' || phase === 'alarmDetail'">
        <b-button size="sm" variant="primary" @click="clickBack" class="mx-2">
          {{ $t('message.common_button_back') }}
        </b-button>
      </template>
      <!-- 異常終了(エラー) -->
      <template v-if="phase === 'failed'">
        <b-button size="sm" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>