<b-modal
  v-model="modal_requirement_074"
  size="md"
  :header-bg-variant="headerColor"
  header-text-variant="light"
  no-close-on-backdrop
  no-close-on-esc
  centered
  no-stacking
>
  <template v-slot:header>
    <div class="text-center w-100">
      <template v-if="phase === 'start' || phase === 'cancel'">{{ 
        $t('message.common_title_confirmation') }}
      </template>
      <template v-if="phase === 'execute' || phase === 'beforeStart'">{{ 
        $t('individual.common_title_execution') }}
      </template>
      <template v-if="phase === 'success'">{{ 
        $t('message.common_title_success') }}
      </template>
      <template v-if="phase === 'failed' || phase === 'timeout' || phase === 'error'">{{ 
        $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <ShowLessBOverlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 開始 -->
        <template v-if="phase === 'beforeStart'"></template>
        <!-- 待機画面 -->
        <template v-if="phase === 'start'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_74_TXT_1`) }}
            </div>
          </div>
        </template>
        <!-- 実行中 -->
        <template v-if="phase === 'execute'">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="text-left align-middle">
                <div class="box-margin-right work-support-message">{{ 
                  $t(`${i18nWsKey}.REQ_WS_74_TXT_2_0001`) }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- 正常終了 -->
        <template v-if="phase === 'success'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_74_TXT_3_0002`) }}
            </div>
          </div>
        </template>
        <!-- 異常終了(タイムアウト) -->
        <template v-if="phase === 'timeout'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_74_TXT_6`) }}
            </div>
          </div>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              displayInfo }}
            </div>
          </div>
        </template>
        <!-- 異常終了(通信エラー) -->
        <template v-if="phase === 'error'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_74_TXT_7`) }}
            </div>
          </div>
        </template>
        <!-- キャンセル -->
        <template v-if="phase === 'cancel'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{ 
              $t(`${i18nWsKey}.REQ_WS_74_TXT_4`) }}
            </div>
          </div>
        </template>
      </div>
    </ShowLessBOverlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <!-- 待機画面 -->
      <template v-if="phase === 'start'">
        <b-button
          :disabled="isLoading"
          size="sm"
          variant="outline-primary"
          @click="onStartCancelClicked"
          class="mx-2"
        >{{ 
          $t('message.common_button_cancel') }}
        </b-button>
        <b-button
          :disabled="isLoading"
          size="sm"
          variant="primary"
          @click="onStartOkClicked"
          class="mx-2"
        >{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- キャンセル -->
      <template v-if="phase === 'cancel'">
        <b-button
          :disabled="isLoading"
          size="sm"
          variant="outline-primary"
          @click="onCancelCancelClicked"
          class="mx-2"
        >{{ 
          $t('message.common_button_cancel') }}
        </b-button>
        <b-button 
          :disabled="isLoading"
          size="sm"
          variant="primary"
          @click="onCancelOkClicked"
          class="mx-2"
        >{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 正常終了 -->
      <template v-if="phase === 'success'">
        <b-button
          :disabled="isLoading"
          size="sm"
          variant="primary"
          @click="onSuccessOkClicked"
          class="mx-2"
        >{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 異常終了 -->
      <template v-if="phase === 'failed'">
        <b-button 
          :disabled="isLoading"
          size="sm"
          variant="primary"
          @click="onErrOkClicked"
          class="mx-2"
        >{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- タイムアウトエラー -->
      <template v-if="phase === 'timeout'">
        <b-button 
          :disabled="isLoading"
          size="sm"
          variant="primary"
          @click="onTimeoutErrorOkClicked"
          class="mx-2"
        >{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
      <!-- 通信エラー -->
      <template v-if="phase === 'error'">
        <b-button 
          :disabled="isLoading"
          size="sm"
          variant="primary"
          @click="onCommunicationErrorOkClicked"
          class="mx-2"
        >{{ 
          $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>
