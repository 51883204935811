<b-modal v-model="modal_requirement_139" :size="modalSize" :header-bg-variant="headerColor" header-text-variant="light"
  no-close-on-backdrop no-close-on-esc centered no-stacking>
  <template v-slot:header>
    <div class="text-center w-100">
      <!-- 一覧表示 -->
      <template v-if="phase === 'listView'">
        {{ $t('message.common_title_confirmation') }}
      </template>
      <!-- プロジェクト保存 -->
      <template v-if="phase === 'projectData'">
        {{ $t(`individual.label_input`) }}
      </template>
      <!-- 共通エラー -->
      <template v-if="phase === 'failed'">
        {{ $t('message.common_title_error') }}
      </template>
    </div>
  </template>

  <template v-slot:default>
    <b-overlay :show="isLoading" opacity="0.3" spinner-variant="primary">
      <div class="container-fluid" style="min-height: 140px;">
        <!-- 一覧表示 -->
        <template v-if="phase === 'listView'">
          <custom-table useStripeStyle hasTabs :items="displayInfo" :columns="tableColumns" class="mt-2 ml-2"
            type="simple" usePopup rowSelect>
          </custom-table>
        </template>
        <!-- プロジェクトデータ保存 -->
        <template v-if="phase === 'projectData'">
          <project-data ref="projectData" :vin="vin" :vehicleName="vehicleName" :displayProjectID="displayProjectID"
            :registrationNumber="registrationNumber" :functionName="functionName" :dataName="dataName" :memo="memo"
            :hideRegistrationNumber="hideRegistrationNumber"></project-data>
        </template>
        <!-- 異常終了(エラー) -->
        <template v-if="phase === 'failed'">
          <div class="w-100">
            <div class="box-margin-right work-support-message" style="white-space: break-spaces;">{{
              $t(`individual.error_communication_error`) }}</div>
          </div>
        </template>
      </div>
    </b-overlay>
  </template>

  <template v-slot:footer>
    <div class="w-100 text-center ">
      <template v-if="phase === 'listView'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="screenMovement" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <template v-if="phase === 'projectData'">
        <b-button size="sm" :disabled="isLoading" variant="outline-primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_cancel') }}
        </b-button>
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="callSaveEvent" class="mx-2">
          {{ $t('message.common_button_save') }}
        </b-button>
      </template>
      <template v-if="phase === 'failed'">
        <b-button size="sm" :disabled="isLoading" variant="primary" @click="closeWizard" class="mx-2">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </template>
    </div>
  </template>
</b-modal>