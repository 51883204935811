<div class="tree" v-bind:style="isAutoHeight? {'height': 'auto'} : {'height': maxHeight + 'px'}">
  <ul class="list-group item-cursor">
    <tree-item
      ref="treeViewNode"
      :item="treeData"
      :level="level"
      :showHeadElements="showHeadElements"
      @expanded-item="expanded"
      @node-Selected="selected"
    ></tree-item>
  </ul>
</div>