import individualDiagnose from '../../../../../share/util/individualDiagnose';
import individualWorkSupportUtil from '../../../../../share/util/individualWorkSupportUtil';
import session from '../../../../../share/session';
import { REQUEST_STATUS_COMPLETED, REQUEST_STATUS_FAILED } from '../../../../../share/util/api/polling';
import { PHASE, STANDBY_TYPE } from './constants';
import error from '../../../../../common/nodejs/errors';
import { showUnexpectedError, validateErrorResult, historyInfoTableDataConvert } from './utils';

/**
 * プリクラッシュブレーキの履歴を取得
 * @param {object} self this インスタンス
 */
export async function readHistoryData(self) {
  // EUCキーの取得
  const systemId = individualDiagnose.getCurrentSystemId(self);
  const token = session.getToken();
  // 開始処理・セキュリティアクセス (要求)（バックエンドAPI_POST） Getのポーリング処理も行う
  const response = await individualWorkSupportUtil.pollingWspApi(
    self,
    self.netAppId,
    systemId,
    self.workSupportId,
    {
      id_token: token.id_token,
      type: 'standby',
      is_special: true,
      standbyType: self.standbyType
    },
    // エラー発生のコールバック
    (errorType) => { errorType; showUnexpectedError(self, self.standbyType); },
    // POST API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { validateErrorResult(result, self, self.standbyType); },
    // GET API 結果が異常の場合（handler.validate でエラーが発生した場合）のコールバック
    (result) => { validateErrorResult(result, self, self.standbyType); },
  );
  if (response) {
    // 履歴結果を結合する
    Object.assign(self.historyData, response?.data.user_data);

    // ポーリングが正常に完了した場合
    // standbyTypeが｢HALT・FAIL履歴｣の場合は、結果に関わらず次の画面への遷移処理を行う
    if (self.standbyType === STANDBY_TYPE.HALT_FAIL) {
      nextPhase(self);
      return;
    }

    switch (response.data.status) {
      // ステータス: 完了
      case REQUEST_STATUS_COMPLETED:
        switch (self.standbyType) {
          // standbyTypeが｢作動履歴の発生日時｣の場合、｢HALT・FAIL履歴｣にして再読出し
          case STANDBY_TYPE.CONTROL_HISTORY:
            self.standbyType = STANDBY_TYPE.HALT_FAIL;
            break;
          // ｢IG ON回数(アイサイトバージョン3の読出し)｣の場合、次の画面に遷移
          case STANDBY_TYPE.IG_ON: {
            nextPhase(self);
            break;
          }
        }
        break;
      // ステータス: 失敗
      case REQUEST_STATUS_FAILED:
        // 履歴が存在しないエラーの場合
        if (response.data.async_code === error.WSP_ES_NO_HISTORY_ERROR) {
          switch (self.standbyType) {
            // standbyTypeが｢作動履歴の発生日時｣の場合、｢HALT・FAIL履歴｣にして再読出し
            case STANDBY_TYPE.CONTROL_HISTORY:
              self.historyData = {};
              self.standbyType = STANDBY_TYPE.HALT_FAIL;
              break;
            // standbyTypeが｢IG ON回数(アイサイトバージョン3の読出し)｣の場合、失敗画面に遷移
            case STANDBY_TYPE.IG_ON: {
              self.phase = PHASE.FAILED;
              break;
            }
          }
        } else {
          showUnexpectedError(self, self.standbyType);
        }
        break;
      default:
        showUnexpectedError(self, self.standbyType);
        break;
    }
  }
}

/**
 * 次の画面への遷移処理
 * @param {object} self this インスタンス
 */
function nextPhase(self) {
  const historyArr = historyInfoTableDataConvert(self);
  // 履歴が存在する場合、履歴表示画面に遷移
  if (historyArr.length > 0) {
    self.phase = PHASE.HISTORY_VIEW;
    self.displayHistoryInfo = [];
    self.$nextTick(() => {
      self.displayHistoryInfo = historyArr;
    });
  } else {
    // 履歴が存在しない場合、失敗画面に遷移
    self.phase = PHASE.FAILED;
  }
}