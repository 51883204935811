// State class for file resource.

import api from '@/common/api';
import config from '../config';
import handler from '../util/apiResultValidator';

// 店舗種別とリソースIDのマッピング
const shopGroupList = [
  { value: config.SHOP_GROUP_JP_HEAD_OFFICE, text: 'message.option_shop_group_jp_head_office' },
  { value: config.SHOP_GROUP_JP_DEALER, text: 'message.option_shop_group_jp_dealer' },
  { value: config.SHOP_GROUP_JP_TRADE_SALES, text: 'message.option_shop_group_jp_trade_sales' },
  { value: config.SHOP_GROUP_JP_EXCEPT_DISTRIBUTOR, text: 'message.option_shop_group_jp_except_distributor' },
  { value: config.SHOP_GROUP_NA_DIST_HQ, text: 'message.option_shop_group_na_dist_hq' },
  { value: config.SHOP_GROUP_NA_DIST_BRANCH, text: 'message.option_shop_group_eu_dist_branch' },
  { value: config.SHOP_GROUP_NA_DEALER, text: 'message.option_shop_group_na_dealer' },
  { value: config.SHOP_GROUP_NA_SERVICE_SHOP, text: 'message.option_shop_group_eu_service_shop' },
  { value: config.SHOP_GROUP_NA_AFTERMARKET_SHOP, text: 'message.option_shop_group_na_aftermarket_shop' },
  { value: config.SHOP_GROUP_NA_GST, text: 'message.option_shop_group_na_gst' },
  { value: config.SHOP_GROUP_NA_OTHER, text: 'message.option_shop_group_na_other' },
  { value: config.SHOP_GROUP_EU_DIST_HQ, text: 'message.option_shop_group_eu_dist_hq' },
  { value: config.SHOP_GROUP_EU_DIST_BRANCH, text: 'message.option_shop_group_eu_dist_branch' },
  { value: config.SHOP_GROUP_EU_DIST_INDEPENDENT, text: 'message.option_shop_group_eu_dist_independent' },
  { value: config.SHOP_GROUP_EU_DEALER, text: 'message.option_shop_group_eu_dealer' },
  { value: config.SHOP_GROUP_EU_SERVICE_SHOP, text: 'message.option_shop_group_eu_service_shop' },
  { value: config.SHOP_GROUP_EU_OTHER, text: 'message.option_shop_group_eu_other' },
  { value: config.SHOP_GROUP_OTHER_SERVICE_DEPERTMENT, text: 'message.option_shop_group_other_service_depertment' },
  { value: config.SHOP_GROUP_OTHER_ENGINEERING_DEPERTMENT, text: 'message.option_shop_group_other_engineering_depertment' },
  { value: config.SHOP_GROUP_OTHER_OTHER, text: 'message.option_shop_group_other_other' },
];

export default {
  namespaced: true,

  state: {
    specialCompanies: [],
    languages: config.LANGUAGE_LIST.map(e => { return { value: e.value, label: 'message.common_language_' + e.value }; }),
    constLangList: config.LANGUAGE_LIST,
    groups: [],
    areas: [],
    groupAreas: [],
    regions: [],
    shortConnectors: [],
    translations: []
  },

  mutations: {

    setResource(state, payload) {
      if (payload.resources) {
        const obj = JSON.parse(payload.resources);

        // 特殊対応が必要な会社の設定
        state.specialCompanies = obj.special_handling_companies;

        // グループ情報の設定
        state.groups = [];
        obj.groups.forEach((group) => {
          switch (group) {
            case config.REGION_EUROPE:
              state.groups.push({ value: group, id: 'message.common_region_europe' });
              break;
            case config.REGION_NORTH_AMERICA:
              state.groups.push({ value: group, id: 'message.common_region_north_america' });
              break;
            case config.REGION_DOMESTIC:
              state.groups.push({ value: group, id: 'message.common_region_domestic' });
              break;
          }
        });

        // エリア情報の設定
        state.areas = [];
        obj.areas.forEach((area) => {
          switch (area) {
            case 'Europe':
              state.areas.push({ value: area, id: 'message.common_area_europe' });
              break;
            case 'NorthCentralAmerica':
              state.areas.push({ value: area, id: 'message.common_area_north_central_america' });
              break;
            case 'SouthAmerica':
              state.areas.push({ value: area, id: 'message.common_area_south_america' });
              break;
            case 'Asia':
              state.areas.push({ value: area, id: 'message.common_area_asia' });
              break;
            case 'Pacific':
              state.areas.push({ value: area, id: 'message.common_area_pacific' });
              break;
            case 'Africa':
              state.areas.push({ value: area, id: 'message.common_area_africa' });
              break;
            case 'Other':
              state.areas.push({ value: area, id: 'message.common_area_other' });
              break;
            case 'Japan':
              state.areas.push({ value: area, id: 'message.common_area_japan' });
              break;
          }
        });

        // グループ情報とエリアの関係情報
        state.groupAreas = obj.group_area_relations;

        // regionとショップの区分関係情報
        state.regions = [];
        obj.regions.forEach((region) => {
          for (let i = 0; i < region.shop_groups.length; i++) {
            const index = shopGroupList.findIndex(item => item.value === region.shop_groups[i]);
            if (index !== -1) {
              region.shop_groups[i] = shopGroupList[index];
            }
          }
        });
        state.regions = obj.regions;

        // ショートコネクタ情報を設定
        state.shortConnectors = obj.short_connectors;

        // 翻訳言語情報一覧を設定
        state.translations = obj.translation_languages;
      }
    },

  },

  actions: {

    // サーバからリソースデータを取得する
    async getResourceData(context, reload = false) {

      let update = false;
      if (reload) {
        update = true;
      }
      else if (context.state.groups.length <= 0) {
        update = true;
      }

      // リソース情報の取得
      if (update) {
        const params = {
          target: 'constant'
        };
        const response = await api.getParamCall(config.ADMIN, '/fileResources', params);

        handler.validate(
          handler.validateTypes.response, response, null, null,
          () => {
            // 処理が成功したときのコールバック処理
            context.commit('setResource', response.data);
          },
          () => {
            // 処理が失敗したときのコールバック処理
            return response.data;
          }
          , null, false);
      }
    },

    // 特別対応が必要かどうかを判定
    needSpecialHandling(context, companyId) {
      const companies = context.state.specialCompanies;
      if (!companies || companies.length <= 0) return false;
      const index = companies.findIndex(el => companyId === el.id);
      return index !== -1;
    },

    // SBRユーザーか判定
    checkSBRUser(context, companyId) {
      let result = false;
      const companies = context.state.specialCompanies;
      if (!companies || companies.length <= 0) return false;
      const index = companies.findIndex(el => companyId === el.id);
      if (index !== -1) {
        if (companies[index].key === config.SBR_COMPANY) { result = true; }
      }
      return result;
    },
  }

};