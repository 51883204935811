import api from '../../common/api';
import handler from '../../share/util/apiResultValidator';
import config from '../../share/config';


export default {
  namespaced: true,

  state: {
    // Mac鍵搭載フラグ
    isMacSupport: false
  },

  mutations: {
    setMacSupport(state, payload) {
      state.isMacSupport = payload;
    },
  },

  actions: {
    async getMacVisibility(context, arg) {
      // Response結果をオブジェクトに入れる
      const getMacResponse = {
        errorResult: null
      };
      if (arg.netAppId !== "" && !arg.isTestMode) {
        const endPoint = '/macMenuVisibility/' + arg.netAppId;
        const res = await api.getCall(config.MAC, endPoint);
        handler.validate(
          handler.validateTypes.all,
          res, null, null,
          () => {
            // 処理が成功したときデータを入れる
            context.commit('setMacSupport', res.data.menu_visibility);
          },
          (result) => {
            // 処理が失敗してる場合
            getMacResponse.errorResult = result;
          }, null, false
        );
        return getMacResponse;
      }
      else {
        context.commit('setMacSupport', false);
        return getMacResponse;
      }

    }
  }
};
