<div class="container-fluid ml-n2">

<!-- 鍵登録実行中  -->
<b-modal v-model="modal_key_registration_progress" size="lg" header-bg-variant="secondary" header-text-variant="light"
  no-stacking centered no-close-on-backdrop no-close-on-esc hide-footer>
  <template v-slot:header>
    <div class="text-center w-100">{{ $t('mac.executing') }}</div>
  </template>

  <template v-slot:default>
    <div class="container-fluid mt-3 mb-3">
      <processing-view :processing="modelUpdateProcessing" class="update-processing-view"></processing-view>
    </div>
    <div class="text-center w-100 mt-3">{{ $t('mac.auth_key_register_in_progress') }}</div>
    <div class="text-center w-100 mt-1">{{ $t('mac.plz_wait') }}</div>
  </template>
</b-modal>

<!-- IG-ON確認  -->
<b-modal v-model="modal_key_registration_ig_on" size="lg" header-bg-variant="secondary" header-text-variant="light"
  no-stacking centered no-close-on-backdrop no-close-on-esc>
  <template v-slot:header>
    <div class="text-center w-100">
      {{ $t('message.common_title_confirmation') }}
    </div>
  </template>

  <template v-slot:default>
    <div class="m-4">
      <div class="text-center w-100">
        <div>
          {{ $t('mac.turn_on_ig_SW_vehicle') }}
        </div>
        <div class="checkbox-size-lg">
          <b-form-checkbox v-model="turnIgOnChecked">
            {{ $t('mac.confirm_ignition_is_on') }}
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </template>

  <template v-slot:footer>
    <div class="text-center w-100">
      <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
        v-on:click="macRegisterEventOnIgnition('ok')">
        {{ $t('message.common_button_ok') }}
      </b-button>
    </div>
  </template>
</b-modal>

<!-- IG-ON再確認 -->
<b-modal v-model="modal_key_registration_ig_on_retry" size="lg" header-bg-variant="secondary" header-text-variant="light"
  no-stacking centered no-close-on-backdrop no-close-on-esc>
  <template v-slot:header>
    <div class="text-center w-100">
      {{ $t('message.common_title_confirmation') }}
    </div>
  </template>

  <template v-slot:default>
    <div class="m-4">
      <div class="text-center w-100">
        <div>{{ $t('mac.turn_on_ig_SW_vehicle') }}</div>
        <div>{{ $t('mac.click_retry') }}</div>
        <div class="checkbox-size-lg">
          <b-form-checkbox v-model="turnIgOnChecked">
            {{ $t('mac.confirm_ignition_is_on') }}
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </template>

  <template v-slot:footer>
    <div class="text-center w-100">
      <b-button size="sm" variant="outline-primary"
        v-on:click="macRegisterEventOnIgnition('cancel')" class="ml-2 mr-2">
        {{ $t('mac.quit') }}
      </b-button>
      <b-button size="sm" variant="primary" :disabled="!turnIgOnChecked"
        v-on:click="macRegisterEventOnIgnition('ok')" class="ml-2 mr-2">
        {{ $t('mac.retry') }}
      </b-button>
    </div>
  </template>
</b-modal>

<!-- 鍵登録失敗 -->
<b-modal v-model="modal_key_registration_err_failed" size="lg" header-bg-variant="danger" header-text-variant="light"
  no-stacking centered no-close-on-backdrop no-close-on-esc>
  <template v-slot:header>
    <div class="text-center w-100">
      {{ $t('message.common_title_error') }}
    </div>
  </template>

  <template v-slot:default>
    <div class="text-center m-4">
      <div v-html="$t(authKeyStatusInfo.error_message_key, [authKeyStatusInfo.comm_target_ecu])"></div>
      <div v-if="authKeyStatusInfo.error_code == errorCodes.MAC_SPECIFIC_DTC_ERROR_CODE || authKeyStatusInfo.error_code == errorCodes.FAILED_ERROR_CODE">
        <br>
      </div>
      <table class="table-align">
        <div class="text-left" v-for="system in authKeyStatusInfo.systems">
          <!-- 診断通信エラー -->
          <td class="d-flex" v-if="authKeyStatusInfo.error_code == errorCodes.DIAG_COMM_ERROR_CODE">
            <div>{{ system.system_name }}</div>
            <div>&nbsp;:&nbsp;{{ system.last_error_response }}</div>
          </td>
          <!-- 機器間認証用鍵登録 異常通知 -->
          <table class="table-align" v-if="authKeyStatusInfo.error_code == 7027">
            <div class="text-left">
              <br>
              <div>{{ system.ecu_status }}</div>
              <br>
            </div>
          </table>
        </div>
      </table>
    </div>
  </template>

  <template v-slot:footer>
    <div class="text-center w-100">
      <b-button size="sm" variant="primary" v-on:click="onOkButtonClick(authKeyStatusInfo.error_code)">
        {{ $t('message.common_button_ok') }}
      </b-button>
    </div>
  </template>
</b-modal>

<!-- 鍵登録完了 -->
<b-modal v-model="modal_key_registration_completed" size="lg" header-bg-variant="success" header-text-variant="light"
  no-stacking centered no-close-on-backdrop no-close-on-esc>
  <template v-slot:header>
    <div class="text-center w-100">
      {{ $t('message.common_title_success') }}
    </div>
  </template>

  <template v-slot:default>
    <div class="text-center m-4" v-html="$t('mac.auth_key_register_completed')">
    </div>
  </template>

  <template v-slot:footer>
    <div class="text-center w-100">
      <b-button size="sm" variant="primary" v-on:click="onOkButtonClick(errorCodes.SUCCESS)">
        {{ $t('message.common_button_ok') }}
      </b-button>
    </div>
  </template>
</b-modal>
</div>